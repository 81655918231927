@font-face {
    src: url("./../font/DubaiW23-Regular.ttf");
    font-family: "Dubai";
    font-weight: 400;
}

@font-face {
    src: url("./../font/DubaiW23-Bold.ttf");
    font-family: "Dubai";
    font-weight: 700;
}

@font-face {
    src: url("./../font/DubaiW23-Light.ttf");
    font-family: "Dubai";
    font-weight: 300;
}

@font-face {
    src: url("./../font/DubaiW23-Medium.ttf");
    font-family: "Dubai";
    font-weight: 600;
}
[lang="ar"] p,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
span,
a,
input,
button,
p {
    font-family: "dubai";
    font: "dubai" !important;
}
[lang="ar"] .top__navbar {
    direction: rtl;
}
[lang="ar"] .top__navbar .phone .text {
    margin-right: 10px;
}
[lang="ar"] .shipping-v2 h3 {
    font-size: 16px;
    font-weight: 800;
    font: "dubai";
}
[lang="ar"] .shipping-v2 p {
    font-size: 12px;
    font-weight: 600;
}
[lang="ar"] .page-login {
    direction: rtl;
}
[lang="ar"] .page-login .social a i {
    margin-left: 5px;
}
[lang="ar"] .page-login form .control-label {
    text-align: right;
}
[lang="ar"] .btn-left {
    float: left;
}
[lang="ar"] .btn {
    font: 700 16px/38px "Roboto Slab";
}
[lang="ar"] .englisg-text {
    direction: ltr;
}
[lang="ar"] .englisg-text::placeholder {
    direction: rtl !important;
}
[lang="ar"] .page-login h3:after {
    right: 0;
}
[lang="ar"] .auth__dropdown .dropdown-menu li {
    text-align: right;
}
[lang="ar"] .cart-list .total span.right {
    float: left;
}
[lang="ar"] .cart-list .total span {
    float: right;
}
[lang="ar"] .qty .qtyspan {
    float: left;
}
[lang="ar"] table {
    direction: rtl;
}
[lang="ar"] table.table.cart-table td.product-quantity .quantity .plus {
    right: 31px;
}
[lang="ar"] table.table.cart-table td.product-quantity .quantity .minus {
    left: 80px;
}
[lang="ar"] .info-order .product-order .left {
    float: right;
}
[lang="ar"] .info-order .product-order .right {
    float: left;
}
[lang="ar"] .title-brand {
    text-align: center;
}
[lang="ar"] .checkout-address {
    direction: rtl;
}
[lang="ar"] .main__nav {
    direction: rtl;
}
[lang="ar"] .main__nav .navbar__items {
    flex: none;
    text-align: left;
}
[lang="ar"] .main__nav .navbar__items .cart.hover-menu {
    margin-right: 15px;
}
[lang="ar"] .main__nav .user__icon {
    margin-left: 0;
}
[lang="ar"] .alert {
    direction: rtl;
}
[lang="ar"] .close__icon-font {
    left: 10px;
}
[lang="ar"] .language__item img {
    margin-left: 10px;
}
[lang="ar"] #topbar .dropdown {
    padding-right: 24px;
}
[lang="ar"] .search__input input.search__input-field {
    padding: 13px 4px 13px 10px;
    direction: rtl;
}
[lang="ar"] .search__box {
    padding-left: 18px;
}
[lang="ar"] .list-menu {
    left: 0px !important;
    top: 54px;
}
[lang="ar"] .sub__menu .fa {
    direction: rtl;
    margin-left: 10px;
}
[lang="ar"] .dropdown-menu-right {
    right: auto;
    left: 0;
}
[lang="ar"] .navbar__search .search__input {
    margin-left: 144px;
}
[lang="ar"] .search__input .search__btn {
    left: 0;
}
[lang="ar"] .open > .dropdown-menu {
    display: block;
    direction: rtl;
    text-align: right;
}
[lang="ar"] .language__dropdown {
    left: 0;
}
[lang="ar"].banner-home3 .text h4 {
    font: 300 24px/30px "dubai";
}
[lang="ar"].banner-home3 .text h3 {
    font: 500 60px/70px "dubai";
}
[lang="ar"].banner-home3 .text p {
    font: 300 24px/30px "dubai";
}
[lang="ar"] #footer .title-footer {
    font: 400 14px "dubai";
}
[lang="ar"] .service__provider-jumbotron {
    direction: rtl;
}
[lang="ar"] .jumbotron__btn {
    margin-right: 15px;
}
[lang="ar"] #secondary {
    direction: rtl;
}
[lang="ar"] #primary {
    direction: rtl;
}
[lang="ar"] .widget .widget-title:after {
    right: 0px;
}
[lang="ar"] .dropdown__sub-menu {
    left: 100%;
    right: auto;
    direction: rtl;
    padding-right: 0;
}
[lang="ar"] .tc-arabic {
    direction: rtl;
    line-height: 35px !important;
}

[lang="ar"] .search__btn {
    right: auto;
    left: 0;
}

[lang="ar"] .search.navbar__search {
    margin-left: 20px;
}

[lang="ar"] .cart-list.list-menu {
    left: 0;
    right: auto;
}

[lang="ar"] .cart-product-image {
    position: absolute;
    right: 0;
}

[lang="ar"] .categories__heading .row {
    display: flex;
    flex-direction: row-reverse;
}

[lang="ar"] .categories__heading .col-xs-7 {
    text-align: right;
}
[lang="ar"] .categories__heading .col-xs-5 {
    text-align: left;
}

[lang="ar"] .title-footer {
    text-align: right;
}

[lang="ar"] .list-footer,
[lang="ar"] .arabic__row {
    text-align: right !important;
    font-family: "dubai";
}

[lang="ar"] .thead-light th {
    text-align: right !important;
}
[lang="ar"] .select__diretction {
    direction: ltr;
}

[lang="ar"] table.table.cart-table td.product-quantity .quantity .minus {
    left: 60px !important;
}

[lang="ar"] .cart-table th:first-of-type {
    text-align: center;
}

[lang="ar"] .cart-table th {
    text-align: right;
}

[lang="ar"] .ar__text-left {
    text-align: left !important;
}

[lang="ar"] .btn-order__custom {
    line-height: 6px;
}

[lang="ar"] .arabic__row {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}

[lang="ar"] .order__action-btn {
    align-items: flex-start;
}

[lang="ar"] .arabic__content {
    direction: rtl;
}
[lang="ar"] .widget ul.product-categories li a:before {
    margin-left: 15px;
}
[lang="ar"] .total__price {
    direction: rtl;
}
