@media (max-width: 500px) {
    .info-order .product-name .name {
        width: 159px;
        font: 700 13px/50px "Roboto Condensed" !important;
    }
    .info-order .product-name .head .qty,
    .info-order .product-name .head .total {
        font: 700 11px/50px "Roboto Condensed" !important;
    }
}
@media (max-width: 350px) {
    .info-order .product-name .name {
        width: 129px;
        font: 700 11px/50px "Roboto Condensed" !important;
    }
    .info-order .product-name .head .qty,
    .info-order .product-name .head .total {
        font: 700 11px/50px "Roboto Condensed" !important;
    }
    .info-order .product-order .left {
        font: 700 11px "Roboto Condensed";
        color: #565656;
        text-transform: uppercase;
        float: left;
        line-height: 50px;
    }
}
