.no-margin-x {
    margin: 0 !important;
}

a {
    text-decoration: none !important;
}

.mt-1 {
    margin-top: 1em !important;
}
.mt-30 {
    margin-top: 30px !important;
}
.ref-text {
    font-weight: 700;
}
.ref-sec {
    padding: 20px 0px;
}
.mt-2 {
    margin-top: 2em !important;
}
.mt-10 {
    margin-top: 30px !important;
}
.mbt-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}
.ml-3 {
    margin-left: 3em;
}
.lh-27 {
    line-height: 27px;
}
.lh-47 {
    line-height: 47px;
}
.justify-content-between {
    justify-content: space-between;
}
.align-items-center {
    align-items: center;
}
.d-inline {
    display: flex;
}
.mb-1 {
    margin-bottom: 0.5em;
}
.language {
    border: none;
    outline: none;
}
.coupen-lineheight {
    line-height: 38px !important;
}
.icon-ok-style {
    color: green;
    font-size: 137px;
}
.d-block {
    display: block !important;
}
.d-none {
    display: none !important;
}
.Changequantity {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Changequantity input.form-control {
    max-width: 60px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    height: auto;
}

.green {
    color: #009b3d;
}
.m-7 {
    margin: 7px;
}
.cursorPointer {
    cursor: pointer;
}
.no-padding {
    padding: 0px;
}

.product-details {
    display: inline-flex;
}

.primary_image {
    max-height: 100px;
    width: auto !important;
    margin: 0 auto;
}

.main-quantity {
    display: flex;
    justify-content: center;
}

.main-quantity .quantity {
    flex: 1;
    max-width: 170px;
}

.main-quantity .btn {
    width: auto !important;
    height: 48px !important;
}

.main-quantity .quantity-selector {
    width: 100%;
}

.owl-item .product {
    width: 100%;
}

.cart__btn {
    margin-left: 10px;
}

.product .product-images a {
    text-decoration: none;
}

.product .product-images img {
    width: 100% !important;
    max-height: 150px;
    object-fit: contain;
    object-position: center;
}

.products__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.products__grid .item-inner {
    margin: 15px 15px;
    max-width: 250px;
    display: inline-grid;
    width: 100%;
    min-height: 380px;
}

.products__grid .item-inner .product {
    padding: 25px 15px;
}

.mt-30 {
    margin-top: 30px;
}

.products__grid .item-inner:hover {
    border-radius: 0.75rem;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}
.shopping-cart-btn {
    margin: 10px 7px;
}
.payment-paragrapch {
    color: #9d9d9d;
    font: 400 12px/20px "Poppins";
    margin-top: 10px;
}
.card {
    border: none;
}

.card-img {
    border-radius: 0;
}
.card-title {
    margin-left: 5px;
}
.coupen-modal {
    top: -155px !important;
}
.coupen-input {
    height: 42px !important;
}
.card-text {
    margin-left: 5px;
}
.vgr-cards .card {
    display: -webkit-box;
    display: flex;
    flex-flow: wrap;
    -webkit-box-flex: 100%;
    flex: 100%;
    margin-bottom: 40px;
}

.vgr-cards .card:nth-child(even) .card-body {
    padding-left: 0;
    padding-right: 1.25rem;
}
@media (max-width: 576px) {
    .vgr-cards .card {
        display: block;
    }
    .slick-slide img {
        max-height: 170px;
    }
    .arrow {
        padding: 15px 10px !important;
    }
}
.vgr-cards .card-img-body {
    -webkit-box-flex: 1;
    flex: 1;
    overflow: hidden;
    position: relative;
}
@media (max-width: 576px) {
    .vgr-cards .card-img-body {
        width: 100%;
        height: 200px;
        margin-bottom: 20px;
    }
}
.vgr-cards .card-img {
    width: 100%;
    height: auto;
    position: absolute;
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
@media (max-width: 1140px) {
    .vgr-cards .card-img {
        margin: 0;
        -webkit-transform: none;
        transform: none;
        width: 100%;
        height: auto;
    }
}
.vgr-cards .card-body {
    -webkit-box-flex: 2;
    flex: 2;
    padding: 0 0 0 1.25rem;
}
@media (max-width: 576px) {
    .vgr-cards .card-body {
        padding: 0;
    }
}
.profile-gender {
    display: flex;
}
.profile-gender span {
    top: 7px !important;
    left: 5px !important;
}

.logo__image {
    max-height: 60px;
}

.top__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #cdcdcd;
}

.top__navbar .phone {
    font-weight: 500;
}

.top__navbar .phone .text {
    margin-left: 10px;
}

.top__navbar .phone .phone__number {
    color: #009b3d;
}

.main__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar__items {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.navbar__search {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.navbar__search .icon {
    position: absolute;
    top: 7px;
    right: 10px;
}

.navbar__search .search__input {
    display: none;
    width: 100%;
    max-width: 350px;
    margin-left: auto;
}

.search__input input.search__input-field {
    width: 100%;
    padding: 13px 35px 13px 10px;
    border: 1px solid #cdcdcd;
}

.navbar__search.active .search__input {
    display: block;
}

.navbar__search.active .search__icon {
    display: none !important;
}

.search__dropdown {
    list-style-type: none;
    text-align: left;
    padding-left: 0;
}

.search__dropdown li {
    padding: 5px;
    font-size: 16px;
}

.search__dropdown li a {
    color: #9d9d9d;
    text-decoration: none;
}

#header .cart p {
    transform: translateY(7px);
}

.contact__info {
    display: flex;
}

.contact__info .email__address {
    margin-left: 51px;
}

@media (max-width: 768px) {
    .contact__info {
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
    }

    .contact__info .email__address {
        margin-left: 0;
    }
}

.email__address {
    margin-left: 10px;
}
.save__btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #cdcdcd;
    z-index: 1;
    cursor: pointer;
}

.save__btn .icon {
    color: red;
}

.user__icon {
    margin-left: 10px;
}

.user__icon img {
    width: 30px;
    margin-top: 18px;
}

.auth__dropdown .dropdown-menu {
    top: calc(100% - 15px);
    background-color: #fff;
}

.auth__dropdown .dropdown-menu li {
    border-bottom: 1px solid #cdcdcd;
}
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
    background-color: #f7f7f7 !important;
    color: black !important;
}
.dropdown-menu li a:hover,
.dropdown-menu li a:active,
.dropdown-menu li a:focus {
    background-color: #f7f7f7 !important;
    color: black !important;
}
@media (max-width: 767px) {
    #topbar {
        height: auto;
    }
}

.sub__menu {
    position: relative;
}

.dropdown__sub-menu {
    position: absolute;
    right: 100%;
    top: 0;
    display: none;
    background-color: #fff;
    border: 1px solid #cdcdcd;
    list-style: none;
    padding-left: 0;
}

.sub__menu:hover .dropdown__sub-menu {
    display: block;
    width: 174px;
}

.dropdown__sub-menu a {
    text-decoration: none;
    padding-right: 20px;
}

.dflex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.service__provider-jumbotron {
    padding: 50px;
}
@media (max-width: 768px) {
    .service__provider-jumbotron {
        padding: 2px;
    }
}
.jumbotron__btn {
    margin-left: 15px;
}

#topbar .search {
    margin-top: 12px;
}

.search__input {
    position: relative;
}

.search__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #9d9d9d;
    display: none;
}

.search__dropdown.has-items {
    display: block;
}

.close__icon {
    background-image: url(../images/icon-menu-mobile-active.svg) !important;
    background-size: 15px !important;
    margin-top: 9px;
    margin-left: 7px;
}

.shopping__icon {
    font-size: 30px;
}

.search__item {
    display: flex;
}

.search__item img {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}

.search__item .item__details {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.search__item .item__details .name {
    font-size: 20px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
}

.search__item .item__quantity {
    display: flex;
    align-items: center;
    position: relative;
}

.search__item .action__icon {
    position: absolute;
    left: 3px;
    top: 9px;
    background-color: green;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.search__item .action__icon.fa-minus {
    right: 3px;
    left: auto;
}

.search__item .quantity__input {
    width: 120px;
    padding: 7px 25px;
    border: 1px solid #cdcdcd;
    text-align: center;
    border-radius: 4px;
}

.search__input-field {
    padding-top: 20px;
}

.product__wrapper {
    padding: 0 5px;
}

.product {
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.06), 0 1px 6px rgba(0, 0, 0, 0.04);
    border-radius: 0.75rem;
    border-bottom: none;
    border-right: none;
    border-left: none;
    width: 100%;
}

.featured-products .products .product:after {
    background-color: transparent;
}

.cart__btn {
    margin-left: 0;
    margin-right: 0;
    height: 42px;
}

.main-quantity .quantity,
.cart__btn {
    width: 100%;
    max-width: 100%;
}
.table > tbody > tr > td {
    /* padding: 27px 6px !important; */
    /* text-align: center !important; */
}
.price_details_style {
    text-align: right !important;
    font-weight: 600;
}

.inner-topbar.main__nav .search {
    margin-top: 3px !important;
}

.inner-topbar.main__nav .cart.hover-menu {
    padding-top: 3px !important;
}

.user__icon img {
    margin-top: 7px;
}
.control-group {
    display: inline-block;
    vertical-align: top;
    background: #fff;
    text-align: left;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 200px;
    height: 210px;
    margin: 10px;
}
.control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 18px;
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control__indicator {
    position: absolute;
    top: 2px;
    left: 4px;
    height: 20px;
    width: 20px;
    background: #b9b3b3;
}
.control--radio .control__indicator {
    border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
    background: #ccc;
}
.control input:checked ~ .control__indicator {
    background: #0c517f;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
    background: #0e647d;
}
.control input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}
.control__indicator:after {
    content: "";
    position: absolute;
    display: none;
}
.control input:checked ~ .control__indicator:after {
    display: block;
}
.control--checkbox .control__indicator:after {
    left: 8px;
    top: 4px;
    width: 3px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
    left: 7px;
    top: 7px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
    background: #7b7b7b;
}
.card-header {
    background-color: #009b3d;
    border-radius: 11px;
}

.card-header .accordion-toggle:after {
    /* symbol for "opening" panels */
    font-family: "Glyphicons Halflings"; /* essential for enabling glyphicon */
    content: "\e114"; /* adjust as needed, taken from bootstrap.css */
    float: right; /* adjust as needed */
    color: white; /* adjust as needed */
}
.card-header .accordion-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\e080"; /* adjust as needed, taken from bootstrap.css */
}
.accordion-toggle {
    width: 100%;
    text-align: left !important;
}
.accordion-toggle :hover {
    color: white !important;
}

.order__item {
    background: #fff;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.08), 0 1px 6px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    margin-top: 10px;
    padding: 15px;
}

.btn-order__custom {
    width: 200px;
    height: 33px;
    font-size: 13px;
}

.btn-order__custom:last-child {
    margin-right: 10px;
}

.font-weight-bold {
    font-weight: 500;
}

.order__action-btn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@media (max-width: 992px) {
    .order__action-btn {
        align-items: center;
    }
}

.content {
    padding-left: 15px;
}

.content__item {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}

.modal-open {
    padding-right: 12px;
}

.modal__custom {
    background-color: rgba(0, 0, 0, 0.5);
}

.close__btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.btn__custom-modal {
    width: auto;
    height: auto;
    padding: 13px 25px;
}

.deliver__qty-input {
    width: 55px;
}

.modal__custom .modal-body {
    padding-right: 15px;
    padding-left: 15px;
}

.modal__container {
    padding-right: 20px;
    padding-left: 20px;
}

.mb__custom-3 {
    margin-bottom: 25px;
}

.language__currency {
    display: flex;
}

.language__currency .currency__symbol {
    margin-right: 15px;
}

.language__currency .currency__symbol .title {
    margin-right: 10px;
}

.widget_feature {
    display: none !important;
}

@media (max-width: 768px) {
    .language__currency .currency__symbol,
    .navbar__search .search__input,
    .widget_feature {
        display: none !important;
    }
}

.user__icon {
    margin-top: 10px;
    display: inline-block;
}

.user__icon .icon {
    font-size: 30px;
    color: #000000;
}

.sub__menu .fa {
    font-size: 14px;
    margin-right: 10px;
}

.categories__heading .title {
    font-weight: 500;
    font-size: 20px;
}

.categories__heading .link {
    display: inline;
    font-weight: 600;
    text-decoration: none;
    color: #009b3d;
    line-height: 4;
    font-size: 17px;
}

.catbox-container.home-4 .items .text h3 {
    font-size: 15px;
    width: 100%;
}
@media (max-width: 768px) {
    .catbox-container.home-4 .items .text h3 {
        font-size: 10px;
    }
    .h1 {
        font-size: 18px;
    }
}
@media (max-width: 360px) {
    .catbox-container.home-4 .items .text h3 {
        font-size: 8px;
    }
    .h1 {
        font-size: 16px;
    }
    .download-link {
        display: flex;
        padding: 5px;
    }
    #footer .title-footer {
        font: 400 11px "Gotham";
    }
    #footer .list-footer li a {
        color: #666;
        font: 500 11px/20px "Gotham";
    }
}
.products .product p.product-title {
    font-size: 16px;
    line-height: 22px;
    margin-top: 4px;
    height: 72px;
}

.product-categories {
    padding-left: 15px;
}

.product-categories a,
.products .product p.product-title,
.widget .widget-title,
.title-brand,
.order-complete p {
    font-family: "Gotham" !important;
}

.title-brand {
    font-size: 24px;
}

.product-categories a {
    font-size: 16px !important;
    text-decoration: none;
}

.widget-area {
    margin-bottom: 0 !important;
}

.banner-header {
    position: relative;
}

#footer.footer-v3 {
    margin-top: 200px;
}

.app__banner {
    background-color: #6ec7cd;
}
.content-box {
    border-radius: 3px;
    display: block;
    position: relative;
    width: 100%;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.08), 0 1px 6px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 30px;
}
.app-icon {
    display: block;
    max-width: 100%;
}
.justify-content-center {
    justify-content: center !important;
}
.d-flex {
    display: flex !important;
}
.justify-content-center {
    justify-content: center !important;
}
.download-btn {
    height: 47px;
    background-color: #337ab7;
    color: white;
    border-radius: 5px;
    border: #337ab7;
}
.text-white {
    color: white !important;
}

.jumbotron__btn {
    width: 146px;
    background-color: #337ab7;
    color: white;
    height: 40px;
    line-height: 37px;
    margin-top: 10px;
    /* margin: 0 auto; */
    text-decoration: none;
    display: inline-block;
}
.jumbotron__btn :hover {
    text-decoration: none;
    color: white;
}
.paymanet-method-logo img {
    width: 44px;
    margin-right: 30px;
}
.quantity-incre {
    color: black;
    font: unset !important;
}
.order-column {
    margin: 30px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.08), 0 1px 6px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
}
.dashboard__list {
    display: flex;
    justify-content: center;
}
.employee-btn {
    width: 200px;
    border-radius: 5px;
    width: 181px;
    line-height: 30px;
    margin-top: 12px;
    float: right;
    height: 45px;
}
/*  */
@media (max-width: 768px) {
    .banner-header.banner-lbook3 .text {
        display: none;
    }

    .location__option {
        flex-direction: column;
    }
    .link-v1 {
        width: 130px;
    }
    .dashboard__list {
        display: block;
        justify-content: center;
    }
    .order-column {
        margin: 5px;
    }
}

.alert {
    position: relative;
}

.close__icon-font {
    background-repeat: no-repeat;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.search__input .search__btn {
    height: 100%;
    position: relative;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px;
    background-color: #cdcdcd;
    border: none;
}

.search__btn .icon {
    background-size: contain !important;
    background-position: center 4px !important;
    height: 34px !important;
}

.mobile__logo {
    width: auto;
    height: 38px;
}

.show__mobile {
    display: none;
}

@media (max-width: 768px) {
    .hide__mobile {
        display: none;
    }

    .show__mobile {
        display: block;
    }
}

.total__price {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.total__price-item .font-weight-bold {
    font-weight: bold;
}

/* .total__price-item .price {
    color: #009b3d;
} */

.total__price-item.saving {
    background-color: #009b3d;
    text-align: center;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}
.total__price-item {
    font-size: 11px;
}
.cross__text {
    text-decoration: line-through;
}

.modal__img-container {
    width: 100%;
    max-width: 340px;
    max-height: 150px;
}

.modal__img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}

.modal__text {
    margin-top: 20px;
}

.modal {
    background-color: hsla(0, 20%, 10%, 0.23);
}

.custom__dropdown {
    position: relative;
}

.dropdown__tigger {
    background-color: #fff;
    border: none;
}

.language__dropdown {
    top: 0;
    right: 0;
    left: auto;
    display: none;
}
.language__dropdown :hover {
    cursor: pointer;
}
.language__dropdown .language__item {
    background-color: #fff;
    padding: 5px;
    border-bottom-color: #cdcdcd;
}

.language__item img {
    margin-right: 10px;
}
.checkout-header {
    line-height: 39px;
    background-color: #009b3d;
    height: 45px;
    color: white;
}

.modal .modal-footer .raashan-bcolor,
.raashan-bcolor,
.btn-warning {
    background-color: #009b3d !important;
    color: #fff;
    border-color: #009b3d !important;
}

.market-bcolor,
.btn-info,
.table.table.cart-table thead {
    background-color: #d3425c !important;
    border-color: #d3425c !important;
}

.market-bcolor-outline {
    background-color: #fff !important;
    color: #d3425c !important;
    border: 1px solid #d3425c;
}

.link-v1:hover,
.link-v1:focus {
    background-color: #d3425c !important;
    color: #fff !important;
    border: 1px solid #d3425c;
}

.raashan-color {
    color: #009b3d !important;
}

.market-color {
    color: #d3425c !important;
}

.product-title,
.product-price,
.product-price-old {
    color: #661020 !important;
}

.bg-white {
    background-color: #fff !important;
}

.buttons {
    margin-bottom: 25px;
}

.ml-0 {
    margin-left: 0 !important;
}

.title-footer {
    color: yellow !important;
}

.list-footer li a {
    color: #ffffff !important;
}

.slick-slide img {
    height: 300px !important;
}

.arrow {
    position: absolute;
    top: 50%;
    padding: 35px 15px;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 24px;
    z-index: 100;
    cursor: pointer;
}

.arrow.right {
    right: 0;
}

.arrow.left {
    left: 0;
}

.social__icons {
    display: flex;
}

.social__item {
    margin-left: 15px;
    cursor: pointer;
}

.fa-facebook {
    color: #3b5998;
}

.fa-twitter {
    color: #55acee;
}

.fa-instagram {
    color: #125688;
}

.forgotten__text {
    font-size: 18px;
}

.full-link {
    width: 100% !important;
}

.icon__input {
    position: relative;
}

.icon__input input {
    padding-left: 45px;
}

.icon__input .input__icon {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    font-size: 20px;
    color: #999999;
    z-index: 10;
}

.paymentcontainer input:checked ~ .checkmark {
    background-color: #fff !important;
}

.paymentcontainer .checkmark {
    border: 1px solid #d3425c;
    background-color: white;
}

.paymentcontainer .checkmark::after {
    background-color: #d3425c !important;
    transform: translate(-17%, -20%);
}

.not-found {
    margin-top: 35px;
}

.modal-footer-sm button {
    height: auto;
    width: auto;
    padding: 7px 30px;
}

.rm__p .modal-content {
    padding-bottom: 0;
}

.infinite-scroll-component {
    text-align: center;
}

.input-group .form-control {
    float: none;
}

.btn {
    font-family: "Gotham";
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

/* Notification */
.notification__container {
    max-width: 660px;
    background-color: white;
    border: 3px solid rgba(237, 237, 228, 0.75);
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 200px;
    padding-bottom: 100px;
}

.notification__heading {
    font-size: 26px;
    color: rgba(47, 46, 46, 1);
    margin-bottom: 25px;
    margin-top: 25px;
    font-weight: 600;
}

.no__notification {
    height: 284px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Avenir;
    border: 1px solid #d5d5d5;
}

.no__notification h3 {
    font-size: 26px;
    margin-bottom: 18px;
    font-weight: 600;
}

.no__notification p {
    font-size: 14px;
    font-weight: 700;
    max-width: 400px;
    text-align: center;
    color: #5c627c;
    font-family: Museo;
}

.notification__item {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dedede;
}

.notification__item:first-of-type {
    border-top: 1px solid #dedede;
}

.notification__item .notification__img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.notification__img img {
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    border-radius: 50%;
}

.notification__text {
    font-size: 15px;
    font-weight: 600;
    flex: 1;
    margin-right: 15px;
}

.notification__date {
    padding-left: 10px;
    padding-right: 15px;
    font-size: 15px;
    font-weight: 600;
}

.notification__unread {
    min-width: 12px;
    max-width: 12px;
    height: 12px;
    border-radius: 50%;
}

.isunread .notification__unread {
    background-color: #4bd6c0;
}

.paytem-img {
    background-color: white;
    border: none;
    text-align: unset;
}

.delete__notification {
    font-size: 20px;
    cursor: pointer;
}

.notification__img {
    max-height: 20px;
}

.justify-content-center {
    justify-content: center !important;
}

.share__icon {
    cursor: pointer;
    font-size: 17px;
    margin-left: 10px;
}
.green_c {
    color: #d3425c;
}
.social__icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
}

.social__icon.fa-facebook {
    background-color: #3b5998;
}

.social__icon.fa-twitter {
    background-color: #55acee;
}

.social__icon.fa-instagram {
    background-color: #125688;
}

.social__icon.fa-whatsapp {
    background-color: #25d366;
}

.social__icon.fa-envelope-o {
    background-color: #3b3b3b;
}

.copy__input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.copy__input input {
    padding: 11px 15px;
    height: auto;
    width: 100%;
    max-width: 350px;
}

.copy__input .copy__icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #444;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    cursor: pointer;
}

.modal-header .close {
    position: absolute;
    top: 10px;
    right: 15px;
}

.my-3,
.mt-3 {
    margin-top: 3rem;
}

.my-3,
.mb-3 {
    margin-bottom: 3rem;
}

.btn-custom-block {
    width: 100% !important;
    display: block !important;
}
