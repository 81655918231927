.faqs {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 15px;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;

  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;

  /* background-image: url("./arrow-down-mint.svg"); */
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  transition: all 0.4s ease-out;
}

.faqs .faq .sub_faq {
  opacity: 0;
  max-height: 0;
  font-size: large;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.sfaq-question {
  max-height: 1000px;
  opacity: 7;
  font-size: large;

  width: 100%;
  padding-left: 12px;
}
.faqs .faq.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .sub_faq {
  max-height: 1000px;
  opacity: 1;
}

.list-style-arrow li::before {
  content: "> ";
  font-size: 18px;
  font-weight: 900;
}

.sub-question h6 {
  font-size: 18px;
  padding-bottom: 10px;
  cursor: pointer;
}

.sub-question h6::before {
  content: ">";
  font-size: 18px;
  position: relative;
  left: -5px;
}

.sub-question h6.active::before {
  content: "^";
}

.sub-question p {
  font-size: 14px;
  margin-left: 10px;
  display: none;
}

.sub-question p.show {
  display: block;
}

.card h5 {
  padding-bottom: 0;
}

.card .btn-link:hover {
  text-decoration: none;
}
