@charset "UTF-8";
/**
	Theme Name: FutureLife
	Theme URI: http://engotheme.com/
	Author: Engo Team
	Author URI: http://engotheme.com/
	Description: Engo ABC HTML Template
	Version: 1.0
	Last change:	2016/02/20 [Initial Release]
	License: GNU General Public License v2 or later
	Tags: two-columns, left-sidebar, fixed-layout, responsive-layout
	Text Domain: Engotheme
 **/
/*------------------------------------------------------------------
[Table of contents]

	1. Import vendor
		1.1. Vendor
			1.1.1. Google fonts
			1.1.2. Icon Fonts
			1.1.3. Vendor library
		1.2. Bootstrap
	2. Reset
		2.1. Reset
		2.2. Default base
			2.2.1.	Button
			2.2.2.	Link
			2.2.3.	Typo
			2.2.4.	Heading
			...
	3. Mixins
	4. Elements
		4.1. Our new
		4.2. News letter
		4.3. Form our
		4.4. Brand
		...
	5. Ecommerce
		5.1. Shop General
		5.2. Item view templates
		5.3. Shop Widget
		5.4. Shop view Pages
		5.5. Shop Single
		5.6. Shop Cart
		5.7. Shop Checkout
		5.8. Shop Wishlist
		5.9. Track Order
	6. Templates Layout
		6.1. General
		6.2. Header
			6.2.1. General
			6.2.2. Header version 1
			6.2.3. Header version 2
			6.2.4. Header version 3
			...
		6.3. Sidebar
		6.4. Contents
			6.4.1. CMS default
			6.4.2. Blog
			...
		6.5. Footers
			6.5.1. General
			6.5.2. Footer Version 1
			6.5.3. Footer Version 2
			...
		6.6. Home pages
			6.6.1. Home page 1
			6.6.2. Home page 2
			...
		6.7. Pages
			6.7.1. About Us
				6.7.1.1. About Us Version 1
				6.7.1.2. About Us Version 2
			6.7.2. Contact Us
			6.7.3. FAQ's
			...
-------------------------------------------------------------------*/
/** 1. Import vendor **/
/** 1.1. Vendor **/
/** 1.1.1.	Google fonts **/
@import url(https://fonts.googleapis.com/css?family=Montserrat:400, 700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400, 500, 600, 700, 300);
@import url(https://fonts.googleapis.com/css?family=Roboto:300, 400, 700, 500);
@import url(https://fonts.googleapis.com/css?family=Oswald:400, 300, 700);
@import url(https://fonts.googleapis.com/css?family=Roboto + Slab:300, 400, 700);
@import url(https://fonts.googleapis.com/css?family=Roboto + Condensed:300, 400, 700);
@import url(https://fonts.googleapis.com/css?family=Playfair + Display:400, 700, 900);
/** 1.1.2.	Icon Fonts **/
@import url("../sass/components/Vendor/font-awesome/font-awesome.min.css");
@import url("../sass/components/Vendor/simple-line-icon/css/simple-line-icons.css");
@import url("../sass/components/Vendor/pe-icon-7-stroke/css/pe-icon-7-stroke.css");
@import url("../sass/components/Vendor/pe-icon-7-stroke/css/helper.css");
/** 1.1.3. Vendor library **/
/* EX: @import url('components/Vendor/owlcarousel/owlcarousel.css'); */
/** 1.3. Animation **/
/* effect-v1 */

.effect-v1 {
    background-color: #000;
    overflow: hidden;
    position: relative;
    /* style for hover */
}

.effect-v1:before {
    border: 1px solid #ffffff;
    bottom: 20px;
    content: "";
    left: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    z-index: 1;
}

.effect-v1 img {
    -webkit-transform: scale(1.12);
    -ms-transform: scale(1.12);
    -o-transform: scale(1.12);
    transform: scale(1.12);
    -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
}

.effect-v1 .action {
    -webkit-transform: scale(1.12);
    -ms-transform: scale(1.12);
    -o-transform: scale(1.12);
    transform: scale(1.12);
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
}

.effect-v1:hover:before {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.effect-v1:hover img {
    opacity: 0.5;
    filter: alpha(opacity=50);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.effect-v1:hover .action {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100);
}

/* effect-v2*/
.effect-v2 {
    background-color: #000;
    overflow: hidden;
    position: relative;
    /* style for hover */
}

.effect-v2:before {
    border: 1px solid #ffffff;
    bottom: 20px;
    content: "";
    left: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    -webkit-transform: translate3d(-20px, 0px, 0px);
    transform: translate3d(-20px, 0px, 0px);
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    z-index: 1;
}

.effect-v2 img {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
}

.effect-v2 .action {
    -webkit-transform: translate3d(-10px, 0px, 0px);
    transform: translate3d(-10px, 0px, 0px);
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
}

.effect-v2:hover:before {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
}

.effect-v2:hover img {
    opacity: 0.7;
    filter: alpha(opacity=70);
    -webkit-transform: scale(1.2, 1);
    -ms-transform: scale(1.2, 1);
    -o-transform: scale(1.2, 1);
    transform: scale(1.2, 1);
}

.effect-v2:hover .action {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    filter: alpha(opacity=100);
}

/* effect-v3*/
.effect-v3 {
    background-color: #000;
    overflow: hidden;
    position: relative;
    /* style for hover */
}

.effect-v3:before,
.effect-v3:after {
    bottom: 20px;
    content: "";
    left: 20px;
    opacity: 0;
    position: absolute;
    right: 20px;
    top: 20px;
    -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    z-index: 1;
}

.effect-v3:before {
    border-bottom: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    -o-transform: scale(0, 1);
    transform: scale(0, 1);
}

.effect-v3:after {
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
}

.effect-v3 img {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s;
    transition: opacity 0.35s ease 0s;
}

.effect-v3 .banner-title {
    -webkit-transform: translate3d(0px, -20px, 0px);
    transform: translate3d(0px, -20px, 0px);
    -webkit-transition: transform 0.35s ease 0s;
    -o-transition: transform 0.35s ease 0s;
    transition: transform 0.35s ease 0s;
}

.effect-v3 .action {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translate3d(0px, 40px, 0px);
    transform: translate3d(0px, 40px, 0px);
    -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
}

.effect-v3:hover:before,
.effect-v3:hover:after {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.effect-v3:hover img {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.effect-v3:hover .banner-title,
.effect-v3:hover .action {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
}

/* effect-v4*/
.effect-v4 {
    background-color: #000;
    overflow: hidden;
    position: relative;
    /* style for hover */
}

.effect-v4:before {
    border: 2px solid #ffffff;
    bottom: 20px;
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.2);
    content: "";
    left: 20px;
    opacity: 0;
    position: absolute;
    right: 20px;
    top: 20px;
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
    transform: scale(1.4);
    -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    z-index: 1;
}

.effect-v4 img {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s;
    transition: opacity 0.35s ease 0s;
}

.effect-v4 .banner-title {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: transform 0.35s ease 0s;
    -o-transition: transform 0.35s ease 0s;
    transition: transform 0.35s ease 0s;
}

.effect-v4 .action {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
}

.effect-v4:hover:before {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.effect-v4:hover img {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.effect-v4:hover .banner-title {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
}

.effect-v4:hover .action {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

/* effect-v5*/
.effect-v5 {
    background-color: #000;
    overflow: hidden;
    position: relative;
    /* style for hover */
}

.effect-v5 img {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 1s ease 0s, transform 1s ease 0s;
    -o-transition: opacity 1s ease 0s, transform 1s ease 0s;
    transition: opacity 1s ease 0s, transform 1s ease 0s;
}

.effect-v5 .action {
    transform: translate3d(-150%, 0px, 0px);
    -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
}

.effect-v5 .action:first-child {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}

.effect-v5 .action:nth-of-type(2) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.effect-v5 .action:nth-of-type(3) {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

.effect-v5:hover img {
    opacity: 0.5;
    filter: alpha(opacity=50);
    transform: scale3d(1.1, 1.1, 1);
}

.effect-v5:hover .action {
    opacity: 1;
    filter: alpha(opacity=100);
    transform: scale3d(1.1, 1.1, 1);
    transform: translate3d(0px, 0px, 0px);
}

.effect-v5:hover .action:first-child {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.effect-v5:hover .action:nth-of-type(2) {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

.effect-v5:hover .action:nth-of-type(3) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

/* effect-v6*/
.effect-v6 {
    background-color: #000;
    overflow: hidden;
    position: relative;
    /* style for hover */
}

.effect-v6 img {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s;
    transition: opacity 0.35s ease 0s;
}

.effect-v6 .action {
    transform: translate3d(0px, -100px, 0px);
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    opacity: 0;
}

.effect-v6:hover img {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.effect-v6:hover .action {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
}

/* effect-v7*/
.effect-v7 {
    overflow: hidden;
    position: relative;
    /* style for hover */
}

.effect-v7:before {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 0.4s cubic-bezier(0.94, 0.85, 0.1, 0.62) 0s;
    -o-transition: all 0.4s cubic-bezier(0.94, 0.85, 0.1, 0.62) 0s;
    transition: all 0.4s cubic-bezier(0.94, 0.85, 0.1, 0.62) 0s;
    z-index: 1;
    border: 200px double transparent;
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
}

.effect-v7:hover:before {
    opacity: 0;
    filter: alpha(opacity=0);
    border: 0 double rgba(255, 255, 255, 0.7);
    visibility: hidden;
}

/* effect-v8*/
.effect-v8 {
    overflow: hidden;
    position: relative;
    /* style for hover */
}

.effect-v8:before {
    border-bottom: 50px solid rgba(255, 255, 255, 0.4);
    border-top: 50px solid rgba(255, 255, 255, 0.4);
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    top: 0;
    transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
    -o-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
    -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
    -moz-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
    -ms-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
    -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transform-origin: 50% 50% 0;
    width: 100%;
}

.effect-v8:hover:before {
    opacity: 1;
    filter: alpha(opacity=100);
    transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
    -o-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
    -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
    -moz-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
    -ms-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
}

/* effect-v9*/
.effect-v9 {
    overflow: hidden;
    position: relative;
    /* style for hover */
}

.effect-v9:before {
    border: 50px solid transparent;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    top: 0;
    content: "";
    opacity: 1;
    filter: alpha(opacity=100);
    width: 100px;
    height: 100px;
    -webkit-transform: scale(7);
    -ms-transform: scale(7);
    -o-transform: scale(7);
    transform: scale(7);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    visibility: visible;
    z-index: 1;
}

.effect-v9:hover:before {
    border: 0 solid rgba(0, 0, 0, 0.7);
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
}

/* effect-v10*/
.effect-v10 {
    overflow: hidden;
    position: relative;
    /* style for hover */
}

.effect-v10:before {
    background-color: rgba(255, 255, 255, 0.2);
    display: block;
    width: 100%;
    height: 100%;
    left: -100%;
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    top: 0;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    content: "";
    z-index: 1;
}

.effect-v10:hover:before {
    left: 0;
    opacity: 1;
    filter: alpha(opacity=100);
}

@font-face {
    src: url("./../font/Gotham-Book.otf");
    font-family: "Gotham";
    font-weight: 400;
}

@font-face {
    src: url("./../font/Gotham-Bold.otf");
    font-family: "Gotham";
    font-weight: 700;
}

@font-face {
    src: url("./../font/Gotham-Light.ttf");
    font-family: "Gotham";
    font-weight: 300;
}

/** 2. Reset **/
/** 2.1. Reset **/
body {
    margin: 0;
    padding: 0;
    font-family: "Gotham";
    font-size: 14px;
    display: inline-block;
    width: 100%;
    color: #161616;
    position: relative;
    font-weight: 400;
}

body.ver-1 {
    /* 

input[type="text"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="text"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

a,
p,
strong,
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

li {
  list-style: none;
  outline: none;
}

a:focus,
input:focus {
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

body {
  overflow-x: hidden;
  position: relative;
  right: 0;
  left: 0px;
}

.pushmenu {
  /*this is the nav*/
    background: #fff;
    text-align: center;
    width: 364px;
    height: 100%;
    top: 0;
    z-index: 1000;
    position: fixed;
    padding: 0px 25px;
}

@media (min-width: 1500px) {
    .pushmenu {
        width: 445px;
        padding: 0 70px;
    }
}

.pushmenu-left {
    right: -364px;
}

@media (min-width: 1500px) {
    .pushmenu-left {
        right: 445px;
    }
}

.pushmenu-left.pushmenu-open {
    right: 0;
}

.pushmenu-push-toright {
    right: 364px;
    left: inherit;
}

@media (min-width: 1500px) {
    .pushmenu-push-toright {
        right: 445px;
    }
}

/*Transition*/
.pushmenu,
body {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.pushmenu-push-toright .wrappage {
    position: relative;
}

.pushmenu-push-toright .wrappage:after {
    position: absolute;
    background: rgba(29, 29, 31, 0.6) none repeat scroll 0 0;
    content: "";
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 9999;
    cursor: pointer;
}

.center {
    text-align: center;
}

/** 2.2. Default base **/
/** 2.2.1.	Button **/
.dropdown-menu {
    background-color: #333333;
    border-radius: 0px;
    box-shadow: none;
    margin-top: 10px;
    padding: 0px;
    transform: translate(0px, 20px);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.dropdown-menu.hover {
    transform: translate(0px, 0px);
}

.dropdown-menu li {
    border-bottom: 1px solid #444;
}

.dropdown-menu li:last-child {
    border-bottom: none;
}

.dropdown-menu li a {
    color: #888888;
    font-size: 11px;
    line-height: 40px;
    display: inline-block;
    text-transform: uppercase;
    width: 100%;
    padding: 5px 20px;
}

.dropdown-menu li a:hover,
.dropdown-menu li a:active,
.dropdown-menu li a:focus {
    background: #333333;
    color: #ddd;
}

.box {
    display: inline-block;
    width: 100%;
}

.box-shadow {
    box-shadow: 0px 0px 26.73px 0.27px rgba(86, 86, 86, 0.08);
}

.width-100 {
    width: 100%;
}

.center {
    text-align: center;
}

.border-bottom-home1 {
    height: 6px;
    width: 100%;
}

.tooltip.right {
    max-width: 200px;
}

.box {
    display: inline-block;
    width: 100%;
}

.dropcap {
    display: inline-block;
    width: 56px;
    height: 56px;
    text-align: center;
    float: left;
    background: #000;
    color: #fff;
    margin-right: 10px;
    margin-top: 7px;
    line-height: 56px;
    font: 500 30px "Poppins";
}

.title-text {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 30px 0px;
    position: relative;
}

.title-text h3 {
    font-size: 14px;
    color: #000;
    text-transform: uppercase;
    line-height: 30px;
    display: inline-block;
    padding-bottom: 10px;
    position: relative;
    letter-spacing: 2px;
    font-weight: 700;
}

.title-text h3:after {
    position: absolute;
    content: "";
    height: 2px;
    width: 50px;
    left: 50%;
    bottom: 0px;
    margin-left: -25px;
    background: #000;
}

.title-text p {
    font-size: 14px;
    color: #888888;
    line-height: 25px;
    font-family: "TXGR";
    margin-top: 10px;
}

.title-text.title-text-v2 {
    text-align: left;
    margin: 30px 0px;
}

.title-text.title-text-v2:before {
    position: static;
}

.title-text.title-text-v2 h3 {
    font: 400 36px/40px "Gotham";
    padding: 0;
    color: #2b2b2b;
}

.title-text.title-text-v2.title-text-v3 {
    position: relative;
}

.title-text.title-text-v2.title-text-v3 h3 {
    font-size: 36px;
    color: #555555;
    position: relative;
}

.title-text.title-text-v2.title-text-v3 h3:after {
    position: absolute;
    content: "";
    top: -50px;
    background: #6dd6d6;
    height: 5px;
    width: 30px;
    left: 0px;
}

.title-text.title-text-v2.title-text-v3 p {
    font-family: "Lora";
    font-style: italic;
    color: #888888;
    width: 100%;
    margin-top: 30px;
}

.title-text.title-text-v2.title-text-v3 ul.tabs {
    position: absolute;
    top: 0px;
    right: 0px;
}

.title-text.title-text-v2.title-text-v3 ul.tabs li {
    font-family: "Lora";
    font-style: italic;
    font-weight: 700;
    font-size: 18px;
    text-transform: capitalize;
    color: #888888;
}

.title-text.title-text-v2.title-text-v3 ul.tabs li.active,
.title-text.title-text-v2.title-text-v3 ul.tabs li:hover {
    color: #6dd6d6;
    border-bottom: 1px solid #6dd6d6;
}

.title-text.title-about {
    margin-top: 0px;
}

.title-text.title-about h3 {
    background: none;
}

.title-text.title-about:before {
    position: static;
}

.title-text.border-color-red h3:after {
    background: #e95252;
}

.title-text-v2 {
    text-align: center;
}

.title-text-v2 h3 {
    font: 400 36px/40px "Gotham";
    color: #2b2b2b;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.title-text-v2 .link {
    font: 400 12px/20px "Gotham";
    color: #999;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.border-title {
    width: 100%;
    display: inline-block;
    height: 1px;
    background: #ececec;
    margin-bottom: 30px;
}

h3.title-v2 {
    margin-bottom: 30px;
    text-align: left;
    font: 700 16px/20px "Gotham";
    color: #2b2b2b;
}

.align-center {
    text-align: center;
}

h3.title-v3 {
    font: 700 14px/20px "Oswald";
    letter-spacing: 3px;
    color: #000;
    text-transform: uppercase;
    width: 100%;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    margin-bottom: 25px;
}

.title-brand {
    color: #80b435;
    font: 700 30px "Gotham";
    margin-bottom: 15px;
}

.tile-lookbook-v2 {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 20px;
}

.tile-lookbook-v2 span.icons {
    font-size: 80px;
    color: #000;
}

.tile-lookbook-v2 p {
    font: 400 24px "Gotham";
    color: #000;
    line-height: 30px;
    padding: 20px;
    max-width: 1120px;
    margin: 0 auto;
}

.tile-lookbook-v2 p b {
    font-weight: 700;
}

.tile-lookbook-v2 p span {
    font-style: italic;
}

.tile-lookbook-v2 h3 {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 30px;
    position: relative;
    padding-top: 20px;
    margin-top: 10px;
}

.tile-lookbook-v2 h3:after {
    position: absolute;
    content: "";
    left: 50%;
    width: 60px;
    height: 2px;
    background: #e8e8e8;
    margin-left: -30px;
    top: 0px;
}

.title-product {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.title-product h3 {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

.title-product h3:after {
    position: absolute;
    content: "";
    left: 50%;
    height: 2px;
    width: 50px;
    background: #000;
    margin-left: -25px;
    bottom: 0px;
}

.slider-product.tabs-title-v2 {
    text-align: center;
    position: relative;
}

.slider-product.tabs-title-v2:before {
    content: "";
    background: #e8e8e8;
    width: 100%;
    left: 0px;
    top: 50px;
    height: 1px;
    position: absolute;
}

.slider-product.tabs-title-v2 .tabs {
    border: none;
    position: relative;
    width: auto;
    margin: 0 auto 30px;
    background: #fff;
}

.slider-product.tabs-title-v2 .tabs li:hover:before,
.slider-product.tabs-title-v2 .tabs li.active:before {
    bottom: -8px;
    background: #e95252;
}

ul.tabs.tabs-title.tabs-title-por li {
    padding: 0 30px;
}

.button-v2 {
    padding: 7px 10px;
    border-radius: 0px;
    border: 2px solid #fff;
    color: #fff;
    display: inline-block;
    text-transform: uppercase;
    font-family: "Oswald";
    letter-spacing: 2px;
    font-size: 10px;
}

.button-v2.hover-black {
    padding: 7px 10px;
}

.button-v2.color-black {
    color: #000;
    border-color: #000;
}

.hover-images {
    overflow: hidden;
    display: inline-block;
}

.hover-images img {
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.hover-images:hover img {
    transform: scale(1.05);
}

.title-v2 {
    width: 100%;
    text-align: center;
}

.title-v2 h3 {
    display: inline-block;
    font-size: 28px;
    font-family: "NexaBold";
    color: #333333;
    font-weight: 400;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 20px;
}

.title-v2 h3:after {
    position: absolute;
    content: "";
    width: 66px;
    height: 2px;
    background: #666;
    bottom: 0px;
    left: 50%;
    margin-left: -33px;
}

.link-icon-black {
    width: 10px;
    height: 10px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    margin-left: 5px;
}

.link-icon-white {
    width: 10px;
    height: 10px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    margin-left: 5px;
}

.link-icon-gray {
    width: 10px;
    height: 10px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    margin-left: 5px;
}

.hover-black {
    padding: 0 20px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.hover-black:hover {
    color: #fff;
    background: #000;
    border-color: #000;
}

.hover-black:hover .link-icon-black {
    background-position: center bottom;
}

.hover-black:hover .link-icon-gray {
    background-position: center bottom;
}

.hover-white {
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.hover-white:hover {
    color: #000;
    background: #fff;
    border-color: #000;
}

.hover-white:hover .link-icon-white {
    background-position: center top;
}

.title-page h3 {
    font-size: 80px;
    display: inline-block;
    width: 100%;
    font-family: "Gotham";
    color: #000;
    text-align: center;
    position: relative;
    line-height: 55px;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.title-page h3:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 0px;
    width: 225px;
    height: 7px;
}

.title-page.size-50 h3 {
    font-size: 50px;
    margin: 10px;
}

.title-page.size-50 p {
    text-align: center;
    font: 300 12px/20px "Oswald";
    color: #a5a5a5;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 30px;
}

ul.tabs.title span {
    font-size: 30px;
    color: #222;
}

ul.tabs.title li {
    font-size: 30px;
    color: #222;
}

ul.tabs.title li.active,
ul.tabs.title li:hover {
    color: #808f66;
}

ul.tabs.title p {
    font-family: "TXGR";
    font-size: 13px;
    color: #888888;
}

ul.bastian {
    display: inline-block;
    width: 100%;
    margin-bottom: 100px;
}

ul.bastian li {
    float: left;
    width: 33.33%;
    text-align: center;
    display: inline-block;
    padding: 15px 0px;
    border: 1px solid #000;
}

ul.bastian li:first-child {
    border-right: none;
}

ul.bastian li:last-child {
    border-left: none;
}

ul.bastian li p {
    font: 300 12px/20px "Oswald";
    color: #7c7c7c;
    text-transform: uppercase;
    letter-spacing: 2px;
}

ul.bastian li p span {
    font-weight: 700;
    color: #000;
}

.content-text h3 {
    font: 400 25px/30px "Gotham";
    color: #000;
    text-transform: capitalize;
    padding-bottom: 15px;
    margin-bottom: 20px;
    position: relative;
}

.content-text h3:after {
    position: absolute;
    content: "";
    left: 0px;
    width: 50px;
    height: 1px;
    background: #000;
    bottom: 0px;
}

.content-text p {
    font: 400 13px/20px "Poppins";
    color: #858585;
}

.content-text.center {
    text-align: center;
}

.content-text.center h3:after {
    left: 50%;
    margin-left: -25px;
}

.breadcrumb {
    background: transparent;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 3px solid #2e2e2e;
}

.breadcrumb li {
    font-size: 12px;
    display: inline-block;
    font-family: "Poppins";
    font-weight: 400;
    padding-right: 20px;
    position: relative;
}

.breadcrumb li:last-child:after {
    content: "";
}

.breadcrumb li:after {
    position: absolute;
    content: "";
    right: 8px;
    font-family: "FontAwesome";
    font-size: 12px;
    top: -1px;
    color: #fff;
}

.breadcrumb li.active {
    color: #717171;
}

.breadcrumb li a {
    color: #fff;
}

.return-home a:first-child {
    margin-right: 20px;
}

.banner-grid {
    padding: 0px;
}

.banner-grid img {
    width: 100%;
}

.bg-white {
    background-color: #fff;
}

.link-button {
    background: #000;
    color: #fff;
    font-weight: 700;
    border: 2px solid #000;
    font-size: 12px;
    text-transform: uppercase;
    font-style: normal;
    padding: 0px 40px;
    line-height: 40px;
    border-radius: 0px;
    letter-spacing: 3px;
}

.link-button.color-red {
    background: #e95252;
    border: 2px solid #e95252;
    color: #fff;
}

.link-button.color-red:hover {
    border-color: #e95252;
    color: #e95252;
}

.link-button:hover {
    background: #80b435;
    color: #fff;
}

.link-button.lh-55 {
    line-height: 55px;
}

.link-button.link-button-fb {
    background: #2c5990;
    color: #fff;
    font-size: 13px;
    border-color: #2c5990;
    border-radius: 25px;
    line-height: 55px;
}

.link-button.link-button-fb i.fa {
    font-size: 17px;
    line-height: 20px;
    margin-right: 10px;
    vertical-align: middle;
}

.link-button.link-button-gg {
    background: #ea3032;
    color: #fff;
    border-color: #ea3032;
    border-radius: 25px;
    line-height: 55px;
}

.link-button.link-button-gg i.fa {
    font-size: 17px;
    vertical-align: middle;
    margin-right: 10px;
    line-height: 20px;
}

.link-v1 {
    font: 500 16px/38px "Gotham";
    color: #333;
    width: 180px;
    height: 42px !important;
    text-align: center;
    display: inline-block;
    border: 1px solid #e4e4e4;
    text-transform: uppercase;
    color: #333;
    transition: all 0.3s ease 0s;
}

.link-v1.color-brand {
    color: #80b435;
    border-color: #80b435;
}

.link-v1:hover,
.link-v1:focus {
    background: #80b435;
    color: #fff;
    border-color: #80b435;
}

.link-v1.lh-50 {
    /* line-height: 48px; */
    width: auto;
    padding: 0 25px;
}

.link-v1.rt {
    background: #80b435;
    color: #fff;
    border-color: #80b435;
}

.link-v1.rt:hover,
.link-v1.rt:focus {
    color: #fff !important;
    border-color: #80b435;
    background: #80b435;
}

.link-v1.full {
    width: 100%;
}

.link-v1.bg-brand {
    background: #80b435;
    color: #fff;
    border-color: #80b435;
}

.link-v1.bg-brand:hover {
    color: #80b435;
    background: #fff;
}

.link-v1.font-300 {
    font-weight: 300;
}

.link-footer {
    text-transform: uppercase;
    font: 700 10px/20px "Gotham";
    margin-top: 30px;
    display: inline-block;
}

.link-footer i {
    font-size: 10px;
    margin-left: 5px;
}

.margin-right-20 {
    margin-right: 20px;
}

.link-ver1 {
    display: inline-block;
    height: 60px;
    min-width: 60px;
    padding: 0 20px;
    text-align: center;
    background: transparent;
    border: 2px solid #d6d6d6;
    color: #333;
    font-weight: 700;
    text-transform: uppercase;
}

.link-ver1 span {
    font: 700 18px/56px "Gotham";
}

.link-ver1 i {
    font-size: 24px;
    line-height: 56px;
    color: #333;
    font-weight: 400;
}

.link-ver1:hover {
    border-color: #80b435;
    color: #80b435;
}

.link-ver1:hover i {
    color: #80b435;
}

.link-ver1.rt {
    border-color: #80b435;
    color: #80b435;
}

.link-ver1.rt:hover {
    color: #333;
    border-color: #d6d6d6;
}

.acc-login .form-group .btn:nth-child(2n + 1) {
    width: 48%;
    margin-right: 2%;
    float: left;
    padding: 5px;
}

.acc-login .form-group .btn:nth-child(2n) {
    margin-left: 2%;
    width: 48%;
    float: left;
    padding: 5px;
}

.margin-top-30 {
    margin-top: 30px;
}

.color-red {
    color: #ff1313;
}

.button1 {
    font-family: "Oswald";
    font-weight: 700;
    color: #000;
    font-size: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: none;
}

.button2 {
    width: 140px;
    height: 40px;
    line-height: 36px;
    border: 2px solid #80b435;
    color: #80b435;
    background: transparent;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    display: inline-block;
}

.hover-control-wrap .owl-carousel:hover .owl-prev {
    opacity: 0;
    transform: translate3d(-60px, 0px, 0px);
}

.hover-control-wrap .owl-carousel:hover .owl-next {
    opacity: 0;
    transform: translate3d(60px, 0px, 0px);
}

.hover-control-wrap:hover .owl-carousel .owl-prev,
.hover-control-wrap:hover .owl-carousel .owl-next {
    opacity: 100;
    transform: translate3d(0px, 0px, 0px);
}

.color-brand {
    color: #80b435;
}

.hover-effect-images,
.hover-effect-images-ver2 {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.hover-effect-images img,
.hover-effect-images-ver2 img {
    -webkit-transition: all 900ms ease 0s;
    -o-transition: all 900ms ease 0s;
    transition: all 900ms ease 0s;
}

.hover-effect-images:before,
.hover-effect-images-ver2:before {
    background: rgba(255, 255, 255, 0.3) none repeat scroll 0 0;
    bottom: 0px;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    -webkit-transition: all 900ms ease 0s;
    -o-transition: all 900ms ease 0s;
    transition: all 900ms ease 0s;
}

.hover-effect-images:hover img,
.hover-effect-images-ver2:hover img {
    transform: scale(1.1);
}

.hover-effect-images:hover:before,
.hover-effect-images-ver2:hover:before {
    left: 50%;
    opacity: 1;
    right: 50%;
}

.hover-effect-images-ver2:after {
    background: rgba(255, 255, 255, 0.3) none repeat scroll 0 0;
    bottom: 0px;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    -webkit-transition: all 900ms ease 0s;
    -o-transition: all 900ms ease 0s;
    transition: all 900ms ease 0s;
}

.hover-effect-images-ver2:hover:after {
    top: 50%;
    bottom: 50%;
    opacity: 1;
}

/** 3. Mixins **/
/** Loadding **/
@-webkit-keyframes engoloaddingmini {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes engoloaddingmini {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/****/
/** elements mixins **/
/**
* Transition-timing-function property@mixin 
*/
/*background RGBA
============================================*/
/*inline-block
============================================*/
/****/
/****/
/** Responsive mixin **/
/** 4. Elements **/
/** 4.1. Accordions **/
.shipping-container {
    margin-bottom: 60px;
}

.shipping-container .shipping-content {
    border: 1px solid #ececec;
    display: inline-block;
    width: 100%;
    padding: 10px 0px;
}

.shipping-container .col-md-4 {
    text-align: center;
    border-right: 1px solid #ececec;
    padding: 10px 0px;
}

.shipping-container .col-md-4 h3 {
    display: inline-block;
    font-family: "Oswald";
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 3px;
    vertical-align: 3px;
    color: #000;
}

.shipping-container .col-md-4 i {
    margin-right: 10px;
    font-size: 18px;
}

.shipping-container .col-md-4:last-child {
    border-right: none;
}

.shipping-v2 {
    padding: 35px 0px;
    text-align: center;
}

@media (max-width: 1200px) {
    .shipping-v2 {
        margin-bottom: 40px;
    }
}

.shipping-v2.home3-shiping {
    background: none;
    box-shadow: 0px 8px 33px 0px rgba(0, 0, 0, 0.13);
}

@media (min-width: 1200px) {
    .shipping-v2.home3-shiping {
        margin-top: -110px;
        z-index: 999;
        position: relative;
        background: #fff;
    }
}

.shipping-v2.home3-shiping h3 {
    color: #231f20;
}

.shipping-v2.home3-shiping .col-md-3:after {
    background: #eaeaea;
}

.shipping-v2.home3-shiping.home2-shipping {
    box-shadow: none;
    margin-top: 30px;
    padding: 0px;
    background: none;
}

.shipping-v2.home3-shiping.home2-shipping .col-md-3:after {
    width: 0px;
}

.shipping-v2.home3-shiping.home2-shipping .border {
    border: 1px solid #e5e5e5;
    padding: 35px 0px;
}

.shipping-v2.home3-shiping.home4-shipping {
    box-shadow: none;
    margin-top: 0px;
    padding: 0px;
    background: none;
}

.shipping-v2 i {
    font-size: 40px;
    color: #80b435;
    margin-bottom: 5px;
}

.shipping-v2 h3 {
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 500;
    line-height: 20px;
    font-family: "Gotham";
    margin-top: 10px;
}

.shipping-v2 p {
    font-family: "Gotham";
    font-size: 11px;
    font-weight: 400;
    color: #979797;
    line-height: 15px;
}

.shipping-v2 .col-md-3 {
    position: relative;
}

.shipping-v2 .col-md-3:after {
    position: absolute;
    content: "";
    right: 0px;
    top: 0px;
    width: 1px;
    height: 98px;
    background: #404040;
}

.shipping-v2 .col-md-3:last-child:after {
    position: static;
    width: 0px;
}

/** 4.2. Call to action **/
.blog-post-container {
    display: inline-block;
    margin: 10px 0 50px;
    width: 100%;
}

.blog-post-container .blog-post-image img {
    max-width: 100%;
}

.blog-post-container .post-date {
    font-family: "Lora";
    color: #aaa;
    line-height: 20px;
    font-style: italic;
    margin: 15px 0px;
}

.blog-post-container .post-date i {
    font-size: 15px;
    line-height: 20px;
    margin-right: 5px;
    color: #aaa;
}

.blog-post-container h3 {
    color: #555;
    text-transform: uppercase;
    line-height: 20px;
    margin-bottom: 10px;
    min-height: 50px;
}

.blog-post-container .post-tags a {
    display: inline-block;
    color: #aaa;
    font-family: "Lora";
    font-style: italic;
    line-height: 20px;
    margin-bottom: 10px;
}

.blog-post-container .post-tags a.like {
    margin-right: 20px;
    position: relative;
}

.blog-post-container .post-tags a.like::after {
    position: absolute;
    content: "";
    background-color: #aaa;
    height: 12px;
    width: 1px;
    right: -12px;
    top: 3px;
}

.blog-post-container .post-tags a i {
    margin-right: 5px;
}

.blog-post-container .read-more a {
    display: inline-block;
    padding: 10px 30px;
    border-radius: 10px;
    background-color: #eee;
    color: #666;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 20px;
}

.blog-post-container .read-more a:hover {
    background-color: #80b435;
    color: #fff;
}

/** 4.3. Our New **/
.slider-product {
    display: inline-block;
    width: 100%;
}

.slider-product .tabs {
    display: inline-block;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 30px;
}

.slider-product .tabs li {
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #bdbdbd;
    margin: 0 15px 15px;
    letter-spacing: 3px;
    padding: 0 15px;
    padding-top: 0px;
    position: relative;
}

.slider-product .tabs li:after {
    position: absolute;
    content: "";
    right: -22px;
    top: 6px;
    width: 7px;
    height: 7px;
}
.slider-product .tabs li:hover,
.slider-product .tabs li.active {
    color: #000;
}

.slider-product .tabs li:hover:before,
.slider-product .tabs li.active:before {
    position: absolute;
    content: "";
    bottom: -16px;
    left: 15px;
    right: 15px;
    height: 2px;
    background: #000;
}

.slider-product .tabs li:last-child:after {
    width: 0px;
    height: 0px;
}

.tab-container .owl-stage-outer {
    padding: 20px 0px;
}

.hover-shadow.owl-carousel .owl-stage-outer {
    width: calc(100% + 30px);
    padding-left: 15px;
    margin-left: -15px;
}

.tp-banner-container {
    overflow: hidden;
}

.tp-banner-container li img {
    max-width: 100%;
}

.title-text ul.tabs {
    float: right;
    display: inline-block;
}

.title-text ul.tabs li {
    display: inline-block;
    cursor: pointer;
    margin: 0 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #555555;
    padding-bottom: 5px;
}

.title-text ul.tabs li:last-child {
    margin-right: 0px;
}

.title-text ul.tabs li.active {
    color: #80b435;
    border-bottom: 3px solid #80b435;
}

.related-products .product-details {
    background: #f5f5f5;
    padding: 60px;
    position: relative;
}

.related-products .product-details:after {
    position: absolute;
    content: "";
    width: 30px;
    height: 118px;

    right: 45px;
}

.related-products .product-details h4 {
    font-family: "Oswald";
    font-size: 14px;
    margin-top: 10px;
    color: #000;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    padding-bottom: 15px;
    margin-bottom: 30px;
    position: relative;
}

.related-products .product-details h4:after {
    position: absolute;
    width: 50px;
    height: 3px;
    background: #000;
    left: 0px;
    bottom: 0px;
    content: "";
}

.related-products .product-details h3 {
    font-family: "Gotham";
    font-size: 40px;
    line-height: 30px;
    color: #000;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.related-products .product-details .category {
    font-family: "Oswald";
    font-size: 12px;
    color: #9d9d9d;
    text-transform: uppercase;
    line-height: 20px;
    margin-bottom: 10px;
    letter-spacing: 2px;
    font-weight: 300;
}

.related-products .product-details .price {
    font-family: "Poppins";
    font-size: 40px;
    font-weight: 300;
    color: #000;
    margin: 25px 0 16px;
}

.related-products .product-details .product-content {
    color: #858585;
    font-size: 13px;
    font-family: "Poppins";
    line-height: 25px;
    margin-bottom: 50px;
}

.related-products .product-details a {
    font-family: "Oswald";
    font-weight: 700;
    letter-spacing: 3px;
    font-size: 10px;
    color: #000;
    text-transform: uppercase;
    display: inline-block;
    height: 32px;
    line-height: 28px;
    padding: 0px 10px;
    margin-bottom: 0px;
    border: 2px solid #000;
}

.related-products .slider-text {
    height: 46px;
    width: 100%;
    background: #e9e9e9;
}

.related-products .slider-text h3 {
    font-family: "Oswald";
    font-weight: 700;
    font-size: 12px;
    color: #000;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    line-height: 46px;
}

.related-products .slider-product.owl-loaded .products .owl-prev,
.related-products .slider-product.owl-loaded .products .owl-next {
    margin: 0px;
    margin-top: -5px;
    background: #000;
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
    position: relative;
    width: 50%;
    height: 40px;
    box-shadow: 0 0 0 30px #2c2c2c inset;
}

.related-products .slider-product.owl-loaded .products .owl-prev:hover,
.related-products .slider-product.owl-loaded .products .owl-next:hover {
    box-shadow: 0 0 0 1px #000 inset;
}

.related-products .slider-product.owl-loaded .products .owl-prev i,
.related-products .slider-product.owl-loaded .products .owl-next i {
    color: #fff;
    margin-top: 8px;
}

.related-products .slider-product.owl-loaded .products .owl-prev {
    left: 0px;
    border-right: 1px solid #353535;
}

.related-products .slider-product.owl-loaded .products .owl-next {
    right: 0px;
    float: right;
}

.related-products .product-images {
    overflow: hidden;
}

.related-products .product-images img {
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.related-products .product-images:hover img {
    transform: scale(1.05);
}

.featured-product .col-md-5 .product {
    width: 50%;
    float: left;
}

.featured-product .col-md-5 .product:nth-child(2n + 1) {
    padding-right: 15px;
}

.featured-product .col-md-5 .product:nth-child(2n) {
    padding-left: 15px;
}

.featured-product .col-md-7 .products {
    background: #efefef;
}

.featured-product .col-md-7 .products .product-title {
    font-family: "Gotham";
    font-size: 40px;
    color: #000;
    margin-bottom: 15px;
    margin-top: 28px;
}

.featured-product .col-md-7 .products .cat {
    font-size: 12px;
    color: #9d9d9d;
    font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 15px;
}

.featured-product .col-md-7 .products .product-price {
    font-family: "Poppins";
    font-size: 20px;
    color: #000;
    margin-bottom: 20px;
}

.featured-product .dots-show .owl-dots {
    bottom: 200px;
}

.tab-content {
    height: 0px;
}

.tab-content .product {
    display: none;
}

.tab-content.active,
.ordering span.tab-content:hover {
    height: auto;
}

.tab-content.active .product,
.ordering span.tab-content:hover .product {
    display: inline-block;
}

.hoz-tab-container .tab-content {
    display: none;
}

.hoz-tab-container .tab-content.active,
.hoz-tab-container .ordering span.tab-content:hover,
.ordering .hoz-tab-container span.tab-content:hover {
    display: block;
}

.slider-one-item-home3 {
    margin-bottom: 30px;
}

.slider-one-item-home3 .product {
    position: relative;
}

.slider-one-item-home3 .product .text {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 550px;
    height: 350px;
    margin-left: -225px;
    margin-top: -175px;
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
}

.slider-one-item-home3 .product .text h4 {
    font-weight: 300;
    letter-spacing: 2px;
    color: #9a9a9a;
    font-style: 12px;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 20px;
    margin-top: 50px;
}

.slider-one-item-home3 .product .text h4:after {
    position: absolute;
    content: "";
    width: 55px;
    height: 1px;
    background: #000;
    margin-left: -23px;
    left: 50%;
    bottom: 0px;
}

.slider-one-item-home3 .product .text h3 {
    font-family: "Gotham";
    font-size: 40px;
    color: #000;
    line-height: 35px;
    margin-bottom: 25px;
}

.slider-one-item-home3 .product .text p {
    font-family: "Poppins";
    font-size: 13px;
    color: #858585;
    line-height: 20px;
    max-width: 390px;
    padding: 0 20px;
    margin: 0 auto;
}

.slider-one-item-home3 .product .text button {
    border: none;
    color: #fff;
    font-size: 12px;
    background: #000;
    padding: 0px 65px;
    height: 46px;
    line-height: 42px;
    margin-top: 25px;
    border: 2px solid #000;
}

.slider-one-item-ver2 .slider-dot-images h3 {
    color: #2b2b2b;
}

.slider-one-item-ver2 .slider-dot-images p {
    color: #2b2b2b;
}

ul.tabs.tabs-title {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-bottom: 30px;
}

ul.tabs.tabs-title.tabs-title-v2 {
    background: #131313;
    margin-bottom: 0px;
    padding: 30px 0px 40px;
}

ul.tabs.tabs-title.tabs-title-v2 li {
    color: #fff;
}

ul.tabs.tabs-title li {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    padding: 0 17px;
    cursor: pointer;
    font: 400 16px "Gotham";
    color: #2b2b2b;
}

@media (max-width: 460px) {
    ul.tabs.tabs-title li {
        padding: 0 10px;
        font-size: 16px;
        margin-bottom: 5px;
    }
}

ul.tabs.tabs-title li.active,
ul.tabs.tabs-title li:hover {
    color: #80b435;
}

ul.tabs.tabs-title li.active span,
ul.tabs.tabs-title li:hover span {
    color: #80b435;
    border-bottom: 1px solid #e95252;
}

ul.tabs.tabs-title li.active span.count,
ul.tabs.tabs-title li:hover span.count {
    border: none;
}

ul.tabs.tabs-title li span.count {
    color: #e95252;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    vertical-align: 10px;
}

.slider-product-2-item,
.slider-product-3-item {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}

.slider-product-2-item .col-md-3,
.slider-product-2-item .col-md-9,
.slider-product-3-item .col-md-3,
.slider-product-3-item .col-md-9 {
    padding: 0px !important;
}

.wrap-slider-slick-home8 {
    display: inline-block;
    width: 100%;
}

.slider-slick-home8 .slick-slide {
    opacity: 1;
}

.slider-slick-home8 .slick-slide .product-images {
    display: inline-block;
    position: relative;
}

.slider-slick-home8 .slick-slide .product-images:after {
    position: absolute;
    content: "";
    background: rgba(255, 255, 255, 0.7);
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
}

.slider-slick-home8 .slick-slide.slick-center .product-images:after {
    width: 0px;
}

.slider-slick-home8 .slick-slide h3 {
    font: 400 40px "Gotham";
    color: #000;
    text-transform: capitalize;
    line-height: 40px;
    margin-bottom: 10px;
}

.slider-slick-home8 .slick-slide p {
    font: 400 20px "Poppins";
    line-height: 20px;
    margin-bottom: 20px;
}

.slider-slick-home8 .slick-slide .text {
    padding-top: 30px;
}

.slider-slick-home8 .slick-next {
    right: 500px;
}

.slider-slick-home8 .slick-prev {
    /* left: 500px; */
    z-index: 999;
}
.slick-prev {
    z-index: 999;
    left: 10px !important;
}
.slick-next {
    z-index: 999;
    right: 15px !important;
}
.tab-product-all-v3 .product-title {
    position: absolute;
    width: 100%;
    top: 30%;
    left: 0px;
    text-align: center;
    color: #fff;
    position: absolute;
    font: 400 14px "Poppins";
    z-index: 9;
    transition: all 0.3s ease 0s;
    opacity: 0;
    transform: translate(0px, 0px);
}

.tab-product-all-v3 .quick-view {
    transform: translate(0px, 0px);
    position: absolute;
    top: 30%;
    left: 0px;
    width: 100%;
    text-align: center;
    transition: all 0.3s ease 0s;
    opacity: 0;
}

.tab-product-all-v3 .quick-view i {
    color: #fff;
    font-size: 18px;
}

.tab-product-all-v3 .product-price {
    z-index: 9;
}

.tab-product-all-v3 .product {
    position: relative;
}

.tab-product-all-v3 .product:hover .product-price {
    color: #fff !important;
}

.tab-product-all-v3 .product:hover .product-images img {
    opacity: 0.5;
}

.tab-product-all-v3 .product:hover .product-title {
    opacity: 1;
    transform: translate(0px, 80px);
}

.tab-product-all-v3 .product:hover .quick-view {
    transform: translate(0px, 30px);
    opacity: 1;
}

.tab-product-all-v3 .product:hover .quick-view:after {
    position: absolute;
    content: "";
    left: 50%;
    width: 40px;
    margin-left: -20px;
    bottom: -10px;
    height: 2px;
    background: #fe5252;
}

.tab-product-all-v3 .product .product-images {
    background: #000;
}

.tab-product-all-v3 .product .product-images img {
    transition: all 0.3s ease 0s;
}

@media (max-width: 767px) {
    .slider-full-screen2 {
        margin-top: 15px;
    }
}

.slider-full-screen2 .bx-wrapper {
}

.slider-full-screen2 .bx-wrapper .bx-controls-direction a {
    width: 50%;
    margin-top: 0px;
    top: inherit;
    bottom: -35px;
    height: 38px;
    transition: all 0.3s ease 0s;
}

.slider-full-screen2 .bx-wrapper .bx-controls-direction a:after {
    position: absolute;
    font-size: 16px;
    left: 50%;
    top: 15px;

    width: 20px;
    content: "";
    margin-left: -10px;
}

.slider-full-screen2 .bx-wrapper .bx-controls-direction a.bx-prev:after {
    background-position: center bottom 14px;
}

.slider-full-screen2 .bx-wrapper .bx-prev {
    right: 0px;
    left: inherit;
    background: #000;
    box-shadow: 0 0 0 30px #2c2c2c inset;
}

.slider-full-screen2 .bx-wrapper .bx-prev:hover {
    box-shadow: 0 0 0 1px #000 inset;
}

.slider-full-screen2 .bx-wrapper .bx-next {
    left: 0px;
    right: inherit;
    background: #2c2c2c;
    box-shadow: 0 0 0 30px #000 inset;
    border-right: 1px solid #353535;
}

.slider-full-screen2 .bx-wrapper .bx-next:hover {
    box-shadow: 0 0 0 1px #2c2c2c inset;
}

.slider-full-screen2 .slide {
    display: inline-block;
    width: 100%;
    float: left;
    overflow: hidden;
}

.slider-full-screen2 .slide:after,
.slider-full-screen2 .slide:before {
    position: absolute;
    content: "";
    background: #4a4a49;
    height: 1px;
    width: 100%;
    left: 50%;
    bottom: 0px;
}

.slider-full-screen2 .slide:before {
    bottom: 50%;
    width: 30px;
    background: #fff;
}

.slider-full-screen2 .slide .text,
.slider-full-screen2 .slide .images {
    display: inline-block;
    float: left;
    width: 50%;
}

.slider-full-screen2 .slide .images img {
    width: 100%;
}

.slider-full-screen2 .slide .text {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    padding-left: 50px;
}

.slider-full-screen2 .slide .text h3 {
    color: #fff;
    font: 300 14px/20px "Oswald";
    text-transform: uppercase;
    letter-spacing: 3px;
}

.slider-full-screen2 .slider-one-item .product .text {
    position: absolute;
    left: 100px;
    top: 40%;
    text-align: left;
    max-width: 580px;
}

.slider-full-screen2 .slider-one-item .product .text h3 {
    font-family: "Gotham";
    font-size: 40px;
    line-height: 30px;
    color: #000;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.slider-full-screen2 .slider-one-item .product .text .price {
    font-family: "Poppins";
    font-size: 40px;
    font-weight: 300;
    color: #000;
    margin: 25px 0 16px;
}

.slider-full-screen2 .slider-one-item .product .text .product-content {
    color: #858585;
    font-size: 13px;
    font-family: "Poppins";
    line-height: 25px;
    margin-bottom: 20px;
}

.slider-full-screen2 .slider-one-item .product .text a {
    font-family: "Oswald";
    font-weight: 700;
    letter-spacing: 3px;
    font-size: 10px;
    color: #000;
    text-transform: uppercase;
    display: inline-block;
    height: 32px;
    line-height: 28px;
    padding: 0px 10px;
    margin-bottom: 0px;
    border: 2px solid #000;
}

.slider-full-screen2 .slider-one-item .owl-dots {
    text-align: left;
    padding-left: 100px;
    bottom: 150px;
}

.upsell-product.products.owl-carousel .owl-nav .owl-prev,
.upsell-product.products.owl-carousel .owl-nav .owl-next {
    opacity: 1;
    transform: translate(0, 0);
    width: 80px;
    height: 30px;
    border: 1px solid #cdcdcd;
    top: -20px;
    left: calc(50% + 5px);
}

.upsell-product.products.owl-carousel .owl-nav .owl-prev i,
.upsell-product.products.owl-carousel .owl-nav .owl-next i {
    margin-top: 0px;
    line-height: 26px;
}

.upsell-product.products.owl-carousel .owl-nav .owl-prev:hover,
.upsell-product.products.owl-carousel .owl-nav .owl-next:hover {
    border-color: #80b435;
}

.upsell-product.products.owl-carousel .owl-nav .owl-prev:hover i,
.upsell-product.products.owl-carousel .owl-nav .owl-next:hover i {
    color: #80b435;
}

.upsell-product.products.owl-carousel .owl-nav .owl-prev {
    left: calc(50% - 85px);
}

.upsell-product.products .item-inner {
    padding-top: 20px;
    padding-bottom: 40px;
}

.home7-control.owl-carousel .owl-nav .owl-prev,
.home7-control.owl-carousel .owl-nav .owl-next {
    margin: 0px;
    background: rgba(0, 0, 0, 0.3);
    height: 50px;
    width: 50px;
    transform: translateY(-50%);
}

.home7-control.owl-carousel .owl-nav .owl-prev:hover,
.home7-control.owl-carousel .owl-nav .owl-next:hover {
    background: black;
}

.home7-control.owl-carousel .owl-nav .owl-prev i,
.home7-control.owl-carousel .owl-nav .owl-next i {
    color: #fff;
    margin-top: 13px;
}

.slider-user {
    padding-top: 80px;
    padding-bottom: 50px;
    background-attachment: fixed;
}

.slider-user .slider-dot-images p,
.slider-user .slider-dot-images h3 {
    color: #fff;
}

.slider-user .slider-dot-images h3 {
    font-weight: 700;
}

.slider-dot-images {
    margin-top: 80px;
    padding: 0 20px;
    margin-bottom: 30px;
}

.slider-dot-images .slider-nav {
    max-width: 450px;
    margin-top: 80px;
}

.slider-dot-images .slider-nav img {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.slider-dot-images .slider-nav .slick-slide {
    transform: scale(0.8);
    transition: all 0.5s ease 0s;
    opacity: 0.3;
    cursor: pointer;
}

.slider-dot-images .slider-nav .slick-slide.slick-current,
.slider-dot-images .slider-nav .slick-slide:hover {
    transform: scale(1);
    opacity: 1;
}

.slider-dot-images .owl-dots {
    text-align: center;
}

.slider-dot-images .owl-dots .owl-dot {
    display: inline-block;
    background-size: 100% auto;
    width: 75px;
    height: 75px;
    text-align: center;
    border-radius: 50%;
    opacity: 0.3;
    transform: scale(0.85);
    transition: all 0.5s ease 0s;
}

.slider-dot-images .owl-dots .owl-dot.active,
.slider-dot-images .owl-dots .ordering span.owl-dot:hover,
.ordering .slider-dot-images .owl-dots span.owl-dot:hover,
.slider-dot-images .owl-dots .owl-dot:hover {
    opacity: 1;
    transform: scale(1);
}

@media (max-width: 420px) {
    .slider-dot-images .owl-dots .owl-dot {
        width: 60px;
        height: 60px;
    }
}

@media (max-width: 320px) {
    .slider-dot-images .owl-dots .owl-dot {
        width: 50px;
        height: 50px;
    }
}

.slider-dot-images p {
    max-width: 810px;
    font: 400 16px/25px "Gotham";
    color: #fff;
    margin: auto;
    margin-bottom: 30px;
    padding-bottom: 30px;
    margin-top: 20px;
    position: relative;
}

.slider-dot-images p:after {
    position: absolute;
    margin: auto;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 1px;
    width: 90px;
    background: #c1c1c1;
    content: "";
}

.slider-dot-images h3 {
    font: 700 24px/30px "Gotham";
    color: #ffffff;
    letter-spacing: 2px;
}

.slider-dot-images a {
    color: #80b435;
    font: 300 14px "Gotham";
}

.slider-one-item-ver2 .slider-dot-images .slider-nav {
    margin-top: 0px;
}

.brand-content {
    height: 170px;
}

.brand-content .items {
    padding: 15px 0px;
}

.featured-products {
    width: 100%;
    display: inline-block;
    margin-bottom: 30px;
}

@media (min-width: 1200px) {
    .featured-products.home_2 .products .product {
        width: 20%;
    }
}

.featured-products .products .product {
    /* float: left; */
    padding: 0 10px;
    position: relative;
    border-bottom: 1px solid #e8e8e8;
    padding-top: 30px;
    padding-bottom: 10px;
}

.featured-products .products .product:after {
    position: absolute;
    content: "";
    top: 30px;
    bottom: 30px;
    width: 1px;
    background: #e8e8e8;
    right: 0px;
}

@media (min-width: 1200px) {
    .featured-products .products .product:nth-last-child(1),
    .featured-products .products .product:nth-last-child(2),
    .featured-products .products .product:nth-last-child(3),
    .featured-products .products .product:nth-last-child(4),
    .featured-products .products .product:nth-last-child(5),
    .featured-products .products .product:nth-last-child(6) {
        /* border-bottom: none; */
    }
    .featured-products .products .product:nth-child(6n):after {
        /* width: 0px; */
    }
}

@media (max-width: 1119px) and (min-width: 768px) {
    .featured-products .products .product {
        /* width: 33.33%; */
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .featured-products .products .product {
        /* width: 50%; */
    }
    .featured-products .products .product:nth-last-child(1),
    .featured-products .products .product:nth-last-child(2),
    .featured-products .products .product:nth-last-child(3),
    .featured-products .products .product:nth-last-child(4) {
        /* border-bottom: none; */
    }
    .featured-products .products .product:nth-child(4n):after {
        /* width: 0px; */
    }
}

@media (max-width: 480px) {
    .featured-products .products .product {
        /* width: 50%; */
    }
    .featured-products .products .product:nth-last-child(1),
    .featured-products .products .product:nth-last-child(2) {
        /* border-bottom: none; */
    }
    .featured-products .products .product:nth-child(2n):after {
        /* width: 0px; */
    }
}

@media (max-width: 980px) and (min-width: 768px) {
    .featured-products .products.ver2 .product p.product-title {
        margin-top: -60px;
        font: 400 16px/20px "Gotham";
    }
}

.featured-products.new-arrivals .products .product {
    border: none;
    text-align: left;
}

.rtl .featured-products.new-arrivals .products .product {
    text-align: right;
}

.featured-products.new-arrivals .products .product:after {
    width: 0px;
}

.featured-products.new-arrivals .products .product .product-title {
    padding-left: 20px;
    padding-right: 20px;
}

.featured-products.new-arrivals .products .product .product-price-old {
    padding-left: 20px;
}

@media (max-width: 480px) {
    .featured-products.new-arrivals .products .product {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
    .featured-products.new-arrivals .products .product .product-title {
        padding-left: 10px;
    }
    .featured-products.new-arrivals .products .product .product-price-old {
        padding-left: 10px;
    }
}

.owl-nav-ver1 .owl-carousel .owl-nav .owl-prev,
.owl-nav-ver1 .owl-carousel .owl-nav .owl-next {
    background: #959595;
    height: 50px;
    width: 40px;
}

.owl-nav-ver1 .owl-carousel .owl-nav .owl-prev:hover,
.owl-nav-ver1 .owl-carousel .owl-nav .owl-next:hover {
    background: #80b435;
}

.owl-nav-ver1 .owl-carousel .owl-nav .owl-prev i,
.owl-nav-ver1 .owl-carousel .owl-nav .owl-next i {
    color: #fff;
    font-size: 36px;
    margin: 0px;
    line-height: 50px;
}

/** 4.4. Banner Center **/
.banner-private {
    position: relative;
}

.banner-private img {
    width: 100%;
}

.banner-private .text {
    position: absolute;
    bottom: 50px;
    left: 50px;
    color: #333;
}

.banner-private .text h2 {
    text-transform: uppercase;
}

.banner-private .text p {
    font-weight: 300;
}

.banner-private .text img {
    width: auto;
}

.banner-private.center .text {
    text-align: center;
    right: 35px;
    bottom: 65%;
    transform: translateY(50%);
}

.banner-private.center .text img {
    margin: auto;
    margin-bottom: 15px;
    opacity: 1;
}

.banner-private.center .text h2 {
    font: 400 60px/60px "Gotham";
    color: #333;
    text-transform: capitalize;
    margin-bottom: 15px;
    padding-bottom: 15px;
    position: relative;
}

.banner-private.center .text p {
    font: 300 18px/25px "Gotham";
    color: #565656;
}

.banner-home2 img {
    width: 100%;
}

.banner-home2 .col-md-5 {
    position: relative;
}

.banner-home2 .col-md-5 a:first-child {
    margin-bottom: 37px;
}

.banner-home2 .col-md-5 .hover-effect-border {
    display: inline-block;
    position: relative;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .banner-home2 .col-md-5 .hover-effect-border {
        width: 100%;
    }
}

.banner-home2 .col-md-5 .hover-effect-border .text {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0px;
    top: 20%;
}

.banner-home2 .col-md-5 .hover-effect-border .text h4 {
    font-weight: 300;
    letter-spacing: 2px;
    color: #9a9a9a;
    font-style: 12px;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.banner-home2 .col-md-5 .hover-effect-border .text h4:after {
    position: absolute;
    content: "";
    width: 55px;
    height: 1px;
    background: #000;
    margin-left: -23px;
    left: 50%;
    bottom: 0px;
}

.banner-home2 .col-md-5 .hover-effect-border .text h3 {
    font-family: "Gotham";
    font-size: 40px;
    color: #000;
    line-height: 35px;
    margin-bottom: 25px;
}

.banner-home2 .col-md-5 .hover-effect-border .text p {
    font-family: "Poppins";
    font-size: 13px;
    color: #858585;
    line-height: 20px;
    max-width: 390px;
    padding: 0 20px;
    margin: 0 auto;
}

.banner-home2 .col-md-5 .hover-effect-border .text button {
    border: none;
    color: #fff;
    font-size: 12px;
    background: #000;
    padding: 0px 65px;
    height: 46px;
    line-height: 42px;
    margin-top: 25px;
    border: 2px solid #000;
}

.banner-header {
    position: relative;
}

.banner-header img {
    width: 100%;
}

.banner-header .text {
    position: absolute;
    width: 100%;
    height: 170px;
    top: 50%;
    margin-top: -85px;
    border: 4px solid #fff;
    text-align: center;
    color: #fff;
    padding: 35px;
}

.banner-header .text h3 {
    display: inline-block;
    width: 100%;
    text-transform: uppercase;
    font-size: 65px;
    font-family: "Gotham";
    line-height: 50px;
    letter-spacing: 15px;
    padding-bottom: 15px;
}

.banner-header .text p {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    padding-top: 10px;
    font-family: "Gotham";
    color: #80b435;
}

.banner-header .text p i {
    font-size: 14px;
    margin: 0 20px;
    color: #fff;
}

.banner-header .text p a {
    color: #fff;
}

.banner-header .text p a:hover {
    color: #80b435;
}

.banner-header.banner-lbook3 img {
    width: 100%;
}

.banner-header.banner-lbook3 .text {
    border: none;
}

.banner-header.banner-lbook3 .text img {
    width: auto;
}

.banner-header.banner-lbook3 .text h3 {
    display: inline-block;
    width: 100%;
    text-transform: capitalize;
    font-size: 65px;
    font-family: "Gotham";
    line-height: 65px;
    letter-spacing: 5px;
    padding-bottom: 15px;
}

.banner-header.banner-lbook3 .text p {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    padding-top: 10px;
    font-family: "Gotham";
    color: #80b435;
}

.banner-header.banner-lbook3 .text p i {
    font-size: 14px;
    margin: 0 20px;
    color: #fff;
}

.banner-header.banner-lbook3 .text p a {
    color: #fff;
}

.banner-header.banner-lbook3 .text p a:hover {
    color: #80b435;
}

.banner-header.banner-lbook3.single-post .text {
    width: 100%;
    left: 0px;
    margin-left: 0px;
}

.banner-header.banner-lbook3.single-post .text h3 {
    font: 400 50px/50px "Gotham";
}

.banner-header.banner-lbook3.single-post .text p {
    font: 400 13px/20px "Poppins";
    color: #a3a3a3;
}

.banner-header.banner-lbook3.single-post .text p.cat {
    font: 300 12px/20px "Oswald";
    letter-spacing: 2px;
    color: #9a9a9a;
}

.banner-top-home3 .items {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}

.banner-top-home3 .items .text {
    background: rgba(255, 255, 255, 0.3);
    padding: 30px 0px 0px 0px;
    position: absolute;
    top: 0px;
    right: 30px;
    left: 30px;
    bottom: 0px;
    margin: auto;
    text-align: center;
    height: 170px;
}

.banner-top-home3 .items .text h3 {
    font-family: "Gotham";
    font-size: 40px;
    text-transform: capitalize;
    color: #fff;
    line-height: 40px;
    position: relative;
    margin-bottom: 50px;
}

.banner-top-home3 .items .text h3:after {
    position: absolute;
    left: 50%;
    bottom: -25px;
    width: 66px;
    margin-left: -33px;
    height: 3px;
    content: "";
    background-color: #fff;
}

.banner-top-home3 .items .text p {
    font-weight: 300;
    color: #fff;
    letter-spacing: 4px;
    text-transform: uppercase;
    line-height: 30px;
}

.banner-top-home3 .items a {
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    border-bottom: 1px solid #fff;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding-bottom: 4px;
    display: inline-block;
    text-align: center;
    margin: 30px 0px;
}

.banner-home4-top {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}

.banner-home4-top .col-md-6 {
    padding: 0px;
    position: relative;
}

.banner-home4-top .col-md-6 .col-md-12 {
    padding: 0px;
    position: relative;
}

.banner-home4-top img {
    width: 100%;
}

.banner-home4-top .text {
    position: absolute;
    left: 40px;
    bottom: 40px;
}

.banner-home4-top .text h3 {
    font-family: "Gotham";
    font-size: 35px;
    color: #000;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 10px;
}

.banner-home4-top .text p {
    font-weight: 300;
    padding-left: 3px;
    font-size: 12px;
    color: #828282;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-bottom: 30px;
    position: relative;
}

.banner-home4-top .text p:after {
    position: absolute;
    content: "";
    bottom: 0px;
    left: 3px;
    width: 50px;
    background: #e95252;
    height: 3px;
}

.banner-home4 .catbox-container .items {
    width: 100%;
}

.banner-home4 .catbox-container .items:first-child {
    margin-bottom: 12px;
}

.banner-home4 .hover-images {
    position: relative;
}

.banner-home4 .hover-images img {
    max-width: 100%;
}

.banner-home4 .hover-images .text {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    margin: auto;
}

.banner-home4 .hover-images .text h4 {
    background: #80b435;
    color: #fff;
    text-transform: uppercase;
}

@media (max-width: 1200px) and (min-width: 768px) {
    .banner-home4 .row {
        margin-left: -7px;
        margin-right: -7px;
    }
    .banner-home4 .col-sm-4 {
        padding: 0px 7px;
    }
    .banner-home4 .col-sm-4 .items:first-child {
        margin-bottom: 14px;
    }
}

@media (min-width: 441px) and (max-width: 767px) {
    .banner-home4 .row {
        margin-left: -7px;
        margin-right: -7px;
    }
    .banner-home4 .col-sm-4 {
        width: 50%;
        float: left;
        padding-left: 7px;
        padding-right: 7px;
    }
    .banner-home4 .col-sm-4 .items:first-child {
        margin-bottom: 14px;
    }
    .banner-home4 .col-sm-4:last-child {
        width: 100%;
    }
    .banner-home4 .col-sm-4:last-child .items {
        width: 50%;
        padding-left: 7px;
    }
    .banner-home4 .col-sm-4:last-child .items:first-child {
        padding-right: 7px;
        padding-left: 0px;
    }
}

/* @media (max-width: 440px) {
    .banner-home4 .catbox-container .items:first-child {
        margin-bottom: 0px;
    }
} */

.blog-post-container-v2.blog-post-container {
    margin: 0px;
}

.blog-post-container-v2.blog-post-container .blog-post-item {
    margin: 0px;
}

.blog-post-container-v2.blog-post-container .blog-post-item .blog-post-content {
    left: -1px;
    right: -1px;
    bottom: 4px;
    background: rgba(0, 0, 0, 0.3);
}

.blog-post-container-v2.blog-post-container .blog-post-item .blog-post-content .cat {
    color: #fff;
    margin: 15px 0px !important;
}

.blog-post-container-v2.blog-post-container .blog-post-item .blog-post-content .cat:after {
    background: #6f6f6f;
    bottom: -10px;
}

.blog-post-container-v2.blog-post-container .blog-post-item .blog-post-content .blog-title {
    color: #fff;
    margin-bottom: 35px;
}

.blog-post-container-v2.blog-post-container .blog-post-item .blog-post-content:before {
    border: none;
    position: static;
}

.banner-private-v2 {
    position: relative;
}

.banner-private-v2 .text {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0px;
    top: 20%;
}

.banner-private-v2 .text h4 {
    font-weight: 300;
    letter-spacing: 2px;
    color: #9a9a9a;
    font-style: 12px;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.banner-private-v2 .text h4:after {
    position: absolute;
    content: "";
    width: 55px;
    height: 1px;
    background: #000;
    margin-left: -23px;
    left: 50%;
    bottom: 0px;
}

.banner-private-v2 .text h3 {
    font-family: "Gotham";
    font-size: 40px;
    color: #000;
    line-height: 35px;
    margin-bottom: 25px;
}

.banner-private-v2 .text p {
    font-family: "Poppins";
    font-size: 13px;
    color: #858585;
    line-height: 20px;
    max-width: 390px;
    padding: 0 20px;
    margin: 0 auto;
}

.banner-private-v2 .text button {
    border: none;
    color: #fff;
    font-size: 12px;
    background: #000;
    padding: 0px 65px;
    height: 46px;
    line-height: 42px;
    margin-top: 25px;
    border: 2px solid #000;
}

.banner-hom7-center .banner-private-v2 {
    text-align: center;
}

.banner-hom7-center .banner-private-v2 .text {
    width: 555px;
    height: 350px;
    background: rgba(255, 255, 255, 0.8);
    top: 50%;
    left: 50%;
    margin-top: -175px;
    margin-left: -273px;
}

.banner-hom7-center .banner-private-v2 .text h4 {
    margin-top: 60px;
}

.banner-home7-center1 .text,
.banner-home7-center2 .text {
    text-align: center;
    left: 0px;
    width: 100%;
    bottom: inherit;
    top: 25%;
    position: absolute;
}

.banner-home7-center1 .text span,
.banner-home7-center2 .text span {
    font-size: 80px;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 25px;
    display: inline-block;
}

.banner-home7-center1 .text span:after,
.banner-home7-center2 .text span:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 0px;
    width: 60px;
    height: 2px;
    margin-left: -30px;
    background: #fff;
}

.banner-home7-center1 .text h3,
.banner-home7-center2 .text h3 {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 3px;
    max-width: 265px;
    line-height: 25px;
    margin: 0 auto;
}

.banner-home7-center2 .text {
    text-align: center;
}

.banner-home7-center2 .text span,
.banner-home7-center2 .text h3 {
    color: #000;
    max-width: 100%;
}

.banner-home7-center2 .text span {
    font-size: 80px;
}

.banner-home7-center2 .text span:after {
    background: #000;
}

.banner-home3-colection-top {
    position: relative;
}

.banner-home3-colection-top img {
    width: 100%;
}

.banner-home3-colection-top a.button {
    position: absolute;
    color: #fff;
    left: 50%;
    bottom: 20%;
    font-size: 13px;
    letter-spacing: 4px;
    margin-left: -90px;
}

.banner-home3-colection-top a.button:hover {
    background: none !important;
}

.banner-home3 {
    margin-bottom: 50px;
}

@media (min-width: 1200px) {
    .banner-home3 {
        margin-bottom: 130px;
    }
}

.banner-home3 .container {
    position: relative;
}

.banner-home3 .text {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    margin: auto;
    text-align: center;
    transform: translate(0, -50%);
}

.banner-home3 .text img {
    margin-bottom: 20px;
}

.banner-home3 .text h4 {
    font: 300 24px/30px "Gotham";
    color: #fff;
    position: relative;
    margin-bottom: 35px;
    padding-bottom: 25px;
    text-transform: uppercase;
}

.banner-home3 .text h4:after {
    position: absolute;
    content: "";
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 1px;
    background-color: #fff;
    width: 110px;
    margin: auto;
}

.banner-home3 .text h3 {
    font: 500 60px/70px "Gotham";
    color: #fff;
    letter-spacing: 3px;
}

.banner-home3 .text p {
    font: 300 24px/30px "Gotham";
    color: #fff;
}

@media (max-width: 1200px) {
    .banner-home3 .text h3 {
        font: 500 45px/50px "Gotham";
    }
    .banner-home3 .text h4 {
        margin-bottom: 15px;
        padding-bottom: 15px;
    }
    .banner-home3 .text p {
        font: 300 18px/30px "Gotham";
    }
}

@media (max-width: 440px) {
    .banner-home3 .text img {
        max-width: 25px;
        margin-bottom: 5px;
    }
    .banner-home3 .text h3 {
        font: 500 20px/25px "Gotham";
    }
    .banner-home3 .text h4 {
        font: 300 14px/16px "Gotham";
        margin-bottom: 5px;
        padding-bottom: 5px;
    }
    .banner-home3 .text p {
        font: 300 12px/16px "Gotham";
    }
}

.banner-home3 .icon-banner {
    position: absolute;
    bottom: -50px;
    left: -128px;
}

@media (max-width: 1200px) {
    .banner-home3 .icon-banner {
        left: -20px;
    }
    .banner-home3 .icon-banner img {
        max-width: 200px;
    }
}

@media (max-width: 767px) {
    .banner-home3 .icon-banner {
        display: none;
    }
}

.banner-home3-lookbook1 .text {
    width: 100%;
    left: 0px;
    text-align: center;
    bottom: 70px;
}

.banner-home3-lookbook1 .text h3 {
    font: 400 60px "Oswald";
    letter-spacing: 20px;
    position: relative;
    display: inline-block;
}

.banner-home3-lookbook1 .text h3:after {
    position: absolute;
    content: "";
    background: #fff;
    height: 3px;
    right: 15px;
    left: 2px;
    bottom: 0px;
}

.banner-home3-lookbook1 .text p {
    font: 400 16px "Oswald";
    letter-spacing: 5px;
    padding-top: 10px;
    position: relative;
}

.banner-home3-lookbook1 .text p:after {
    position: absolute;
    content: "";
    left: 50%;
    width: 1px;
    height: 500px;
    top: 50px;
    background: #fff;
}

.banner-home3-lookbook1.banner-home3-top-50 .text {
    bottom: 40%;
}

.banner-home3-lookbook2 .text {
    width: 100%;
    left: 0px;
    text-align: center;
    top: 30%;
    bottom: inherit;
}

.banner-home3-lookbook2 .text .icons {
    font-size: 80px;
    padding-bottom: 50px;
    display: inline-block;
}

.banner-home3-lookbook2 .text h3 {
    font: 300 35px/40px "Oswald";
    letter-spacing: 10px;
    padding-bottom: 50px;
}

.banner-home3-lookbook2 .text a {
    font: 700 10px "Oswald";
    letter-spacing: 3px;
}

.banner-home3-lookbook2 .text a:hover {
    background: none;
}

.banner-video {
    position: relative;
}

.banner-video img {
    width: 100%;
}

.banner-video .text {
    position: absolute;
    left: 0px;
    width: 100%;
    top: 40%;
    color: #fff;
    text-align: center;
}

.banner-video .text .icons {
    height: 154px;
    width: 154px;
}

.banner-video .text h3 {
    font: 400 30px/30px "Oswald";
    text-transform: uppercase;
    letter-spacing: 15px;
    margin-top: 50px;
}

.banner-home-collec1.related-products {
    padding: 0px;
}

.banner-home-collec1.related-products .product-details {
    padding: 0px;
}

.banner-home-collec1.related-products .product-details:after {
    background: none;
    height: 0px;
    width: 0px;
}

.banner-home-collec1.related-products .product-details img {
    width: 100%;
}

.banner-home-collec1.related-products .product-details .text {
    position: absolute;
    top: 30%;
    left: 150px;
    max-width: 520px;
}

.banner-home-collec1.related-products .product-details h4 {
    font-weight: 300;
    color: #9d9d9d;
}

.banner-product-details3 {
    position: relative;
    margin-bottom: 30px;
}

.banner-product-details3 img {
    width: 100%;
}

.banner-product-details3 h3 {
    position: absolute;
    width: 100%;
    text-align: center;
    font: 400 50px/50px "Oswald";
    color: #fff;
    top: 50%;
    left: 0px;
    margin-top: -25px;
    letter-spacing: 10px;
}

.banner-header a.action {
    display: inline-block;
    width: 19px;
    height: 59px;
    position: absolute;
    bottom: 80px;
    left: 50%;
    margin-left: -10px;
}

.banner-por-single {
    text-align: center;
}

.banner-por-single img {
    display: inline-block;
}

.special {
    padding: 40px 0px 55px;
}

@media (min-width: 1200px) {
    .special .col-md-7 {
        padding-right: 30px;
    }
    .rtl .special .col-md-7 {
        padding-left: 30px;
        padding-right: inherit;
    }
}

.special .col-md-5,
.special .col-md-7 {
    padding: 0px;
}

.special .images-logo {
    position: relative;
    z-index: 999;
}

@media (min-width: 1200px) {
    .special .images-logo {
        transform: translate(0px, 80px);
    }
}

@media (min-width: 1200px) {
    .special.special-v2 .images-logo {
        transform: translate(0px, 0px);
    }
}

.special.home3-special img {
    max-width: 570px;
    box-shadow: 0px 8px 33px 0px rgba(0, 0, 0, 0.13);
}

@media (max-width: 980px) {
    .special.home3-special img {
        max-width: 350px;
    }
}

@media (max-width: 639px) {
    .special.home3-special img {
        max-width: 100%;
    }
}

.special.home3-special .special-content h5 {
    padding-bottom: 0px;
}

.special.home3-special .special-content h5:after {
    width: 0px;
}

.special.home3-special .special-content .time {
    background: #333;
    display: inline-block;
    width: auto;
    padding: 50px 60px;
    margin-left: -80px;
    margin-bottom: 50px;
}

.rtl .special.home3-special .special-content .time {
    margin-right: -80px;
    margin-left: inherit;
}

.special.home3-special .special-content .time .distance {
    background: none;
    margin: 0px;
    border-right: 1px solid #fff;
    border-radius: 0px;
    width: 120px;
}

.special.home3-special .special-content .time .distance .number {
    font-size: 48px;
    line-height: 50px;
    font-weight: 300;
}

.special.home3-special .special-content .time .distance:last-child {
    border-right: none;
}

.special.home3-special .special-content .time .distance .text {
    color: #fff;
}

@media (max-width: 1024px) {
    .special.home3-special .special-content .time {
        padding: 20px 30px;
    }
    .special.home3-special .special-content .time .distance {
        width: 80px;
        text-align: center;
    }
    .special.home3-special .special-content .time .distance .number {
        font-size: 30px;
        line-height: 30px;
    }
    .special.home3-special .special-content .time .distance .text {
        line-height: 14px;
    }
}

@media (max-width: 980px) and (min-width: 768px) {
    .special.home3-special .special-content .time {
        margin-bottom: 25px;
    }
}

.special.home3-special .special-content a {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-right: 10px;
    background: #333;
    text-align: center;
    border: 1px solid #333;
    transition: all 0.3s ease 0s;
}

.special.home3-special .special-content a:hover {
    background: #fff;
}

.special.home3-special .special-content a:hover i {
    color: #333;
}

.special.home3-special .special-content a i {
    color: #fff;
    line-height: 78px;
    font-size: 36px;
}

.special.home3-special .special-content a:last-child {
    margin-right: 0px;
}

.special.special-v2 {
    padding-top: 75px;
}

.special.special-v2 .special-content {
    text-align: center;
    max-width: 500px;
    padding-top: 0px;
}

.special.special-v2 .special-content p {
    font: 300 24px/30px "Gotham";
    color: #2b2b2b;
}

.special.special-v2 .special-content h3 {
    font: 700 60px/70px "Gotham";
}

.special.special-v2 .special-content h5 {
    font: 300 24px/30px "Gotham";
    letter-spacing: 4px;
    position: relative;
}

.special.special-v2 .special-content h5:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 110px;
    background: #b9b9b8;
    margin: auto;
    left: 0px;
    bottom: -24px;
    right: 0px;
}

.special.special-v2 .special-content .time {
    margin: auto;
    background: none;
    text-align: center;
}

.special.special-v2 .special-content .time .distance {
    width: 80px;
    height: 80px;
}

.special.special-v2 .special-content .time .distance:after {
    width: 0px;
}

.special.special-v2 .special-content .time .distance .number {
    font: 300 32px/35px "Gotham";
}

.special.special-v2 .special-content .time .distance .text {
    font: 400 12px/15px "Gotham";
}

.special.special-v2 .special-content .link-v1 {
    margin: auto;
    float: inherit;
}

@media (max-width: 1200px) and (min-width: 980px) {
    .special.home_v1 .col-md-5,
    .special.home_v1 .col-md-7 {
        width: 50%;
        float: left;
    }
    .special.home_v1 .special-content {
        padding-top: 0px;
    }
    .special.home_v1 .special-content .time .distance {
        width: 100px;
        height: 80px;
    }
    .special.home_v1 .special-content .time .distance .number {
        font: 300 32px/35px "Gotham";
    }
}

.special-content {
    font-family: "Gotham";
    text-align: left;
    padding-top: 70px;
}

.rtl .special-content {
    text-align: right;
}

.special-content h4 {
    font-size: 30px;
    text-transform: capitalize;
    line-height: 30px;
    margin-bottom: 10px;
}

.special-content h3 {
    font: 300 42px/50px "Gotham";
    color: #80b435;
    text-transform: uppercase;
    vertical-align: bottom;
    display: inline-block;
    margin-left: 10px;
}

.special-content .icon-big {
    vertical-align: 5px;
}

.special-content h5 {
    text-transform: uppercase;
    margin-bottom: 20px;
    font: 300 20px/30px "Gotham";
    letter-spacing: 3px;
    color: #2b2b2b;
}

.special-content .link-v1 {
    margin-left: 430px;
    margin-top: -20px;
    float: left;
}

@media (max-width: 980px) and (min-width: 768px) {
    .special-content {
        padding-top: 20px;
    }
    .special-content h3 {
        margin-bottom: 10px;
        font-size: 35px;
        line-height: 35px;
    }
    .special-content h5 {
        font-size: 15px;
        line-height: 20px;
    }
    .special-content h4 {
        font-size: 20px;
        line-height: 20px;
    }
}

.special-content .time {
    text-align: left;
    background-color: #fff;
    max-width: 740px;
    display: block;
    text-align: center;
    margin-left: -120px;
    padding: 40px 0px;
}

.rtl .special-content .time {
    text-align: right;
}

.special-content .time .distance {
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    width: 155px;
    height: 100px;
    margin: 0 5px;
    text-align: center;
    position: relative;
}

.special-content .time .distance:after {
    position: absolute;
    width: 1px;
    height: 60px;
    background-color: #e5e5e5;
    right: 0px;
    top: 16px;
    content: "";
}

.special-content .time .distance:first-child {
    margin-left: 0px;
}

.special-content .time .distance:last-child {
    margin-right: 0px;
}

.special-content .time .distance:last-child:after {
    width: 0px;
}

.special-content .time .distance .number {
    font: 400 48px/50px "Gotham";
    color: #80b435;
    display: block;
}

.special-content .time .distance .number b {
    font-weight: 300 !important;
}

.special-content .time .distance .text {
    font: 400 14px/15px "Gotham";
    color: #333;
    display: block;
    text-transform: uppercase;
}

.instagram .item {
    display: inline-block;
    float: left;
    width: 12.5%;
}

.instagram .item img {
    width: 100%;
}

@media (max-width: 980px) {
    .instagram .item {
        width: 25%;
    }
}

@media (max-width: 639px) {
    .instagram .item {
        width: 50%;
    }
}

.home3-latest-news h2 {
    font: 400 40px "Gotham";
    color: #2b2b2b;
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;
}

.home3-latest-news h2:after {
    position: absolute;
    content: "";
    left: 0px;
    width: 100px;
    bottom: 0px;
    height: 2px;
    background: #80b435;
}

.home3-latest-news .item {
    padding: 30px 0px;
    border-bottom: 1px solid #cdcdcd;
}

.home3-latest-news .item:last-child {
    border: none;
}

.home3-latest-news .item .date {
    text-align: center;
    color: #333;
    margin-right: 20px;
}

.rtl .home3-latest-news .item .date {
    margin-left: 20px;
    margin-right: inherit;
}

.home3-latest-news .item .date .day {
    font: 400 60px "Gotham";
}

.home3-latest-news .item .date .month {
    font: 400 14px "Gotham";
}

.home3-latest-news .item .text .title {
    color: #333;
    font: 400 20px "Gotham";
    margin-bottom: 10px;
    display: block;
}

.home3-latest-news .item .text .title:hover {
    color: #80b435;
}

.home3-latest-news .item .text p {
    font: 300 14px/20px "Gotham";
}

.home3-latest-news .item .text .read-more {
    color: #80b435;
    font: 300 14px/20px "Gotham";
}

@media (max-width: 767px) {
    .home1-banner1 .hover-images {
        width: 100%;
    }
    .home1-banner1 .hover-images img {
        width: 100%;
    }
}

.bg-slider-one-item {
}

.bg-slider-one-item:after {
    position: absolute;
    bottom: 180px;
    left: 0px;
    background-color: #fff;
    content: "";
    opacity: 0.5;
    width: 100%;
    height: 1px;
}

.bg-slider-one-item.bg-home2-slider {
    padding: 30px 0px;
}
.bg-slider-one-item.bg-home2-slider:after {
    height: 0px;
}

.bg-slider-one-item.bg-home2-slider .title-text-v2 h3 {
    color: #fff;
}

.bg-slider-one-item.bg-home2-slider .brand-content {
    margin-top: 30px;
}

@media (max-width: 1200px) and (min-width: 768px) {
    .choose-us img {
        margin: auto;
    }
}

.choose-us .title-choose {
    text-align: center;
    margin-bottom: 50px;
}

.choose-us .title-choose h4 {
    font: 400 30px/35px "Gotham";
    text-transform: capitalize;
    color: #80b435;
}

.choose-us .title-choose h3 {
    font: 400 48px/50px "Gotham";
    color: #2b2b2b;
    text-transform: capitalize;
    margin-bottom: 20px;
}

@media (max-width: 420px) {
    .choose-us .title-choose h3 {
        font-size: 22px;
        line-height: 30px;
    }
}

.choose-us .title-choose p {
    max-width: 760px;
    font: 400 16px/25px "Gotham";
    color: #666;
    margin: 0 auto;
}

.choose-us .title-choose.about h3 {
    font-weight: 700;
    margin-top: 20px;
}

.choose-us .title-choose.about .border-choose {
    margin-top: 30px;
}

.choose-us .title-choose.color-brand {
    margin-bottom: 80px;
}

.choose-us .title-choose.color-brand h3 {
    color: #80b435;
    text-transform: uppercase;
    font: 700 48px "Gotham";
    margin-bottom: 10px;
}

.choose-us .title-choose.color-brand .border-choose {
    margin-top: 0px;
}

.choose-us .right-items h3 {
    margin-left: 0px;
    margin-right: 10px;
}

.choose-us .text {
    margin-bottom: 80px;
}

.choose-us .text h3 {
    display: inline-block;
    margin-left: 10px;
    color: #80b435;
    font: 400 24px/30px "Gotham";
    margin-bottom: 20px;
}

.choose-us .text p {
    display: block;
    color: #666;
    font: 400 14px/20px "Gotham";
}

.choose-us .border-choose {
    max-width: 480px;
    text-align: center;
    margin-top: 30px;
}

.choose-us .border-choose .images {
    display: inline-block;
    width: 70px;
    text-align: center;
    background: #fff;
}

.choose-us.about-choose .items {
    width: 100%;
    display: inline-block;
    margin-top: 8px;
}

.choose-us.about-choose .items .icon {
    float: right;
    margin-left: 18px;
    display: inline-block;
    width: auto;
}

.choose-us.about-choose .items .text p {
    padding-right: 82px;
    color: #666;
}

.choose-us.about-choose .items .text h3 {
    margin-bottom: 10px;
    color: #2b2b2b;
}

@media (min-width: 768px) and (max-width: 980px) {
    .choose-us.about-choose .items .text h3 {
        font: 400 16px/30px "Gotham";
    }
}

@media (min-width: 981px) and (max-width: 1199px) {
    .choose-us.about-choose .items .text h3 {
        font: 400 16px/30px "Gotham";
    }
}

@media (max-width: 1200px) {
    .choose-us.about-choose .items .icon {
        float: left;
        margin-left: 0px;
        margin-right: 18px;
    }
    .choose-us.about-choose .items .text {
        text-align: left;
    }
    .choose-us.about-choose .items .text h3 {
        margin-left: 0px;
    }
    .choose-us.about-choose .items .text p {
        padding-right: 0px;
        padding-left: 82px;
    }
}

.choose-us.about-choose .align-left .icon {
    float: left;
    margin-left: 0px;
    margin-right: 18px;
}

.choose-us.about-choose .align-left .text p {
    padding-right: 0px;
    padding-left: 82px;
}

.choose-us.about-choose .align-left .text h3 {
    margin-left: 0px;
}

@media (max-width: 440px) {
    .choose-us.about-choose .col-md-4 {
        padding: 0px;
    }
}

.choose-us.choose-us-home2 .title-choose {
    margin-bottom: 10px;
    margin-top: 30px;
}

.choose-us.choose-us-home2 .title-choose h3 {
    font: 700 48px/50px "Gotham";
    color: #2b2b2b;
    text-transform: inherit;
}

.choose-us.choose-us-home2 .title-choose h3 span {
    font-weight: 300;
}

.choose-us.choose-us-home2 .col-md-8 {
    padding: 0px;
}

@media (max-width: 420px) {
    .choose-us.choose-us-home2 .title-choose h3 {
        font-size: 32px;
    }
}

.banner-home2 {
    position: relative;
    z-index: 999;
    margin-top: -165px;
    margin-bottom: 40px;
}

.banner-home2 img {
    border-radius: 5px;
}

@media (max-width: 767px) {
    .banner-home2 {
        margin-top: -30px;
        z-index: 1;
    }
    .banner-home2 .col-md-4 {
        width: 33.33%;
        float: left;
    }
}

@media (max-width: 440px) {
    .banner-home2 .col-md-4 {
        width: 100%;
        margin-bottom: 20px;
    }
}

/** 4.5. Banner bottom **/
.instafeed {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}

.instafeed img {
    width: 100%;
}

.instafeed .items {
    width: 20%;
    float: left;
    position: relative;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    overflow: hidden;
}

.instafeed .items:before {
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.instafeed .items:hover:before {
    background-size: 100% 100%;
}

.instafeed .items:hover .text {
    transform: translate(0, 100px);
}

.instafeed .items .text {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    text-align: center;
    color: #fff;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    transform: translate(0, -150px);
}

.instafeed .items .text img {
    width: auto;
    margin-bottom: 20px;
}

.instafeed .items .text h3 {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 400;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    line-height: 20px;
}

.instafeed .items .text h3:after {
    position: absolute;
    content: "";
    width: 66px;
    margin-left: -33px;
    height: 2px;
    background: #fff;
    bottom: 0px;
    left: 50%;
}

.instafeed .items .text p {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
}

/** 4.6. News letter **/
.newsletter {
    margin-bottom: 75px;
}

.newsletter .col-md-5 .col-md-6:first-child {
    background: #1c1c1c;
    padding: 10px;
}

.newsletter .col-md-5 .col-md-6:first-child .border-free-shipping {
    border: 1px solid #353535;
    display: inline-block;
    width: 100%;
    padding: 26px;
}

.newsletter .col-md-5 .col-md-6:first-child h3 {
    font-family: "Oswald";
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 30px;
}

.newsletter .col-md-5 .col-md-6:first-child a {
    color: #4c4c4c;
    font-family: "Oswald";
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    font-size: 10px;
    display: inline-block;
    margin-top: 25px;
    border: 2px solid #4c4c4c;
    height: 32px;
    line-height: 28px;
    padding: 0px 10px;
}

.newsletter .col-md-5 .col-md-6:last-child {
    background: #f4f4f4;
    text-align: center;
    padding: 61px 0px;
}

.newsletter .col-md-5 .col-md-6:last-child i.icon {
    font-size: 100px;
}

.newsletter-content {
    display: inline-block;
    width: 100%;

    border: 2px solid #000;
    position: relative;
    height: 224px;
}

.newsletter-content .icon {
    font-size: 25px;
    color: #000;
    margin-top: 25px;
    display: inline-block;
    margin-bottom: 20px;
}

.newsletter-content h3 {
    font-family: "Oswald";
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 18px;
    color: #000;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.newsletter-content h3:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 0px;
    height: 1px;
    background: #000;
    width: 30px;
    margin-left: -15px;
}

.newsletter-content p {
    font-family: "Oswald";
    font-size: 12px;
    text-transform: uppercase;
    color: #9a9a9a;
    letter-spacing: 2px;
}

.newsletter-content form {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 66px;
    border-top: 1px solid #e9e9e9;
}

.newsletter-content form input {
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 66px;
    border: none;
    width: 100%;
    background: transparent;
    padding: 0px 160px 0px 40px;
    font-family: "Poppins";
    color: #8d8c8c;
}

.newsletter-content form button {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 160px;
    border: none;
    border-left: 1px solid #e9e9e9;
    height: 66px;
    background: transparent;
}

.newsletter-v2 {
    padding: 30px 0px;
}

.newsletter-v2 .col-md-6 {
    padding: 0px;
    text-align: center;
}

.newsletter-v2 .col-md-6 i.icon-envelope-letter {
    font-size: 40px;
    color: #000;
    display: inline-block;
    width: auto;
    margin-right: 120px;
    position: relative;
}

.newsletter-v2 .col-md-6 i.icon-envelope-letter:after {
    position: absolute;
    left: 58px;
    top: 50%;
    width: 80px;
    height: 2px;
    background: #000;
    content: "";
}

.newsletter-v2 .col-md-6 .text {
    display: inline-block;
    text-align: left;
}

.newsletter-v2 .col-md-6 .text h3 {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 25px;
}

.newsletter-v2 .col-md-6 .text p {
    font-size: 12px;
    font-weight: 300;
    color: #9a9a9a;
    letter-spacing: 3px;
}

.newsletter-v2 .col-md-6:last-child {
    text-align: left;
}

.newsletter-v2 .col-md-6:last-child form {
    display: inline-block;
    position: relative;
}

.newsletter-v2 .col-md-6:last-child form input {
    width: 548px;
    height: 48px;
    background: #fff;
    border: 2px solid #000;
    padding: 0px 150px 0px 20px;
    font-family: "Poppins";
    color: #8d8c8c;
    font-size: 12px;
}

@media (max-width: 767px) {
    .newsletter-v2 .col-md-6:last-child form input {
        width: 100%;
    }
}

.newsletter-v2 .col-md-6:last-child form button {
    position: absolute;
    height: 44px;
    width: 120px;
    right: 2px;
    top: 2px;
    box-shadow: none;
    border: none;
    background: #000;
    color: #fff;
    border-left: 2px solid #000;
}

.newsletter-v2 .col-md-6:last-child form button:hover {
    border-left: 2px solid #000;
}

.special img {
    max-width: 100%;
}

.newsletter-home4 {
    display: inline-block;
    width: 100%;
    margin-bottom: 60px;
    height: 215px;
}

@media (max-width: 980px) {
    .newsletter-home4 {
        background-size: auto 100%;
        background-position: center;
        height: auto;
    }
}

.newsletter-home4 .wrap-center .items {
    display: table;
    width: 100%;
    height: 215px;
}

.newsletter-home4 .wrap-center .items .text,
.newsletter-home4 .wrap-center .items .wrap-form {
    display: table-cell;
    vertical-align: middle;
    width: 50%;
}

.newsletter-home4 .wrap-center .items .wrap-form {
    padding-left: 20px;
    padding-right: 20px;
}

@media (max-width: 980px) {
    .newsletter-home4 .wrap-center .items {
        height: auto;
        display: inline-block;
    }
    .newsletter-home4 .wrap-center .items .text,
    .newsletter-home4 .wrap-center .items .wrap-form {
        display: inline-block;
        width: 100%;
    }
}

.newsletter-home4 img {
    width: 100%;
}

@media (max-width: 980px) {
    .newsletter-home4 .col-md-6 {
        width: 100%;
        margin-bottom: 30px;
    }
}

.newsletter-home4 .wrap-center .items .text {
    width: 100%;
    float: left;
    position: relative;
    top: inherit;
    left: inherit;
}

.newsletter-home4 .wrap-center .items .text i {
    font-size: 22px;
    margin-bottom: 20px;
    display: inline-block;
}

.newsletter-home4 .wrap-center .items .text h3 {
    font-size: 36px;
    font-family: "Gotham";
    font-weight: 400;
    margin-top: 50px;
    text-transform: capitalize;
}

.newsletter-home4 .wrap-center .items .text h3:after {
    height: 2px;
    width: 80px;
    margin-left: -40px;
}

.newsletter-home4 .wrap-center .items .text p {
    max-width: 400px;
    margin: auto;
    margin-top: 20px;
    font: 300 12px/20px "Gotham";
}

.newsletter-home4 .wrap-center .items form {
    width: 100%;
    height: 60px;
    float: left;
    padding: 0px;
    margin-top: 15px;
}

.newsletter-home4 .wrap-center .items form input {
    width: 100%;
    height: 100%;
    background: none;
    border: 1px solid #fff;
    box-shadow: none;
    padding: 0px 100px 0px 20px;
    font-family: "Gotham";
    font-size: 16px;
    font-weight: 300;
    color: #fff;
}

@media (max-width: 479px) {
    .newsletter-home4 .wrap-center .items form input {
        padding-right: 60px;
    }
}

.newsletter-home4 .wrap-center .items form button {
    position: absolute;
    top: 0px;
    right: 0px;
    box-shadow: none;
    border: none;
    background: none;
    border-left: 1px solid #fff;
    height: 100%;
    padding: 0 40px;
    color: #fff;
    font: 400 16px "Gotham";
    text-transform: uppercase;
    letter-spacing: 0px;
}

.newsletter-home4 .wrap-center .items form button i {
    margin-left: 5px;
}

.newsletter-home4 .wrap-center .items form button:hover {
    border-color: #b7b7b7 !important;
}

@media (max-width: 479px) {
    .newsletter-home4 .wrap-center .items form button {
        font-size: 0px;
    }
    .newsletter-home4 .wrap-center .items form button i {
        font-size: 14px;
    }
}

.newsletter-home4 .items {
    position: relative;
}

.newsletter-home4 .items .text {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    top: 40%;
    left: 0px;
}

.newsletter-home4 .items .text h3 {
    font-family: "Gotham";
    font-size: 34.87px;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.newsletter-home4 .items .text h3:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 0px;
    width: 50px;
    height: 2px;
    background: #fff;
    margin-left: -25px;
}

.newsletter-home4 .items .text p {
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.newsletter-home6 {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}

.newsletter-home6 .newsletter-content {
    border: none;
}

.newsletter-home6 .newsletter-content .icon,
.newsletter-home6 .newsletter-content h3,
.newsletter-home6 .newsletter-content p {
    color: #fff;
}

.newsletter-home6 .newsletter-content p {
    font-weight: 300;
}

.newsletter-home6 .newsletter-content h3:after {
    background: #fff;
}

.newsletter-home6 .newsletter-content form,
.newsletter-home6 .newsletter-content input,
.newsletter-home6 .newsletter-content button {
    position: relative;
    max-width: 555px;
}

.newsletter-home6 .newsletter-content .icon {
    margin-top: 50px;
}

.newsletter-home6 .newsletter-content form {
    margin: 0 auto;
    border-top: none;
    border-bottom: 2px solid #5e5e5e;
    margin-top: 20px;
}

.newsletter-home6 .newsletter-content input {
    padding: 0px 20px;
    text-align: center;
}

.newsletter-home6 .newsletter-content button {
    border: none;
    height: 40px;
    line-height: 40px;
    margin-top: 30px;
    background: #000;
    color: #fff;
}

.newsletter-home6.newsletter-home-8 {
}

.newsletter-home6.newsletter-home-8 .newsletter-content {
    background: none;
}

.newsletter-bottom {
    position: relative;
}

.newsletter-bottom .text {
    position: absolute;
    left: 0px;
    right: 0px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
}

.newsletter-bottom h3 {
    font: 400 30px/30px "Gotham";
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.newsletter-bottom p {
    font: 300 16px "Gotham";
    color: #80b435;
    margin-bottom: 25px;
}

.newsletter-bottom form {
    width: 90%;
    max-width: 700px;
    position: relative;
    margin: auto;
}

.newsletter-bottom form input {
    font: 300 16px/40px "Gotham";
    color: #333;
    width: 100%;
    padding: 0 80px 0px 20px;
}

.newsletter-bottom form button {
    position: absolute;
    right: 0px;
    top: 0px;
    border: none;
    background: none;
    color: #80b435;
    font: 700 18px/40px "Gotham";
    text-transform: uppercase;
    letter-spacing: 0px;
}

.best-selling {
    display: inline-block;
    width: 100%;
}

.best-selling .col-md-4 {
    padding: 0px;
    border-left: 1px solid #e6e6e6;
    position: relative;
}

.best-selling .col-md-4 .button {
    display: inline-block;
    width: 100%;
    text-align: center;
    height: 65px;
    line-height: 65px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

.best-selling .col-md-4 .title-text {
    margin: 0px;
    padding: 20px 0px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

.best-selling .col-md-4 .title-text h3 {
    font-size: 16px;
}

.best-selling .col-md-4:last-child {
    border-right: 1px solid #e6e6e6;
}

.best-selling .col-md-4 .products {
    display: inline-block;
    width: 100%;
    padding: 30px 0px;
}

.best-selling .col-md-4 .product {
    text-align: left;
    margin: 30px 0px;
    width: 100%;
    float: left;
}

.best-selling .col-md-4 .product .product-title {
    font-family: "Poppins";
    font-size: 18px;
    color: #000;
}

.best-selling .col-md-4 .product .product-price {
    font-family: "Poppins";
    font-size: 18px;
    color: #979797;
    margin-bottom: 0px;
}

.best-selling .col-md-4 .product .wrap-star {
    margin-bottom: 30px;
}

.best-selling .col-md-4 .product .wrap-star a i {
    color: #e2e2e2;
    font-size: 12px;
}

.best-selling .col-md-4 .product .wrap-star a.active i {
    color: #eb5050;
}

.best-selling .col-md-4 .product .action {
    position: relative;
    left: 0px;
}

.best-selling .col-md-4 .product .action a {
    display: inline-block;
    text-align: center;
    opacity: 1;
    transform: translate(0px, 0px);
}

.best-selling .col-md-4 .product .action a i {
    background: #f1f1f1;
}

.best-selling .col-md-4 .product .action a:hover:after {
    width: auto;
}

.best-selling .col-md-4 .product .action a:after {
    content: "";
}

.best-selling .col-md-4 .product-images {
    max-width: 210px;
    float: left;
    margin-left: 70px;
    margin-right: 40px;
}

@media (width: 1024px) {
    .newsletter .col-md-5 .col-md-6:first-child .border-free-shipping {
        padding: 41px;
    }
}

.popup-content {
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
}

.popup-content-wrapper {
    background: #fff;
    background-size: 100% auto;
    max-width: 770px;
    margin: 200px auto 0px;
    width: 90%;
    position: relative;
    padding: 0px;
}

.popup-content-wrapper .popup-container {
    overflow: hidden;
    position: relative;
}

.popup-content-wrapper .popup-container .images {
    float: left;
    padding: 0px;
}

.popup-content-wrapper .popup-container .text {
    float: left;
    padding-left: 30px;
    padding-right: 30px;
    position: absolute;
    left: 0px;
    right: 0px;
    transform: translate(0, -50%);
    top: 50%;
}

.popup-content-wrapper .popup-container .logo {
    margin-bottom: 30px;
}

.popup-content-wrapper .close-popup {
    display: inline-block;
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    top: 10px;
    right: 10px;
    margin: 0 auto;
    z-index: 9;
}
.popup-content-wrapper .col-md-offset-6 {
    text-align: left;
}

.popup-content-wrapper .abs-req {
    display: none;
}

.popup-content-wrapper h3 {
    font: 400 16px/20px "Gotham";
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #333;
    margin-top: 130px;
    padding-bottom: 15px;
    margin-bottom: 25px;
    position: relative;
}

.popup-content-wrapper h3:after {
    position: absolute;
    content: "";
    width: 68px;
    height: 3px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: 0 auto;
    background: #80b435;
}

.popup-content-wrapper h4 {
    font: 400 35px/40px "Gotham";
    color: #000;
    margin-bottom: 15px;
}

.popup-content-wrapper p {
    font: 400 16px/25px "Gotham";
    color: #717171;
    max-width: 560px;
    margin: auto;
}

.popup-content-wrapper .popup-content-text {
    font-size: 13px;
    line-height: 20px;
    color: #666;
    margin-bottom: 30px;
}

.popup-content-wrapper input[type="text"] {
    width: 100%;
    max-width: 340px;
    background: #fff;
    line-height: 30px;
    padding: 12px 15px;
    font-size: 14px;
    font-weight: 400;
    color: #666;
    font-family: "Gotham";
    text-align: center;
    border: none;
    margin-bottom: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
}

.popup-content-wrapper button {
    background: #80b435;
    border: 2px solid #80b435;
    color: #fff;
    font-size: 15px;
    height: 46px;
    line-height: 42px;
    margin-top: 25px;
    padding: 0px 30px;
    font-family: "Gotham";
    font-weight: 400;
    letter-spacing: 0px;
}

.popup-content-wrapper .not-again {
    margin-top: 20px;
}

.popup-content-wrapper .not-again span {
    color: #333;
    margin-left: 10px;
    text-transform: uppercase;
    font: 700 10px/15px "Oswald";
    letter-spacing: 2px;
    text-decoration: underline;
    cursor: pointer;
}

.popup-content-wrapper.popup-content-wrapper-v2 {
    width: 650px;
    height: 650px;
    border-radius: 100%;
}

.popup-content-wrapper.popup-content-wrapper-v2 .popup-container {
    max-width: 500px;
    margin: 0 auto;
}

.popup-content-wrapper.popup-content-wrapper-v2 .close-popup {
    left: inherit;
    right: 10px;
    height: 46px;
    width: 46px;
    border: none;
    font-size: 0px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.33);
}

.popup-content-wrapper.popup-content-wrapper-v2 h4 {
    font: 700 14px/20px "Oswald";
    color: #000;
    letter-spacing: 1px;
}

.popup-content-wrapper.popup-content-wrapper-v2 h3 {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    font-family: "Gotham";
    letter-spacing: 0px;
}

.popup-content-wrapper.popup-content-wrapper-v2 h2 {
    font: 400 35px/40px "Gotham";
    color: #000;
    text-transform: uppercase;
    margin-top: 85px;
}

.popup-content-wrapper.popup-content-wrapper-v2 h1 {
    font-family: "Gotham";
    font-style: italic;
    font-size: 120px;
    color: #000;
    line-height: 80px;
}

.popup-content-wrapper.popup-content-wrapper-v2 h1 span {
    font-size: 80px;
}

.popup-content-wrapper.popup-content-wrapper-v2 p {
    font: 400 10px/10px "Oswald";
    color: #a5a5a5;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.popup-content-wrapper.popup-content-wrapper-v2 input[type="text"] {
    background: #fff;
    border: 1px solid #000;
}

.home3-newsletter-content {
    border: 9px solid #80b435;
    padding: 70px;
}

.home3-newsletter-content h3 {
    font: 400 36px "Gotham";
    color: #333;
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;
}

.home3-newsletter-content h3:after {
    position: absolute;
    content: "";
    left: 0px;
    bottom: 0px;
    width: 50px;
    height: 1px;
    background: #000;
    left: 0px;
    right: 0px;
    margin: auto;
}

.home3-newsletter-content p {
    font: 300 14px/20px "Gotham";
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

.home3-newsletter-content form input {
    width: 100%;
    text-align: center;
    font: 300 16px/40px "Gotham";
    height: 40px;
    padding: 0px;
    border: 1px solid #000;
    color: #a4a4a4;
    margin-bottom: 30px;
}

.home3-newsletter .item .text .read-more:hover {
    color: #333;
}

.quickview-wrapper {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    text-align: center;
}

.quickview-wrapper .quick-modal {
    max-width: 1140px;
    width: 90%;
    margin: 100px auto 0px;
    background: #fff;
    padding: 20px;
    text-align: left;
    position: relative;
}

.quickview-wrapper .product-details-content .product-name h1 {
    font: 400 36px/40px "Gotham";
    margin-bottom: 0px;
}

.quickview-wrapper .product-details-content .rating i {
    font-size: 14px;
}

.quickview-wrapper .product-details-content .box-detalis-v2 .options {
    padding: 0px;
}

.quickview-wrapper .product-details-content .box-detalis-v2 .wrap-price {
    padding-top: 20px;
    border-top: 1px solid #ebebeb;
}

.quickview-wrapper .product-details-content .box-detalis-v2 p.price {
    color: #80b435;
    display: inline-block;
    vertical-align: bottom;
    font: 400 36px/36px "Gotham";
    margin: 0px;
}

.quickview-wrapper .product-details-content .box-detalis-v2 p.price-old {
    display: inline-block;
    text-transform: line-through;
    color: #979797;
    vertical-align: bottom;
    font: 400 30px/36px "Gotham";
    margin: 0px;
    margin-right: 20px;
}

.quickview-wrapper .product .action {
    transform: translate(0px, 0px);
    opacity: 1;
    border-radius: 0px;
    box-shadow: none;
}

.quickview-wrapper #quickview-content .product {
    float: left;
    width: 100%;
}

.quickview-wrapper #quickview-content .product .product-images {
    float: left;
    width: 40%;
    padding-right: 15px;
}

.quickview-wrapper #quickview-content .product .product-images img {
    width: 100%;
}

.quickview-wrapper #quickview-content .product .product-info {
    float: left;
    width: 60%;
    padding-left: 30px;
}

.quickview-wrapper .closeqv {
    background: #fff;
    border: 2px solid #e6e6e6;
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.quickview-wrapper .closeqv i {
    color: #e6e6e6;
    line-height: 36px;
}

.quickview-wrapper #quickview-content {
    overflow: hidden;
}

.quickview-wrapper .quick-desc {
    font: 400 14px/25px "TXGR";
    color: #333;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebebeb;
}

.quickview-wrapper .single_variation_wrap {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ebebeb;
}

.quickview-wrapper .social-sharing .widget-title {
    font-size: 12px;
    display: inline-block;
    margin-bottom: 0px;
    padding-bottom: 10px;
    margin-right: 10px;
}

.quickview-wrapper .social-sharing .social-icons {
    display: inline-block;
}

.quickview-wrapper .social-sharing .social-icons li {
    display: inline-block;
    margin: 0px 8px;
}

.quickview-wrapper .social-sharing .social-icons li i {
    font-size: 16px;
}

.quickview-wrapper .quick-thumbnails ul {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
}

.quickview-wrapper .quick-thumbnails ul li {
    float: left;
    width: 25%;
}

.quickview-wrapper .quick-thumbnails ul li:nth-child(4n + 1) {
    padding-right: 6px;
}

.quickview-wrapper .quick-thumbnails ul li:nth-child(4n + 2) {
    padding-left: 2px;
    padding-right: 4px;
}

.quickview-wrapper .quick-thumbnails ul li:nth-child(4n + 3) {
    padding-left: 4px;
    padding-right: 2px;
}

.quickview-wrapper .quick-thumbnails ul li:nth-child(4n) {
    padding-left: 6px;
}

.quickview-wrapper .product-details-content .options .add-cart {
    margin: 0px 10px;
}

.quickview-wrapper .product-details-content .options p {
    font: 400 14px/25px "Gotham";
    color: #6d6d6d;
    margin-top: 20px;
}

.quickview-wrapper .product-details-content .options .infomation p {
    color: #80b435;
    margin-bottom: 0px;
    font: 400 16px/25px "Gotham";
    margin-bottom: 10px;
}

.quickview-wrapper .product-details-content .options .infomation p span {
    color: #000;
}

.quickview-wrapper .product-details-content .options form {
    display: inline-block;
}

.quickview-wrapper .product-details-content .options input {
    width: 41px;
    height: 58px;
    border: none;
    border-right: 1px solid #d6d6d6;
}

.quickview-wrapper .product-details-content .options .quantity {
    height: 60px;
    vertical-align: top;
    border: 1px solid #d6d6d6;
}

.quickview-wrapper .product-details-content .options .action a,
.quickview-wrapper .product-details-content .options .action form {
    vertical-align: top;
}

/** 4.7. Brand **/
.brand-content {
    display: inline-block;
    padding-bottom: 10px;
    margin-top: 60px;
}

.brand-content .owl-item {
    border-left: none;
    padding: 2px;
}

.brand-content.owl-carousel .owl-item img {
    width: auto;
}

.brand-content.no-border {
    border: none;
}

.brand-content.no-border .owl-item {
    border: none;
}

/** 4.8. Iconbox **/
.feature-box .fbox-icon {
    float: left;
    margin: 10px 20px 0 0;
}

.rtl .feature-box .fbox-icon {
    float: right;
}

.rtl .feature-box .fbox-icon {
    margin: 10px 0 0 20px;
}

.feature-box .fbox-body {
    overflow: hidden;
}

.feature-box .fbox-body .fbox-icon {
    margin-top: 0;
}

.feature-box small {
    font-size: 13px;
    margin-bottom: 15px;
    display: inline-block;
}

.feature-box h4 {
    margin-bottom: 7px;
    text-transform: uppercase;
}

.feature-box .fbox-title {
    margin: 10px 0;
    font-weight: 600;
    text-transform: none;
}

.feature-box.light-style {
    color: #dfdfdf;
}

.feature-box.light-style a {
    color: #fff;
}

.feature-box.light-style a:hover {
    color: #000;
}

.feature-box.light-style .fbox-title,
.feature-box.light-style h4 {
    color: #fff;
}

.feature-box.feature-box-right {
    text-align: right;
}

.rtl .feature-box.feature-box-right {
    text-align: left;
}

.feature-box.feature-box-right .fbox-icon {
    float: right;
    margin: 10px 0 0 20px;
}

.rtl .feature-box.feature-box-right .fbox-icon {
    float: left;
}

.rtl .feature-box.feature-box-right .fbox-icon {
    margin: 10px 20px 0 0;
}

.feature-box.feature-box-center {
    text-align: center;
}

.feature-box.feature-box-center .fbox-icon {
    float: none;
}

[class*="feature-box-"] .fbox-icon {
    float: none;
    margin: 0;
}

[class*="feature-box-"] h4 {
    padding: 10px 0 20px;
    position: relative;
    font-weight: 700;
    text-transform: capitalize;
}

[class*="feature-box-"] p {
    color: #888888;
    line-height: 22px;
    font-family: "Poppins";
}

.feature-box-v2 .fbox-icon {
    float: left;
    margin-right: 30px;
}

.feature-box-v2 .fbox-icon .icons {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 24px;
}

.feature-box-v2 .fbox-body {
    font-size: 18px;
}

.feature-box-v3 .fbox-icon .icons {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 40px;
    color: #80b435;
}

.feature-box-v3 .fbox-icon .icons.icons-plain {
    width: auto;
    height: auto;
    line-height: 100%;
}

.feature-box-v3.feature-box-right .fbox-icon {
    float: none;
    margin: 0;
}

.feature-box-v3.feature-box-right h4:before {
    left: inherit;
    right: 0;
}

.feature-box-v3.about-icon .icons {
    font-size: 80px;
}

.feature-box-v3.about-icon .fbox-icon {
    margin-bottom: 40px;
}

.feature-box-v3.about-icon h4 {
    text-transform: uppercase;
    font-size: 15px;
}

.feature-box-v4 {
    position: relative;
    border: 1px solid #333;
    padding: 35px 30px 20px;
    text-align: center;
}

.feature-box-v4 .fbox-icon {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: -36px;
    margin: 0 auto;
}

.feature-box-v4 .fbox-icon .icons {
    font-size: 24px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    background-color: #fff;
    border: 1px solid #333;
    color: #333;
}

.feature-box-v4 .fbox-icon.fbox-bg .icons {
    background-color: #80b435;
    border: 1px solid #80b435;
    color: #fff;
}

.feature-box-v4 h4 {
    padding: 10px 0 20px;
}

.feature-box-v4 h4:before {
    width: 0px;
    height: 0px;
    background-color: transparent;
}

.feature-box-v4[class*="bg-"] {
    border: none;
}

.feature-box-v5 {
    padding: 20px 30px;
    border: 1px solid #ddd;
    border-bottom: 3px solid #80b435;
}

.feature-box-v5 .fbox-icon .icons {
    font-size: 40px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    color: #333;
}

/** 4.9. Iconbox **/
.contact-form .col-md-6:first-child {
    padding-right: 10px;
}

.contact-form h3 {
    font-size: 18px;
    font-style: normal;
    color: #555555;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
}

.contact-form h3::after {
    position: absolute;
    content: "";
    width: 30px;
    height: 3px;
    left: 0px;
    bottom: -25px;
    background: #ba933e;
}

.contact-form.post-comment {
    padding: 0px;
    background: none;
}

.contact-form.contact-form-bg {
    background: #f4f4f4;
}

.form-horizontal .col-md-6:nth-child(2n + 1) {
    padding-left: 0px;
    padding-right: 10px;
}

.form-horizontal .col-md-6:nth-child(2n) {
    padding-right: 0px !important;
    padding-left: 10px;
}

.form-horizontal label.control-label {
    font-size: 15px;
    color: #333;
    font-weight: 400;
    margin-bottom: 10px;
    display: block;
    text-align: left;
    font-family: "TXGB";
}

.form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
}

.form-horizontal .form-group.col-md-6 {
    padding-right: 10px;
}

.form-horizontal .form-group.col-md-6:last-child {
    padding-right: 0px;
    padding-left: 10px;
}

.form-control {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 0;
    color: #888888;
    padding: 9px 20px;
    line-height: 1.625;
    height: 47px;
    display: block;
    width: 100%;
    font: 400 16px "Gotham";
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.form-control:focus {
    border-color: #ddd;
    box-shadow: none;
    outline: none;
}

.form-control.col-md-4 {
    width: 31.33%;
    margin-right: 3%;
}

.form-control.col-md-4:last-child {
    margin-right: 0px;
}

#googleMap {
    height: 100%;
}

.contact-content {
    display: inline-block;
    width: 100%;
}

.contact-us {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}

.contact-us .col-md-6:last-child {
    padding-right: 0px;
    padding-left: 30px;
}

.contact-us .contact-form {
    padding: 50px 70px 30px;
    background: #1c1c1c;
    text-align: center;
    position: relative;
}

.contact-us .contact-form:before {
    position: absolute;
    content: "";
    border: 2px solid #353535;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
}

.contact-us .contact-form h3 {
    font-size: 18px;
    font-style: normal;
    color: #555555;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
}

.contact-us .contact-form h3::after {
    position: absolute;
    content: "";
    width: 55px;
    height: 1px;
    left: 50%;
    bottom: -15px;
    background: #fff;
    margin-left: -23px;
}

.contact-us .contact-form .form-control {
    background: transparent;
    color: #636363;
    font-family: "Poppins";
    font-size: 13px;
    border: none;
    border-bottom: 2px solid #252525;
    box-shadow: none;
    padding-left: 0px;
    padding-right: 0px;
}

.contact-us .contact-form .btn {
    border: 2px solid #fff;
    border-radius: 0px;
    color: #fff;
    padding: 7px 15px;
}

.contact-us .contact-form .icon {
    font-size: 25px;
    color: #fff;
    display: inline-block;
    margin-bottom: 20px;
}

.contact-us .contact-form h3 {
    color: #fff;
    letter-spacing: 2px;
    font-size: 18px;
    font-weight: 400;
}

.contact-us .contact-form p {
    color: #636363;
    letter-spacing: 2px;
    font-weight: 300;
    font-size: 12px;
    position: relative;
    margin-bottom: 100px;
}

.contact-us .contact-form p:after {
    position: absolute;
    content: "";
    left: -58px;
    right: -58px;
    height: 2px;
    bottom: -40px;
    background: #252525;
}

@media (max-width: 980px) {
    .wrappage .page-contact .head .item {
        width: 100%;
    }
}

/** 4.10. Interactive **/
.interactive-banner {
    color: #fff;
    position: relative;
    overflow: hidden;
}

.interactive-banner .interactive-banner-profile {
    position: absolute;
    z-index: 2;
    margin: auto;
    content: "";
}

.interactive-banner .interactive-banner-profile h2 {
    text-transform: uppercase;
    margin: 10px 0 15px;
}

.interactive-banner .interactive-banner-profile small {
    text-transform: uppercase;
}

.interactive-banner .mask-link {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
}

.interactive-banner .banner-title h2,
.interactive-banner .banner-title h3,
.interactive-banner .banner-title h4,
.interactive-banner .banner-title h5,
.interactive-banner .banner-title h6 {
    color: #fff;
}

.interactive-banner .action .icons:hover {
    background-color: #5cb85c;
    border: #5cb85c;
}

.interactive-banner.left .interactive-banner-profile {
    left: 20px;
}

.interactive-banner.right .interactive-banner-profile {
    right: 20px;
}

.interactive-banner.center .interactive-banner-profile {
    right: 0;
    left: 0;
}

.interactive-banner-v1 .interactive-banner-profile {
    width: 100%;
    height: 20px;
    bottom: 0;
    top: 0;
}

.interactive-banner-v2 .interactive-banner-profile {
    top: 40px;
    width: 100%;
    height: 20px;
}

.interactive-banner-v2 .interactive-banner-profile .action {
    padding: 5px 15px;
    margin-bottom: 3px;
    background-color: #fff;
    color: #333;
}

.interactive-banner-v2.left .interactive-banner-profile {
    left: 60px;
}

.interactive-banner-v2.right .interactive-banner-profile {
    right: 60px;
}

.interactive-banner-v2.center .interactive-banner-profile {
    right: 0;
    left: 0;
}

.interactive-banner-v3 .interactive-banner-profile {
    padding: 40px 60px;
    width: 100%;
    height: 100%;
    top: 0;
}

.interactive-banner-v3 .interactive-banner-profile .action {
    bottom: 60px;
    left: 60px;
    right: 60px;
    content: "";
    position: absolute;
}

.interactive-banner-v3 .interactive-banner-profile .action:after {
    content: "";
    position: absolute;
    border: 5px solid #fff;
    bottom: -30px;
    left: -30px;
    right: -30px;
    height: calc(100% + 60px);
    width: calc(100% + 60px);
    z-index: -1;
}

.interactive-banner-v4 .interactive-banner-profile {
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 0 30px;
    position: absolute;
    content: "";
    transform: translate3d(0px, 60px, 0px);
    -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
}

.interactive-banner-v4 .interactive-banner-profile .action {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s;
    transition: opacity 0.35s ease 0s;
    position: relative;
    background-color: #5cb85c;
    margin: 0 -30px;
    padding: 20px 30px;
}

.interactive-banner-v4 .interactive-banner-profile img {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s;
    transition: opacity 0.35s ease 0s;
}

.interactive-banner-v4:hover .interactive-banner-profile {
    transform: translate3d(0px, 0px, 0px);
}

.interactive-banner-v4:hover .interactive-banner-profile .action {
    opacity: 1;
    filter: alpha(opacity=100);
}

.interactive-banner-v4:hover .interactive-banner-profile img {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.interactive-banner-v5 .interactive-banner-profile {
    width: 55%;
    height: 120px;
    bottom: 0;
    top: 0;
}

.interactive-banner-v5 a.links {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -30px;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    transform: translate3d(0px, -60px, 0px);
}

.interactive-banner-v5:hover a.links {
    opacity: 1;
    filter: alpha(opacity=100);
    transform: translate3d(0px, 0px, 0px);
}

/** 4.11. About-team **/
.effect-v6 {
    background-color: #000;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    /* style for hover */
}

.effect-v6 img {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s;
    transition: opacity 0.35s ease 0s;
    width: 100%;
}

.effect-v6 .action {
    transform: translate3d(0px, -100px, 0px);
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
    opacity: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
}

.effect-v6 .action i.icons {
    color: #555555;
    font-size: 22px;
    margin: 0 10px;
}

.effect-v6 .action i.icons:hover {
    color: #80b435 !important;
    background: none !important;
}

.effect-v6:hover {
    border-color: #80b435;
}

.effect-v6:hover img {
    opacity: 0.3;
    filter: alpha(opacity=30);
}

.effect-v6:hover .action {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
}

.about-icon .feature-box p {
    font-family: "TXGR";
    font-style: normal;
}

.about-content {
    background-color: #eee;
}

.about-content .banner-title {
    text-align: center;
}

.about-content .banner-title h3 {
    font-size: 18px;
    text-transform: uppercase;
    line-height: 30px;
    font-family: "TXGR";
}

.about-content .banner-title p {
    font-family: "Lora";
    font-style: italic;
    color: #555555;
    line-height: 20px;
}

.about-content .effect-v6 {
    border-radius: 50%;
    background: #fff;
    border: 4px solid #80b435;
}

.about-content .effect-v6 img {
    border-radius: 50%;
}

.our-skills h3 {
    font-family: "TXGR";
    color: #555555;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px;
}

.our-skills .progress-bar-success {
    background: #80b435;
}

.our-skills .progress-bar {
    box-shadow: none;
    position: relative;
}

.our-skills .progress-bar span {
    position: absolute;
    top: -30px;
    right: -15px;
    color: #555555;
    font-family: "texgyreadventorbold";
    font-size: 14px;
}

.our-skills .progress {
    border-radius: 0;
    height: 8px;
    box-shadow: none;
    overflow: visible;
}

.head-about h3 {
    font: 400 30px/35px "Gotham";
    color: #2b2b2b;
    margin-bottom: 25px;
}

.head-about p {
    font: 400 16px/23px "Gotham";
    color: #666;
    margin-bottom: 20px;
}

.head-about .col-md-6 img.img-responsive {
    position: relative;
    z-index: 3;
}

.head-about .col-md-6.pd-left-10 {
    padding-left: 10px;
    padding-right: 45px;
    position: relative;
}

@media (max-width: 440px) {
    .head-about .col-md-6.pd-left-10 {
        padding-right: 10px;
    }
}

.head-about .col-md-6.pd-left-10 h3 {
    position: relative;
    background-color: #fff;
    z-index: 3;
    display: inline-block;
    padding-right: 45px;
}

.head-about .col-md-6.pd-left-10:before {
    position: absolute;
    content: "";
    left: -110px;
    right: 15px;
    bottom: -80px;
    top: 16px;
    border: 1px solid #80b435;
    display: inline-block;
}

.head-about .col-md-6.pd-left-10:after {
    position: absolute;
    content: "";
    left: -80px;
    height: 1px;
    width: 90px;
    background: #fff;
    top: 16px;
}

@media (max-width: 1200px) {
    .head-about .col-md-6 {
        text-align: center;
    }
    .head-about .col-md-6 img {
        margin: auto;
    }
    .head-about .col-md-6 h3 {
        margin-top: 30px;
    }
    .head-about .col-md-6.pd-left-10:before {
        border: none;
    }
}

.head-about .slider-about {
    padding: 0px 40px;
}

.about-choose {
    background: #f7f7f7;
}

.about-choose h3 {
    font: 400 48px/50px "Gotham";
    color: #80b435;
    margin-bottom: 30px;
}

.about-choose .choose-us .border-choose .images {
    background: #f7f7f7;
}

.about-choose .border p {
    font: 400 18px/25px "Gotham";
    color: #565656;
    margin: auto;
    margin-bottom: 30px;
    padding: 0 40px;
    position: relative;
    padding-bottom: 30px;
}

.about-choose .border p:before {
    position: absolute;
    left: 100px;
    right: 100px;
    height: 1px;
    bottom: 0px;
    background: #e6e6e6;
    content: "";
}

.about-choose .fbox-icon {
    font-size: 48px;
    margin-top: 0px;
}

.about-choose .feature-box .fbox-title {
    font: 700 16px/20px "Gotham";
    color: #333;
    text-transform: uppercase;
    letter-spacing: 0px;
}

.our-team .head {
    margin-bottom: 40px;
}

.our-team .head h3 {
    font: 400 48px/50px "Gotham";
    color: #80b435;
    margin: 10px 0px;
}

.our-team .head p {
    font: 400 14px/30px "Gotham";
    color: #565656;
    text-transform: capitalize;
}

.our-team .interactive-banner {
    display: inline-block;
    width: auto;
    border-radius: 50%;
    max-width: 100%;
    transition: all 0.3s ease 0s;
}

.our-team .interactive-banner:hover {
    box-shadow: 0px 8px 33px 0px rgba(0, 0, 0, 0.13);
}

.our-team .interactive-banner img {
    margin: auto;
    border-radius: 50%;
}

.our-team .banner-title h3 {
    font: 700 24px/30px "Gotham";
    text-transform: uppercase;
    margin: 10px 0px;
}

.our-team .banner-title p {
    font: 400 16px/25px "Gotham";
    color: #666;
    margin-bottom: 10px;
    margin-top: 20px;
    padding-top: 20px;
    position: relative;
}

.our-team .banner-title p:after {
    position: absolute;
    content: "";
    width: 110px;
    height: 1px;
    left: 0px;
    right: 0px;
    top: 0px;
    background: #eee;
    margin: auto;
}

.our-team .banner-title .action a {
    display: inline-block;
    margin: 0px 8px;
    transition: all 0.3s ease 0s;
}

.our-team .banner-title .action a:hover i {
    color: #80b435;
}

.our-team .banner-title .action i {
    font-size: 16px;
    color: #333;
}

@media (max-width: 767px) {
    .about-us {
        margin-bottom: 20px;
    }
    .about-us .col-md-4:nth-child(2) .feature-box {
        margin: 40px 0px 60px;
    }
}

/** 4.12. Blog **/
.blog-post-container h3 {
    font-size: 14px;
    margin-bottom: 3px;
}

.blog-post-container .read-more {
    margin-top: 20px;
}

.blog-post-container .post-by {
    font-size: 14px;
    text-transform: capitalize;
    color: #a7a7a7;
    display: inline-block;
    width: 100%;
    margin: 12px 0px;
    font-family: "Gotham";
    font-weight: 300;
}

.blog-post-container .post-by span:last-child {
    margin-left: 10px;
    padding-left: 10px;
}

@media (max-width: 1200px) {
    .blog-post-container .row {
        margin: 0px;
    }
}

.blog-post-container.blog-post-columns-2 .blog-post-item {
    width: 50%;
    box-shadow: 0px 0px 26.73px 0.27px rgba(86, 86, 86, 0.08);
}

.blog-post-container.blog-post-columns-2 .blog-post-item:nth-child(2n + 1) {
    padding-right: 10px;
}

.blog-post-container.blog-post-columns-2 .blog-post-item:nth-child(2n) {
    padding-left: 10px;
}

@media (max-width: 467px) {
    .blog-post-container.blog-post-columns-2 .blog-post-item {
        width: 100%;
    }
    .blog-post-container.blog-post-columns-2 .blog-post-item:nth-child(2n + 1),
    .blog-post-container.blog-post-columns-2 .blog-post-item:nth-child(2n) {
        padding: 0px;
    }
}

.blog-post-container.blog-post-columns-3 .blog-post-item {
    margin-bottom: 30px;
    width: 33.33%;
    box-shadow: 0px 0px 26.73px 0.27px rgba(86, 86, 86, 0.08);
}

.blog-post-container.blog-post-columns-3 .blog-post-item:nth-child(3n + 1) {
    padding-right: 20px;
}

.blog-post-container.blog-post-columns-3 .blog-post-item:nth-child(3n + 2) {
    padding-right: 10px;
    padding-left: 10px;
}

.blog-post-container.blog-post-columns-3 .blog-post-item:nth-child(3n) {
    padding-left: 20px;
}

@media (max-width: 767px) and (min-width: 480px) {
    .blog-post-container.blog-post-columns-3 .blog-post-item {
        width: 50%;
    }
    .blog-post-container.blog-post-columns-3 .blog-post-item:nth-child(2n + 1) {
        padding-right: 10px;
        padding-left: 0px;
    }
    .blog-post-container.blog-post-columns-3 .blog-post-item:nth-child(2n) {
        padding-left: 10px;
        padding-right: 0px;
    }
}

@media (max-width: 479px) {
    .blog-post-container.blog-post-columns-3 .blog-post-item {
        width: 100%;
    }
    .blog-post-container.blog-post-columns-3 .blog-post-item:nth-child(3n + 2),
    .blog-post-container.blog-post-columns-3 .blog-post-item:nth-child(3n + 1),
    .blog-post-container.blog-post-columns-3 .blog-post-item:nth-child(3n) {
        padding: 0px;
    }
}

.blog-post-container.blog-list-width .blog-post-item {
    width: 100%;
    position: relative;
}

.blog-post-container.blog-list-width .blog-post-item .blog-post-image {
    width: 35%;
    display: inline-block;
    float: left;
}

.blog-post-container.blog-list-width .blog-post-item .text {
    width: calc(65% + 85px);
    display: inline-block;
    right: 0px;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    padding-left: 50px;
    padding-bottom: 30px;
    box-shadow: 0px 0px 26.73px 0.27px rgba(86, 86, 86, 0.08);
}

.blog-post-container.blog-list-width .blog-post-item .text h3 a {
    font-size: 24px;
}

@media (max-width: 980px) {
    .blog-post-container.blog-list-width .blog-post-item .blog-post-image,
    .blog-post-container.blog-list-width .blog-post-item .text {
        width: 50%;
        position: relative;
        transform: translateY(0px);
    }
    .blog-post-container.blog-list-width .blog-post-item .text {
        padding: 25px 20px;
    }
}

@media (max-width: 767px) {
    .blog-post-container.blog-list-width .blog-post-item .blog-post-image,
    .blog-post-container.blog-list-width .blog-post-item .text {
        width: 50%;
    }
    .blog-post-container.blog-list-width .blog-post-item .text {
        padding-top: 10px;
        padding-bottom: 10px;
        box-shadow: none;
    }
    .blog-post-container.blog-list-width .blog-post-item .text h3 a {
        font-size: 18px;
    }
}

@media (max-width: 420px) {
    .blog-post-container.blog-list-width .blog-post-item .blog-post-image,
    .blog-post-container.blog-list-width .blog-post-item .text {
        width: 100%;
    }
}

.blog-post-container.blog-list-width .blog-post-item.right .blog-post-image {
    float: right;
}

.blog-post-container.blog-list-width .blog-post-item.right .text {
    right: initial;
    left: 0px;
}

.blog-post-container.comments {
    padding: 30px 0px;
}

.blog-post-container.comments h3.title {
    font: 700 16px/20px "Gotham";
    color: #000;
}

.blog-post-container.comments .media-object {
    width: 60px;
}

.blog-post-container .blog-post-item {
    float: left;
    margin-bottom: 50px;
}

.blog-post-container .blog-post-item h3 {
    min-height: auto;
    margin-top: 10px;
}

.blog-post-container .blog-post-item h3 a {
    color: #2b2b2b;
    font: 400 20px "Gotham";
    text-transform: initial;
}

.blog-post-container .blog-post-item h3 a:hover {
    color: #80b435;
}

.blog-post-container .blog-post-item .post-date {
    margin: 5px 0px;
    font: 300 14px "Gotham";
}

.blog-post-container .blog-post-item .content {
    font-family: "Gotham";
    line-height: 22px;
    color: #555555;
    margin-top: 10px;
    font-size: 14px;
    margin-bottom: 20px;
}

.blog-post-container .blog-post-item .text {
    width: calc(100% - 20px);
    margin: auto;
    margin-top: -100px;
    padding: 30px 20px;
    background: #fff;
    position: relative;
}

.blog-post-container .blog-post-item .blog-post-image {
    background: none;
    border-radius: 0;
    height: auto;
    border: none;
    position: relative;
    width: 100%;
}

.blog-post-container .blog-post-item .blog-post-image:hover {
    border: none;
}

.blog-post-container .blog-post-item .blog-post-image .action {
    position: absolute;
    text-align: center;
    left: 0px;
    top: 50%;
    margin-top: -33px;
}

.blog-post-container .blog-post-item .blog-post-image .action .icons {
    -webkit-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    font-size: 24px;
    color: #fff;
    box-shadow: 0 0 0 30px #80b435 inset;
    padding: 20px;
    border-radius: 50%;
}

.blog-post-container .blog-post-item .blog-post-image .action .icons:hover {
    box-shadow: 0 0 0 2px #80b435 inset;
    color: #80b435;
}

.blog-post-container .blog-post-item .blog-post-image img {
    width: 100%;
    border-radius: 0;
}

.blog-post-container .blog-post-item .posted {
    font-family: "TXGR";
    color: #666;
    margin-top: 25px;
}

.blog-post-container .blog-post-item .posted i {
    font-size: 14px;
    color: #888888;
}

.blog-post-container .contact-content {
    font-family: "TXGR";
}

.blog-post-container .contact-content h4 {
    color: #333333;
    margin: 20px 0px;
    font-size: 14px;
}

.blog-post-container .contact-content label.control-label {
    font-size: 14px;
}

.blog-post-container .blog-colum-left {
    padding-right: 0px;
}

.blog-post-container .blog-colum-left .image img {
    max-width: 100%;
}

.blog-post-container .blog-colum-left {
    padding-left: 0px;
}

.blog-post-container .pagination-container {
    text-align: center;
}

.blog-post-container .pagination-container nav.pagination a,
.blog-post-container .pagination-container nav.pagination span {
    font-weight: 700;
    font-size: 12px;
    font-style: normal;
}

.blog-post-container .pagination-container nav.pagination a.next,
.blog-post-container .pagination-container nav.pagination a.prev {
    width: auto;
    padding: 0 15px;
}

.blog-post-container .share-tags {
    display: inline-block;
    width: 100%;
    border-top: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
    padding: 20px 0px;
}

.blog-post-container .share-tags span {
    font-size: 13px;
    color: #555555;
    text-transform: uppercase;
    font-weight: 700;
    margin-right: 15px;
}

.blog-post-container .share-tags i {
    color: #555555;
    margin: 0px 5px;
}

.blog-post-container .share-tags i:hover {
    color: #80b435;
}

.blog-post-container .share-tags .share {
    float: left;
}

.blog-post-container .share-tags .tags {
    float: right;
}

.blog-post-container .share-tags a {
    font-family: "TXGR";
    margin: 0 5px;
    color: #555555;
}

.blog-post-container .share-tags a.active {
    color: #80b435;
    text-decoration: underline;
}

.blog-post-container .profile {
    border-bottom: 1px solid #d7d7d7;
    display: inline-block;
    width: 100%;
}

.blog-post-container .profile .avatar {
    width: 125px;
    height: 125px;
    border: 3px solid #80b435;
    border-radius: 50%;
    float: left;
    margin-right: 20px;
}

.blog-post-container .profile .avatar img {
    width: 100%;
    border-radius: 50%;
}

.blog-post-container .profile .text {
    font-family: "TXGR";
}

.blog-post-container .profile .text h3 {
    text-transform: capitalize;
    color: #333333;
    font-size: 16px;
}

.blog-post-container .profile .text p {
    font-size: 14px;
    color: #666;
}

.banner-page-top {
    position: relative;
    display: inline-block;
    width: 100%;
}

.banner-page-top.banner-page-top-ver2 .text h3 {
    font-size: 50px;
}

.banner-page-top .text {
    height: 100px;
    width: 100%;
    text-align: center;
    top: 50%;
    margin-top: -50px;
    position: absolute;
}

.banner-page-top .text h4 {
    font-size: 36px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: relative;
}

.banner-page-top .text h4 span {
    position: relative;
}

.banner-page-top .text h4 span:after {
    content: "";
    height: 4px;
    width: 14px;
    position: absolute;
    top: 50%;
    left: -20px;
    background: #fff;
}

.banner-page-top .text h4 span:before {
    content: "";
    height: 4px;
    width: 14px;
    position: absolute;
    top: 50%;
    right: -20px;
    background: #fff;
}

.banner-page-top .text h3 {
    font-size: 40px;
    color: #fff;
    text-transform: uppercase;
}

.banner-page-top .text p {
    font-family: "TXGR";
    color: #80b435;
    margin-top: 15px;
}

.banner-page-top img {
    width: 100%;
}

.page-blog .blog-post-container .blog-post-item h3 {
    margin-top: 0px;
}

.page-blog .blog-post-container .blog-post-item p.content {
    margin-top: 10px;
}

.page-blog .blog-post-container .blog-post-item .post-by {
    margin: 15px 0px;
}

.blog-slider .blog-post-item {
    position: relative;
    margin-bottom: 80px;
}

.blog-slider .blog-post-item .blog-post-content {
    position: absolute;
    left: 20px;
    right: 20px;
    background: #fff;
    bottom: -60px;
    text-align: center;
}

.blog-slider .blog-post-item .blog-post-content:before {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    right: 10px;
    bottom: 10px;
    border: 2px solid #e9e9e9;
}

.blog-slider .blog-post-item .blog-post-content .cat {
    font-size: 12px;
    font-weight: 300;
    color: #9a9a9a;
    display: inline-block;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 30px 0px;
    position: relative;
}

.blog-slider .blog-post-item .blog-post-content .cat:after {
    position: absolute;
    content: "";
    width: 38px;
    height: 1px;
    background: #000;
    margin-left: -19px;
    left: 50%;
    bottom: -15px;
}

.blog-slider .blog-post-item .blog-post-content .blog-title {
    font-family: "Gotham";
    color: #000;
    font-size: 25px;
    font-weight: 400;
    line-height: 25px;
    display: inline-block;
    width: 100%;
    position: relative;
}

.blog-slider .blog-post-item .blog-post-content .comment {
    font-family: "Poppins";
    color: #8d8c8c;
    font-size: 13px;
    line-height: 20px;
    margin-top: 15px;
    padding-bottom: 30px;
}

.blog-home1 .blog-content .text p.date {
    font: 500 14px/20px "Gotham";
    color: #242626;
    margin-top: 10px;
}

.blog-home1 .blog-content .text p.by {
    font: 500 12px/15px "Gotham";
    color: #898989;
}

.blog-home1 .blog-content .text p.by span {
    color: #242626;
    font-weight: 700;
}

.blog-home1 .blog-content .text h3 {
    color: #242626;
    font: 400 25px/30px "Gotham";
    margin: 10px 0px;
}

.blog-home1 .blog-content .text h3:hover {
    color: #80b435;
}

.blog-home1 .blog-content .text-v2 h3 {
    font: 700 18px/20px "Gotham";
    color: #000;
    margin-top: 22px;
}

.blog-home1 .blog-content .text-v2 h3:hover {
    color: #80b435;
}

.blog-home1 .blog-content .text-v2 p.by {
    font: 300 14px/20px "Gotham";
    color: #bbb;
    margin: 20px 0px 30px;
}

.blog-home1 .blog-content .text-v2 p.des {
    font: 400 16px/30px "Gotham";
    color: #666;
}

.blog-post-container.blog-post-container-v3 .blog-post-item .blog-post-content {
    position: relative;
    background: #fff;
    left: 0px;
    right: 0px;
    top: 0px;
}

.blog-post-container.blog-post-container-v3 .blog-post-item .blog-post-content:before {
    position: static;
    border: none;
    height: 0px;
    width: 0px;
}

.blog-post-container.blog-post-container-v3 .blog-post-item .blog-post-image {
    border-radius: 50%;
}

.blog-post-container-v4 .blog-post-item {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ececec;
    overflow: hidden;
    position: relative;
}

.blog-post-container-v4 .blog-post-item:after {
    position: absolute;
    content: "";
    left: 50%;
    right: 50%;
    bottom: 0px;
    height: 1px;
    background: #000;
    transition: all 0.3s ease 0s;
}

.blog-post-container-v4 .blog-post-item:hover:after {
    left: 0px;
    right: 0px;
}

.blog-post-container-v4 .blog-post-item:hover .blog-post-image,
.blog-post-container-v4 .blog-post-item:hover .blog-post-content {
    transform: translate(0px, 0px);
    opacity: 1;
}

.blog-post-container-v4 .blog-post-item:hover .read-more i {
    color: #000;
}

.blog-post-container-v4 .blog-post-image {
    display: inline-block;
    margin-right: 10px;
    transform: translate(-180px, 0px);
    transition: all 0.5s ease 0s;
    opacity: 0;
}

.blog-post-container-v4 .blog-post-image img {
    max-width: 155px;
}

.blog-post-container-v4 .blog-post-content {
    transform: translate(-167px, 0px);
    transition: all 0.4s ease 0s;
}

.blog-post-container-v4 .blog-post-content,
.blog-post-container-v4 .read-more {
    display: inline-block;
    vertical-align: -15px;
}

.blog-post-container-v4 .blog-title {
    font: 400 25px/30px "Gotham";
    color: #000;
    margin-bottom: 5px;
}

.blog-post-container-v4 .post-tag {
    font: 400 13px/20px "Poppins";
    text-transform: uppercase;
    display: inline-block;
    margin-top: 8px;
    width: 100%;
}

.blog-post-container-v4 .post-tag a {
    color: #000;
    font-weight: 600;
    margin-right: 15px;
}

.blog-post-container-v4 .post-tag span {
    color: #8d8c8c;
    text-transform: capitalize;
    margin-right: 5px;
}

.blog-post-container-v4 .read-more {
    float: right;
    margin-top: 25px;
}

.blog-post-container-v4 .read-more i {
    font-size: 30px;
    color: #cecece;
}

.blog-classic .wrap-sidebar {
    border: 1px solid #f2f2f2;
    display: inline-block;
    width: 100%;
    padding: 30px;
}

.blog-classic #primary.col-md-9 {
    float: left;
    padding-left: 0px;
    padding-right: 15px;
    width: 66.66667%;
}

.blog-classic #secondary.col-md-3 {
    float: right;
    padding-right: 0px;
    padding-left: 15px;
    width: 33.3333%;
}

.blog-classic .blog-post-container {
    margin-top: 0px;
    margin-bottom: 0px;
}

.blog-classic .blog-post-container .blog-post-item {
    padding-bottom: 40px;
    border-bottom: 1px solid #ececec;
    display: inline-block;
    width: 100%;
}

.blog-classic .blog-post-container .blog-post-images {
    overflow: hidden;
}

.blog-classic .blog-post-container .cat a {
    font: 300 12px/20px "Oswald";
    letter-spacing: 2px;
    color: #9a9a9a;
    text-transform: uppercase;
    margin-top: 20px;
    display: inline-block;
}

.blog-classic .blog-post-container .cat a:hover {
    color: #e95252;
}

.blog-classic .blog-post-container h3 a {
    font: 400 35px/35px "Gotham";
    color: #000;
    text-transform: capitalize;
}

.blog-classic .blog-post-container h3 a:hover {
    color: #e95252;
}

.blog-classic .blog-post-container .post-by {
    margin: 15px 0 20px;
    position: relative;
}

.blog-classic .blog-post-container .post-by:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 70px;
    bottom: -15px;
    left: 0px;
    background: #000;
}

.blog-classic .blog-post-container .post-by p {
    display: inline-block;
    text-transform: capitalize;
    font: 400 13px/20px "Poppins";
    color: #000;
}

.blog-classic .blog-post-container .post-by p.by-user {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #eee;
}

.blog-classic .blog-post-container .post-by p.by-user span {
    color: #858585;
}

.blog-classic .blog-post-container .post-by p.by-user a {
    color: #000;
}

.blog-classic .blog-post-container .post-by p.by-user a:hover {
    color: #e95252;
}

.blog-classic .blog-post-container .content p {
    font: 400 13px/25px "Poppins";
    color: #858585;
    margin-bottom: 30px;
}

.blog-classic .blog-post-container a.button {
    background: #000;
    color: #fff;
    font-size: 12px;
    letter-spacing: 2px;
    width: 165px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: inline-block;
}

.blog-classic .blog-post-container a.button:hover {
    background: #e95252;
}

.blog-classic .blog-post-container .owl-carousel .owl-controls .owl-nav .owl-prev,
.blog-classic .blog-post-container .owl-carousel .owl-controls .owl-nav .owl-next {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
    width: 35px;
    height: 35px;
    background: #fff;
    margin: 0px;
    bottom: 15px;
    top: inherit;
}

.blog-classic .blog-post-container .owl-carousel .owl-controls .owl-nav .owl-prev i,
.blog-classic .blog-post-container .owl-carousel .owl-controls .owl-nav .owl-next i {
    margin-top: 5px;
}

.blog-classic .blog-post-container .owl-carousel .owl-controls .owl-nav .owl-next {
    right: 10px;
}

.blog-classic .blog-post-container .owl-carousel .owl-controls .owl-nav .owl-prev {
    right: 50px;
    left: inherit;
}

.blog-classic .blog-post-container.single-post .form-control {
    border: 1px solid #e6e6e6;
}

.blog-classic .blog-post-container.single-post textarea {
    min-height: 130px;
}

.blog-classic .blog-post-container.single-post .blog-related .blog-post-item .text {
    padding: 0px;
    width: 100%;
    margin-top: 0px;
}

.blog-classic .blog-post-container.single-post .blog-related .blog-post-item .text h3 {
    margin-top: 10px;
    float: left;
}

.blog-classic .blog-post-container.single-post .blog-related .blog-post-item .text h3 a {
    margin-top: 20px;
    font: 400 16px/20px "Gotham";
}

.blog-classic .blog-post-container.single-post .blog-related .blog-post-item .text p {
    font-size: 12.5px;
    font-weight: 300;
    margin: 5px 0px;
}

.blog-classic .blog-post-container.single-post .blog-post-item {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border: none;
}

.blog-classic .blog-post-container.single-post .blog-post-item h3.title-v2 {
    color: #2b2b2b;
}

.blog-classic .blog-post-container.single-post .blog-post-item h3.title-v1 {
    font: 700 24px/30px "Gotham";
    text-transform: uppercase;
    color: #2b2b2b;
}

.blog-classic .blog-post-container.single-post .blog-post-item .head h3 {
    margin-top: 20px;
    font: 700 30px/30px "Gotham";
    text-transform: initial;
    color: #2b2b2b;
}

.blog-classic .blog-post-container.single-post .blog-post-item .head p span {
    margin: 0px 10px;
}

.blog-classic .blog-post-container.single-post .blog-post-item .head p:after {
    height: 0px;
}

.blog-classic .blog-post-container.single-post .blog-post-item .tag-cat {
    text-transform: uppercase;
}

.blog-classic .blog-post-container.single-post .blog-post-item .tag-cat a {
    display: inline-block;
    font: 300 12px/20px "Oswald";
    color: #7d7d7d;
    letter-spacing: 2px;
    margin-right: 3px;
}

.blog-classic .blog-post-container.single-post .blog-post-item .tag-cat span {
    font: 700 12px/30px "Oswald";
    margin-right: 10px;
    letter-spacing: 2px;
}

.blog-classic .blog-post-container.single-post .blog-post-item .text {
    width: 100%;
    padding: 30px 0px;
    margin-top: 0px;
}

.blog-classic .blog-post-container.single-post .blog-post-item .text p.normal {
    font-weight: 400;
    color: #666;
}

.blog-classic .blog-post-container.single-post .blog-post-item .text p,
.blog-classic .blog-post-container.single-post .blog-post-item .text li {
    font: 300 16px/25px "Gotham";
    color: #565656;
    margin-bottom: 20px;
}

.blog-classic .blog-post-container.single-post .blog-post-item .text p:after,
.blog-classic .blog-post-container.single-post .blog-post-item .text li:after {
    height: 0px;
}

.blog-classic .blog-post-container.single-post .blog-post-item .text ul li {
    margin-bottom: 0px;
    line-height: 30px;
}

.blog-classic .blog-post-container.single-post .blog-post-item .text ul li:before {
    display: inline-block;
    height: 5px;
    width: 5px;
    background: #333;
    margin-right: 10px;
    content: "";
    margin-bottom: 2px;
}

.blog-classic .blog-post-container.single-post .blog-post-item .text .content {
    padding: 40px;
}

.blog-classic .blog-post-container.single-post .blog-post-item .text .content p {
    font: 300 16px/25px "Gotham";
    font-style: italic;
    color: #818181;
}

.blog-classic .blog-post-container.single-post .social {
    display: inline-block;
    width: 100%;
    text-align: left;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    padding: 15px 0px;
    margin-bottom: 20px;
}

.blog-classic .blog-post-container.single-post .social h3 {
    display: inline-block;
    font: 700 14px "Gotham";
    color: #2b2b2b;
}

.blog-classic .blog-post-container.single-post .social a {
    display: inline-block;
    margin: 0px 5px;
}

.blog-classic .blog-post-container.single-post .social a.twitter i {
    color: #55acee;
}

.blog-classic .blog-post-container.single-post .social a.dribbble i {
    color: #e04c86;
}

.blog-classic .blog-post-container.single-post .social a.skype i {
    color: #00aff0;
}

.blog-classic .blog-post-container.single-post .social a.pinterest i {
    color: #bd081c;
}

.blog-classic .blog-post-container.single-post .social a.facebook {
    color: #3b5998;
}

.blog-classic .blog-post-container.single-post .social a i {
    font-size: 24px;
    margin: 5px 0px;
}

.blog-classic .blog-post-container.single-post .pagination {
    display: inline-block;
    width: 100%;
}

.blog-classic .blog-post-container.single-post .pagination .next,
.blog-classic .blog-post-container.single-post .pagination .prev {
    float: left;
    width: 50%;
    margin-bottom: 30px;
}

.blog-classic .blog-post-container.single-post .pagination .next a,
.blog-classic .blog-post-container.single-post .pagination .prev a {
    width: 100%;
    text-align: center;
    height: 50px;
    font-size: 12px;
    line-height: 46px;
    padding: 0px !important;
}

.blog-classic .blog-post-container.single-post .pagination .prev a {
    border-right: none;
}

.blog-classic .blog-post-container.single-post .pagination .prev a i {
    transform: rotate(180deg);
    margin-left: 0px;
    margin-right: 10px;
}

.blog-classic .blog-post-container.single-post .panel {
    box-shadow: none;
}

.blog-classic .blog-post-container.single-post .panel .media-left,
.blog-classic .blog-post-container.single-post .panel .media > .pull-left {
    padding-right: 30px;
}

.blog-classic .blog-post-container.single-post .panel .text {
    min-height: 10px;
    display: inline-block;
    position: relative;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.blog-classic .blog-post-container.single-post .panel .text a.reply {
    position: absolute;
    right: 0px;
    top: 0px;
    font: 300 12px/20px "Gotham";
    text-transform: uppercase;
    color: #979797;
}

.blog-classic .blog-post-container.single-post .panel .text a.reply i {
    font-size: 14px;
    margin-right: 5px;
}

.blog-classic .blog-post-container.single-post .panel .text a.reply:hover {
    color: #80b435;
}

.blog-classic .blog-post-container.single-post .panel .panel-body {
    padding: 0px;
}

.blog-classic .blog-post-container.single-post .panel .panel-body h3 {
    font-weight: 700;
    color: #000;
    min-height: auto;
    display: inline-block;
    margin-right: 10px;
    font: 500 16px/20px "Gotham";
}

.blog-classic .blog-post-container.single-post .panel .panel-body .date {
    font: 300 16px/20px "Gotham";
    color: #b4b4b4;
    margin-bottom: 10px;
    display: inline-block;
}

.blog-classic .blog-post-container.single-post .panel .panel-body .date:before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #d5d5d5;
    margin-right: 10px;
    margin-bottom: 2px;
}

.blog-classic .blog-post-container.single-post .panel .panel-body p {
    font: 300 16px/20px "Gotham";
    color: #858585;
    font-style: italic;
}

.blog-classic .blog-post-container.single-post .form-horizontal {
    display: inline-block;
    width: 100%;
}

.blog-classic .blog-post-container.single-post .form-horizontal a.button-v2 {
    float: right;
    background: #000;
    border: 2px solid #000;
    font-size: 14px;
    letter-spacing: 2px;
    font-family: "Oswald";
    width: 120px;
    text-align: center;
    height: 46px;
    line-height: 42px;
    padding: 0px !important;
}

.blog-masonry {
    margin: 0 -15px;
}

.blog-masonry .grid-sizer,
.blog-masonry .grid-item {
    width: 33.333333333333%;
}

.blog-masonry .grid-item {
    padding: 0 15px;
}

.blog-masonry .grid-item img {
    width: 100%;
}

.blog-masonry.masonry-por {
    margin: 0px;
}

.blog-masonry.masonry-por .grid-sizer {
    width: 25%;
}

.blog-masonry .inner-por-masonry {
    display: inline-block;
    width: 100%;
}

.blog-masonry .inner-por-masonry .grid-item {
    overflow: hidden;
    width: 25%;
    padding: 0px;
    height: 440px;
}

.blog-masonry .inner-por-masonry .grid-item:after {
    position: absolute;
    content: "";
    height: 70px;
    width: 70px;

    top: 50%;
    margin-left: -35px;
    margin-top: -35px;
    opacity: 0;
}

.blog-masonry .inner-por-masonry .grid-item .images {
    width: 100%;
}

.blog-masonry .inner-por-masonry .grid-item h3,
.blog-masonry .inner-por-masonry .grid-item h4 {
    transition: all 0.5s ease 0s;
    margin-left: 30px;
}

.blog-masonry .inner-por-masonry .grid-item h3 {
    font: 400 20px/25px "Gotham";
    color: #fff;
    margin-bottom: 10px;
}

.blog-masonry .inner-por-masonry .grid-item h4 {
    font: 300 12px/20px "Oswald";
    color: #888;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.blog-masonry .inner-por-masonry .grid-item p {
    width: 100%;
    text-align: right;
    color: #fff;
    font: 400 12px/20px "Oswald";
    transition: all 0.4s ease 0s;
    padding-right: 30px;
}

.blog-masonry .inner-por-masonry .grid-item.width-2x {
    width: 50%;
    height: 880px;
    padding: 0px;
}

.blog-masonry .inner-por-masonry .grid-item:hover:after {
    opacity: 1;
}

.blog-masonry .inner-por-masonry .grid-item:hover h3,
.blog-masonry .inner-por-masonry .grid-item:hover h4 {
    transform: translate(0, -85px);
}

.blog-masonry .inner-por-masonry .grid-item:hover p {
    transform: translate(0, -125px);
}

.blog-classic .blog-post-container.blog-masonry .post-by {
    margin: 10px 0px 30px;
}

.blog-classic .blog-post-container.blog-masonry .post-by:after {
    height: 0px;
}

.blog-classic .blog-post-container.blog-masonry h3 {
    min-height: 30px;
    margin-bottom: 20px;
}

.blog-classic .blog-post-container.blog-masonry h3 a {
    font-size: 25px;
    position: relative;
    display: inline-block;
}

.blog-classic .blog-post-container.blog-masonry h3 a:after {
    position: absolute;
    content: "";
    left: 0px;
    bottom: -10px;
    height: 1px;
    width: 60px;
    background: #000;
}

.blog-classic .blog-post-container.blog-masonry a.button {
    background: none;
    text-align: left;
    color: #000;
    height: 40px;
    line-height: 30px;
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    position: relative;
}

.blog-classic .blog-post-container.blog-masonry a.button:after {
    left: 0px;
    bottom: 10px;
    width: 0px;
    height: 2px;
    background: #e95252;
    content: "";
    position: absolute;
    transition: all 0.3s ease 0s;
}

.blog-classic .blog-post-container.blog-masonry a.button:hover {
    color: #e95252;
}

.blog-classic .blog-post-container.blog-masonry a.button:hover:after {
    width: 90px;
}

.blog-classic .blog-post-container.blog-masonry a.button:hover .link-icon-black {
    background-position: 0 bottom;
}

.blog-classic .blog-post-container.blog-masonry .content p {
    margin-bottom: 10px;
}

.blog-classic .blog-post-container.blog-masonry .link-icon-black {
    background-image: url(../images/Uno-button-icon2.png);
}

.link {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
}

a.load-more {
    display: inline-block;
    width: 166px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    font: 700 12px/46px "Oswald";
    color: #fff;
    letter-spacing: 2px;
    background: #000;
    text-transform: uppercase;
    margin: 0 auto;
}

a.load-more:hover {
    background: #e95252;
}

a.load-more i {
    color: #fff;
    font-size: 16px;
    vertical-align: -2px;
}

.bastian-content .images img {
    width: 100%;
}

.bastian-content .col-md-6 h3 {
    font: 400 25px/30px "Gotham";
    color: #000;
    text-transform: capitalize;
    padding-bottom: 15px;
    margin-bottom: 20px;
    position: relative;
}

.bastian-content .col-md-6 h3:after {
    position: absolute;
    content: "";
    left: 0px;
    bottom: 0px;
    height: 1px;
    width: 40px;
    background: #333;
}

.bastian-content .col-md-6 p {
    font: 400 13px/25px "Poppins";
    color: #858585;
    margin-bottom: 30px;
}

.control-page {
    text-align: center;
    float: left;
    width: 100%;
    position: relative;
}

.control-page .next,
.control-page .prev,
.control-page .box-icon {
    display: inline-block;
}

.control-page .next {
    float: left;
    text-align: left;
}

.control-page .prev {
    float: right;
    text-align: right;
}

.control-page .box-icon {
    position: absolute;
    width: 80px;
    height: 80px;
    background: #000000;
    border-radius: 50%;
    text-align: center;
    left: 50%;
    margin-left: -40px;
}

.control-page .box-icon i {
    color: #fff;
    font-size: 30px;
    line-height: 80px;
}

.control-page a.title {
    font: 400 50px/50px "Gotham";
    color: #000;
    text-transform: capitalize;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    letter-spacing: 0px;
}

.control-page a {
    font: 300 12px/20px "Oswald";
    color: #8e8e8e;
    letter-spacing: 4px;
    display: inline-block;
    text-transform: uppercase;
}

.control-page a:hover i.link-icon-white {
    background-position: 0 0;
}

.control-page a i {
    margin: 0px !important;
}

.control-page .next a:hover i.link-icon-white {
    background-position: 0 0;
}

.last-news h2 {
    font: 400 40px "Gotham";
    color: #2b2b2b;
    margin-bottom: 20px;
}

@media (min-width: 1200px) {
    .last-news .col-md-6:last-child {
        padding-left: 50px;
    }
    .rtl .last-news .col-md-6:last-child {
        padding-right: 50px;
        padding-left: inherit;
    }
    .last-news .col-md-6:first-child {
        padding-right: 100px;
        border-right: 1px solid #cdcdcd;
    }
    .rtl .last-news .col-md-6:first-child {
        padding-left: 100px;
        padding-right: inherit;
    }
    .rtl .last-news .col-md-6:first-child {
        border-left: 1px solid #cdcdcd;
        border-right: inherit;
    }
}

.last-news .lastest-news h3 {
    font: 400 20px/25px "Gotham";
    color: #2b2b2b;
    margin-bottom: 5px;
}

.last-news .lastest-news h3 a {
    color: #2b2b2b;
}

.last-news .lastest-news h3 a:hover {
    color: #80b435;
}

.last-news .lastest-news p {
    color: #b5b5b5;
    font: 300 14px "Gotham";
}

.last-news .lastest-news li {
    display: block;
    padding: 20px 0px;
    border-bottom: 1px solid #eaeaea;
    position: relative;
    padding-left: 20px;
}

.last-news .lastest-news li:after {
    position: absolute;
    content: "";
    left: 0px;
    top: 33px;
    height: 9px;
    width: 8px;
}

.rtl .last-news .lastest-news li:after {
    right: 0px;
    left: auto;
}

.rtl .last-news .lastest-news li:after {
    transform: rotateY(180deg);
}

.rtl .last-news .lastest-news li {
    padding-right: 20px;
    padding-left: inherit;
}

.last-news .lastest-news li:last-child {
    border: none;
}

.last-news .client-say .content-text {
    position: relative;
    margin-bottom: 30px;
}

.last-news .client-say .content-text p {
    font: 300 16px/20px "Gotham";
    font-style: italic;
    position: relative;
    padding: 30px 20px 40px 20px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
}

.last-news .client-say .content-text:after {
    position: absolute;
    content: "";

    bottom: -25px;
    width: 26px;
    height: 30px;
}

.rtl .last-news .client-say .content-text:after {
    right: 60px;
    left: auto;
}

.last-news .user {
    padding-left: 40px;
}

.rtl .last-news .user {
    padding-right: 40px;
    padding-left: inherit;
}

.last-news .user .avatar,
.last-news .user .text {
    display: inline-block;
}

.last-news .user .text {
    padding-left: 10px;
    padding-top: 10px;
}

.rtl .last-news .user .text {
    padding-right: 10px;
    padding-left: inherit;
}

.last-news .user h3 {
    font: 500 16px/20px "Gotham";
    color: #333;
    text-transform: uppercase;
}

.last-news .user p {
    color: #80b435;
    text-transform: capitalize;
    font: 300 12px/15px "Gotham";
}

@media (min-width: 768px) {
    .menu-vertical {
        width: 100%;
    }
}

/** 4.13. Images Zoom **/
.product-img-box {
    margin-bottom: 50px;
    text-align: center;
}

.product-img-box #image-view {
    display: inline-block;
    float: right;
    max-width: 630px;
}

.product-img-box .product-thumb {
    float: left;
    display: inline-block;
}

.product-img-box .thumb-content {
    width: 100%;
    max-width: 150px;
    float: left;
}

.product-img-box .thumb-content li {
    display: block;
    float: left;
    padding-right: 15px;
    margin-bottom: 20px;
}

.product-img-box .thumb-content li a {
    display: inline-block;
    border: 1px solid #fff;
}

.product-img-box .thumb-content li.active a {
    border: 1px solid #333;
}

.product-img-box img {
    width: 100%;
}

.product-img-box.product-img-box-v2 .thumb-content {
    max-width: 100%;
    margin-top: 20px;
}

.product-img-box.product-img-box-v2 .thumb-content li {
    width: 33.33%;
    display: inline-block;
}

.product-img-box.product-img-box-v2 .thumb-content li:nth-child(3n + 1) {
    padding-right: 14px;
    padding-left: 0px;
}

.product-img-box.product-img-box-v2 .thumb-content li:nth-child(3n + 2) {
    padding: 0px 7px;
}

.product-img-box.product-img-box-v2 .thumb-content li:nth-child(3n) {
    padding-right: 0px;
    padding-left: 14px;
}

.product-details-content {
    width: 100%;
    float: left;
}

.product-details-content .col-md-6:first-child {
    padding-left: 0px;
}

.product-details-content .slider-nav button {
    display: none !important;
    position: absolute;
    left: -30px;
    top: 50%;
    font-size: 0px;
    transform: translateY(-50%);
    background: none;
    border: none;
}

.product-details-content .slider-nav button:focus {
    outline: none;
}

.product-details-content .slider-nav button:hover:before {
    color: #80b435;
}

.product-details-content .slider-nav button:before {
    content: "\f104";
    font: normal normal normal 30px/1 "FontAwesome";
    color: #a2a2a2;
}

.product-details-content .slider-nav button.slick-prev {
    /* right: -30px; */
    left: initial;
}

.product-details-content .slider-nav button.slick-prev:before {
    content: "\f105";
}

.product-details-content .slider-nav .slick-slide {
    padding: 2px;
    margin: 0px;
    cursor: pointer;
}

.product-details-content .slider-nav .slick-slide img {
    border: 1px solid #fff;
}

.product-details-content .slider-nav .slick-slide.slick-current img,
.product-details-content .slider-nav .slick-slide:hover img {
    border: 1px solid #80b435;
}

.product-details-content .slide-vertical {
    position: relative;
}

@media (min-width: 1200px) {
    .product-details-content .slide-vertical.right {
        float: right;
    }
    .product-details-content .slide-vertical .slider-for {
        width: 80%;
        float: right;
        transform: rotate(90deg);
    }
    .product-details-content .slide-vertical .slider-for .slick-slide {
        transform: rotate(-90deg);
    }
    .product-details-content .slide-vertical .slider-nav {
        float: left;
        transform: rotate(90deg);
        height: 170px;
        width: 700px;
        position: absolute;
        left: -265px;
        top: 280px;
    }
    .product-details-content .slide-vertical .slider-nav button {
        display: inline-block !important;
        position: absolute;
        left: -30px;
    }
    .product-details-content .slide-vertical .slider-nav button.slick-prev {
        /* right: -30px; */
        left: initial;
    }
    .product-details-content .slide-vertical .slider-nav .slick-slide {
        transform: rotate(-90deg);
    }
    .product-details-content .slide-vertical .slider-nav .slick-slide.slick-current img,
    .product-details-content .slide-vertical .slider-nav .slick-slide:hover img {
        border-color: #80b435;
    }
    .product-details-content .slide-vertical .slider-nav .slick-slide img {
        border: 1px solid #fff;
    }
    .product-details-content .slide-vertical.right .slider-for {
        float: left;
    }
    .product-details-content .slide-vertical.right .slider-nav {
        float: right;
        left: initial;
        right: -265px;
    }
}

.draggable {
    padding: 0px !important;
}

.slick-slide:focus {
    outline: none;
}

.slick-slide img {
    width: 100%;
}

.slick-prev:before,
.slick-next:before {
    color: black !important;
    font-size: 26px !important;
}

.slider-for,
.slider-nav {
    width: 100%;
    margin: auto;
}

.slider-for {
    margin-bottom: 0px;
}

.slider-for .slick-slide {
    overflow: hidden;
}

/* these styles are for the demo, but are not required for the plugin */
.zoom {
    display: inline-block;
    position: relative;
}

.zoom img {
    display: block;
}

/** 4.14. Icon Progress Bar **/
.icon-rating {
    float: left;
}

.icon-rating input {
    left: -9999px;
    position: absolute;
}

.icon-rating label {
    cursor: pointer;
    display: block;
    float: right;
    font-size: 22px;
    height: 17px;
    line-height: 17px;
    padding: 0 1px;
}

.icon-rating label {
    color: #e0e0e0;
    transition: color 0.3s ease 0s;
}

.icon-rating input + label:hover,
.icon-rating input + label:hover ~ label {
    color: #e19824;
}

.icon-rating input:checked ~ label {
    color: #e19824;
}

.item-inner .product .icon-rating input:checked ~ label {
    color: #e19824;
}

.icon-rating-horizontal {
    float: none;
    text-align: center;
}

.icon-rating-horizontal label {
    float: none;
    height: 20px;
    line-height: 20px;
}

.light-style {
    color: #fff;
}

.light-style h1,
.light-style h2,
.light-style h3,
.light-style h4,
.light-style h5,
.light-style h6 {
    color: #fff;
}

.bg-success .icon-rating label {
    color: #648d29;
}

.bg-success .icon-rating input + label:hover,
.bg-success .icon-rating input + label:hover ~ label,
.bg-success .icon-rating input:checked ~ label {
    color: #fff;
}

.bg-danger .icon-rating label {
    color: #b52b27;
}

.bg-danger .icon-rating input + label:hover,
.bg-danger .icon-rating input + label:hover ~ label,
.bg-danger .icon-rating input:checked ~ label {
    color: #fff;
}

.bg-warning .icon-rating label {
    color: #df8a13;
}

.bg-warning .icon-rating input + label:hover,
.bg-warning .icon-rating input + label:hover ~ label,
.bg-warning .icon-rating input:checked ~ label {
    color: #fff;
}

.bg-primary .icon-rating label {
    color: #567924;
}

.bg-primary .icon-rating input + label:hover,
.bg-primary .icon-rating input + label:hover ~ label,
.bg-primary .icon-rating input:checked ~ label {
    color: #fff;
}

.bg-info .icon-rating label {
    color: #28a1c5;
}

.bg-info .icon-rating input + label:hover,
.bg-info .icon-rating input + label:hover ~ label,
.bg-info .icon-rating input:checked ~ label {
    color: #fff;
}

div.stars {
    width: 270px;
    display: inline-block;
}

input.star {
    display: none;
}

label.star {
    float: right;
    padding: 10px;
    font-size: 36px;
    color: #444;
    transition: all 0.2s;
}

input.star:checked ~ label.star:before {
    content: "\f005";
    color: #fd4;
    transition: all 0.25s;
}

input.star-5:checked ~ label.star:before {
    color: #fe7;
    text-shadow: 0 0 20px #952;
}

input.star-1:checked ~ label.star:before {
    color: #f62;
}

label.star:hover {
    transform: rotate(-15deg) scale(1.3);
}

label.star:before {
    content: "\f006";
    font-family: FontAwesome;
}

/** 4.15. Parallax **/
/** 4.16 Instagram **/
.testimonial {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #fff;
}

.testimonial h3 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 15px;
    line-height: 20px;
}

.testimonial h3:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 0px;
    width: 50px;
    margin-left: -25px;
    background: #eeeeee;
    height: 2px;
}

.testimonial p {
    font-family: "Gotham";
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    max-width: 980px;
    margin: 0 auto 30px;
}

.testimonial p.user-name {
    font-family: "Oswald";
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
}

.testimonial img {
    width: auto;
    border-radius: 50%;
    border: 3px solid #bababa;
}

.testimonial .wrap-star {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}

.testimonial .wrap-star a {
    color: #eed52e;
}

.testimonial .wrap-star a i {
    font-size: 12.42px;
}

.testimonial.dots-show .owl-dots {
    bottom: 80px;
}

.testimonial.dots-show .owl-dots .owl-dot {
    background: #bbb;
}

.testimonial.dots-show .owl-dots .owl-dot.active,
.testimonial.dots-show .owl-dots .ordering span.owl-dot:hover,
.ordering .testimonial.dots-show .owl-dots span.owl-dot:hover {
    background: #fff;
}

/** 4.16 Instagram **/
.cat-box-home3 img {
    width: 100%;
}

.cat-box-home3 span.label {
    position: absolute;
    display: inline-block;
    right: 40px;
    top: 60px;
    color: #e95252;
    text-transform: uppercase;
    font-size: 12px;
    transform: rotate(90deg);
    font-weight: 700;
    letter-spacing: 2px;
    padding: 10px;
    z-index: 9;
    border: 2px solid #e95252;
    border-radius: 0px;
}

.cat-box-home3 .row {
    margin: 0px;
    margin-bottom: 30px;
}

.cat-box-home3 .col-md-6 {
    position: relative;
}

.cat-box-home3 .col-md-6.box-text {
    min-height: 300px;
}

.cat-box-home3 .col-md-6:first-child {
    padding-left: 0px;
}

.cat-box-home3 .col-md-6:first-child span.label {
    right: 0px;
}

@media (max-width: 460px) {
    .cat-box-home3 .col-md-6:first-child span.label {
        right: -20px;
    }
}

.cat-box-home3 .col-md-6:last-child {
    padding-right: 0px;
}

.cat-box-home3 .col-md-6:last-child span.label {
    right: inherit;
    left: 0px;
}

@media (max-width: 460px) {
    .cat-box-home3 .col-md-6:last-child span.label {
        left: inherit;
        right: -20px;
    }
}

.cat-box-home3 .col-md-6.bg-text {
    min-height: 350px;
}

.cat-box-home3 .col-md-6.bg-text:before {
    content: " " attr(bg-text) " ";
    font-size: 100px;
    font-family: "Gotham";
    color: #e9e9e9;
    font-weight: 400;
    text-transform: uppercase;
    position: absolute;
    left: 0px;
    width: 100%;
    text-align: center;
    height: 100%;
    top: 25%;
}

.cat-box-home3 .col-md-6.bg-text h1 {
    font-family: "Gotham";
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 400;
    color: #000;
    position: absolute;
    width: 100%;
    left: 0px;
    text-align: center;
    top: 45%;
}

.cat-box-home3 .col-md-12 {
    padding: 0px;
    position: relative;
}

.cat-box-home3 .col-md-12 .col-md-6 {
    padding: 0px;
}

.cat-box-home3 .col-md-12 .col-md-6:first-child {
    padding-right: 15px;
}

.cat-box-home3 .col-md-12 .col-md-6:last-child {
    padding-left: 15px;
}

.cat-box-home3 .col-md-12 .col-md-6 .text {
    left: 35px;
}

.cat-box-home3 .text {
    position: absolute;
    bottom: 50px;
    left: 65px;
}

.cat-box-home3 .text h3 {
    font-size: 40px;
    color: #000;
    font-family: "Gotham";
    position: relative;
    line-height: 40px;
    margin-bottom: 10px;
}

.cat-box-home3 .text h3:after {
    position: absolute;
    content: "";
    left: -80px;
    width: 60px;
    height: 2px;
    top: 50%;
    background: #000;
}

.cat-box-home3 .text p {
    font-size: 12px;
    color: #9d9d9d;
    font-weight: 300;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.cat-box-home3 .text a {
    display: inline-block;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border: 2px solid #000;
}

.catbox-container {
    float: left;
    width: 100%;
    margin-bottom: 30px;
}

.catbox-container .items {
    width: 33.33%;
    float: left;
    position: relative;
}

@media (max-width: 440px) {
    .catbox-container .items {
        width: 100%;
    }
}

.catbox-container .items .text {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    top: auto;
    bottom: 0;
    text-align: center;
}

.catbox-container .items .text h3 {
    font: 700 42px/75px "Gotham";
    color: #fff;
    /* background: #80b435; */
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(0, 0, 0)) !important;
    display: inline-block;
    padding: 0px 0px 5px;
    margin-bottom: 0;
    margin-top: 0;
}

.catbox-container .items .text a {
    font: 400 18px/60px "Gotham";
    color: #000;
    background-color: #fff;
    padding: 0px;
    display: block;
    margin: auto;
    max-width: 195px;
}

@media (max-width: 1200px) {
    .catbox-container .items .text h3 {
        font: 700 25px/60px "Gotham";
    }
    .catbox-container .items .text a {
        font: 400 14px/40px "Gotham";
        max-width: 150px;
    }
}

@media (max-width: 767px) and (min-width: 441px) {
    .catbox-container .items .text h3 {
        font: 700 16px/50px "Gotham";
    }
}

.catbox-container.home-4 .items .text h3 {
    font: 500 19px/55px "Gotham";
}

.catbox-container.home-4 .items .text a {
    font: 400 14px/50px "Gotham";
    max-width: 150px;
}

@media (max-width: 1200px) {
    .catbox-container.home-4 .items .text h3 {
        font: 500 21px/15px "Gotham";
    }
}

.catbox-container.home-4 .items.left .text {
    width: 240px;
    text-align: left;
    left: 30px;
    right: inherit;
    top: inherit;
    bottom: 25px;
    transform: translate(0, 0);
}

.catbox-container.home-4 .items.left .text a {
    display: inline-block;
    width: 150px;
    text-align: center;
}

.catbox-container.home-4 .items.bottom .text {
    top: inherit;
    bottom: 40px;
    transform: translate(0, 0);
}

/** 4.16 Instagram **/
.time {
    text-align: center;
    padding: 30px 0px;
    display: inline-block;
    width: 100%;
}

.time .distance {
    display: inline-block;
    margin: 0px 15px;
    width: 240px;
    padding: 20px 0px 50px;
}

.time .distance .number {
    font-size: 100px;
    display: inline-block;
}

.time .distance .number b {
    font-weight: 400;
}

.time .distance .text {
    font-size: 40px;
    text-transform: capitalize;
    display: inline-block;
    font-family: "Gotham";
    color: #a4a4a4;
    font-weight: 400;
    margin-left: 5px;
}

.time.time-v2 .distance {
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.11);
    margin: 0px 25px;
}

.time.time-v2 .distance .number {
    display: block;
}

.time.time-v2 .distance .number b {
    color: #f68585;
}

.time.time-v2 .distance .text {
    display: block;
    margin-left: 0px;
}

.time.time-v3 .distance .number {
    font-size: 50px;
    vertical-align: -40px;
    min-width: 60px;
}

.time.time-v3 .distance .text {
    font-family: "Oswald";
    font-size: 20px;
    color: #e95252;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.social-v3,
.social-v2,
.social-v1 {
    text-align: center;
    display: inline-block;
    width: 100%;
}

.social-v3 a,
.social-v2 a,
.social-v1 a {
    color: #000;
}

.social-v1 a i {
    font-size: 20px;
    margin: 0px 20px;
}

.border-box {
    display: inline-block;
    width: 100%;
    margin: 50px 0px 20px;
    height: 1px;
    background: #ebebeb;
}

.social-v2 a i {
    font-size: 80px;
    margin: 30px 80px;
}

.social-v3 a {
    display: inline-block;
    padding: 25px 40px;
    width: 184px;
    height: 90px;
    margin-right: 5px;
}

.social-v3 a:last-child() {
    margin-right: 0px;
}

.social-v3 a i {
    font-size: 40px;
    color: #fff;
}

.social-v3 a.twitter {
    background: #01bbf6;
}

.social-v3 a.dribbble {
    background: #f5699a;
}

.social-v3 a.facebook {
    background: #3b5999;
}

.social-v3 a.youtube {
    background: #fe0000;
}

.social-v3 a.rss {
    background: #f7a42c;
}

.social-v3 a.pinterest {
    background: #f23434;
}

.title-box {
    display: inline-block;
    width: 100%;
}

.title-box h3 {
    text-transform: uppercase;
    font: 700 14px "Oswald";
    letter-spacing: 2px;
    line-height: 20px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    position: relative;
}

.title-box h3:after {
    position: absolute;
    content: "";
    left: 0px;
    bottom: 0px;
    width: 50px;
    background: #000;
    height: 2px;
}

.title-box p {
    font: 400 13px/20px "Poppins";
    color: #858585;
    margin-bottom: 15px;
}

.title-box.center {
    text-align: center;
}

.title-box.center h3:after {
    left: 50%;
    margin-left: -25px;
}

.accordion .panel-title a {
    font: 600 18px "Poppins";
    color: #e95252;
}

.accordion .panel-title a:before {
    height: 30px;
    width: 30px;

    display: inline-block;
    float: right;
}

.accordion .panel-title a.collapsed {
    color: #000;
}

.accordion .panel-collapse {
    font: 400 13px/25px "Poppins";
    color: #858585;
}

.accordion .panel {
    border: none;
    box-shadow: none;
}

.accordion .panel-default .panel-heading {
    background: none;
    padding-left: 0px;
    padding-right: 0px;
    border-bottom: 1px solid #eee;
}

.accordion .panel-body {
    padding: 0px;
    padding: 20px 0px;
}

.accordion .panel-group .panel-heading + .panel-collapse > .panel-body,
.accordion .panel-group .panel-heading + .panel-collapse > .list-group {
    border-top: none;
}

.boxed-content {
    background: #93cacd;
    padding: 35px;
    display: inline-block;
    position: relative;
}

.boxed-content h2 {
    font: 700 12px/20px "Oswald";
    color: #fff;
    letter-spacing: 2px;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.boxed-content h2.center {
    text-align: center;
}

.boxed-content h2:after {
    position: absolute;
    content: "";
    width: 50px;
    height: 1px;
    background: #fff;
    bottom: 0px;
    left: 0px;
}

.boxed-content p {
    font: 400 13px/25px "Poppins";
    color: #fff;
    padding-bottom: 20px;
}

.boxed-content.images {
    padding: 0px;
    background: #f5f5f5;
    border: 1px solid #eaeaea;
}

.boxed-content.images p,
.boxed-content.images h2 {
    max-width: 250px;
    padding-left: 35px;
}

.boxed-content.images p:after,
.boxed-content.images h2:after {
    left: 35px;
}

.boxed-content.images p {
    color: #858585;
}

.boxed-content.images h2 {
    color: #000;
}

.boxed-content.images h2:after {
    background: #d9d9d9;
}

.boxed-content.images img {
    float: right;
    max-width: 290px;
}

.boxed-content.images .text {
    float: left;
    margin-top: 30px;
}

.boxed-content.center {
    text-align: center;
}

.boxed-content.center h2:after {
    left: 50%;
    margin-left: -25px;
}

.boxed-content.boxed-content-v2 {
    background: #f5f5f5;
    padding: 50px 70px;
}

.boxed-content.boxed-content-v2 p {
    color: #858585;
}

.boxed-content.boxed-content-v2 h2 {
    color: #000;
}

.boxed-content.boxed-content-v2 h2:after {
    background: #d9d9d9;
}

.boxed-content.boxed-content-v3 {
    background: #e6bdad;
    padding-top: 65px;
}

.boxed-content .icons {
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 74px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-size: 30px;
    background: #e95252;
    border: 3px solid #f8abab;
    position: absolute;
}

.boxed-content .icons.bg-black {
    background: #000;
    border: 3px solid #a18479;
}

.boxed-content .icons.left,
.boxed-content .icons.right {
    left: -40px;
    top: 50%;
    margin-top: -40px;
}

.boxed-content .icons.right {
    left: inherit;
    right: -40px;
}

.boxed-content .icons.top,
.boxed-content .icons.bottom {
    left: 50%;
    margin-left: -40px;
    top: -40px;
}

.boxed-content .icons.bottom {
    top: inherit;
    bottom: -40px;
}

.tab-v1 .tab-content {
    display: inline-block;
}

.tab-v1 .nav-tabs {
    text-align: center;
    border: none;
}

.tab-v1 .nav-tabs li {
    float: initial;
    display: inline-block;
    width: 250px;
    height: 66px;
    background: #ededed;
    line-height: 66px;
}

.tab-v1 .nav-tabs li.active,
.tab-v1 .nav-tabs li:hover {
    background: #2a2a2a;
}

.tab-v1 .nav-tabs li.active a,
.tab-v1 .nav-tabs li:hover a {
    border: none;
    background: none;
    color: #fff;
    box-shadow: none;
    position: relative;
}

.tab-v1 .nav-tabs li.active a:after,
.tab-v1 .nav-tabs li:hover a:after {
    position: absolute;
    content: "";
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid #2a2a2a;
    height: 0px;
    width: 0px;
    bottom: -10px;
    left: 50%;
}

.tab-v1 .nav-tabs li a {
    text-transform: uppercase;
    font-weight: 700;
    color: #000;
    letter-spacing: 2px;
    border: none;
    line-height: 66px;
    height: 66px;
    padding: 0px;
}

.tab-v1 .tab-pane {
    text-align: center;
}

.tab-v1 .products .item-inner {
    width: 33.33%;
    float: left;
    padding: 0px 10px;
}

.tab-v1 .custom-content {
    text-align: left;
}

.tab-v1 .custom-content h1 {
    font: 400 50px/50px "Gotham";
    color: #000;
    text-transform: capitalize;
    padding-bottom: 15px;
}

.tab-v1 .custom-content h3 {
    font: 300 14px/20px "Oswald";
    color: #9d9d9d;
    letter-spacing: 3px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;
}

.tab-v1 .custom-content h3:after {
    position: absolute;
    content: "";
    left: 0px;
    bottom: 0px;
    height: 1px;
    width: 70px;
    background: #000;
}

.tab-v1 .custom-content p {
    font: 300 14px/25px "Poppins";
    padding-bottom: 20px;
}

.tab-v1 .custom-content a {
    display: inline-block;
    line-height: 36px;
    height: 40px;
    border: 2px solid #000;
}

.tab-v1 .accordion {
    text-align: left;
}

.btn {
    font: 400 14px "Oswald";
    letter-spacing: 2px;
    text-transform: uppercase;
    width: 215px;
    text-align: center;
    margin-right: 10px;
    border-radius: 0px;
    border: none;
    height: 58px;
    color: #fff;
}

.btn:hover {
    opacity: 0.8;
}

.btn:last-child {
    margin-right: 0px;
}

.btn.btn-default {
    background: #363636;
}

.btn.btn-default:hover,
.btn.btn-default:focus {
    color: #fff;
}

.btn.btn-success {
    background: #6bdb80;
}

.btn.btn-danger {
    background: #e95151;
}

.btn.btn-info {
    background: #5ad0cf;
}

.btn.btn-warning {
    background: #f1cd4f;
}

.buttton-v2 .btn {
    border-radius: 30px;
}

.buttton-v3 .btn {
    color: #000;
    background: none;
}

.buttton-v3 .btn.btn-default {
    border: 2px solid #363636;
}

.buttton-v3 .btn.btn-default:hover,
.buttton-v3 .btn.btn-default:focus {
    color: #000;
}

.buttton-v3 .btn.btn-success {
    border: 2px dashed #6bdb80;
}

.buttton-v3 .btn.btn-danger {
    border: 2px solid #e95151;
}

.buttton-v3 .btn.btn-info {
    border: 2px solid #5ad0cf;
}

.buttton-v3 .btn.btn-warning {
    border: 2px solid #f1cd4f;
}

.buttton-v4 .btn {
    color: #000;
    background: none;
    border-radius: 30px;
}

.buttton-v4 .btn.btn-default {
    border: 2px solid #363636;
}

.buttton-v4 .btn.btn-default:hover,
.buttton-v4 .btn.btn-default:focus {
    color: #000;
}

.buttton-v4 .btn.btn-success {
    border: 2px solid #6bdb80;
}

.buttton-v4 .btn.btn-danger {
    border: 2px solid #e95151;
}

.buttton-v4 .btn.btn-info {
    border: 2px solid #5ad0cf;
}

.buttton-v4 .btn.btn-warning {
    border: 2px dashed #f1cd4f;
}

.buttton-icon .btn.btn-success {
    border-radius: 30px;
}

.buttton-icon .btn.btn-danger {
    background: none;
    border: 2px solid #e95151;
    color: #e95151;
}

.buttton-icon .btn.btn-info {
    border-radius: 5px;
}

.buttton-icon .btn.btn-warning {
    border: 2px dashed #f1cd4f;
    background: none;
    color: #000;
}

.buttton-icon .btn i {
    font-size: 20px;
    vertical-align: -2px;
}

.feature-box .fbox-icon .icons {
    color: #000;
}

.feature-box h4 {
    font: 700 14px/20px "Oswald";
    color: #000;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.feature-box p {
    font: 400 13px/20px "Poppins";
    color: #858585;
}

.icon-box-v* .col-md-3,
.icon-box-v* .col-md-4 {
    padding: 0 15px;
}

.icon-box-v1 .feature-box .fbox-icon .icons {
    font-size: 50px;
}

.icon-box-v1 .feature-box h4 {
    font: 700 14px "Oswald";
    letter-spacing: 3px;
}

.icon-box-v1 .feature-box.color-red .fbox-icon .icons {
    color: #e95151;
}

.icon-box-v1 .feature-box.color-red h4 {
    color: #e95151;
}

.icon-box-v1 .feature-box.color-blue .fbox-icon .icons {
    color: #55e1dc;
}

.icon-box-v1 .feature-box.color-blue h4 {
    color: #55e1dc;
}

.icon-box-v1 .feature-box.color-purple .fbox-icon .icons {
    color: #c941eb;
}

.icon-box-v1 .feature-box.color-purple h4 {
    color: #c941eb;
}

.link-icon-box {
    display: inline-block;
    font: 600 12px/20px "Poppins";
    color: #000;
    margin-top: 30px;
}

.link-icon-box:hover {
    color: #80b435;
}

.link-icon-box:hover i {
    color: #80b435;
}

.link-icon-box i {
    font-size: 13px;
    color: #000;
    margin-left: 6px;
}

.icon-box-v2 .link-icon-box {
    text-transform: uppercase;
}

.icon-box-v2 .feature-box .fbox-icon {
    margin-bottom: 30px;
}

.icon-box-v2 .feature-box .fbox-icon .icons {
    font-size: 40px;
    height: 127px;
    width: 127px;
    text-align: center;
    line-height: 127px;
    color: #fff;
    background: #e95151;
    display: inline-block;
    border-radius: 50%;
}

.icon-box-v2 .feature-box .fbox-icon .icons.bg-yellow {
    background: #ffd457;
}

.icon-box-v3 .feature-box .fbox-icon .icons {
    font-size: 40px;
    color: #000;
}

.icon-box-v3 .feature-box h4 {
    margin-top: 20px;
}

.icon-box-v3 .feature-box p {
    float: left;
    width: 100%;
    margin-top: 10px;
}

.icon-box-v3.icon-box-v6 .col-md-4 {
    padding: 0 15px;
}

.icon-box-v3.icon-box-v6 .col-md-4 .feature-box {
    background: #e95151;
    display: inline-block;
    width: 100%;
    padding: 30px;
    border: 2px solid #e95151;
}

.icon-box-v3.icon-box-v6 .col-md-4 .feature-box .fbox-icon .icons {
    color: #fff;
    font-size: 30px;
}

.icon-box-v3.icon-box-v6 .col-md-4 .feature-box h4 {
    color: #fff;
    margin-top: 15px;
}

.icon-box-v3.icon-box-v6 .col-md-4 .feature-box p {
    color: #fff;
}

.icon-box-v3.icon-box-v6 .col-md-4 .feature-box.ver2 {
    background: #fff;
    border-color: #70deee;
}

.icon-box-v3.icon-box-v6 .col-md-4 .feature-box.ver2 .fbox-icon .icons {
    color: #000;
}

.icon-box-v3.icon-box-v6 .col-md-4 .feature-box.ver2 h4,
.icon-box-v3.icon-box-v6 .col-md-4 .feature-box.ver2 p {
    color: #000;
}

.icon-box-v3.icon-box-v6 .col-md-4 .feature-box.ver3 {
    background: #55c2e5;
    border-color: #55c2e5;
}

.icon-box-v4 .feature-box .fbox-icon .icons {
    width: 68px;
    height: 68px;
    text-align: center;
    line-height: 68px;
    font-size: 25px;
    color: #fff;
    background: #4f4f4f;
    display: inline-block;
    border-radius: 50%;
}

.icon-box-v4 .feature-box .fbox-icon .icons.bg-gray {
    background: #e5e5e5;
}

.icon-box-v4 .feature-box .fbox-icon .icons.blue {
    background: #57d6ff;
}

.icon-box-v4 .feature-box h4 {
    text-transform: uppercase;
}

.icon-box-v5 .feature-box {
    margin-top: 30px;
    padding-top: 60px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
}

.icon-box-v5 .feature-box .fbox-icon {
    top: -42px;
}

.icon-box-v5 .feature-box .fbox-icon .icons {
    font-size: 30px;
    display: inline-block;
    width: 84px;
    height: 84px;
    text-align: center;
    line-height: 84px;
    border-radius: 50%;
    border: none;
    color: #fff;
}

.icon-box-v5 .feature-box .fbox-icon .icons.bg-red {
    background: #e95151;
}

.icon-box-v5 .feature-box .fbox-icon .icons.bg-yellow {
    background: #ffd457;
    border: 3px solid #e7bd42;
}

.icon-box-v5 .feature-box .fbox-icon .icons.bg-green {
    background: #88d091;
}

.icon-box-v5 .feature-box.ver1 {
    background: #f5f5f5;
    border: none;
    border: 2px solid #fff;
}

.icon-box-v5 .feature-box.ver2 {
    border-radius: 5px;
    border: 2px solid #000;
}

.icon-box-v5 .feature-box.ver3 {
    border: 2px dashed #88d091;
}

.icon-box-v5 .feature-box.ver4 {
}

.icon-box-v5 .feature-box.ver4 h4 {
    color: #fff;
}

.icon-box-v5 .feature-box.ver4 p {
    color: #fff;
}

.icon-box-v5 .col-md-3 {
    padding: 0px 15px;
}

.icon-box-v7 .col-md-3 {
    padding: 0px 15px;
}

.icon-box-v7 .feature-box {
    overflow: hidden;
    background: #313131;
    padding: 20px;
    max-height: 265px;
}

.icon-box-v7 .feature-box .fbox-icon {
    transform: translate(0px, 50px);
    transition: all 0.5s ease 0s;
}

.icon-box-v7 .feature-box .fbox-icon .icons {
    color: #55c2e5;
    font-size: 60px;
}

.icon-box-v7 .feature-box .fbox-icon .icons.green {
    color: #88d091;
}

.icon-box-v7 .feature-box .fbox-icon .icons.red {
    color: #e95151;
}

.icon-box-v7 .feature-box .fbox-icon .icons.yellow {
    color: #edb317;
}

.icon-box-v7 .feature-box h4 {
    color: #fff;
    transform: translate(0px, 60px);
    transition: all 0.5s ease 0s;
}

.icon-box-v7 .feature-box p {
    color: #858585;
    max-height: 120px;
    overflow: hidden;
    transform: translate(0px, 120px);
    transition: all 0.5s ease 0s;
    line-height: 25px;
}

.icon-box-v7 .feature-box .fbox-body {
    overflow: visible;
}

.icon-box-v7 .feature-box:hover .fbox-icon {
    transform: translate(0px, -100px);
}

.icon-box-v7 .feature-box:hover h4 {
    transform: translate(0px, -50px);
}

.icon-box-v7 .feature-box:hover p {
    transform: translate(0px, -50px);
}

/** 5. Ecommerce **/
/** 5.1. Shop General **/
.pagination-container {
    display: inline-block;
    width: 100%;
}

nav.pagination {
    font-family: "TXGR";
    font-size: 13px;
    margin: 0px;
}

nav.pagination a,
nav.pagination span {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #eeeeee;
    color: #333333;
    vertical-align: bottom;
}

nav.pagination a:hover,
nav.pagination span:hover {
    background: #333333;
    color: #eeeeee;
}

nav.pagination span {
    background: #333333;
    color: #eeeeee;
}

nav.pagination .next i,
nav.pagination .prev i {
    font-size: 18px;
    color: #666;
    margin-top: 8px;
}

nav.pagination .next:hover,
nav.pagination .prev:hover {
    background: #80b435;
}

nav.pagination .next:hover i,
nav.pagination .prev:hover i {
    color: #eeeeee;
}

/** 5.2. Item view templates **/
.products.grid_full .item-inner {
    width: 20%;
    float: left;
    margin: 15px 0px;
    transition: all 0.3s ease 0s;
}

.products.grid_full.border-space-full {
    margin-left: -15px;
    margin-right: -15px;
}

@media (max-width: 460px) {
    .products.grid_full.border-space-full {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.products.grid_full.border-space-full .item-inner {
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width: 980px) {
    .products.grid_full.border-space-full .item-inner .product .action {
        width: 220px;
    }
    .products.grid_full.border-space-full .item-inner .product .action a {
        width: 66px;
    }
}

.products.grid_full.grid_sidebar .item-inner {
    width: 25%;
    transition: all 0.3s ease 0s;
}

.products.grid_full.grid_sidebar .item-inner:nth-child(4n + 1) {
    padding: 0px 21px 0 0;
}

.products.grid_full.grid_sidebar .item-inner:nth-child(4n + 2) {
    padding: 0 14px 0 7px;
}

.products.grid_full.grid_sidebar .item-inner:nth-child(4n + 3) {
    padding: 0 7px 0 14px;
}

.products.grid_full.grid_sidebar .item-inner:nth-child(4n) {
    padding: 0px 0 0 21px;
}

.products.grid_full .container {
    padding: 0px;
}

.products.grid_full .social {
    display: none;
}

.products.grid_full.list-item .item-inner {
    transition: all 0.4s ease 0s;
    width: 100%;
    padding: 0 0 10px 0 !important;
}

.products.grid_full.list-item .item-inner .product {
    text-align: left;
    padding: 30px 0px;
}

.products.grid_full.list-item .item-inner .product .product-title {
    color: #000;
    font: 400 27px/50px "Gotham";
    margin-bottom: 20px;
}

.products.grid_full.list-item .item-inner .product .product-price {
    width: auto;
    float: initial;
    font: 400 28px "Gotham";
    vertical-align: bottom;
}

.products.grid_full.list-item .item-inner .product .product-price-old {
    vertical-align: bottom;
    font: 400 25px "Gotham";
}

.products.grid_full.list-item .item-inner .product .description {
    margin: 0px 0px 40px;
    font: 400 18px/30px "Gotham";
    color: #666;
}

.products.grid_full.list-item .item-inner .product p.rating {
    display: block;
    margin-bottom: 20px;
}

.products.grid_full.list-item .item-inner .product p.rating i {
    color: #80b435;
    font-size: 24px;
}

.products.grid_full.list-item .item-inner .product p.rating i:first-child {
    margin-left: 20px;
}

.products.grid_full.list-item .item-inner .product:hover img.primary_image {
    transform: scale(1.05);
}

.products.grid_full.list-item .item-inner .product .action {
    position: static;
    padding-left: 20px;
    transform: translate(0px, 0px);
    opacity: 1;
    display: inline-block;
    width: auto;
    float: left;
    box-shadow: none;
}

@media (max-width: 1200px) {
    .products.grid_full.list-item .item-inner .product .action {
        padding-left: calc(35% + 20px);
    }
}

.products.grid_full.list-item .item-inner .product .action a {
    transform: translate(0px, 0px);
    opacity: 1;
    display: inline-block;
    background: #fff;
    text-align: center;
    height: 50px;
    width: 50px;
    border: 1px solid #d6d6d6;
    margin-right: 10px;
}

.products.grid_full.list-item .item-inner .product .action a.add-cart {
    width: 200px;
    border-color: #80b435;
}

.products.grid_full.list-item .item-inner .product .action a.add-cart:hover:after {
    background: #fff;
    color: #80b435;
}

.products.grid_full.list-item .item-inner .product .action a.add-cart:after {
    content: "Add To Cart";
    text-transform: uppercase;
    font: 700 14px/50px "Gotham";
    color: #fff;
    background: #80b435;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

.products.grid_full.list-item .item-inner .product .action a.wish:after,
.products.grid_full.list-item .item-inner .product .action a.zoom:after {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.products.grid_full.list-item .item-inner .product .action a.wish:hover,
.products.grid_full.list-item .item-inner .product .action a.zoom:hover {
    border-color: #80b435;
}

.products.grid_full.list-item .item-inner .product .action a.wish:after {
}
.products.grid_full.list-item .item-inner .product .action a.wish:hover:after {
    background-image: url("../images/product-action-2-hover.png");
}

.products.grid_full.list-item .item-inner .product .action a.zoom:after {
}
.products.grid_full.list-item .item-inner .product .action a.zoom:hover:after {
    background-image: url("../images/product-action-3-hover.png");
}

.products.grid_full.list-item .item-inner .product .action a i {
    background-color: #fff;
    line-height: 48px;
}

.products.grid_full.list-item .item-inner .product .action a:hover i {
    background-color: #000;
    color: #fff;
}

.products.grid_full.list-item .item-inner .social {
    display: block;
    margin-top: 15px;
    padding-top: 15px;
    position: relative;
}

.products.grid_full.list-item .item-inner .social:after {
    position: absolute;
    content: "";
    left: 15px;
    right: 15px;
    height: 1px;
    background: #f5f5f5;
    top: 0px;
}

.products.grid_full.list-item .item-inner .social h3 {
    display: inline-block;
    font: 700 14px/20px "Gotham";
    text-transform: uppercase;
}

.products.grid_full.list-item .item-inner .social a {
    display: inline-block;
    margin: 0 5px;
}

.products.grid_full.list-item .item-inner .social a.twitter i {
    color: #55acee;
}

.products.grid_full.list-item .item-inner .social a.dribbble i {
    color: #e04c86;
}

.products.grid_full.list-item .item-inner .social a.skype i {
    color: #00aff0;
}

.products.grid_full.list-item .item-inner .social a.pinterest i {
    color: #bd081c;
}

.products.grid_full.list-item .item-inner .social a.facebook i {
    color: #3b5998;
}

.products.grid_full.list-item .item-inner .social a i {
    font-size: 24px;
}

.products.grid_full.list-item .item-inner .product-images {
    width: 25%;
    float: left;
}

.products.grid_full.list-item .item-inner .product-title,
.products.grid_full.list-item .item-inner .product-price,
.products.grid_full.list-item .item-inner .description,
.products.grid_full.list-item .item-inner .action,
.products.grid_full.list-item .item-inner .social {
    width: 75%;
    float: right;
    padding-left: 20px;
}

.products.grid_full.list-item .item-inner .product-title {
    font-weight: 700;
    font-size: 16px;
    color: #555555;
}

.products.grid_full.list-item .item-inner .description {
    display: inline-block;
}

.products.grid_full.list-item .item-inner .content-star {
    float: left;
    width: 100%;
}

@media (max-width: 1200px) {
    .products.grid_full.list-item .item-inner .product-images {
        width: 35%;
    }
    .products.grid_full.list-item .item-inner .product-title,
    .products.grid_full.list-item .item-inner .product-price,
    .products.grid_full.list-item .item-inner .description,
    .products.grid_full.list-item .item-inner .action,
    .products.grid_full.list-item .item-inner .social {
        width: 65%;
    }
    .products.grid_full.list-item .item-inner .action a {
        float: left;
    }
}

.products.grid_full .product .wrap-product-images {
    position: relative;
}

.products.grid_full .product .product-price::after {
    position: static;
}

.products.ver2 .product {
    padding: 0px;
}

.products.ver2 .product p.product-title,
.products.ver2 .product .product-price-old,
.products.ver2 .product .product-price {
    margin-top: -80px;
    position: relative;
    z-index: 2;
}

.products.ver2 .product .product-price-old,
.products.ver2 .product .product-price {
    margin-top: 0px;
}

@media (max-width: 767px) and (min-width: 640px) {
    .products.ver2 .col-sm-6 {
        width: 50%;
        float: left;
    }
}

.products.border-space-product {
    margin-left: -15px;
    margin-right: -15px;
}

@media (max-width: 980px) {
    .products.border-space-product {
        margin-left: -10px;
        margin-right: -10px;
    }
}

.products.border-space-product .product {
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    overflow: visible;
}

@media (max-width: 980px) {
    .products.border-space-product .product {
        margin-bottom: 20px;
        padding-right: 10px;
        padding-left: 10px;
    }
    .products.border-space-product .product .action {
        width: 220px;
    }
    .products.border-space-product .product .action a {
        width: 66px;
    }
}

.products.border-space-product .product .product-images {
    overflow: hidden;
}

.products.border-space-product .product:hover .product-images {
    box-shadow: 0px 8px 33px 0px rgba(0, 0, 0, 0.13);
}

.products.border-space-product.hover-shadow .product:hover {
    box-shadow: none;
}

.products .product {
    position: relative;
    text-align: center;
    overflow: hidden;
}

.products .product .product-images {
    /* display: inline-block; */
    position: relative;
    width: 100%;
}

.products .product .product-images img {
    width: 100%;
}

.products .product:hover .product-title {
    color: #80b435;
}

.products .product p.product-title {
    font: 400 20px/34px "Gotham";
    color: #000;
}

.products .product .description {
    display: none;
    font: 400 13px/20px "Poppins";
    color: #858585;
    margin-bottom: 15px;
}

.products .product .product-price {
    font-size: 14px;
    color: #80b435;
    line-height: 20px;
    position: relative;
    font-family: "Gotham";
    font-weight: 400;
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: bottom;
}

.products .product .product-price:after {
    position: absolute;
    content: "";
    height: 1px;
    background: #eeeeee;
    bottom: 0px;
    left: 50%;
    right: 50%;
    -webkit-transition: all 0.6s ease 0s;
    -o-transition: all 0.6s ease 0s;
    transition: all 0.6s ease 0s;
}

.products .product .product-price-old {
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 16px;
    font-size: 13px;
    color: #979797;
    font-family: "Gotham";
    font-weight: 400;
    text-decoration: line-through;
    margin-right: 5px;
}

.products .product .add-to-cart {
    display: inline-block;
    padding: 0px;
    text-transform: uppercase;
    font-size: 13px;
    color: #222;
    margin-bottom: 20px;
    font-style: normal;
    opacity: 0;
    padding-left: 20px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: relative;
}

.products .product .add-to-cart::after {
    font-family: "FontAwesome";
    font-size: 16px;
    font-style: normal;
    line-height: 1;
    position: absolute;
    content: "\f067";
    left: 0px;
    top: 3px;
    color: #555555;
}

.products .product img.secondary_image {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
}

.products .product .action {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    margin: auto;
    width: 240px;
    height: 60px;
    text-align: center;
    padding-top: 2px;
}

@media (max-width: 640px) {
    .products .product .action {
        display: none;
    }
}

.products .product .action a {
    opacity: 0;
    display: inline-block;
    position: relative;
    background: none;
    text-align: center;
    width: 77px;
    height: 60px;
    line-height: 60px;
}

.products .product .action a.add-cart {
}

.products .product .action a.add-cart:hover {
    background-image: url("../images/product-action-1-hover.png");
}

.products .product .action a.wish {
}

.products .product .action a.wish:hover {
    background-image: url("../images/product-action-2-hover.png");
}

.products .product .action a.zoom {
}

.products .product .action a.zoom:hover {
    background-image: url("../images/product-action-3-hover.png");
}

.products .product .action a:after {
    position: absolute;
    content: "";
    right: -2px;
    top: 20px;
    width: 1px;
    height: 15px;
    background: #d5d5d5;
}

.products .product .action a:last-child:after {
    width: 0px;
}

.products .product .action a i {
    font-size: 18px;
    background: none;
    padding: 0px;
    color: #000;
    display: inline-block;
    line-height: 35px;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.products .product .action a:hover i {
    color: #80b435;
}

.products .product .content-star {
    float: right;
    font-size: 10px;
    margin-bottom: 0px;
}

.products .product .content-star i.active {
    color: #e19824;
}

.products .product .lable {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
    font-size: 11px;
    text-transform: capitalize;
    color: #fff;
    display: inline-block;
    padding: 5px 10px;
    background-color: #000;
}

.products .product.sale .product-price {
    color: #e19824;
}

.products .product.sale .product-price .price-old {
    color: #999;
    font-weight: 400;
    margin-left: 5px;
    text-decoration: line-through;
}

.products-size-small .products .product p.product-title {
    font-size: 18px;
}

.products-size-small .products .product .product-price {
    font-size: 18px;
}

.products-size-small .products .product .product-price-old {
    font-size: 14px;
    margin-bottom: 19px;
}

.product {
    border-radius: 0x;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.product .action {
    transform: translate(0px, 30px);
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    background-color: #fff;
    opacity: 0;
    border-radius: 30px;
    box-shadow: 3px 4px 35px 0px rgba(0, 0, 0, 0.09);
}

.product p.rating {
    display: none;
}

.product:hover img.primary_image {
    transform: scale(1.1);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.product:hover .action {
    transform: translate(0px, 0px);
    opacity: 1;
}

.product:hover .action a {
    opacity: 100;
    transform: translate(0px, 0px);
}

.product:hover .add-to-cart {
    opacity: 100;
}

.product .lab {
    text-transform: uppercase;
    font: 500 10px/24px "Gotham";
    color: #bc9e6b;
    border: 2px solid #bc9e6b;
    z-index: 9999;
    position: absolute;
    top: 30px;
    left: 0px;
    width: 65px;
    height: 30px;
    text-align: center;
    letter-spacing: 6px;
    padding-left: 6px;
    transform: rotate(-90deg);
}

.product .lab.new {
    color: #fa7468;
    border-color: #fa7468;
}

.product .lab.sold {
    color: #000;
    border-color: #000;
    width: 120px;
    top: 50px;
    left: -40px;
}

.tab-content {
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    transform: translate(0px, 20px);
}

.tab-content.active,
.ordering span.tab-content:hover {
    transform: translate(0px, 0px);
}

.tab-product-all .products {
    margin: 0 -15px;
}

.tab-product-all .products .item-inner {
    width: 20%;
    float: left;
    padding: 0 15px;
}

.tab-product-all.tab-product-all-v2 {
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
}

.tab-product-all.tab-product-all-v2 .products {
    margin: 0px;
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    overflow: hidden;
}

.tab-product-all.tab-product-all-v2 .products .item-inner {
    padding: 0px;
    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
}

.tab-product-all.tab-product-all-v2 .products .item-inner .product-price {
    position: absolute;
    left: 30px;
    top: 30px;
    color: #000;
}

.tab-product-all.tab-product-all-v2 .products .item-inner .action {
    left: 20px;
    bottom: 10px;
}

.tab-product-all.tab-product-all-v4 .col-md-6,
.tab-product-all.tab-product-all-v4 .col-md-12 {
    padding: 0px;
}

.tab-product-all.tab-product-all-v4 .item-inner {
    width: 100%;
}

.tab-product-all.tab-por .products {
    border: none;
}

.tab-product-all.tab-por .products .item-inner {
    border: none;
    width: 33.33%;
    padding: 0 15px;
    margin-bottom: 50px;
}

.tab-product-all.tab-por .products .item-inner .product {
    text-align: left;
}

.tab-product-all.tab-por .products .item-inner .product-title {
    position: static;
    left: 0px;
    top: 0px;
    opacity: 1;
    color: #333;
    font: 400 20px/30px "Gotham";
    text-align: left;
}

.tab-product-all.tab-por .products .item-inner .product-brand {
    text-align: left;
    font: 300 12px/20px "Oswald";
    color: #979797;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.tab-product-all.tab-por .products .item-inner .wishlist {
    display: inline-block;
    float: right;
    margin-top: -22px;
    font: 300 12px/20px "Oswald";
    color: #000;
}

.tab-product-all.tab-por .products .item-inner .wishlist i {
    margin-right: 3px;
}

.tab-product-all.tab-por .products .item-inner .product-images {
    margin-bottom: 20px;
}

.tab-product-all.tab-por .product:hover .quick-view:after {
    height: 0px;
}

.tab-product-all.tab-por .product:hover .product-title {
    transform: translate(0px, 0px);
}

.tab-product-all.tab-por .quick-view i {
    font-size: 40px;
}

.tab-product-all.tab-por.tab-por-fulll-width .products .item-inner {
    width: 25%;
}

.products.mason-v1 .product {
    width: 100%;
    padding-right: 0px !important;
}

.products.mason-v1 .product:nth-child(1),
.products.mason-v1 .product:nth-child(2) {
    width: 50%;
    float: left;
}

.products.mason-v1 .product:nth-child(1) {
    padding-right: 15px !important;
}

.products.mason-v1 .product:nth-child(2) {
    padding-left: 15px;
}

.products.mason-v2 .product {
    width: 100%;
    padding-left: 0px !important;
}

.products.mason-v2 .product:nth-child(2),
.products.mason-v2 .product:nth-child(3) {
    width: 50%;
    float: left;
}

.products.mason-v2 .product:nth-child(2) {
    padding-right: 15px;
}

.products.mason-v2 .product:nth-child(3) {
    padding-left: 15px !important;
}

.tab-product-all-mason .tab-container .owl-stage-outer {
    padding: 0px;
}

.products.hover-shadow .product {
    transition: all 0.3s ease 0s;
}

.products.hover-shadow .product:hover {
    box-shadow: 0px 8px 33px 0px rgba(0, 0, 0, 0.13);
    z-index: 2;
}

/** 5.3. Shop Widget **/
.widget ul.product-categories ul li a,
.widget ul li a {
    color: #666;
    font: 400 18px "Gotham";
    text-transform: capitalize;
}

.widget ul.product-categories ul li a:hover,
.widget ul li a:hover {
    color: #80b435;
}

.widget {
    color: #666;
    margin-bottom: 30px;
    border-bottom: 1px solid #d6d6d6;
    float: left;
    width: 100%;
}

.widget.bottom-home1 {
    padding: 0px;
}

.widget.border-bottom .widget-title {
    border-bottom: 1px solid #eeeeee;
}

.widget.border-bottom .widget-title:after {
    width: 0px;
    height: 0px;
}

.widget .widget-title {
    color: #333;
    text-transform: uppercase;
    font-style: normal;
    padding-bottom: 10px;
    margin-bottom: 20px;
    position: relative;
    font: 500 24px "Gotham";
}

.widget .widget-title:after {
    position: absolute;
    content: "";
    bottom: 0px;
    left: 0;
    width: 70px;
    height: 2px;
    background: #80b435;
}

.widget ul.product-categories {
    margin-bottom: 30px;
}

.widget ul.product-categories li {
    line-height: 30px;
    display: block;
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 0px;
    padding-top: 0px;
}

.widget ul.product-categories li.hassub ul {
    padding-top: 12px;
    margin-top: 12px;
    border-top: 1px dashed #eeeeee;
}

.widget ul.product-categories li.hassub ul li {
    padding-left: 20px;
}

.widget ul.product-categories li i {
    position: absolute;
    right: 0;
    top: 0;
    padding: 9px;
    cursor: pointer;
    color: #000;
}

.widget ul.product-categories li i.active::before {
    content: "\f0d7";
}

.widget ul.product-categories li a {
    text-transform: uppercase;
    color: #000;
    font: 400 18px "Gotham";
}

.widget ul.product-categories li a:hover {
    color: #80b435;
}

.widget ul.product-categories li a:hover:before {
    background: #80b435;
}

.widget ul.product-categories li a:before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #2b2b2b;
    margin-right: 10px;
    margin-bottom: 4px;
}

.widget ul.product-categories ul {
    display: none;
    margin: 0px;
    padding: 6px 0px;
}

.widget ul.product-categories ul li {
    border-bottom: none;
    margin-bottom: 0px;
}

.widget ul.product-categories ul li a {
    transition: all 0.3s ease-out 0s;
}

.widget ul.product-categories ul li a:hover {
    margin-left: 15px;
}

.widget ul.product-categories ul li i {
    display: none;
}

.widget.widget_color i {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    background: #fff;
    border: 1px solid #e2e1e1;
}

.widget.widget_color .color1 {
    background: #3e7bc5;
}

.widget.widget_color .color2 {
    background: #70684f;
}

.widget.widget_color .color3 {
    background: #c0c0c0;
}

.widget.widget_color .color4 {
    background: #4cbc99;
}

.widget.widget_color .color5 {
    background: #d39b17;
}

.widget.widget_color .color6 {
    background: #fff;
}

.widget.widget_feature li {
    display: block;
    float: left;
    width: 100%;
}

.widget.widget_feature .images {
    float: left;
    width: 140px;
    margin-right: 10px;
}

.widget.widget_feature .text h2 a {
    font: 400 16px/20px "Gotham";
    color: #2b2b2b;
}

.widget.widget_feature .text h2 a:hover {
    color: #80b435;
}

.widget.widget_feature .rating i {
    font-size: 16px;
    color: #e4ba4a;
}

.widget.widget_feature span {
    position: static;
}

.widget.widget_feature .text {
    padding-top: 18px;
}

.widget.widget_feature p span {
    font: 400 18px "Gotham";
    color: #80b435;
    vertical-align: bottom;
}

.widget.widget_feature p span.price-old {
    margin-right: 10px;
    color: #979797;
    font-size: 16px;
    text-decoration: line-through;
    line-height: 23px;
}

.widget.widget_feature.blog .text {
    padding-top: 0px;
}

.widget.widget_link ul li:last-child {
    border: none;
}

.widget.sidebar-newsletter {
    background: #6dc5ee;
    padding: 20px;
}

.widget.sidebar-newsletter h3 {
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    padding-bottom: 20px;
    border-bottom: 1px solid #aedaee;
    margin-bottom: 20px;
    margin-top: 10px;
}

.widget.sidebar-newsletter p {
    color: #fff;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 20px;
}

.widget.sidebar-newsletter form {
    position: relative;
}

.widget.sidebar-newsletter form input {
    background: #fff;
    border: none;
    font-size: 12px;
    height: 35px;
    width: 100%;
    padding: 0px 35px 0 20px;
}

.widget.sidebar-newsletter form button {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 35px;
    height: 35px;
    background: #fff;
    border: #fff;
}

.widget.sidebar-newsletter form button i {
    font-size: 18px;
    color: #888888;
}

.widget ul {
    margin-bottom: 15px;
}

.widget ul li {
    position: relative;
    line-height: 30px;
    padding: 5px 0px;
}

.widget ul li span {
    position: absolute;
    right: 0px;
    top: 15px;
    font: 400 14px "Gotham";
    color: #d6d6d6;
}

.widget h4 {
    font-style: normal;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 13px;
    color: #333;
    font-weight: 700;
}

.widget .social {
    padding: 30px 0px;
}

.widget .social a {
    margin-right: 30px;
    margin-left: 10px;
}

.widget .social a:last-child {
    margin-right: 0px;
}

.widget .social a i {
    color: #000;
    font-size: 20px;
}

.widget .newsletter-content-widget {
    height: 280px;
}

.widget .newsletter-content-widget form {
    height: 105px;
}

.widget .newsletter-content-widget form input,
.widget .newsletter-content-widget form button {
    height: 50px;
    width: 100%;
}

.widget .newsletter-content-widget form input {
    bottom: 50px;
    padding: 0px 20px !important;
}

.widget .newsletter-content-widget form button {
    color: #fff;
    background: #000;
    border-left: none;
    border-top: 2px solid #000;
}

.widget .related-post .images {
    display: inline-block;
    max-width: 70px;
    float: left;
    margin-right: 10px;
    margin-top: 7px;
}

.widget .related-post .images img {
    width: 100%;
}

.widget .related-post h3 a {
    text-transform: uppercase;
    font: 300 12px/20px "Oswald";
    letter-spacing: 3px;
    color: #000;
    display: inline-block;
    margin-bottom: 4px;
}

.widget .related-post p {
    font: 300 12px/15px "Poppins";
    color: #c4c4c4;
}

.widget .related-post .items {
    display: inline-block;
    width: 100%;
    margin-bottom: 13px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;
}

.widget .related-post .items:last-child {
    border: none;
}

.widget .wrap-instag {
    display: inline-block;
    width: 100%;
    position: relative;
}

.widget .wrap-instag:before {
    background: #fff;
    width: 6px;
    height: 100%;
    left: 66.66666%;
    top: 0px;
    margin-left: -3px;
    position: absolute;
    content: "";
}

.widget .wrap-instag .instag:after {
    background: #fff;
    height: 6px;
    width: 100%;
    left: 0px;
    top: 50%;
    margin-top: -3px;
    position: absolute;
    content: "";
}

.widget .wrap-instag .instag:before {
    background: #fff;
    width: 6px;
    height: 100%;
    left: 33.33333%;
    top: 0px;
    margin-left: -3px;
    position: absolute;
    content: "";
}

.widget .wrap-instag .instag img {
    width: 100%;
}

.widget.tags-cloud a {
    display: inline-block;
    font: 400 18px/40px "Gotham";
    height: 42px;
    padding: 0 12px;
    color: #000;
    text-transform: capitalize;
    border: 1px solid #e6e6e6;
    margin-right: 10px;
    margin-bottom: 10px;
}

.widget.tags-cloud a:hover {
    background: #80b435;
    border-color: #80b435;
    color: #fff;
}

.my-account {
    margin-bottom: 50px;
}

.my-account .widget ul li {
    margin-bottom: 20px;
}

.my-account .breadcrumb {
    margin-bottom: 80px;
}

.my-account .contact-form .link-button {
    float: right;
}

.connect-icon li {
    display: inline-block;
}

.connect-icon li i {
    color: #666;
    font-size: 20px;
    background: #eaeaea;
    height: 50px;
    width: 50px;
    padding: 16px;
    border-radius: 50%;
    text-align: center;
}

.connect-icon li i:hover {
    background: #12a170;
    color: #fff;
}

.widget .newsletter-content form input {
    font-size: 12px;
    padding-left: 10px;
}

.widget .banner-col-left img {
    width: 100%;
    max-width: 330px;
}

.widget.blog-categories ul li a {
    text-transform: uppercase;
    position: relative;
}

.widget.blog-categories ul li a:before {
    content: "";
    height: 5px;
    width: 5px;
    background: #000;
    margin-right: 15px;
    display: inline-block;
    margin-bottom: 5px;
}

.widget.blog-categories ul li span {
    font: 400 18px "Gotham";
    color: #d6d6d6;
}

.widget.recent-post ul li a {
    font: 400 16px/20px "Gotham";
    color: #2b2b2b;
}

.widget.recent-post ul li a:hover {
    color: #80b435;
}

.widget.recent-post ul li a:before {
    content: "";
    height: 5px;
    width: 5px;
    background: #000;
    margin-right: 15px;
    display: inline-block;
    margin-bottom: 5px;
}

.widget.flick-photo ul {
    float: left;
    width: 100%;
}

.widget.flick-photo ul li {
    float: left;
    width: 33.333%;
    padding: 0px;
}

.widget.flick-photo ul li a {
    display: inline-block;
}

.widget.flick-photo ul li img {
    width: 100%;
}

/** 5.4. Shop view Pages **/
#secondary {
    float: left;
}

#secondary.col-md-3 {
    padding-left: 0px;
    padding-right: 30px;
    margin-bottom: 30px;
}

#secondary.col-md-3.float-right {
    float: right;
    padding-right: 0px;
    padding-left: 30px;
    border-right: none;
}

@media (min-width: 1200px) {
    #secondary.col-md-3 {
        border-right: 1px solid #d6d6d6;
    }
}

#secondary .search-form {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #f5f5f5;
}

#secondary .search-form input {
    width: 100%;
    height: 48px;
    border: 1px solid #efefef;
    padding: 0 20px;
    color: #9d9d9d;
    font: 400 14px "Poppins";
    padding-right: 40px;
}

#secondary .search-form button {
    position: absolute;
    background: none;
    color: #565656;
    font-size: 16px;
    border: none;
    position: absolute;
    top: 2px;
    right: 0px;
    width: 48px;
    height: 44px;
}

#primary {
    float: right;
}

#primary.col-md-9 {
    padding-left: 20px;
    padding-right: 0px;
}

#primary.float-left {
    float: left;
    padding-left: 0px;
    padding-right: 20px;
}

#secondary.secondary-home5 {
    float: right;
}

#secondary.secondary-home5.col-md-3 {
    padding-right: 0px;
    padding-left: 20px;
}

#primary.primary-home5 {
    float: left;
}

#primary.primary-home5.col-md-9 {
    padding-right: 10px;
    padding-left: 0px;
}

.bottom-home1-title h3.title {
    font-size: 24px;
    color: #333333;
    text-transform: uppercase;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #eeeeee;
}

.page-login h3 {
    font: 500 16px/20px "Gotham";
    text-transform: uppercase;
    padding-bottom: 10px;
    margin-bottom: 20px;
    position: relative;
}

.page-login h3:after {
    position: absolute;
    content: "";
    left: 0px;
    width: 55px;
    height: 1px;
    background: #80b435;
    bottom: 0px;
}

.page-login p {
    font: 300 14px/20px "Gotham";
    color: #888;
    margin-bottom: 20px;
}

.page-login .social {
    text-align: center;
}

.page-login .social a {
    display: inline-block;
    width: calc(50% - 5px);
    color: #fff;
    font: 500 14px/40px "Gotham";
    background: #3b5998;
}

.page-login .social a i {
    margin-right: 5px;
}

.page-login .social a:last-child {
    background: #55acee;
}

.page-login form .control-label {
    font: 700 14px/20px "Gotham";
    color: #000;
    text-transform: uppercase;
}

.page-login form .form-control {
    border: 1px solid #e6e6e6;
}

.page-login form input[type="checkbox"] {
    display: none;
}

.page-login form input[type="checkbox"]:checked + label:before {
    content: "\2713";
    font-size: 15px;
    color: #333;
    text-align: center;
    line-height: 18px;
}

.page-login form label.label-check {
    position: relative;
    width: 100%;
    display: inline-block;
    padding-left: 30px;
    float: left;
    margin: 10px 0px;
    font: 400 14px "Gotham";
    color: #373737;
}

.page-login form label.label-check:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    bottom: 1px;
    border: 1px solid #b5b5b5;
    background-color: #fff;
}

.page-login form .remember {
    float: left;
    width: 100%;
}

.page-login form .remember label.label-check {
    width: auto;
    font-weight: 300;
    color: #888;
    cursor: pointer;
}

.page-login .remember {
    margin: 10px 0px;
}

.page-login .remember .help {
    float: right;
    font: 300 14px/20px "Gotham";
    vertical-align: bottom;
    margin-top: 12px;
}

@media (max-width: 1024px) {
    .products.grid_full .item-inner,
    .products.grid_full.grid_sidebar .item-inner {
        width: 33.33%;
    }
}

@media (max-width: 767px) {
    .products.grid_full .item-inner,
    .products.grid_full.grid_sidebar .item-inner {
        width: 50%;
    }
}

@media (max-width: 467px) {
    .products.grid_full .item-inner,
    .products.grid_full.grid_sidebar .item-inner {
        width: 100%;
    }
    .ordering .order-by {
        margin-top: 20px;
        margin-left: 0px;
    }
}

/** 5.5. Shop Single **/
.product-details-content {
    padding-top: 50px;
}

@media (max-width: 1024px) {
    .product-details-content {
        padding-top: 30px;
    }
}

.product-details-content .options {
    position: relative;
}

.product-details-content .options input {
    background: none;
    box-shadow: none;
    border: 1px solid #d9d9d9;
    color: #2b2b2b;
    font-size: 16px;
    font-family: "Poppins";
    text-align: center;
    height: 50px;
    width: 180px;
    text-align: left;
    padding-left: 20px;
}

.product-details-content .options select {
    font: 400 18px/50px "Gotham";
    color: #565656;
    width: 100%;
    height: 50px;
    outline: none;
    padding-left: 20px;
    border: 1px solid #d9d9d9;
}

.product-details-content .options select:focus {
    outline: none;
}

.product-details-content .options .quantity {
    display: inline-block;
    position: relative;
    width: 84px;
    border: 2px solid #3e3e3e;
}

.product-details-content .options .quantity .minus {
    position: absolute;
    cursor: pointer;
    bottom: 0px;
    right: 0px;
    width: 40px;
    height: 30px;
}

.product-details-content .options .quantity .minus i {
    height: 100%;
}

.product-details-content .options .quantity .minus i:before {
    content: "";
}

.product-details-content .options .quantity .plus {
    position: absolute;
    top: 0px;
    cursor: pointer;
    right: 0px;
    width: 40px;
    height: 30px;
}

.product-details-content .options .quantity .plus i {
    width: 100%;
    height: 100%;
}

.product-details-content .options .quantity .plus i:before {
    content: "";
}

.product-details-content .social {
    padding-top: 20px;
    border-top: 1px solid #f5f5f5;
}

.product-details-content .social h3 {
    display: inline-block;
    font: 700 14px/20px "Gotham";
    color: #000;
    text-transform: uppercase;
    vertical-align: 4px;
}

.product-details-content .social i {
    font-size: 24px;
    margin: 0px 10px;
}

.product-details-content .social a.twitter i {
    color: #55acee;
}

.product-details-content .social a.dribbble i {
    color: #e04c86;
}

.product-details-content .social a.skype i {
    color: #00aff0;
}

.product-details-content .social a.pinterest i {
    color: #bd081c;
}

.product-details-content .social a.facebook i {
    color: #3b5998;
}

.product-details-content .action {
    display: inline-block;
    padding: 0px 0px 30px;
    width: 100%;
}

.product-details-content .action a.link-v1 {
    vertical-align: top;
    margin-right: 10px;
    border: 1px solid #cdcdcd;
    height: 50px;
    line-height: 50px;
    font: 500 14px/48px "Gotham";
    width: 50px;
    transition: all 0.3s ease 0s;
}

.product-details-content .action a.link-v1.add-cart {
    width: 200px;
    background: #80b435;
    color: #fff;
}

.product-details-content .action a.link-v1.add-cart:hover {
    background: #fff;
    color: #80b435;
}

.product-details-content .action a.link-v1 i {
    color: #000;
    font-size: 20px;
    line-height: 48px;
}

.product-details-content .action a.link-v1:hover {
    background: #80b435;
    border-color: #80b435;
}

.product-details-content .action a.link-v1:hover i {
    color: #fff;
}

.product-details-content .title h3 {
    font: 700 18px "Gotham";
    color: #2b2b2b;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.product-details-content .col-md-6:last-child {
    padding-right: 0px;
    display: inline-block;
}

.product-details-content .product-name h1 {
    color: #2b2b2b;
    font-family: "Gotham";
    font-size: 48px;
    margin-bottom: 10px;
    line-height: 50px;
    position: relative;
    padding-bottom: 20px;
    text-transform: uppercase;
    font-weight: 400;
}

.product-details-content .product-name p {
    color: #888888;
    line-height: 20px;
}

.product-details-content .select-color span {
    display: inline-block;
    width: 45px;
    height: 45px;
    background-color: #606875;
    text-indent: -9999px;
    margin-right: 12px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.3 ease 0s;
    margin-bottom: 10px;
}

.product-details-content .select-color span.active,
.product-details-content .select-color .ordering span:hover,
.ordering .product-details-content .select-color span:hover {
    background-image: url(../images/check-select-color.png);
}

.product-details-content .select-color span:last-child {
    margin-right: 0px;
}

.product-details-content .select-color span.color2 {
    background-color: #20b1ed;
}

.product-details-content .select-color span.color3 {
    background-color: #fc5a00;
}

.product-details-content .select-color span.color4 {
    background-color: #485c80;
}

.product-details-content .select-color span.color5 {
    background-color: #fdab4b;
}

.product-details-content .select-color span.color6 {
    background-color: #df3530;
}

.product-details-content .select-color span.color7 {
    background-color: #72aea1;
}

.product-details-content .select-color span.color8 {
    background-color: #3d78e2;
}

.product-details-content .select-color span.color9 {
    background-color: #3f786c;
}

.product-details-content .rating {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}

.product-details-content .rating .icon-rating {
    margin-right: 20px;
}

.product-details-content .rating .icon-rating input:checked ~ label,
.product-details-content .rating .icon-rating input + label:hover,
.product-details-content .rating .icon-rating input + label:hover ~ label {
    color: #80b435;
}

.product-details-content .rating i {
    font-size: 24px;
}

.product-details-content .rating span {
    color: #717171;
    font-size: 12px;
    font-family: "Poppins";
    vertical-align: -3px;
}

.product-details-content .wrap-price {
    margin-bottom: 0px;
    display: inline-block;
    width: 100%;
}

.product-details-content .wrap-price p {
    font-style: normal;
    color: #80b435;
    font-size: 36px;
    font-weight: 400;
    font-family: "Gotham";
    display: inline-block;
    line-height: 40px;
}

.product-details-content .wrap-price p.price-old {
    font-size: 30px;
    line-height: 40px;
    text-decoration: line-through;
    margin-right: 20px;
    color: #979797;
}

.product-details-content .form-group {
    position: relative;
    display: inline-block;
    width: 100%;
}

.product-details-content label.control-label {
    font-size: 14px;
}

.product-details-content .form-control {
    appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    height: 38px;
}

.product-details-content .form-control option {
    padding: 10px 0;
}

.product-details-content .products .product .add-to-cart {
    background: #80b435;
    margin: 0px;
    line-height: 52px;
    padding: 0 40px;
    color: #fff;
    opacity: 1;
    border: 1px solid #80b435;
}

.product-details-content .products .product .add-to-cart:after {
    content: "";
}

.product-details-content .products .product .add-to-cart:hover {
    background: #fff;
    color: #80b435;
}

.product-details-content .products .product .action {
    float: inherit;
    opacity: 1;
}

.product-details-content .products .product .action a {
    background: #eeeeee;
    opacity: 1;
    line-height: 54px;
    padding: 0 15px;
    display: inline-block;
    border: 1px solid #eeeeee;
}

.product-details-content .products .product .action a:hover {
    background: #80b435;
    border: 1px solid #80b435;
}

.product-details-content .products .product .action a:hover i {
    color: #fff;
}

.product-details-content.product-details-content-v2 {
    position: relative;
}

.product-details-content.product-details-content-v2 img {
    max-width: 100%;
}

.product-details-content.product-details-content-v2 .col-md-5 {
    position: absolute;
    left: 150px;
    top: 120px;
}

.hoz-tab-container {
    display: inline-block;
    width: 100%;
    margin-bottom: 50px;
    margin-top: 40px;
    border-top: 1px solid #e6e6e6;
    position: relative;
}

@media (min-width: 1500px) {
    .hoz-tab-container:after {
        position: absolute;
        content: "";
        left: 300px;
        width: 1px;
        top: 30px;
        bottom: 30px;
        background: #e6e6e6;
    }
}

@media (max-width: 1500px) and (min-width: 1200px) {
    .hoz-tab-container .tabs {
        padding-right: 15px;
    }
}

.hoz-tab-container.ver2:after {
    width: 0px;
}

.hoz-tab-container.ver2 .tabs,
.hoz-tab-container.ver2 .tab-container {
    width: 100%;
}

.hoz-tab-container.ver2 .tabs {
    max-width: 100%;
    margin-bottom: 30px;
    border-bottom: 1px solid #e6e6e6;
}

.hoz-tab-container.ver2 .tabs li {
    display: inline-block;
    margin: 0 10px;
    font: 700 18px/20px "Gotham";
    text-transform: uppercase;
    border: none;
    padding-bottom: 25px;
    position: relative;
}

.hoz-tab-container.ver2 .tabs li:after {
    left: 50%;
    right: 50%;
    transition: all 0.3s ease 0s;
    content: "";
}

.hoz-tab-container.ver2 .tabs li.active:after,
.hoz-tab-container.ver2 .tabs li:hover:after {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -2px;
    height: 3px;
    background: #80b435;
}

.hoz-tab-container .tabs {
    display: inline-block;
    width: 20%;
    float: left;
    max-width: 280px;
}

.hoz-tab-container .tabs li {
    display: block;
    border-bottom: 1px solid #e6e6e6;
    font: 400 24px/60px "Gotham";
    color: #2b2b2b;
    text-transform: capitalize;
    cursor: pointer;
}

.hoz-tab-container .tabs li.active,
.hoz-tab-container .tabs li:hover {
    color: #80b435;
}

.hoz-tab-container .tab-container {
    width: 80%;
    float: right;
    text-align: left;
}

.hoz-tab-container .tab-container .tab-content h3 {
    font: 700 24px/30px "Gotham";
    text-transform: capitalize;
    margin-bottom: 20px;
    color: #2b2b2b;
}

.hoz-tab-container .tab-container .tab-content p {
    font: 400 18px/25px "Gotham";
    margin-bottom: 30px;
    color: #666;
}

.hoz-tab-container .tab-container #description.mockup-v3 {
    text-align: center;
}

.hoz-tab-container .tab-container #description p {
    font-weight: 400;
    line-height: 28px;
}

.hoz-tab-container .tab-container #description p.center {
    text-align: center;
    max-width: 720px;
    color: #858585;
}

.hoz-tab-container .tab-container h3 {
    font-family: "Montserrat";
    font-style: normal;
    text-transform: capitalize;
    font-size: 18px;
    color: #333333;
}

.hoz-tab-container .tab-container h4 {
    color: #333333;
    font-size: 13px;
    margin-bottom: 10px;
}

.hoz-tab-container p {
    font: 400 14px/20px "Poppins";
    color: #000;
}

.hoz-tab-container #product-tags .button-v2 {
    margin-top: 20px;
    font: 700 12px "Oswald";
}

.hoz-tab-container #product-tags p {
    display: block;
    overflow: hidden;
    border-bottom: 1px solid #dddddd;
    margin: 0px;
}

.hoz-tab-container #product-tags p span {
    font: 400 16px/60px "Gotham";
    float: right;
}

.hoz-tab-container #product-tags p span:first-child {
    float: left;
    color: #2b2b2b;
}

#customer .border {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

#customer .border p {
    margin-bottom: 0px;
    font: 400 18px "Gotham";
    color: #979797;
}

#customer .color {
    color: #ff0000;
}

#customer label {
    margin: 5px 0 0;
    font: 400 18px "Gotham";
    color: #2b2b2b;
}

#customer .form-horizontal {
    font-size: 13px;
}

#customer .form-horizontal label {
    margin-bottom: 10px;
    display: inline-block;
}

#customer .form-control {
    font: 400 18px "Gotham";
    border: 1px solid #e6e6e6;
}

#customer input[type="radio"] {
    display: none;
}

#customer .customer-radio label {
    display: inline-block;
    position: relative;
    padding-left: 20px;
    margin-right: 20px;
    font-size: 13px;
    color: #888888;
    text-transform: uppercase;
}

#customer .customer-radio label:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 2px;
    background: #fff;
    border: 1px solid #80b435;
    width: 12px;
    height: 12px;
}

#customer .customer-radio input[type="radio"]:checked + label:before {
    background: #80b435;
}

#customer .rating li {
    display: inline-block;
    margin-right: 15px;
    padding-right: 15px;
    position: relative;
    border-right: 1px solid #e6e6e6;
}

#customer .rating li:last-child {
    border: none;
}

#customer .rating li a.active i,
#customer .rating li a:hover i {
    color: #80b435;
}

#customer .rating li a i {
    font-size: 14px;
    color: #e6e6e6;
}

#customer a.button-v1 {
    display: inline-block;
    text-align: center;
    font: 700 16px/48px "Gotham";
    color: #fff;
    background: #80b435;
    border: 1px solid #80b435;
    text-transform: uppercase;
    width: 180px;
    transition: all 0.3s ease 0s;
    float: right;
}

#customer a.button-v1:hover {
    background: #fff;
    color: #80b435;
}

#customer textarea {
    min-height: 100px;
}

.product-details-content p {
    font: 400 18px/28px "Gotham";
    color: #565656;
    margin-bottom: 30px;
}

.product-details-content .breadcrumb {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 3px solid #2e2e2e;
}

.product-details-content .breadcrumb li {
    padding-right: 10px;
}

.product-details-content .breadcrumb li:first-child {
    padding-left: 0px;
}

.product-details-content .breadcrumb li a {
    color: #fff;
}

.product-details-content .breadcrumb li.active {
    color: #fff;
}

.product-details-content .breadcrumb li:after {
    content: "";
}

.product-details-content .breadcrumb li + li:before {
    color: #fff;
    margin-right: 10px;
}

.product-details-content .product-name {
    display: inline-block;
    width: 100%;
}

.product-details-content .description-lits {
    margin-bottom: 40px;
}

.product-details-content .description-lits h3 {
    font: 700 18px/30px "Gotham";
    color: #2b2b2b;
    margin-bottom: 10px;
}

.product-details-content .description-lits li {
    font: 400 18px/30px "Gotham";
    color: #666;
}

.product-details-content .description-lits li img {
    margin-right: 10px;
}

/** 5.6. Shop Cart **/
.coupon {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: 50px;
}

.coupon .form-group {
    margin-bottom: 0px;
}

.coupon .control-label {
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 0px;
    margin: 0px;
    height: 100%;
    border-right: 1px solid #dcdcdc;
    font-family: "Oswald";
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000;
    font-weight: 700;
    line-height: 60px;
    padding: 0px 20px;
}

.coupon button {
    margin-top: 0px;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 60px;
    outline: none;
}

.coupon .form-control {
    height: 60px;
    border: 1px solid #dcdcdc;
    padding-left: 145px;
    font-family: "Poppins";
    color: #8d8c8c;
    padding-right: 180px;
    box-shadow: none;
}

.cart-box-container .col-md-4 {
    padding-right: 0px;
}

.cart-box-container .col-md-8 {
    padding-left: 0px;
}

.cart-box-container .text-price {
    padding: 20px;
    padding-bottom: 160px;
    background: #f0f0f0;
    margin-bottom: 50px;
    position: relative;
    display: inline-block;
    width: 100%;
}

.cart-box-container .text-price h3 {
    font-family: "Oswald";
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 2px;
    padding: 17px 0px 5px;
    width: 100%;
    display: inline-block;
    border-bottom: 2px solid #000;
}

.cart-box-container .text-price a {
    margin-top: 0px;
    width: 100%;
    height: 75px;
    line-height: 75px;
}

.cart-box-container .text-price a.update {
    position: absolute;
    left: 0px;
    bottom: 75px;
    background: #333;
    border-color: #333;
}

.cart-box-container .text-price a.checkout {
    position: absolute;
    left: 0px;
    bottom: 0px;
}

.cart-box-container .text-price ul li {
    padding: 20px 0px;
    border-bottom: 1px solid #dadada;
}

.cart-box-container .text-price ul li:last-child {
    border: none;
}

.cart-box-container .text-price ul li .text {
    font-weight: 300;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.cart-box-container .text-price ul li .text.calculate {
    display: inline-block;
    width: 100%;
    position: relative;
    cursor: pointer;
}

.cart-box-container .text-price ul li .text.calculate:after {
    position: absolute;
    content: "";
    right: 0px;
    top: 0px;
    height: 12px;
    width: 12px;
    font-family: "FontAwesome";
    content: "";
    color: #000;
}

.cart-box-container .text-price ul li .text.calculate.active:after,
.cart-box-container .text-price ul li .ordering span.text.calculate:hover:after,
.ordering .cart-box-container .text-price ul li span.text.calculate:hover:after {
    content: "";
}

.cart-box-container .text-price ul li .number {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    color: #010101;
}

.cart-box-container .text-price ul li .zipcode {
    display: none;
    margin-top: 30px;
}

.cart-box-container .text-price ul li .payment {
    float: right;
    max-width: 220px;
}

.cart-box-container .text-price ul li .payment label {
    width: 100%;
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 400;
    color: #858585;
    cursor: pointer;
    padding-left: 20px;
    position: relative;
    margin-bottom: 10px;
}

.cart-box-container .text-price ul li .payment label:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0px;
    top: 3px;
}
.cart-box-container .text-price ul li .payment input {
    display: none;
}

.cart-box-container .text-price ul li .payment input[type="radio"]:checked + label:before {
}
@media (max-width: 1200px) {
    .cart-box-container .row {
        margin: 0px;
    }
}

.head-cart .item {
    border: 1px solid #ebebeb;
    padding: 60px 0px;
}

.head-cart .item .icon {
    font: 400 22px/64px "Gotham";
    display: inline-block;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    border: 1px solid #c2c2c2;
    background: #fff;
    color: #565656;
}

.head-cart .item.active .icon,
.head-cart .ordering span.item:hover .icon,
.ordering .head-cart span.item:hover .icon {
    background: #80b435;
    color: #fff;
    border-color: #80b435;
}

.head-cart .item h3 {
    font: 400 24px/30px "Gotham";
    color: #565656;
    text-transform: uppercase;
    margin-top: 25px;
    margin-bottom: 10px;
    padding-bottom: 20px;
    position: relative;
}

.head-cart .item h3:after {
    position: absolute;
    content: "";
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    height: 1px;
    width: 75px;
    margin: auto;
    background: #80b435;
}

table.table.cart-table thead {
    background: #80b435;
}

table.table.cart-table thead th {
    color: #fff;
    font: 500 18px "Gotham";
    padding: 12px 0px;
    letter-spacing: 0px;
}

table.table.cart-table thead th:first-child {
    padding-left: 30px;
}

table.table.cart-table thead th.add-cart {
    text-align: right;
    padding-right: 20px;
}

table.table.cart-table tbody tr {
    border-bottom: 1px solid #ddd;
}

table.table.cart-table td.total-price {
    width: 70px;
}

table.table.cart-table td.product-photo {
    max-width: 200px;
    width: 250px;
}

table.table.cart-table td.product-photo img {
    width: 200px;
}

table.table.cart-table td.produc-name a {
    font: 400 18px "Gotham";
    color: #2b2b2b;
}

table.table.cart-table td.produc-name {
    width: 20%;
}

table.table.cart-table td.produc-price,
table.table.cart-table td.product-quantity,
table.table.cart-table td.total-price {
    width: 15%;
}

@media (max-width: 1200px) and (min-width: 768px) {
    table.table.cart-table td.produc-price,
    table.table.cart-table td.product-quantity,
    table.table.cart-table td.total-price {
        padding-right: 20px;
    }
}

table.table.cart-table td.produc-price input {
    border: none;
    font: 400 18px "Gotham";
    color: #666;
}

table.table.cart-table td.product-quantity input {
    border: 1px solid #d8d8d8;
    height: 50px;
    width: 130px;
    text-align: center;
    /* font: 500 20px "Gotham"; */
    color: black;
}

table.table.cart-table td.product-quantity .quantity {
    position: relative;
    justify-content: center;
    display: flex;
}

table.table.cart-table td.product-quantity .quantity .minus,
table.table.cart-table td.product-quantity .quantity .plus {
    left: 31px;
    top: 15px;
    position: absolute;
    cursor: pointer;
}

table.table.cart-table td.product-quantity .quantity .minus:hover i,
table.table.cart-table td.product-quantity .quantity .plus:hover i {
    color: #80b435;
}

table.table.cart-table td.product-quantity .quantity .plus {
    left: 125px;
}

table.table.cart-table td.product-instock {
    font: 400 18px/25px "Gotham";
    color: #80b435;
}

table.table.cart-table td.product-instock .out {
    color: #333;
}

table.table.cart-table td.product-instock i {
    margin-right: 8px;
}

table.table.cart-table td.total-price {
    color: #009b3d;
    /* font: 400 20px "Gotham"; */
}

table.table.cart-table td.add-cart {
    text-align: right;
    padding: 0 30px;
}

table.table.cart-table td.add-cart i {
    margin-right: 8px;
}

.cart-total .item {
    padding: 30px;
    border: 1px solid #e7e7e7;
}

.cart-total .item h3.title {
    font: 700 18px "Gotham";
    color: #565656;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.cart-total .item p {
    font: 400 14px/20px "Gotham";
    color: #565656;
    margin-bottom: 10px;
}

.cart-total label {
    font: 700 14px/20px "Gotham";
    text-transform: uppercase;
}

.cart-total label span {
    color: #fe0302;
}

.cart-total span.float-right {
    font-size: 18px;
}

.cart-box-container .cart-total .col-md-4 {
    padding-right: 15px;
}

.form-control.control-search {
    color: #333;
}

.row-total {
    float: left;
    width: 100%;
    margin-bottom: 60px;
    border-bottom: 1px solid #e6e6e6;
    padding: 30px 0px 60px;
}

.row-total h3 {
    font: 700 18px/20px "Gotham";
    color: #333;
    text-transform: uppercase;
    padding-left: 45px;
}

.row-total .float-right p {
    color: #80b435;
    font: 700 18px/20px "Gotham";
    padding-right: 45px;
}

/** 5.7. Shop Checkout **/
.checkout-header ul {
    padding: 10px 0px 20px;
    display: inline-block;
    width: 100%;
    margin-bottom: 50px;
    border-bottom: 1px solid #e5e5e5;
}

.checkout-header ul li {
    display: inline-block;
    width: 33.33%;
    float: left;
    text-transform: uppercase;
    color: #333333;
    font-size: 15px;
}

.checkout-header ul li:nth-child(2) {
    text-align: center;
}

.checkout-header ul li:nth-child(3) {
    text-align: right;
}

.checkout-header ul li.active span {
    background: #80b435;
    color: #fff;
}

.checkout-header ul li span {
    display: inline-block;
    margin-right: 10px;
    padding: 8px 13px;
    border-radius: 50%;
    background: #e5e5e5;
    color: #333333;
    width: 40px;
    height: 40px;
}

.check-out .form-horizontal label.control-label {
    font: 700 16px/20px "Gotham";
    color: #2b2b2b;
    text-transform: uppercase;
}

.check-out .form-horizontal label.control-label .color {
    color: #fd0000;
}

.check-out .form-horizontal .form-control {
    border-color: #e5e5e5;
    box-shadow: none;
    font-size: 16px;
    font-family: "Gotham";
}

.check-out .form-check {
    display: inline-block;
    font: 400 14px/20px "Poppins";
    color: #000000;
    padding-left: 25px;

    margin-right: 25px;
}

.check-out .form-check.size-12 {
    font-size: 12px;
    padding-left: 15px;
}

.check-out .form-check.active,
.check-out .ordering span.form-check:hover,
.ordering .check-out span.form-check:hover {
}
.check-out .form-check span {
    color: #e95252;
    display: inline-block !important;
}

.check-out .text-price {
    padding-bottom: 95px;
}

.cart-box-container .text-price ul li .text.text-cap {
    letter-spacing: 0px;
    text-transform: capitalize;
    font-family: "Poppins";
    font-size: 14px;
}

.account .col-md-6:nth-child(2n + 1) {
    padding-left: 0px;
}

.account .col-md-6:nth-child(2n) padding-right {
    padding-right: 0px;
}

.account p {
    font-family: "TXGR";
    color: #888888;
}

.bold {
    font-weight: 700 !important;
}

.cart-review {
    display: inline-block;
    width: 100%;
}

.cart-review table.table th:nth-child(2) {
    width: 30%;
}

.cart-review table.table th:nth-child(3) {
    width: 15%;
}

.cart-review table.table td:nth-child(2) {
    color: #888888;
}

.cart-review table.table td:nth-child(3),
.cart-review table.table td:nth-child(5) {
    color: #555555;
    font-style: normal;
    font-size: 18px;
    font-family: "TXGB";
}

.icon {
    width: 100%;
    text-align: center;
}

.icon .fa-shopping-basket {
    font-size: 300px;
    color: #ddd;
    position: relative;
}

.icon .fa-check {
    position: absolute;
    right: -33px;
    bottom: -50px;
    font-size: 160px;
    color: #e11111;
}

.icon h3,
.icon h4 {
    text-transform: uppercase;
    color: #555555;
    text-align: center;
}

.icon h3 {
    font-size: 30px;
    margin-top: 80px;
}

.icon h4 {
    font-size: 18px;
    margin: 10px 0px;
}

.order-details .col-md-6:nth-of-type(2n + 1) {
    padding-right: 0px;
}

.order-details .col-md-6:nth-of-type(2n) {
    padding-left: 0px;
}

.order-details .col-md-6 h4 {
    font-size: 15px;
    color: #555555;
    line-height: 20px;
    text-transform: capitalize;
    margin-bottom: 15px;
}

.order-details .col-md-6 p {
    font-family: "TXGR";
    color: #888888;
    margin: 4px 0px;
    line-height: 20px;
}

.order-details .col-md-6 p b {
    color: #222;
    font-weight: 400;
}

.order-details .col-md-6 p.total {
    text-transform: uppercase;
    color: #80b435;
    font-style: normal;
    margin-top: 30px;
    font-size: 15px;
    font-family: "TXGB";
}

.order-details .col-md-6 p.total span {
    color: #555555;
}

.payment-order ul.tabs li {
    padding: 20px 0px 20px 20px;
    position: relative;
}

.payment-order ul.tabs li h4 {
    font: 600 18px/20px "Poppins";
    color: #000;
}

.payment-order ul.tabs li p {
    color: #9d9d9d;
    font: 400 12px/20px "Poppins";
    margin-top: 10px;
}

.payment-order ul.tabs li i {
    width: 12px;
    height: 12px;
    content: "";
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: 23px;
    background: transparent url("../images/select-radio.png") no-repeat scroll left top;
}

.payment-order ul.tabs li a {
    font: 400 12px/20px "Poppins";
    color: #e95252;
    margin-top: 20px !important;
    display: inline-block;
    height: auto !important;
}

.payment-order ul.tabs li.active i {
    background: transparent url("../images/select-radio.png") no-repeat scroll left bottom;
}

.payment-order ul.tabs li img {
    margin-left: 30px;
}

.place-order-now {
    text-align: center;
    margin-top: 30px;
}

.form-group {
    margin-bottom: 25px !important;
}

.check-billing .text-price ul li {
    padding: 20px 0;
}

.check-billing .text-price ul li .text {
    font-family: "TXGB";
}

.check-billing .text-price ul li .number.color {
    color: #e11111;
}

.info-order ul li {
    display: block;
    float: left;
    width: 100%;
    line-height: 50px;
}

.info-order .product-name {
    text-align: left;
}

.info-order .product-name .head {
    border-bottom: 1px solid #e6e6e6;
}

.info-order .product-name .head .name {
    font: 700 16px/50px "Gotham";
    color: #565656;
    text-transform: uppercase;
}

.info-order .product-name .head .qty,
.info-order .product-name .head .total {
    font: 400 16px/50px "Gotham";
}

.info-order .product-name .name {
    float: left;
    color: #a7a7a7;
    font: 400 16px/50px "Gotham";
    width: 300px;
}

.info-order .product-name .total {
    float: right;
    color: #a7a7a7;
    font: 400 16px/50px "Gotham";
    text-align: right;
}

.info-order .product-name .qty {
    color: #a7a7a7;
    font: 400 16px/50px "Gotham";
}

.info-order .product-name b {
    color: #2b2b2b;
}

.info-order .product-order {
    margin-bottom: 30px;
    padding-bottom: 30px;
    width: 100%;
    float: left;
}

.info-order .product-order li {
    border-top: 1px solid #e6e6e6;
}

.info-order .product-order .left {
    font: 500 16px "Gotham";
    color: #565656;
    text-transform: uppercase;
    float: left;
    line-height: 50px;
}

.info-order .product-order .right {
    float: right;
    font: 400 16px/50px "Gotham";
    text-transform: uppercase;
}

.info-order .product-order .right.brand {
    color: #009b3d;
    font-weight: 500;
}

.order-complete p {
    font: 300 30px "Gotham";
    color: #333;
}

/** 5.8. Shop Wishlist **/
table.table.cart-table td {
    padding: 30px 0px;
    vertical-align: middle;
}

.caculate-shipping .form-group:after,
.caculate-shipping .form-group:before {
    display: inline;
}

.caculate-shipping button {
    margin-top: 30px;
}

.coupon p {
    color: #888888;
    line-height: 20px;
    margin-bottom: 30px;
    padding-top: 10px;
}

.coupon button {
    margin-top: 40px;
}

.text-price ul li {
    display: block;
    padding: 10px 0px;
    border-bottom: 1px solid #eeeeee;
    width: 100%;
    float: left;
}

.text-price ul li .text {
    font-size: 13px;
    color: #555555;
    float: left;
}

.text-price ul li .number {
    float: right;
    color: #555555;
    font-size: 18px;
    line-height: 20px;
}

.text-price a {
    margin-top: 30px;
}

@media (max-width: 1024px) {
    table.table.cart-table td.add-cart .link-v1 {
        font-size: 0px;
        padding: 0 15px;
    }
    table.table.cart-table td.add-cart .link-v1 i {
        font-size: 16px;
        line-height: 45px;
        margin-right: 4px;
    }
}

@media (max-width: 767px) {
    table.table tr th.description,
    table.table tr th.product-avai,
    table.table tr th.produc-price {
        display: none;
    }
    table.table tr td.description,
    table.table tr td.product-avai,
    table.table tr td.produc-price {
        display: none;
    }
    table.table tr td:nth-child(5) input {
        width: 50px;
    }
    table.table.cart-table td.produc-name a {
        font-size: 16px;
    }
    table.table.cart-table td.product-photo {
        width: 150px;
    }
    table.table.cart-table td.product-photo img {
        width: 150px;
    }
    table.table.cart-table td.total-price {
        width: 100px;
        padding-left: 10px;
    }
    .cart-container .float-right {
        width: 100%;
        text-align: left;
    }
}

@media (max-width: 467px) {
    table.table.cart-table td.produc-name,
    table.table.cart-table th.produc-name {
        display: none;
    }
    table.table.cart-table td.product-photo {
        width: 100px;
    }
    table.table.cart-table td.product-photo img {
        width: 100px;
    }
}

/** 5.9. Track Order **/
.ordering {
    font-size: 13px;
    color: #333333;
    display: inline-block;
    width: 100%;
    text-align: right;
    display: inline-block;
}

.ordering a {
    display: inline-block;
    height: 35px;
    line-height: 35px;
    color: #333;
    padding: 0 15px;
    background: #eee;
    vertical-align: bottom;
}

.ordering span {
    display: inline-block;
    height: 50px;
    width: 50px;
    cursor: pointer;
    border: 1px solid #cdcdcd;
}

.ordering span.active,
.ordering span:hover {
    background-position: center bottom 11px;
}

.ordering span.list {
}
.ordering span.list:hover,
.ordering span.list.active,
.ordering span.list:hover {
    background-position: center bottom 11px;
}

.ordering .order-by {
    display: inline-block;
    margin-left: 5px;
    position: relative;
}

.ordering .order-by::after {
    position: absolute;
    content: "\f0d7";
    top: 12px;
    right: 10px;
    font-family: FontAwesome;
    line-height: 1;
    font-size: 16px;
    font-style: normal;
    color: #2b2b2b;
}

.ordering form {
    vertical-align: bottom;
    margin-top: 5px;
    border: 1px solid #cdcdcd;
    padding-left: 20px;
}

.ordering form p {
    font: 400 16px "Gotham";
    color: #000;
    display: inline-block;
}

.ordering form select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border: none;
    box-shadow: none;
    padding: 9px 20px 9px 3px;
    outline: none;
    color: #80b435;
    text-transform: capitalize;
    font: 400 16px "Gotham";
}

.ordering form select option {
    font: 400 16px "Gotham";
}

p.result-count {
    padding-bottom: 3px;
    display: inline-block;
    font: 400 16px/20px "Gotham";
    color: #2b2b2b;
    margin-left: 20px;
    margin-bottom: 13px;
}

.breadcrumb {
    float: left;
    width: auto;
    display: inline-block;
    margin-bottom: 0px !important;
    border: none;
    padding: 0px;
    margin-top: 5px;
}

.breadcrumb li {
    padding-right: 0px;
    font: 400 12px "Poppins";
    text-transform: capitalize;
}

.breadcrumb li a {
    color: #000;
}

.breadcrumb li + li:before {
    content: "";
    font-family: "FontAwesome";
    font-size: 12px;
}

.wrap-breadcrumb {
    display: inline-block;
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 10px;
    padding-top: 10px;
    text-align: center;
}

.wrap-breadcrumb .ordering-action {
    display: inline-block;
    margin-left: 150px;
}

.wrap-breadcrumb .ordering-action a {
    display: inline-block;
    padding: 5px 8px;
    border: 1px solid #000;
}

.wrap-breadcrumb .ordering-action a span {
    color: #000;
    font-size: 17px;
}

nav.pagination {
    font-family: "Oswald";
    width: 100%;
    padding: 5px;
    margin-bottom: 30px;
}

nav.pagination span {
    background: none;
    color: #000;
    font-weight: 700;
}

nav.pagination span:hover {
    background: none;
    color: #000;
}

nav.pagination a {
    background: none;
    font-weight: 700;
    color: #a8a8a8;
}

nav.pagination a:hover {
    background: none;
    color: #000;
}

nav.pagination a.next,
nav.pagination a.prev {
    border: 1px solid #d6d6d6;
    width: 45px;
}

nav.pagination a.next:hover,
nav.pagination a.prev:hover {
    background: none !important;
}

nav.pagination a.next:hover i,
nav.pagination a.prev:hover i {
    color: #80b435;
}

.pagination-blog nav.pagination {
    border: none;
    text-align: center;
    margin-bottom: 50px;
}

.order-tracking {
    text-align: center;
}

.order-tracking p {
    max-width: 900px;
    padding: 0 20px;
    font: 400 16px/30px "Gotham";
    margin: auto;
    margin-top: 40px;
    color: #565656;
}

.order-tracking .tracking-content {
    width: 420px;
    max-width: 100%;
    margin: auto;
    padding: 40px 0px;
    margin-bottom: 80px;
}

.order-tracking .tracking-content .form-control {
    border: 1px solid #ebebeb;
    font: 400 18px/50px "Gotham";
}

.order-tracking .tracking-content .form-horizontal label.control-label {
    font: 400 18px/20px "Gotham";
    color: #000;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.order-tracking .tracking-content .link-button.color-red {
    line-height: 54px;
    width: 100%;
}

/** 6. Templates Layout **/
/** 6.0. General **/
/*Loading*/
.awe-body-loading {
    overflow: hidden;
}

.awe-page-loading {
    position: fixed;
    z-index: 9999999;
    background: #fff;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.awe-loading-wrapper {
    text-align: center;
    width: 150px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -75px;
}

.awe-loading-wrapper .awe-loading-icon {
    font-size: 75px;
    line-height: 1;
    margin-bottom: 10px;
    -webkit-animation-name: aweIcon;
    animation-name: aweIcon;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

.awe-loading-wrapper .awe-loading-icon img {
    width: 100%;
}

.awe-loading-wrapper .awe-loading-icon .icon {
    cursor: default;
}

.awe-loading-wrapper .progress {
    background: #eee;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
    height: 2px;
}

.awe-loading-wrapper .progress .progress-bar {
    background: #80b435;
    box-shadow: none;
    width: 100%;
    -webkit-animation-name: aweProgress;
    animation-name: aweProgress;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
}

@-webkit-keyframes aweIcon {
    0% {
        color: #333;
    }
    50% {
        color: #666;
    }
    100% {
        color: #000;
    }
}

@-moz-keyframes aweIcon {
    0% {
        color: #333;
    }
    50% {
        color: #666;
    }
    100% {
        color: #000;
    }
}

@keyframes aweIcon {
    0% {
        color: #333;
    }
    50% {
        color: #666;
    }
    100% {
        color: #000;
    }
}

@-webkit-keyframes aweProgress {
    0% {
        width: 0%;
    }
    15% {
        width: 15%;
    }
    25% {
        width: 35%;
    }
    35% {
        width: 50%;
    }
    50% {
        width: 75%;
    }
    65% {
        width: 85%;
    }
    100% {
        width: 100%;
    }
}

@-moz-keyframes aweProgress {
    0% {
        width: 0%;
    }
    15% {
        width: 15%;
    }
    25% {
        width: 35%;
    }
    35% {
        width: 50%;
    }
    50% {
        width: 75%;
    }
    65% {
        width: 85%;
    }
    100% {
        width: 100%;
    }
}

@keyframes aweProgress {
    0% {
        width: 0%;
    }
    15% {
        width: 15%;
    }
    25% {
        width: 35%;
    }
    35% {
        width: 50%;
    }
    50% {
        width: 75%;
    }
    65% {
        width: 85%;
    }
    100% {
        width: 100%;
    }
}

/** 6.1. General **/
/** 6.2. Header **/
/** 6.2.1. General **/
.container {
    width: 100%;
    padding: 0px 30px;
}

#topbar {
    background: #fff;
}

#topbar .topbar-left {
    display: inline-block;
    float: left;
}

#topbar.topbar-ver2 .float-left p {
    font: 300 13px/20px "Gotham";
    color: #231f20;
    margin-top: 4px;
}

#topbar.topbar-ver2 .float-left p span {
    font: 700 13px/20px "Gotham";
    color: #80b435;
}

#topbar.topbar-ver2 .float-right a {
    line-height: 30px;
}

#topbar.topbar-ver2 .float-right a img {
    margin-right: 5px;
    margin-bottom: 2px;
}

@media (max-width: 767px) {
    #topbar.topbar-ver2 {
        display: none;
    }
}

#topbar .float-left p {
    display: block;
    font: 300 13px/20px "Gotham";
    text-align: left;
    margin: 3px 0px;
}

#topbar .float-left p img {
    margin-right: 5px;
}

#topbar .float-left p span {
    color: #80b435;
    font: 700 18px/20px "Gotham";
}

#topbar .float-left p.phone {
    line-height: 30px;
}

#topbar .float-left p.phone img {
    margin-bottom: 5px;
}

#topbar .float-left a {
    color: #333;
}

#topbar .float-right a {
    color: #333;
    font: 400 12px/40px "Gotham";
}

#topbar .float-right .hover-menu {
    display: inline-block;
    padding-left: 10px;
    margin-left: 10px;
    position: relative;
}

#topbar .float-right .hover-menu:after {
    position: absolute;
    content: "";
    top: 15px;
    left: 0px;
    width: 1px;
    height: 10px;
    background: #cdcdcd;
}

#topbar .float-right .hover-menu:first-child:after {
    width: 0px;
}

#topbar .float-right .list-menu {
    background: #fff;
    border: 1px solid #333;
    min-width: 349px;
    text-align: left;
    padding: 0px 15px;
}

#topbar .float-right .list-menu a {
    color: #333;
    /* line-height: 30px; */
}

#topbar .float-right .list-menu a:hover {
    color: #80b435;
}

#topbar .float-right .list-menu a.rt {
    color: #fff;
}

#topbar .inner-topbar {
    border-bottom: 1px solid #cdcdcd;
}

@media (max-width: 767px) {
    #topbar .inner-topbar {
        border: none;
    }
}

#topbar .dropdown {
    padding-left: 10px;
    display: inline-block;
}

#topbar .dropdown:first-child {
    border: none;
    padding-left: 0px;
    padding-right: 10px;
}

#topbar .dropdown .btn {
    height: 50px;
    border: none;
    border-radius: 0px;
    background: none;
    font-size: 13px;
    color: #555555;
    text-transform: uppercase;
    padding: 0 12px;
    outline: none;
    line-height: 50px;
    box-shadow: none;
}

#topbar .dropdown .btn.active,
#topbar .dropdown .ordering span.btn:hover,
.ordering #topbar .dropdown span.btn:hover,
#topbar .dropdown .btn:hover {
    color: #ddd;
}

#topbar .social {
    display: inline-block;
    margin-top: 10px;
}

#topbar .social a {
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    padding: 8px 12px;
}

#topbar .social a:hover i,
#topbar .social a.active i {
    color: #000;
}

#topbar .social a i {
    font-size: 16px;
    color: #555555;
}

@media (max-width: 1024px) {
    #topbar .social a:first-child {
        padding-left: 0px;
    }
}

#topbar .topbar-right {
    display: inline-block;
    float: right;
}

#topbar .topbar-right .sign-in {
    display: inline-block;
    padding: 0;
    height: 55px;
    line-height: 55px;
}

#topbar .topbar-right a {
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 300;
    color: #9d9d9d;
    padding: 0 10px;
    border-right: 1px solid #eee;
}

#topbar .topbar-right a span {
    vertical-align: 1px;
}

#topbar .topbar-right a i {
    font-size: 13px;
    margin-right: 5px;
}

#topbar .topbar-right a:hover,
#topbar .topbar-right a.active {
    color: #000;
}

#topbar .topbar-right .icon-envelope {
    vertical-align: -1px;
}

#topbar .search {
    font-family: "Poppins";
    font-weight: 300;
    color: #9d9d9d;
    font-size: 12px;
    cursor: pointer;
    margin-top: 18px;
}

#topbar .search span {
    vertical-align: 1px;
}

#topbar .search i {
    font-size: 13px;
    margin-right: 5px;
}

#topbar .search.search-v2 i {
    color: #000;
    font-size: 16px;
    margin-right: 10px;
}

.hover-menu {
    position: relative;
}

.hover-menu .list-menu {
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
    right: 0;
    transition: all 0.3s ease;
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    box-shadow: 0px 8px 33px 0px rgba(0, 0, 0, 0.2);
}

.hover-menu:hover .list-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.cart-box-container #close-pushmenu {
    float: left;
    display: inline-block;
    width: 20px;
    height: 30px;

    margin-bottom: 30px;
}

.cart-box-container .cart-bottom {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
}

.input-group {
    margin: 0 auto;
    display: inline-block;
    position: relative;
    width: 100%;
}

.modal-open {
    overflow: visible;
}

.modal-content {
    border-radius: 0px;
    padding: 0px 0px 20px;
}

.modal-header {
    border: none;
}

.modal-title {
    letter-spacing: 4px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 30px;
    position: relative;
}

.modal-title::before {
    position: absolute;
    content: "";
    width: 60px;
    height: 1px;
    background: #000;
    left: 50%;
    margin-left: -40px;
    bottom: -10px;
}

.modal.fade .modal-dialog {
    top: 30%;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
    outline: none;
}

.input-group-btn {
    box-shadow: none;
    position: absolute;
    display: inline-block;
    top: 0px;
    right: 0px;
    width: 40px;
    line-height: 46px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
    z-index: 9999;
}

.modal-body {
    padding: 0 50px;
}

.icon-cart .icon {
    display: inline-block;
    background: url(../images/icon-cart.png) no-repeat;
    width: 23px;
    height: 45px;
}

.icon-cart.ver2 {
    display: table;
}

.icon-cart.ver2 .icon,
.icon-cart.ver2 .text {
    display: table-cell;
}

.icon-cart.ver2 .text {
    padding-left: 10px;
}

.icon-cart.ver2 .text h3 {
    font: 700 14px/15px "Gotham";
    color: #333;
    text-transform: uppercase;
}

.icon-cart.ver2 .text p {
    font: 400 14px/15px "Gotham";
    color: #333;
}

.icon-cart.ver2 .text .price {
    color: #80b435;
}

#topbar .float-right .list-menu.cart-list {
    border: none;
    padding: 0px;
    z-index: 9999;
}

#topbar .float-right .list-menu.cart-list .bottom a {
    font: 700 14px/40px "Gotham";
}

#topbar .float-right .list-menu.cart-list .bottom a:hover {
    color: #fff;
}

#topbar .float-right .list-menu.cart-list .bottom a.rt:hover {
    color: #80b435;
}

.cart-list {
    left: inherit;
    right: 0px;
    min-width: 310px;
    text-align: left;
    padding: 10px 0px 0px;
    background-color: #fff;
    box-shadow: 0px 8px 33px 0px rgba(0, 0, 0, 0.2);
}

.cart-list .cart-product-image {
    width: 70px;
    display: inline-block;
    vertical-align: top;
}

.cart-list .cart-product-image img {
    width: 100%;
}

.cart-list ul {
    padding: 0 20px;
}

.cart-list li {
    padding: 20px 0px;
    border-bottom: 1px solid #eeeeee;
    position: relative;
    list-style-type: none;
}

.cart-list li a,
.cart-list li .text {
    padding: 0px;
    display: inline-block;
    width: auto;
}

.cart-list li a {
    margin-right: 20px;
}

.cart-list li a.close {
    margin: 0px;
    position: absolute;
    bottom: 20px;
    right: 0px;
}

.cart-list li .text .product-name {
    font: 400 16px/20px "Gotham";
    color: #2b2b2b;
}

.cart-list li .text .product-price {
    color: #80b435;
    line-height: 20px;
    font-size: 14px;
    font-family: "Gotham";
}

.cart-list li .text .product-price .price-old {
    font-size: 14px;
    color: #979797;
    text-decoration: line-through;
    margin-right: 10px;
}

.cart-list .total {
    display: block;
    width: 100%;
    padding: 20px 0;
    text-transform: uppercase;
    color: #222;
    padding: 15px 20px;
    font: 700 16px/34px "Gotham";
    float: left;
    width: 100%;
}

.cart-list .total span {
    float: left;
}

.cart-list .total span.right {
    float: right;
    color: #80b435;
    font: 500 18px/34px "Gotham";
}

.cart-list .qty {
    font-family: "Gotham";
    color: #979797;
    text-transform: uppercase;
    font-size: 14px;
}

.cart-list .bottom {
    padding: 20px;
    padding-top: 0px;
    float: left;
    width: 100%;
}

.cart-list .bottom a {
    width: calc(50% - 10px);
    display: inline-block;
    float: right;
}

.cart-list .bottom a:first-child {
    float: left;
}

#header .header-top a.close i.fa {
    color: #333;
    font-size: 18px;
}

#header .header-top a.close:hover i.fa {
    color: #80b435;
}

#header .cart {
    padding-top: 11px;
}

#header .cart .icons {
    color: #000;
    font-size: 18px;
}

#header .cart p {
    position: relative;
    cursor: pointer;
}

#header .cart .cart-count {
    font-size: 11px;
    color: #fff;
    display: inline-block;
    height: 18px;
    width: 18px;
    line-height: 18px;
    text-align: center;
    position: absolute;
    bottom: 10px;
    right: -7px;
    font-family: "Montserrat";
    border-radius: 50%;
    background: #80b435;
}

#header .search {
    position: relative;
}

#header .search .search-form {
    width: 0px;
    height: 40px;
    left: inherit;
    right: 0px;
    top: 0px;
    position: absolute;
}

#header .search .search-form input {
    width: 100%;
    height: 40px;
    box-shadow: none;
    background: #333;
    color: #555555;
    padding: 0 30px 0 15px;
    background-color: #fff;
    position: absolute;
    left: 0px;
    top: 0px;
    border: none;
}

#header .search .search-form .icon-search {
    position: absolute;
    top: 0px;
    right: 0px;
    background: none;
    border: none;
    opacity: 0;
}

#header .search .search-form .icon-search i {
    font-size: 18px;
    color: #888888;
    margin-top: 10px;
    margin-right: 10px;
    height: auto;
}

#header .search i {
    cursor: pointer;
    height: 56px;
}

#header .search i.icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(../images/icon-search-menu.png) no-repeat;
}
#header .box-right {
    float: right;
}

#header .settings {
    display: inline-block;
    float: right;
}

#header .settings .icons-setting {
    display: inline-block;
    width: 20px;
    height: 40px;
}
#header .settings .submenu {
    padding: 30px 0px;
    width: 310px;
    right: 0px;
    left: inherit;
    background: #fff;
}

#header .settings .submenu .container-fluid {
    padding: 0 20px;
}

#header .settings .submenu h3 {
    font: 700 16px/20px "Gotham";
    color: #333;
    text-transform: uppercase;
    margin: 0px;
    margin-bottom: 20px;
}

#header .settings .submenu li {
    list-style: none;
}

#header .settings .submenu ul {
    padding: 0px;
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #e9e8e8;
}

#header .settings .submenu ul li a {
    padding: 0px;
}

#header .settings .submenu ul li a:hover,
#header .settings .submenu ul li a:focus {
    background: none !important;
}

#header .settings .submenu ul.infor {
    padding: 0px;
    margin: 0px;
    border: none;
}

#header .settings .submenu ul.infor .fa {
    color: #333;
    font-size: 16px;
    display: inline-block;
    width: 20px;
    text-align: center;
    margin-right: 5px;
}

#header .settings .submenu ul.infor li {
    display: block;
    line-height: 30px;
    text-transform: uppercase;
    border: none;
}

#header .settings .submenu ul.infor li:hover a {
    color: #80b435;
}

#header .settings .submenu ul.infor li:hover .fa {
    color: #80b435;
}

#header .settings .submenu ul.infor li a {
    font-size: 12px;
    color: #333;
    width: auto;
    display: inline-block;
    font: 400 14px "Gotham";
    color: #333333;
}

#header .settings .submenu ul.language li {
    margin-right: 3px;
    border: 1px solid #e8e8e8;
    opacity: 0.4;
}

#header .settings .submenu ul.language li.active,
#header .settings .submenu ul.language li:hover {
    border-color: #fff;
    opacity: 1;
}

#header .settings .submenu ul.language li:last-child {
    margin-right: 0px;
}

#header .settings .submenu ul.currency li {
    margin-right: 5px;
    width: 35px;
    height: 35px;
    text-align: center;
    background: #fff;
    border: 1px solid #e1e1e1;
}

#header .settings .submenu ul.currency li a {
    display: inline-block;
    width: 35px;
    height: 35px;
}

#header .settings .submenu ul.currency li.active,
#header .settings .submenu ul.currency li:hover {
    background: #80b435;
    border-color: #80b435;
}

#header .settings .submenu ul.currency li.active .fa,
#header .settings .submenu ul.currency li:hover .fa {
    color: #fff;
}

#header .settings .submenu ul.currency li .fa {
    font-size: 16px;
    line-height: 33px;
    color: #333;
}

#header .settings .submenu ul li {
    display: inline-block;
}

.wrappage {
    position: relative;
}

#header.header-top-absolute .header-top {
    position: absolute;
    width: 100%;
    z-index: 99;
}

#header.header-top-absolute .cart {
    margin-right: 50px;
}

#header.header-top-absolute .cart .icons {
    color: #fff;
}

#header.header-top-absolute .cart .cart-count {
    color: #fff;
}

#header.header-top-absolute .header-top {
    padding-top: 45px;
}

#header.header-v1 {
    text-align: center;
}

#header.header-v1 .logo {
    margin: auto;
    display: inline-block;
    width: auto;
}

#header.header-v1 .box-right .cart,
#header.header-v1 .box-right .search {
    float: right;
}

#header.header-v1 #topbar .inner-topbar {
    padding: 25px 0px 15px;
}

@media (max-width: 1200px) {
    #header.header-v1 #topbar .inner-topbar {
        padding-top: 10px;
    }
}

#header.header-v3 .header-top {
    padding: 0px;
}

#header.header-v3.header-v2 .header-top {
    padding: 12px 0px;
}

#header.header-v3.header-v2 .cart:after {
    width: 0px;
}

#header.header-v3.header-v2 .search {
    margin-top: 16px;
}

@media (max-width: 767px) {
    #header.header-v3.header-v2 .header-top {
        margin: 0px;
        padding: 5px 0px 0px;
    }
    #header.header-v3.header-v2 .header-top .cart {
        margin-top: 0px;
    }
    #header .header-top {
        margin-bottom: 0px !important;
    }
}

#header .header-top {
    text-align: center;
    padding: 0px 0px 0px;
}

@media (min-width: 1200px) {
    #header .header-top {
        padding: 0px;
    }
}

#header .header-top .col-md-10 {
    padding-left: 0px;
    position: static;
}

#header .header-top i.fa {
    font-size: 16px;
    color: #232020;
}

#header .header-top .logo {
    float: left;
}

#header .header-top .logo a {
    font-size: 36px;
    color: #fff !important;
    text-transform: uppercase;
}

#header .header-top .logo-mobile {
    display: none;
}

@media (max-width: 1200px) and (min-width: 768px) {
    #header .header-top .logo-mobile {
        margin-top: 12px;
    }
}

#header .header-top .search,
#header .header-top .cart,
#header .header-top .settings {
    float: right;
    display: inline-block;
    vertical-align: top;
    margin-left: 30px;
    margin-top: 5px;
}

#header .header-top .cart {
    margin-left: 15px;
    padding-left: 15px;
    margin-top: 0px;
    position: relative;
}

#header .header-top .cart:after {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    height: 30px;
    width: 1px;
    background: #bfbfbf;
}

#header .header-top .menu-top {
    display: inline-block;
    float: left;
    margin-left: 70px;
}

#header .header-top .menu-top i.fa {
    font-size: 14px;
}

#header .header-top .menu-top a {
    font-size: 14px;
    color: #555555;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 44px;
    padding: 0 5px;
}

#header .header-top .menu-top li {
    display: inline-block;
    padding: 0 20px;
}

#header .header-top .menu-top li.level1 {
    position: relative;
}

#header .header-top .menu-top li:hover a,
#header .header-top .menu-top li:hover i.fa {
    color: #80b435;
}

#header .header-top .menu-top li:hover ul.menu-level2 a {
    color: #888888;
}

#header .header-top .menu-top li .menu-level2 li {
    display: block;
    text-align: center;
}

#header .header-top .menu-top li .menu-level2 li a {
    color: #888888;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 44px;
    width: 100%;
    padding: 5px 0px;
}

#header .header-top .menu-top li .menu-level2 li a:hover {
    color: #ddd;
}

.header-top-absolute .mega-menu ul.navbar-nav li a {
    color: #fff;
}

.mega-menu {
    display: inline-block;
    width: auto;
    position: relative;
    margin: auto;
}

.mega-menu ul.navbar-nav {
    width: 100%;
}

.mega-menu ul.navbar-nav li {
    text-align: left;
    padding: 0 30px;
}

.mega-menu ul.navbar-nav li.level1 {
    padding-top: 6px;
    padding-bottom: 6px;
}

.mega-menu ul.navbar-nav li.level1.dropdown:hover a:before {
    transform: translate(0, 0px);
    opacity: 1;
}
@media (min-width: 1200px) {
    .mega-menu ul.navbar-nav li.level1.dropdown a {
        position: relative;
    }
    .mega-menu ul.navbar-nav li.level1.dropdown a:before {
        position: absolute;
        top: 16px;
        height: 10px;
        width: 10px;
        right: -15px;
        content: "";
        background: url(../images/icon-submenu-1.png) no-repeat scroll center;
    }
}
.mega-menu ul.navbar-nav li.level1.dropdown .level2 a:before {
    height: 0px;
}

.mega-menu ul.navbar-nav li.level1.dropdown .level2 a:before {
    border: none;
}

.mega-menu ul.navbar-nav li.dropdown {
    position: relative;
}

.mega-menu ul.navbar-nav li.dropdown.images {
    position: static;
}

.mega-menu ul.navbar-nav li a {
    text-transform: uppercase;
    -webkit-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    color: #2b2b2b;
    height: 40px;
    padding: 0px;
    font: 700 18px/40px "Gotham";
}

.mega-menu ul.navbar-nav li a:after {
    position: absolute;
    -webkit-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    content: "";
    left: 50%;
    right: 50%;
    height: 0px;
    background: #fff;
    bottom: 5px;
}

.mega-menu ul.navbar-nav li a:hover,
.mega-menu ul.navbar-nav li a.focus {
    background: none !important;
    color: #80b435;
}

.mega-menu ul.navbar-nav li a:hover:after,
.mega-menu ul.navbar-nav li a.focus:after {
    left: 0px;
    right: 0px;
    height: 1px;
}

.mega-menu ul.navbar-nav li:hover ul.menu-level-1 {
    opacity: 1;
}

.mega-menu ul.navbar-nav li.active a:after {
    position: absolute;
    content: "";
    left: 0px;
    right: 0px;
    height: 1px;
    background: #fff;
    bottom: 5px;
}

.mega-menu ul.navbar-nav li.level2 {
    border-bottom: none;
}

.mega-menu ul.navbar-nav ul.menu-level-1 {
    position: absolute;
    box-shadow: 0px 8px 33px 0px rgba(0, 0, 0, 0.13);
    border: none;
    padding: 10px 0px;
    opacity: 0;
    left: 0px;
    min-width: 200px;
    transform: translate(0px, 0px);
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    top: 100%;
    background: #fff;
    z-index: 999;
    /* margin-top: 4px; */
}

@media (max-width: 1024px) {
    .mega-menu ul.navbar-nav ul.menu-level-1 {
        box-shadow: none;
    }
}

.mega-menu ul.navbar-nav ul.menu-level-1.hover {
    transform: translate(0px, -5px);
}

.mega-menu ul.navbar-nav ul.menu-level-1 li a {
    font-size: 13px;
    color: #333333;
    text-transform: capitalize;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    font: 400 14px/40px "Gotham";
    padding-left: 15px;
    position: relative;
    letter-spacing: 0px;
    height: 40px;
    border-bottom: 1px solid #eee;
}

.mega-menu ul.navbar-nav ul.menu-level-1 li a:after {
    position: absolute;
    left: 0px;
    height: 5px;
    width: 5px;
    background: #333;
    top: 15px;
}

.mega-menu ul.navbar-nav ul.menu-level-1 li a:hover {
    color: #80b435;
}

.mega-menu ul.navbar-nav ul.menu-level-1 li a:hover:after {
    background: #80b435;
}

.mega-menu ul.navbar-nav ul.menu-level-1 li:last-child a {
    border: none;
}

.mega-menu ul.navbar-nav ul.menu-level-1 li.level2 {
    position: relative;
    overflow: hidden;
}

.mega-menu ul.navbar-nav ul.menu-level-1 li.level2:hover {
    overflow: visible;
}

.mega-menu ul.navbar-nav ul.menu-level-1 li.level2:hover .menu-level-2 {
    opacity: 1;
    left: calc(100%);
}

.mega-menu ul.navbar-nav ul.menu-level-1 .menu-level-2 {
    position: absolute;
    left: calc(100% + 30px);
    top: 0px;
    opacity: 0;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    padding-top: 10px;
    padding-bottom: 10px;
}

.mega-menu ul.navbar-nav ul.menu-level-1 .menu-level-2 li {
    background: #fff;
    border: none;
    min-width: 230px;
}

.mega-menu ul.navbar-nav ul.menu-level-1 .menu-level-2 li:first-child {
    padding-top: 10px;
}

.mega-menu ul.navbar-nav ul.menu-level-1 .menu-level-2 li:last-child {
    padding-bottom: 5px;
}

.mega-menu ul.navbar-nav .sub-menu {
    -webkit-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    top: 100%;
    background: #fff;
    transform: translate(0px, 20px);
    border: none;
    box-shadow: 0px 8px 33px 0px rgba(0, 0, 0, 0.13);
    left: initial;
}

@media (min-width: 1200px) {
    .mega-menu ul.navbar-nav .sub-menu {
        width: 940px;
        right: calc(50% - 600px);
    }
    .mega-menu ul.navbar-nav .sub-menu.sub-menu-v2 {
        width: 1200px;
    }
}

@media (max-width: 1024px) {
    .mega-menu ul.navbar-nav .sub-menu {
        box-shadow: none;
    }
}

.mega-menu ul.navbar-nav .sub-menu.hover {
    transform: translate(0px, 0px);
}

.mega-menu ul.navbar-nav .sub-menu.sub-menu-v2 .right-submenu {
    display: none;
}

@media (min-width: 1200px) {
    .mega-menu ul.navbar-nav .sub-menu.sub-menu-v2 {
        padding-top: 20px;
        float: left;
    }
    .mega-menu ul.navbar-nav .sub-menu.sub-menu-v2 .menu-level-1 {
        float: left;
        width: 100%;
    }
    .mega-menu ul.navbar-nav .sub-menu.sub-menu-v2 .menu-level-1 .level2 {
        width: 25%;
        float: left;
    }
    .mega-menu ul.navbar-nav .sub-menu.sub-menu-v2 .right-submenu {
        float: right;
        width: 33.3333%;
        display: inline-block;
        padding-top: 25px;
    }
}

.mega-menu ul.navbar-nav .sub-menu .top-sub-menu {
    padding: 30px;
    display: inline-block;
    width: 100%;
    padding-bottom: 0px;
}

.mega-menu ul.navbar-nav .sub-menu .top-sub-menu img {
    max-width: 100%;
}

.mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 {
    background: #fff;
    display: inline-block;
    width: 100%;
    position: relative;
    top: 0px;
    border: none;
    margin-top: 0px;
    padding-top: 0px;
    box-shadow: none;
}

.mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level2 {
    display: inline-block;
    float: left;
    border: none;
    text-align: left;
    width: 24%;
    padding-top: 30px;
}

.mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level2.images {
    padding-top: 0px;
    width: auto;
}

.mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level2.images img {
    max-width: 300px;
}

.mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level2:hover .menu-level-2 {
    left: 0px;
}

.mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level2 a {
    height: auto;
    background: none !important;
    padding: 0px;
    text-transform: uppercase;
    font: 700 16px/30px "Gotham";
    color: #333;
    border: none;
}

.mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level2 a:after {
    width: 0px;
}

.mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level3 {
    border: none;
    width: 100%;
    text-align: left;
    padding: 0px;
    border-top: 1px dotted #e8e8e8;
}

.mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level3:last-child {
    border-bottom: none;
}

.mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level3:first-child {
    margin-top: 10px;
}

.mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level3 a {
    color: #666;
    text-transform: capitalize;
    font: 400 14px/40px "Gotham";
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    position: relative;
}

.mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level3 a:hover {
    color: #80b435;
}

.mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level3 a:hover:after {
    background: #80b435;
}

.mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 ul.menu-level-2 {
    padding-top: 0px;
    position: relative;
    left: 0px;
    opacity: 1;
}

.icon-menu-mobile,
.mega-menu ul.navbar-nav li i {
    display: none;
}

.slidershow-container {
    margin-bottom: 50px;
}

.slidershow-container.slidershow-container-v2 {
    margin-bottom: 0px;
}

.slidershow-container .container {
    width: 100%;
    padding: 0px;
}

.slidershow-container .container img {
    width: 100%;
}

/** 6.2.2. Header version 2 **/
.mega-menu.mega-menu-v2 {
    box-shadow: none;
    border-bottom: 1px solid #eeeeee;
}

.megamenu-v2 .search-form {
    position: relative;
}

.megamenu-v2 .search-form input {
    width: 100%;
    height: 40px;
    padding: 0 40px 0 20px;
    border-radius: 5px;
    background: transparent;
    border: 1px solid #605d5e;
    color: #888;
    font-family: "Lora";
    font-style: italic;
}

.megamenu-v2 .search-form button {
    background: transparent;
    color: #ddd;
    position: absolute;
    border: none;
    top: 10px;
    right: 10px;
}

.megamenu-v2 .search-form i {
    font-size: 18px;
}

.megamenu-v2.header-top .cart .cart-count {
    position: static;
    width: auto;
    height: auto;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    font-size: 14px;
    font-style: italic;
    margin: 0px 10px;
}

.megamenu-v2.header-top .cart a {
    color: #ddd;
    width: 100%;
    text-align: center;
}

.megamenu-v2.header-top .cart i {
    font-size: 20px;
}

#header {
    position: relative;
}

#header .mega-menu.menu-v3 {
    position: static;
}

/** 6.2.3. Header version 2 **/
#header.absolute {
    position: absolute;
    top: 15px;
    left: 0px;
    z-index: 999;
    width: 100%;
    padding-top: 30px;
    text-align: center;
}

@media (min-width: 1200px) {
    #header.absolute {
        padding-left: 30px;
        padding-right: 30px;
    }
}

#header.absolute .logo {
    margin: auto;
    float: inherit;
    display: inline-block;
}

#header.absolute .float-left {
    margin-top: 15px;
}

#header.absolute .container {
    background: none;
}

/** 6.2.4. Header version 3 **/
#header.header-v3-new {
    box-shadow: 0px 0px 10px 0px rgba(35, 31, 32, 0.15);
}

#header.header-v3-new #topbar .container {
    position: relative;
}

#header.header-v3-new #topbar .container .inner-topbar {
    padding-left: 200px;
}

@media (max-width: 1200px) {
    #header.header-v3-new #topbar .container .inner-topbar {
        padding-left: 0px;
    }
}

@media (min-width: 1200px) {
    #header.header-v3-new .mega-menu {
        margin-top: 10px;
        float: left;
        padding-left: 180px;
    }
    #header.header-v3-new .mega-menu ul.navbar-nav li.level1 {
        padding-top: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }
    #header.header-v3-new .mega-menu ul.navbar-nav .sub-menu {
        right: calc(50% - 785px);
    }
}

#header.header-v3-new #topbar .inner-topbar {
    border: none;
    position: relative;
}

#header.header-v3-new #topbar .inner-topbar:after {
    position: absolute;
    content: "";
    left: 200px;
    right: 0px;
    height: 1px;
    background-color: #cdcdcd;
    bottom: 0px;
}

@media (max-width: 1200px) {
    #header.header-v3-new #topbar .inner-topbar:after {
        left: 0px;
    }
}

#header.header-v3-new.header-v3 .header-top {
    padding: 15px 0px 23px;
    margin-bottom: 30px;
}

#header.header-v3-new.header-v3 .header-top .search {
    margin-top: 17px;
}

#header.header-v3-new.header-v3 .header-top .cart:after {
    width: 0px;
}

#header.header-v3-new .logo-header-v3 {
    position: absolute;
    left: 15px;
    top: 0px;
}

@media (max-width: 1200px) {
    #header.header-v3-new .logo-header-v3 {
        display: none;
    }
}

#header.header-v4 .float-left {
    margin-top: 10px;
}

.categories-home3 h3 {
    font: 700 24px/60px "Gotham";
    color: #2b2b2b;
    text-transform: uppercase;
}

.categories-home3 ul li {
    font: 400 18px/60px "Gotham";
    color: #666;
    text-transform: capitalize;
    border-top: 1px dotted #cdcdcd;
}

.categories-home3 ul li a {
    color: #666;
}

.categories-home3 ul li a:hover {
    color: #80b435;
}

@media (max-width: 1200px) and (min-width: 768px) {
    .box-cat-home3 {
        margin-bottom: 30px;
    }
    .box-cat-home3 .col-md-3 {
        width: 25%;
        float: left;
    }
    .box-cat-home3 .col-md-9 {
        width: 75%;
        float: left;
    }
    .box-cat-home3 h3 {
        font: 700 20px/30px "Gotham";
        padding-bottom: 8px;
        margin-top: -10px;
    }
    .box-cat-home3 ul li {
        font: 400 16px/38px "Gotham";
    }
}

@media (max-width: 1200px) and (min-width: 980px) {
    .box-cat-home3 h3 {
        font: 700 20px/40px "Gotham";
    }
    .box-cat-home3 ul li {
        font: 400 16px/45px "Gotham";
    }
}

.icon-head4 {
    margin-left: 30px;
}

/** 6.2.6. Header version 5 **/
.map-icon-label {
    width: 44px;
    height: 48px;
}

.map-icon {
    width: 100%;
    height: 100%;
    display: inline-block;
    text-align: center;
    line-height: 38px;
    color: #fff;
}

.map-icon-point-of-interest:before {
    content: "Uno";
    font-family: "Blanch";
    font-size: 30px;
}

.map-icon-point-of-interest.map-icon-3:before {
    content: "";
    font-family: "Simple-Line-Icons";
    font-size: 20px;
}

.map-icon-point-of-interest.map-icon-6:before {
    content: "";
    font-family: "Simple-Line-Icons";
    font-size: 20px;
    color: #000;
}

#back-to-top {
    position: fixed;
    bottom: 30px;
    right: 5px;
    z-index: 9999;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 30px;
    background: #f5f5f5;
    color: #444;
    cursor: pointer;
    border: 0;
    border-radius: 2px;
    text-decoration: none;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    transform: translate(50px, 0px);
    z-index: 9999;
}

#back-to-top i {
    color: #888888;
    display: inline-block;
    margin-top: 13px;
}

#back-to-top:hover {
    background: #e9ebec;
}

#back-to-top.show {
    transform: translate(0px, 0px);
}

#content {
    height: 2000px;
}

.pushmenu-push-toleft {
    left: 364px;
}

@media (min-width: 1500px) {
    .pushmenu-push-toleft {
        left: 445px;
    }
}

.pushmenu-push-toleft .wrappage:after {
    background: rgba(29, 29, 31, 0.6);
    content: "";
    cursor: pointer;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 9999;
}

.menu-home6 {
    left: -364px;
}

@media (min-width: 1500px) {
    .menu-home6 {
        left: -445px;
    }
}

.menu-home6.pushmenu-open {
    left: 0px;
}

.icon-pushmenu {
    font: 700 14px/20px "Oswald";
    color: #000;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
}

.icon-pushmenu:hover,
.icon-pushmenu.active,
.ordering span.icon-pushmenu:hover {
    color: #80b435;
}

.search-menu {
    position: relative;
    margin-top: 50px;
}

.search-menu input {
    width: 100%;
    height: 50px;
    border: 1px solid #cdcdcd;
    font: 400 16px/50px "Gotham";
    padding-right: 55px;
}

.search-menu button {
    position: absolute;
    right: 3px;
    top: 3px;
    height: 44px;
    width: 50px;
    background: #333 url(../images/icon-search-menu.png) no-repeat scroll center;
    border: none;
    transition: all 0.3s ease 0s;
}

.search-menu button:hover {
    background-color: #80b435;
}

@media (min-width: 1200px) {
    .cart.hover-menu .list-menu,
    #header .settings .submenu {
        right: -50px;
    }
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0, 100%);
    -ms-transform: translate(0, 100%);
    -o-transform: translate(0, 100%);
    transform: translate(0, 100%);
}

.control-search {
    border: 1px solid #e6e6e6;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
    font-size: 18px;
    font-family: "Gotham";
    color: #d9d9d9;
    box-shadow: none;
    outline: none;
}

button.button_search {
    background: #333;
    font: 700 16px/43px "Gotham";
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    width: 130px;
    padding: 0px;
    border: none;
    position: absolute;
    right: 2px;
    top: 2px;
    transition: all 0.4s ease 0s;
    z-index: 99;
}

button.button_search:hover {
    background: #80b435;
}

.popup-search {
    position: relative;
    padding: 90px 0px;
}

.popup-search .close {
    position: absolute;
    right: 20px;
    top: 20px;
}

@media (min-width: 1200px) {
    .modal-lg {
        width: 1170px;
    }
}

.categories-home3 .menu-vertical img {
    margin-right: 10px;
}

@media (max-width: 1200px) and (min-width: 768px) {
    .tp-banner-container.ver1 {
        margin-top: -10px;
    }
    #header.header-v1 #topbar .inner-topbar {
        border: none;
    }
    #header.header-v1 .header-top {
        display: inline-block;
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

/** 6.3. Sidebar **/
/** 6.4. Contents **/
/** 6.4.1. CMS default **/
/** 6.4.2. Blog **/
/** 6.5. Footers **/
#footer.footer-v1 {
    text-align: center;
}

#footer.footer-v1 .social {
    float: right;
}

#footer.footer-v1 .social a {
    margin: 0 8px;
}

#footer.footer-v1 .social a:hover i {
    color: #333;
}

#footer.footer-v1 .social a i {
    color: #80b435;
}

#footer.footer-v1 .social a:last-child {
    margin-right: 0px;
}

@media (min-width: 1200px) {
    #footer.footer-v1 .social {
        margin-right: 50px;
        margin-bottom: 0px;
        margin-top: 20px;
    }
}

#footer.footer-v3 {
    background: #f3f3f3;
    margin-top: 200px;
}

#footer.footer-v3 .footer-inner {
    position: relative;
    padding-top: 80px;
    padding-bottom: 50px;
}

#footer.footer-v3 .footer-inner:after {
    position: absolute;
    height: 200px;
    width: 100%;
    background: url(../images/bg-footer.jpg) no-repeat scroll center bottom;
    content: "";
    left: 0px;
    bottom: calc(100% - 3px);
}

@media (max-width: 767px) {
    #footer.footer-v3 .footer-inner:after {
        left: -15px;
        right: -15px;
        width: auto;
    }
}

#footer.footer-v3 p {
    color: #666;
    font: 400 14px/24px "Gotham";
}

#footer.footer-v3 .bottom {
    padding: 15px 0px 10px;
}

#footer.footer-v3 .bottom p {
    font: 400 13px/20px "Gotham";
    color: #898989;
}

@media (max-width: 767px) {
    #footer.footer-v3 {
        margin-top: 100px;
    }
}

#footer .footer-top {
    padding: 30px 0px;
    display: inline-block;
    width: 100%;
}

#footer .logo-footer {
    float: left;
    text-align: left;
    margin-left: 50px;
}

#footer .logo-footer img {
    max-width: 150px;
}

#footer .logo-footer p {
    color: #80b435;
    font: 400 12px/15px "Gotham";
    margin-top: 10px;
}

#footer .info-footer {
    margin-bottom: 30px;
}

#footer .info-footer li {
    color: #fff;
    line-height: 50px;
    font: 400 14px "Gotham";
    padding: 5px 0px;
}

#footer .info-footer li a {
    color: #fff;
}

#footer .info-footer li a:hover {
    color: #80b435;
}

#footer .info-footer li img {
    margin-right: 10px;
}

#footer .title-footer {
    font: 400 14px "Gotham";
    color: #80b435;
    padding-bottom: 10px;
    margin-bottom: 20px;
    position: relative;
    text-transform: capitalize;
}
#footer .list-footer {
    padding-left: 0px;
}
#footer .list-footer li {
    color: #666;
    font: 400 14px/20px "Gotham";
    padding: 5px 0px;
    list-style-type: none;
}

#footer .list-footer li:first-child {
    padding-top: 0px;
}

#footer .list-footer li a {
    color: #666;
    font: 400 14px/20px "Gotham";
}

#footer .list-footer li a:hover {
    color: #80b435;
}

#footer .list-footer.border li {
    border-bottom: 1px solid #404040;
}

#footer .list-footer.border li:last-child {
    border-bottom: none;
}

#footer .footer-bottom {
    border-top: 1px solid #e3e3e3;
}

@media (max-width: 980px) {
    #footer .footer-bottom {
        text-align: center;
    }
    #footer .footer-bottom .float-left,
    #footer .footer-bottom .float-right {
        float: initial;
    }
    #footer .footer-bottom .float-left {
        margin-bottom: 10px;
    }
}

#footer .subscribe {
    margin-top: 20px;
}

#footer .subscribe input {
    width: 100%;
    height: 50px;
    border: 1px solid #e5e5e5;
    padding: 0px 15px;
    font: 300 12px "Gotham";
    color: #898989;
}

#footer .subscribe button {
    display: inline-block;
    background: #80b435;
    color: #fff;
    border: 1px solid #80b435;
    transiton: all 0.3s ease 0s;
    font: 700 12px/35px "Gotham";
    text-transform: uppercase;
    letter-spacing: 0px;
    width: 140px;
    margin-top: 20px;
}

#footer .subscribe button i {
    color: #fff;
    margin-left: 8px;
}

#footer .subscribe button:hover {
    background: #80b435;
}

#footer .menu-footer {
    display: inline-block;
    margin-top: 20px;
}

#footer .menu-footer li {
    display: inline-block;
}

#footer .menu-footer li a {
    text-transform: uppercase;
    margin: 0px 30px;
    position: relative;
    padding-bottom: 3px;
    font: 700 14px/20px "Gotham";
    color: #2b2b2b;
}

#footer .menu-footer li a:hover {
    color: #80b435;
}

#footer .social {
    margin-bottom: 30px;
}

#footer .social li {
    display: inline-block;
}

#footer .social a {
    margin: 0 12px;
}

#footer .social a:first-child {
    margin-left: 0px;
}

#footer .social a i {
    font-size: 20px;
    color: #666;
}

#footer .social a:hover i {
    color: #80b435;
}

#footer .footer-bottom {
    display: inline-block;
    width: 100%;
}

#footer .footer-bottom p {
    font-family: "Poppins";
    font-weight: 300;
    color: #8d8c8c;
    font-size: 12px;
    line-height: 20px;
}

#footer .footer-bottom p a {
    color: #f56666;
    text-decoration: underline;
}

#footer .footer-bottom .language {
    display: inline-block;
    padding-left: 20px;
}

#footer .footer-bottom .language span,
#footer .footer-bottom .language p,
#footer .footer-bottom .language a {
    font: 400 12px/20px "Gotham";
    color: #666;
}

#footer .footer-bottom .language .hover-menu {
    display: inline-block;
    width: 50px;
    text-align: center;
}

#footer .footer-bottom .language .hover-menu p {
    color: #80b435;
}

#footer .footer-bottom .language .hover-menu p i {
    color: #666;
}

#footer .footer-bottom .language .hover-menu .list-menu {
    bottom: 25px;
    width: 50px;
    padding: 5px 10px;
    text-align: center;
    background: #fff;
}

#footer .footer-bottom .menu-bottom-footer {
    position: relative;
    display: inline-block;
    padding-right: 10px;
}

#footer .footer-bottom .menu-bottom-footer li {
    display: inline-block;
    padding: 0px 10px;
}

#footer .footer-bottom .menu-bottom-footer a {
    color: #666;
    font: 400 12px/20px "Gotham";
}

#footer .footer-bottom .menu-bottom-footer a:hover {
    color: #80b435;
}

#footer .footer-bottom .menu-bottom-footer:after {
    position: absolute;
    content: "";
    right: 0px;
    width: 1px;
    height: 19px;
    background: #cdcdcd;
    top: 3px;
}

#footer .newsletter-footer {
    display: inline-block;
    float: right;
    margin-right: 50px;
}

#footer .newsletter-footer input {
    font: 300 12px/40px "Poppins";
    color: #8d8c8c;
    width: 100%;
    padding: 0px 80px 0px 20px;
    border: 1px solid #e4e4e4;
    box-shadow: none;
}

#footer .newsletter-footer form {
    position: relative;
    width: 510px;
    height: 45px;
}

#footer .newsletter-footer button {
    position: absolute;
    height: 41px;
    right: 0px;
    top: 0px;
    box-shadow: none;
    border: none;
    border-left: 1px solid #e4e4e4;
    font-size: 12px;
}

/** 6.6. Home pages **/
/** 6.6.1 Home page 2 **/
.mega-menu.color-home2 ul.navbar-nav li.active a,
.mega-menu.color-home2 ul.navbar-nav li a:hover,
.mega-menu.color-home2 ul.navbar-nav ul.menu-level-1 li a:hover {
    color: #808f66;
}

.mega-menu.color-home2 ul.navbar-nav .sub-menu .top-sub-menu .item .image {
    background: #808f66;
}

.color-home2 .tp-bullets.simplebullets.round .bullet {
    border: 2px solid #808f66;
}

.color-home2 .owl-carousel .owl-controls .owl-nav .owl-prev:hover,
.color-home2 .owl-carousel .owl-controls .owl-nav .owl-next:hover {
    box-shadow: 0 0 0 30px #808f66 inset;
}

.mega-menu.color-home2 ul.navbar-nav .sub-menu .top-sub-menu {
    border-bottom: 1px solid #808f66;
}

.mega-menu.color-home2 ul.navbar-nav .sub-menu ul.menu-level-1 ul.menu-level-2::after {
    background: #808f66;
}

.mega-menu.color-home2 ul.navbar-nav .sub-menu ul.menu-level-1 li.level3 a:hover {
    color: #808f66;
}

.mega-menu.color-home2 ul.navbar-nav .sub-menu .bottom-sub-menu {
    border-top: 1px solid #808f66;
}

.color-home2.tp-banner-container .tp-rightarrow:hover,
.color-home2.tp-banner-container .tp-leftarrow:hover {
    box-shadow: 0 0 0 30px #808f66 inset;
}

#footer.color-home2 h3::after {
    background: #808f66;
}

#footer.color-home2 .footer-top .col-md-6 a {
    border: 2px solid #808f66;
    color: #808f66;
}

#footer.color-home2 .footer-top ul li a:hover,
#footer.color-home2 .footer-bottom .col-md-6 span {
    color: #808f66;
}

#footer.color-home2 .footer-top .col-md-6 a:hover {
    background: #808f66;
    color: #fff;
}

#footer.color-home2 .footer-top-bottom a.color {
    color: #808f66;
}

.button1.color-home2 {
    background: #808f66;
    border: 2px solid #808f66;
}

.button1.color-home2:hover {
    background: transparent;
    color: #808f66;
}

.color-home2 .tp-bullets.simplebullets.round .bullet {
    border: 2px solid #808f66;
}

.color-home2 .tp-bullets.simplebullets.round .bullet.selected,
.color-home2 .tp-bullets.simplebullets.round .bullet:hover {
    background: #808f66;
    border: 2px solid #808f66;
}

.blog-post-container.color-home2 .blog-post-item h3 a:hover {
    color: #808f66;
}

.blog-post-container.color-home2 .blog-post-item .blog-post-image .action .icons {
    box-shadow: 0 0 0 30px #808f66 inset;
}

.blog-post-container.color-home2 .blog-post-item .blog-post-image .action .icons:hover {
    box-shadow: 0 0 0 2px #808f66 inset;
    color: #808f66 !important;
}

.blog-post-container.color-home2 .blog-post-item .blog-post-image .action .icons {
    background: #808f66;
}

#header.color-home2 .cart .cart-count {
    background-color: #808f66;
}

.policy.color-home2 ul li a:hover i {
    background: #808f66;
}

#topbar.color-home2 .social a:hover i,
#topbar.color-home2 .social a.active i {
    color: #808f66;
}

#topbar.color-home2 .topbar-right a:hover,
#topbar.color-home2 .topbar-right a.active,
#topbar.color-home2 .dropdown .btn:hover {
    color: #808f66 !important;
}

.mega-menu.color-home3 ul.navbar-nav li.active a,
.mega-menu.color-home3 ul.navbar-nav li a:hover,
.mega-menu.color-home3 ul.navbar-nav ul.menu-level-1 li a:hover {
    color: #6dc5ee;
}

.mega-menu.color-home3 ul.navbar-nav .sub-menu .top-sub-menu .item .image {
    background: #6dc5ee;
}

.color-home3 .tp-bullets.simplebullets.round .bullet {
    border: 2px solid #6dc5ee;
}

.color-home3 .owl-carousel .owl-controls .owl-nav .owl-prev:hover,
.color-home3 .owl-carousel .owl-controls .owl-nav .owl-next:hover {
    box-shadow: 0 0 0 30px #6dc5ee inset;
}

.mega-menu.color-home3 ul.navbar-nav .sub-menu .top-sub-menu {
    border-bottom: 1px solid #6dc5ee;
}

.mega-menu.color-home3 ul.navbar-nav .sub-menu ul.menu-level-1 ul.menu-level-2::after {
    background: #6dc5ee;
}

.mega-menu.color-home3 ul.navbar-nav .sub-menu ul.menu-level-1 li.level3 a:hover {
    color: #6dc5ee;
}

.mega-menu.color-home3 ul.navbar-nav .sub-menu .bottom-sub-menu {
    border-top: 1px solid #6dc5ee;
}

.color-home3.tp-banner-container .tp-rightarrow:hover,
.color-home3.tp-banner-container .tp-leftarrow:hover {
    box-shadow: 0 0 0 30px #6dc5ee inset;
}

#footer.color-home3 h3::after {
    background: #6dc5ee;
}

#footer.color-home3 .footer-top .col-md-6 a {
    border: 2px solid #6dc5ee;
    color: #6dc5ee;
}

#footer.color-home3 .footer-top ul li a:hover,
#footer.color-home3 .footer-bottom .col-md-6 span {
    color: #6dc5ee;
}

#footer.color-home3 .footer-top .col-md-6 a:hover {
    background: #6dc5ee;
    color: #fff;
}

#footer.color-home3 .footer-top-bottom a.color {
    color: #6dc5ee;
}

.button1.color-home3 {
    background: #6dc5ee;
    border: 2px solid #6dc5ee;
}

.button1.color-home3:hover {
    background: transparent;
    color: #6dc5ee;
}

.color-home3 .tp-bullets.simplebullets.round .bullet {
    border: 2px solid #6dc5ee;
}

.color-home3 .tp-bullets.simplebullets.round .bullet.selected,
.color-home3 .tp-bullets.simplebullets.round .bullet:hover {
    background: #6dc5ee;
    border: 2px solid #6dc5ee;
}

.blog-post-container.color-home3 .blog-post-item h3 a:hover {
    color: #6dc5ee;
}

.blog-post-container.color-home3 .blog-post-item .blog-post-image .action .icons {
    box-shadow: 0 0 0 30px #6dc5ee inset;
}

.blog-post-container.color-home3 .blog-post-item .blog-post-image .action .icons:hover {
    box-shadow: 0 0 0 2px #6dc5ee inset;
    color: #6dc5ee !important;
}

.blog-post-container.color-home3 .blog-post-item .blog-post-image .action .icons {
    background: #6dc5ee;
}

#header.color-home3 .cart .cart-count {
    background-color: #6dc5ee;
}

.policy.color-home3 ul li a:hover i {
    background: #6dc5ee;
}

#topbar.color-home3 .social a:hover i,
#topbar.color-home3 .social a.active i {
    color: #6dc5ee;
}

#topbar.color-home3 .topbar-right a:hover,
#topbar.color-home3 .topbar-right a.active,
#topbar.color-home3 .dropdown .btn:hover {
    color: #6dc5ee !important;
}

.mega-menu.color-home4 ul.navbar-nav li.active a,
.mega-menu.color-home4 ul.navbar-nav li a:hover,
.mega-menu.color-home4 ul.navbar-nav ul.menu-level-1 li a:hover {
    color: #12a170;
}

.mega-menu.color-home4 ul.navbar-nav .sub-menu .top-sub-menu .item .image {
    background: #12a170;
}

.color-home4 .tp-bullets.simplebullets.round .bullet {
    border: 2px solid #12a170;
}

.color-home4 .owl-carousel .owl-controls .owl-nav .owl-prev:hover,
.color-home4 .owl-carousel .owl-controls .owl-nav .owl-next:hover {
    box-shadow: 0 0 0 30px #12a170 inset;
}

.mega-menu.color-home4 ul.navbar-nav .sub-menu .top-sub-menu {
    border-bottom: 1px solid #12a170;
}

.mega-menu.color-home4 ul.navbar-nav .sub-menu ul.menu-level-1 ul.menu-level-2::after {
    background: #12a170;
}

.mega-menu.color-home4 ul.navbar-nav .sub-menu ul.menu-level-1 li.level3 a:hover {
    color: #12a170;
}

.mega-menu.color-home4 ul.navbar-nav .sub-menu .bottom-sub-menu {
    border-top: 1px solid #12a170;
}

.color-home4.tp-banner-container .tp-rightarrow:hover,
.color-home4.tp-banner-container .tp-leftarrow:hover {
    box-shadow: 0 0 0 30px #12a170 inset;
}

#footer.color-home4 h3::after {
    background: #12a170;
}

#footer.color-home4 .footer-top .col-md-6 a {
    border: 2px solid #12a170;
    color: #12a170;
}

#footer.color-home4 .footer-top ul li a:hover,
#footer.color-home4 .footer-bottom .col-md-6 span {
    color: #12a170;
}

#footer.color-home4 .footer-top .col-md-6 a:hover {
    background: #12a170;
    color: #fff;
}

#footer.color-home4 .footer-top-bottom a.color {
    color: #12a170;
}

.button1.color-home4 {
    background: #12a170;
    border: 2px solid #12a170;
}

.button1.color-home4:hover {
    background: transparent;
    color: #12a170;
}

.color-home4 .tp-bullets.simplebullets.round .bullet {
    border: 2px solid #12a170;
}

.color-home4 .tp-bullets.simplebullets.round .bullet.selected,
.color-home4 .tp-bullets.simplebullets.round .bullet:hover {
    background: #12a170;
    border: 2px solid #12a170;
}

.blog-post-container.color-home4 .blog-post-item h3 a:hover {
    color: #12a170;
}

.blog-post-container.color-home4 .blog-post-item .blog-post-image .action .icons {
    box-shadow: 0 0 0 30px #12a170 inset;
}

.blog-post-container.color-home4 .blog-post-item .blog-post-image .action .icons:hover {
    box-shadow: 0 0 0 2px #12a170 inset;
    color: #12a170 !important;
}

.blog-post-container.color-home4 .blog-post-item .blog-post-image .action .icons {
    background: #12a170;
}

#header.color-home4 .cart .cart-count {
    background-color: #12a170;
}

.policy.color-home4 ul li a:hover i {
    background: #12a170;
}

#topbar.color-home4 .social a:hover i,
#topbar.color-home4 .social a.active i {
    color: #12a170;
}

#topbar.color-home4 .topbar-right a:hover,
#topbar.color-home4 .topbar-right a.active,
#topbar.color-home4 .dropdown .btn:hover {
    color: #12a170 !important;
}

.mega-menu.color-home6 ul.navbar-nav li.active a,
.mega-menu.color-home6 ul.navbar-nav li a:hover,
.mega-menu.color-home6 ul.navbar-nav ul.menu-level-1 li a:hover {
    color: #c03838;
}

.mega-menu.color-home6 ul.navbar-nav .sub-menu .top-sub-menu .item .image {
    background: #c03838;
}

.color-home6 .tp-bullets.simplebullets.round .bullet {
    border: 2px solid #c03838;
}

.color-home6 .owl-carousel .owl-controls .owl-nav .owl-prev:hover,
.color-home6 .owl-carousel .owl-controls .owl-nav .owl-next:hover {
    box-shadow: 0 0 0 30px #c03838 inset;
}

.mega-menu.color-home6 ul.navbar-nav .sub-menu .top-sub-menu {
    border-bottom: 1px solid #c03838;
}

.mega-menu.color-home6 ul.navbar-nav .sub-menu ul.menu-level-1 ul.menu-level-2::after {
    background: #c03838;
}

.mega-menu.color-home6 ul.navbar-nav .sub-menu ul.menu-level-1 li.level3 a:hover {
    color: #c03838;
}

.mega-menu.color-home6 ul.navbar-nav .sub-menu .bottom-sub-menu {
    border-top: 1px solid #c03838;
}

.color-home6.tp-banner-container .tp-rightarrow:hover,
.color-home6.tp-banner-container .tp-leftarrow:hover {
    box-shadow: 0 0 0 30px #c03838 inset;
}

#footer.color-home6 h3::after {
    background: #c03838;
}

#footer.color-home6 .footer-top .col-md-6 a {
    border: 2px solid #c03838;
    color: #c03838;
}

#footer.color-home6 .footer-top ul li a:hover,
#footer.color-home6 .footer-bottom .col-md-6 span {
    color: #c03838;
}

#footer.color-home6 .footer-top .col-md-6 a:hover {
    background: #c03838;
    color: #fff;
}

#footer.color-home6 .footer-top-bottom a.color {
    color: #c03838;
}

.button1.color-home6 {
    background: #c03838;
    border: 2px solid #c03838;
}

.button1.color-home6:hover {
    background: transparent;
    color: #c03838;
}

.color-home6 .tp-bullets.simplebullets.round .bullet {
    border: 2px solid #c03838;
}

.color-home6 .tp-bullets.simplebullets.round .bullet.selected,
.color-home6 .tp-bullets.simplebullets.round .bullet:hover {
    background: #c03838;
    border: 2px solid #c03838;
}

.blog-post-container.color-home6 .blog-post-item h3 a:hover {
    color: #c03838;
}

.blog-post-container.color-home6 .blog-post-item .blog-post-image .action .icons {
    box-shadow: 0 0 0 30px #c03838 inset;
}

.blog-post-container.color-home6 .blog-post-item .blog-post-image .action .icons:hover {
    box-shadow: 0 0 0 2px #c03838 inset;
    color: #c03838 !important;
}

.blog-post-container.color-home6 .blog-post-item .blog-post-image .action .icons {
    background: #c03838;
}

#header.color-home6 .cart .cart-count {
    background-color: #c03838;
}

.policy.color-home6 ul li a:hover i {
    background: #c03838;
}

#topbar.color-home6 .social a:hover i,
#topbar.color-home6 .social a.active i {
    color: #c03838;
}

#topbar.color-home6 .topbar-right a:hover,
#topbar.color-home6 .topbar-right a.active,
#topbar.color-home6 .dropdown .btn:hover {
    color: #c03838 !important;
}

.mega-menu.color-home7 ul.navbar-nav li.active a,
.mega-menu.color-home7 ul.navbar-nav li a:hover,
.mega-menu.color-home7 ul.navbar-nav ul.menu-level-1 li a:hover {
    color: #fe8989;
}

.mega-menu.color-home7 ul.navbar-nav .sub-menu .top-sub-menu .item .image {
    background: #fe8989;
}

.color-home7 .tp-bullets.simplebullets.round .bullet {
    border: 2px solid #fe8989;
}

.color-home7 .owl-carousel .owl-controls .owl-nav .owl-prev:hover,
.color-home7 .owl-carousel .owl-controls .owl-nav .owl-next:hover {
    box-shadow: 0 0 0 30px #fe8989 inset;
}

.mega-menu.color-home7 ul.navbar-nav .sub-menu .top-sub-menu {
    border-bottom: 1px solid #fe8989;
}

.mega-menu.color-home7 ul.navbar-nav .sub-menu ul.menu-level-1 ul.menu-level-2::after {
    background: #fe8989;
}

.mega-menu.color-home7 ul.navbar-nav .sub-menu ul.menu-level-1 li.level3 a:hover {
    color: #fe8989;
}

.mega-menu.color-home7 ul.navbar-nav .sub-menu .bottom-sub-menu {
    border-top: 1px solid #fe8989;
}

.color-home7.tp-banner-container .tp-rightarrow:hover,
.color-home7.tp-banner-container .tp-leftarrow:hover {
    box-shadow: 0 0 0 30px #fe8989 inset;
}

#footer.color-home7 h3::after {
    background: #fe8989;
}

#footer.color-home7 .footer-top .col-md-6 a {
    border: 2px solid #fe8989;
    color: #fe8989;
}

#footer.color-home7 .footer-top ul li a:hover,
#footer.color-home7 .footer-bottom .col-md-6 span {
    color: #fe8989;
}

#footer.color-home7 .footer-top .col-md-6 a:hover {
    background: #fe8989;
    color: #fff;
}

#footer.color-home7 .footer-top-bottom a.color {
    color: #fe8989;
}

.button1.color-home7 {
    background: #fe8989;
    border: 2px solid #fe8989;
}

.button1.color-home7:hover {
    background: transparent;
    color: #fe8989;
}

.color-home7 .tp-bullets.simplebullets.round .bullet {
    border: 2px solid #fe8989;
}

.color-home7 .tp-bullets.simplebullets.round .bullet.selected,
.color-home7 .tp-bullets.simplebullets.round .bullet:hover {
    background: #fe8989;
    border: 2px solid #fe8989;
}

.blog-post-container.color-home7 .blog-post-item h3 a:hover {
    color: #fe8989;
}

.blog-post-container.color-home7 .blog-post-item .blog-post-image .action .icons {
    box-shadow: 0 0 0 30px #fe8989 inset;
}

.blog-post-container.color-home7 .blog-post-item .blog-post-image .action .icons:hover {
    box-shadow: 0 0 0 2px #fe8989 inset;
    color: #fe8989 !important;
}

.blog-post-container.color-home7 .blog-post-item .blog-post-image .action .icons {
    background: #fe8989;
}

#header.color-home7 .cart .cart-count {
    background-color: #fe8989;
}

.policy.color-home7 ul li a:hover i {
    background: #fe8989;
}

#topbar.color-home7 .social a:hover i,
#topbar.color-home7 .social a.active i {
    color: #fe8989;
}

#topbar.color-home7 .topbar-right a:hover,
#topbar.color-home7 .topbar-right a.active,
#topbar.color-home7 .dropdown .btn:hover {
    color: #fe8989 !important;
}

.float-left {
    float: left;
}

.rtl .float-left {
    float: right;
}

.float-right {
    float: right;
}

.rtl .float-right {
    float: left;
}

.align-right {
    text-align: right;
}

.rtl .align-right {
    text-align: left;
}

.align-left {
    text-align: left;
}

.rtl .align-left {
    text-align: right;
}

.rtl-transform0 {
    transform: rotateY(0deg);
}

.rtl .rtl-transform0 {
    transform: rotateY(180deg);
}

.rtl-transform180 {
    transform: rotateY(180deg);
}

.rtl .rtl-transform180 {
    transform: rotateY(0deg);
}

.wrap-hermes-collection.padding-5 {
    padding: 0 5px;
}

.btn.btn-default.button_search:hover,
.btn.btn-default.button_search:focus {
    color: #333;
}

.modal.fade .modal-dialog {
    webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
}

.modal.fade.in .modal-dialog {
    webkit-transform: translate(0, 0%);
    -ms-transform: translate(0, 0%);
    -o-transform: translate(0, 0%);
    transform: translate(0, 0%);
}

.container.container-ver2 {
    width: 1200px;
    padding: 0 15px;
}

.slider-full-screen2 .col-md-2,
.slider-full-screen2 .col-md-10 {
    padding: 0px;
}

.bottom-home1.bottom-home1-home4 {
    display: inline-block;
    width: 100%;
}

.bottom-home1.bottom-home1-home4 .col-md-4:first-child {
    padding-left: 0px;
}

.bottom-home1.bottom-home1-home4 .col-md-4:last-child {
    padding-right: 0px;
}

.nav-hidden .tp-rightarrow.default,
.nav-hidden .tp-leftarrow.default {
    opacity: 0;
}

@media (min-width: 1200px) {
    .home-v2 {
    }
    .main-content.main-content-home2 .container {
        padding: 30px;
        width: 1230px;
    }
}

.ver2 .tp-leftarrow,
.ver2 .tp-rightarrow {
    display: none !important;
}

.main-content.main-content-home2 {
    margin-top: -40px;
    position: relative;
    margin-bottom: -40px;
}

.main-content.main-content-home2 .container {
    background: #fff;
}

.content-bottom-home2 h3 {
    text-transform: uppercase;
    font-size: 22px;
    color: #gray-dark;
    border-bottom: 1px solid #e5e5e5;
    line-height: 20px;
    padding: 25px 0px;
    position: relative;
    margin-bottom: 40px;
}

.content-bottom-home2 h3:after {
    position: absolute;
    content: "";
    background: #808f66;
    height: 3px;
    width: 60px;
    left: 0px;
    bottom: -1px;
}

/** 6.6.3 Home page 3 **/
ul.tabs.title.tabs-home3 {
    position: absolute;
    width: auto;
    top: 5px;
    right: 100px;
}

ul.tabs.title.tabs-home3 li {
    font-size: 15px;
    font-family: "TXGR";
    text-transform: capitalize;
    color: #aaa;
    padding: 0 10px;
}

ul.tabs.title.tabs-home3 li:hover,
ul.tabs.title.tabs-home3 li.active {
    color: #6dc5ee;
}

.carousel-control {
    opacity: 100;
    height: 30px;
    width: 30px;
    top: 50%;
    margin-top: -15px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.carousel-control:hover i {
    color: #80b435;
}

.carousel-control.left {
    left: 20px;
}

.carousel-control.left:hover {
    left: 10px;
}

.carousel-control.right {
    right: 20px;
}

.carousel-control.right:hover {
    right: 10px;
}

.carousel-control i {
    font-size: 30px;
}

nav.megamenu-v2 {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #222;
    width: 400px;
    -webkit-transform: translate3d(-400px, 0px, 0px);
    -moz-transform: translate3d(-400px, 0px, 0px);
    -ms-transform: translate3d(-400px, 0px, 0px);
    -o-transform: translate3d(-400px, 0px, 0px);
    transform: translate3d(-400px, 0px, 0px);
    -webkit-transition: transform 0.5s ease 0s;
    -o-transition: transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s;
    z-index: 999;
}

nav.megamenu-v2.show-ef {
    -webkit-transform: translate3d(0px, 0px, 0px);
    -moz-transform: translate3d(0px, 0px, 0px);
    -ms-transform: translate3d(0px, 0px, 0px);
    -o-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
}

nav.megamenu-v2 .top-menu {
    padding: 20px 0px;
    border-top: 1px solid #393939;
    border-bottom: 1px solid #393939;
    display: inline-block;
    width: 100%;
}

nav.megamenu-v2 .top-menu .dropdown {
    display: inline-block;
    float: left;
    width: 50%;
    text-align: center;
}

nav.megamenu-v2 .top-menu .dropdown:nth-child(1) {
    border-right: 1px solid #393939;
}

nav.megamenu-v2 .top-menu .dropdown .btn {
    background-color: #222;
    color: #ddd;
    text-transform: capitalize;
    padding: 0px;
}

nav.megamenu-v2 .fa-times {
    height: 21px;
    width: 21px;
    cursor: pointer;
    float: right;
    margin-right: 40px;
    margin-top: 10px;
}
nav.megamenu-v2 .fa-times::before {
    content: "";
}

nav.megamenu-v2 ul.main-menu {
    padding-top: 30px;
}

nav.megamenu-v2 ul.main-menu li {
    text-transform: uppercase;
    position: relative;
    padding: 15px 0px;
    border-bottom: 1px solid #393939;
}

nav.megamenu-v2 ul.main-menu li.active a {
    color: #fff;
}

nav.megamenu-v2 ul.main-menu li.active ul li a {
    color: #555555;
}

nav.megamenu-v2 ul.main-menu li.active ul li a:hover {
    color: #fff;
}

nav.megamenu-v2 ul.main-menu li ul {
    display: none;
    padding: 20px 0 10px;
    margin-top: 10px;
    border-top: 1px solid #393939;
}

nav.megamenu-v2 ul.main-menu li ul li {
    padding-left: 20px;
    list-style: inside disc none;
    color: #555555;
    padding: 5px 0px 5px 20px;
    border-bottom: none;
}

nav.megamenu-v2 ul.main-menu li ul li a {
    text-transform: capitalize;
    font-family: "Lora";
    font-style: italic;
    margin-left: 0px;
}

nav.megamenu-v2 ul.main-menu li ul li i {
    display: none;
}

nav.megamenu-v2 ul.main-menu li a {
    color: #555555;
    margin-left: 20px;
}

nav.megamenu-v2 ul.main-menu li a:hover {
    color: #fff;
}

nav.megamenu-v2 ul.main-menu li i {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    color: #555555;
    font-size: 18px;
}

nav.megamenu-v2 ul.main-menu li i:before {
    content: "\f107";
}

nav.megamenu-v2 ul.main-menu li i.active:before {
    content: "\f106";
}

nav.megamenu-v2 ul.main-menu li:last-child {
    border: none;
}

.tp-banner-container {
    width: 100% !important;
    left: 0px !important;
}

.tp-banner-container span {
    display: inline-block;
    width: 100%;
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 16px;
    color: #666;
    line-height: 50px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.tp-banner-container.ver1 {
    margin-bottom: 30px;
}

.tp-banner-container.ver4 {
    margin-bottom: 15px;
}

@media (max-width: 767px) {
    .tp-banner-container.ver4 {
        margin-top: 5px;
    }
}

.tp-banner-container .tp-caption img {
    position: absolute;
    top: 0px;
}

.tp-banner-container .tp-caption.size-100 {
    font-size: 100px;
}

.tp-banner-container .tp-caption.size-200 {
    font-size: 200px;
}

.tp-banner-container .tp-caption.size-130 {
    font-size: 130px;
}

.tp-banner-container .tp-caption.size-90 {
    font-size: 90px;
}

.tp-banner-container .tp-caption.size-80 {
    font-size: 80px;
}

.tp-banner-container .tp-caption.size-60 {
    font-size: 60px;
}

.tp-banner-container .tp-caption.size-55 {
    font-size: 55px;
}

.tp-banner-container .tp-caption.size-50 {
    font-size: 50px;
}

.tp-banner-container .tp-caption.size-42 {
    font-size: 42px;
    line-height: 72px;
}

.tp-banner-container .tp-caption.size-38 {
    font-size: 38px;
}

.tp-banner-container .tp-caption.size-34 {
    font-size: 34px;
}

.tp-banner-container .tp-caption.size-30 {
    font-size: 30px;
}

.tp-banner-container .tp-caption.size-28 {
    font-size: 28px;
    letter-spacing: 2px;
}

.tp-banner-container .tp-caption.size-24 {
    font-size: 24px;
}

.tp-banner-container .tp-caption.size-20 {
    font-size: 20px;
}

.tp-banner-container .tp-caption.size-18 {
    font-size: 18px;
}

.tp-banner-container .tp-caption.size-16 {
    font-size: 16px;
}

.tp-banner-container .tp-caption.size-14 {
    font-size: 14px;
}

.tp-banner-container .tp-caption.font-cap {
    text-transform: capitalize;
}

.tp-banner-container .tp-caption.t-transform-n {
    text-transform: none;
}

.tp-banner-container .tp-caption.weight-300 {
    font-weight: 300;
}

.tp-banner-container .tp-caption.weight-900 {
    font-weight: 900;
}

.tp-banner-container .tp-caption.weight-700 {
    font-weight: 700;
}

.tp-banner-container .tp-caption.lh-23 {
    line-height: 23px;
}

.tp-banner-container .tp-caption.letter-3 {
    letter-spacing: 3px;
}

.tp-banner-container .tp-caption.upper {
    text-transform: uppercase;
}

.tp-banner-container .tp-caption.bg-brand {
    background-color: #80b435;
    padding-left: 25px;
    padding-right: 25px;
}

.tp-banner-container .tp-caption.bg-white {
    background-color: #fff;
    line-height: 60px;
    padding-left: 30px;
    padding-right: 30px;
}

.tp-banner-container .tp-rightarrow {
    right: 20px !important;
    font: 400 36px/36px "FontAwesome";
    color: #333;
    height: 36px;
    width: 36px;
}

.tp-banner-container .tp-rightarrow:before {
    content: "";
    display: inline-block;
}

.tp-banner-container .tp-rightarrow:hover {
    color: #000;
}

.tp-banner-container .tp-leftarrow {
    left: 20px !important;
    font: 400 36px/36px "FontAwesome";
    color: #333;
    height: 36px;
    width: 36px;
}

.tp-banner-container .tp-leftarrow:hover {
    color: #000;
}

.tp-banner-container .tp-leftarrow:before {
    content: "";
    display: inline-block;
}

.tp-banner-container .large_bold_orange,
.tp-banner-container .large_normal_grey {
    text-transform: uppercase;
    color: #eebb45;
    font-size: 70px;
}

.tp-banner-container .text-transform-cap {
    text-transform: capitalize !important;
}

.tp-banner-container .uppercase {
    text-transform: uppercase;
}

.tp-banner-container .letter-spacing {
    letter-spacing: 2px;
}

.tp-banner-container .letter-spacing-5 {
    letter-spacing: 5px;
}

.tp-banner-container .large_normal_grey {
    color: #888888;
}

.tp-banner-container .large_normal_grey .size-60 {
    font-size: 60px;
}

.tp-banner-container .text-shadow {
    text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.45);
}

.tp-banner-container .font-italic {
    font-style: italic;
}

.tp-banner-container .link-1 {
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    padding: 0 45px;
    font-size: 16px;
    border: 1px solid #80b435;
    color: #80b435;
}

.tp-banner-container .link-1.color-white a {
    color: #fff;
    font-weight: 300;
}

.tp-banner-container .link-1.color-white a:hover {
    color: #80b435;
}

.tp-banner-container .link-1 a {
    color: #80b435;
    font-weight: 700;
}

.tp-banner-container .link-1:hover {
    border: 1px solid #80b435;
    background: #80b435;
}

.tp-banner-container .link-1:hover a {
    color: #fff;
}

.tp-banner-container .link-1.link-2 {
    background: transparent;
    border: 2px solid #80b435;
    padding: 0 50px;
}

.tp-banner-container .link-1.link-2 a {
    color: #80b435;
}

.tp-banner-container .link-1.link-2:hover {
    background: #80b435;
}

.tp-banner-container .link-1.link-2:hover a {
    color: #fff;
}

.tp-banner-container .link-1.bg-brand {
    background-color: #80b435;
    border: 1px solid #80b435;
}

.tp-banner-container .link-1.bg-brand:hover {
    background: transparent;
    color: #80b435;
}

.tp-banner-container .link-1.border-white {
    border-color: #fff;
}

.tp-banner-container .link-1.border-white a {
    color: #fff;
}

.tp-banner-container .link-1.border-white:hover {
    border-color: #80b435;
}

.tp-banner-container .link-1.bg-green {
    background: #808f66;
    border: 1px solid #808f66;
}

.tp-banner-container .link-1.no-bg {
    background: none;
    border: none;
}

.tp-banner-container .link-1.border-green {
    background: transparent;
    border: 1px solid #b6b6b6;
}

.tp-banner-container .link-1.height-50 {
    line-height: 45px;
}

.tp-banner-container .link-1.height-40 {
    line-height: 38px;
}

.tp-banner-container .link-1.font-size13 {
    font-size: 13px;
}

.tp-banner-container .link-1.home2 {
    padding: 0 20px;
}

.tp-banner-container .link-3 a {
    color: #fff;
    border-bottom: 2px solid #e95252;
    position: relative;
}

.tp-banner-container .link-3 a:after {
    position: absolute;
    content: "";
    left: 0px;
    height: 2px;
    width: 0px;
    bottom: -2px;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.tp-banner-container .link-3 a:hover {
    color: #fff;
}

.tp-banner-container .link-3 a:hover:after {
    width: 100%;
    background: #fff;
}

.tp-banner-container .link-v4 {
    text-transform: uppercase;
    color: #000;
    letter-spacing: 3px;
    font-weight: 700;
}

.tp-banner-container .link-v4 a {
    color: #000;
    display: inline-block;
    line-height: 30px;
    border: 2px solid #000;
    font-size: 10px;
}

.tp-banner-container .font-os {
    font-family: "Oswald";
}

.tp-banner-container .font-play {
    font-family: "Gotham";
}

.tp-banner-container .font-pop {
    font-family: "Poppins";
}

.tp-banner-container .font-ro {
    font-family: "Gotham";
}

.tp-banner-container .font-roc {
    font-family: "Gotham";
}

.tp-banner-container .font-nel {
    font-family: "NexaLight";
}

.tp-banner-container .font-nelb {
    font-family: "NexaBold";
}

.tp-banner-container .font-mo {
    font-family: "Montserrat";
    font-weight: 700;
}

.tp-banner-container .font-ar {
    font-family: "Arial";
}

.tp-banner-container .font-ros {
    font-family: "Gotham";
}

.tp-banner-container .font-300 {
    font-weight: 300;
}

.tp-banner-container .font-700 {
    font-weight: 700;
}

.tp-banner-container .letter-spacing-2 {
    letter-spacing: 2px;
}

.tp-banner-container .color-blue {
    color: #74cfe2;
}

.tp-banner-container .color-white {
    color: #eeeeee;
}

.tp-banner-container .color-grayd {
    color: #333333;
}

.tp-banner-container .color-brand {
    color: #80b435;
}

.tp-banner-container .color-black {
    color: #000;
}

.tp-banner-container .color-brown {
    color: #623f30;
}

.tp-banner-container .color-home2 {
    color: #808f66;
}

.tp-banner-container .color-ea8800 {
    color: #ea8800;
}

.tp-banner-container .bg-brown {
    background: #623f30;
}

.tp-banner-container .color-grays {
    color: #9d9d9d;
}

.tp-banner-container .color-2b2b2b {
    color: #2b2b2b;
}

.tp-banner-container .bg-orange {
    background: #ffb400;
    padding: 5px 10px;
    border-radius: 5px;
    text-transform: capitalize;
}

.tp-banner-container .bg-brand-home5 {
    background: #12a170;
    border-radius: 15px;
    border: 2px solid #80b435;
    padding: 5px 10px;
}

.tp-banner-container .color-gray {
    color: #555555;
}

.tp-banner-container .color-border {
    color: #000;
    text-shadow: 2px 2px 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.tp-banner-container .tp-caption .size-50 {
    font-size: 50px;
}

@media (min-width: 768px) {
    .tp-banner-container .tp-caption .size-50 br {
        display: none;
    }
}

@media (max-width: 1024px) {
    .tp-banner-container .tp-caption .size-50 {
        font-size: 30px;
    }
}

@media (max-width: 767px) {
    .tp-banner-container .tp-caption .size-50 {
        font-size: 20px;
        line-height: 20px;
    }
}

.tp-banner-container .tp-caption span.size-14 {
    font-size: 14px;
}

@media (max-width: 1024px) {
    .tp-banner-container .tp-caption span.size-14 {
        font-size: 10px;
    }
}

@media (max-width: 767px) {
    .tp-banner-container .tp-caption span.size-14 {
        margin-top: 25px;
    }
}

.tp-banner-container .tp-caption p.size-14 {
    font-size: 14px;
    line-height: 23px;
}

@media (max-width: 1024px) {
    .tp-banner-container .tp-caption p.size-14 {
        margin-top: 10px;
        font-size: 10px;
        line-height: 14px;
    }
}

@media (max-width: 767px) {
    .tp-banner-container .tp-caption p.size-14 {
        display: none;
    }
}

@media (max-width: 1024px) {
    .tp-banner-container .tp-caption .border {
        display: none;
    }
}

@media (max-width: 1024px) {
    .tp-banner-container .tp-caption .link-v5 {
        font-size: 10px;
        line-height: 20px;
        margin-top: 20px;
    }
}

.slide-show-container.slide-show-container-home5 {
    width: 68%;
    float: left;
    display: inline-block;
    max-width: 570px;
}

.box-slide-show.box-slide-show-home5 {
    width: 32%;
    float: right;
}

.box-slide-show.box-slide-show-home5 img {
    width: 100%;
}

.box-slide-show.box-slide-show-home5 .hover-effect-images:first-child {
    margin-bottom: 20px;
}

.box-slide-show.box-slide-show-home5 .hover-effect-images .text {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #000;
    color: #fff;
    opacity: 0.8;
    text-transform: uppercase;
}

.box-slide-show.box-slide-show-home5 .hover-effect-images .text h3 {
    font-size: 12px;
    padding: 5px 15px;
}

.home-v5-slider ul {
    margin-bottom: 30px;
}

.home-v5-slider ul li {
    display: inline-block;
    font-size: 20px;
    margin-right: 20px;
    padding-right: 20px;
    color: #333333;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
}

.home-v5-slider ul li:after {
    position: absolute;
    content: "/";
    font-size: 20px;
    top: 0px;
    right: 0px;
    color: #333333;
}

.home-v5-slider ul li:last-child:after {
    content: "";
}

.home-v5-slider ul li:hover,
.home-v5-slider ul li.active {
    color: #12a170;
}

.bottom-home1.bottom-home1-home5 .owl-carousel .owl-controls .owl-nav .owl-prev,
.bottom-home1.bottom-home1-home5 .owl-carousel .owl-controls .owl-nav .owl-next {
    box-shadow: none;
    border: none;
    background: none;
}

.bottom-home1.bottom-home1-home5 .owl-carousel .owl-controls .owl-nav .owl-prev i,
.bottom-home1.bottom-home1-home5 .owl-carousel .owl-controls .owl-nav .owl-next i {
    font-size: 24px;
}

.bottom-home1.bottom-home1-home5 .owl-carousel .owl-controls .owl-nav .owl-prev:hover,
.bottom-home1.bottom-home1-home5 .owl-carousel .owl-controls .owl-nav .owl-next:hover {
    box-shadow: none;
    background: none;
}

.bottom-home1.bottom-home1-home5 .owl-carousel .owl-controls .owl-nav .owl-prev:hover i,
.bottom-home1.bottom-home1-home5 .owl-carousel .owl-controls .owl-nav .owl-next:hover i {
    color: #12a170;
}

.bottom-home1.bottom-home1-home5 .owl-carousel .owl-controls .owl-nav .owl-prev {
    right: 30px;
}

.bottom-home1.bottom-home1-home5 .owl-carousel .owl-controls .owl-nav .owl-prev:after {
    position: absolute;
    content: "";
    height: 16px;
    width: 1px;
    background: #888888;
    top: 7px;
    right: 0px;
}

.bottom-home1.bottom-home1-home5 .products-item .product-content .product-name {
    color: #555555;
}

.bottom-home1.bottom-home1-home5 .products-item .product-content .product-price {
    color: #333333;
}

.bottom-home1.bottom-home1-home5 .products-item .product-content .add-to-cart {
    color: #12a170;
    font-family: "TXGB";
    font-size: 14px;
}

.bottom-home1.bottom-home1-home6 .products-item {
    width: 33.33%;
    float: left;
}

.bottom-home1.bottom-home1-home6 .products-item .product-images {
    width: 140px;
    margin-bottom: 20px;
}

.banner-home6-top .hover-effect-images img {
    width: 100%;
}

.menu-category {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 270px;
    border: 1px solid #eeeeee;
    margin-top: -4px;
    margin-bottom: 20px;
}

.menu-category li {
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.menu-category li:hover {
    /* background-image: url(../images/Hermes-home7-icon-menucat-hover.png); */
}

.menu-category li:hover a {
    color: #fe8989;
}

.menu-category li a {
    font-family: "TXGR";
    font-size: 15px;
    color: #555555;
    text-transform: capitalize;
    line-height: 52px;
    display: inline-block;
    width: 100%;
    padding-left: 65px;
    border-bottom: 1px solid #eeeeee;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.menu-category li:nth-child(2) {
    background-position: 25px -38px;
}

.menu-category li:nth-child(3) {
    background-position: 25px -90px;
}

.menu-category li:nth-child(4) {
    background-position: 25px -140px;
}

.menu-category li:nth-child(5) {
    background-position: 25px -190px;
}

.menu-category li:nth-child(6) {
    background-position: 25px -243px;
}

.menu-category li:nth-child(7) {
    background-position: 25px -298px;
}

.menu-category li:nth-child(8) {
    background-position: 25px -350px;
}

.menu-category li:last-child a {
    border: none;
}

.slide-show.slide-show-home7 {
    padding-right: 5px;
    margin-top: 20px;
    display: inline-block;
    width: 72%;
    max-width: 570px;
    float: left;
}

.box-slide-show.box-slide-show-home7 {
    float: right;
    width: 35%;
    margin-top: 20px;
    max-width: 270px;
}

.box-slide-show.box-slide-show-home7 img {
    width: 100%;
}

.box-slide-show.box-slide-show-home7 .hover-effect-images {
    margin-bottom: 20px;
}

.products.mason-v2 .product:nth-child(2n + 1) {
    padding-right: 0px;
}

.slider-product.slider-product-home7 ul.tabs {
    text-align: left;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 10px;
}

.slider-product.slider-product-home7 ul.tabs li {
    font-size: 20px;
    font-weight: 400;
    font-family: "TXGB";
    color: #666;
}

.slider-product.slider-product-home7 ul.tabs li.active,
.slider-product.slider-product-home7 ul.tabs li:hover {
    color: #fe8989;
}

.slider-product.slider-product-home7 ul.tabs li:first-child {
    padding-left: 0px;
}

.slider-product.slider-product-home7 ul.tabs span {
    font-size: 20px;
}

.mockup-v2 .by-tags img {
    border-radius: 50%;
}

.pushmenu.menu-home6 {
    overflow-y: auto;
}

.nav-home6 {
    text-align: left;
}

.nav-home6 a {
    font: 700 18px "Gotham";
    text-transform: uppercase;
    color: #333;
    line-height: 50px;
}

.nav-home6 a:hover {
    color: #80b435;
}

.nav-home6 .menu-lv1 {
    border-top: 1px solid #e8e8e8;
    padding: 0 10px;
}

.nav-home6 .menu-lv1 a {
    text-transform: capitalize;
    font-weight: 400;
    font-size: 15px;
}

.nav-home6 .menu-lv1 a:before {
    width: 5px;
    height: 5px;
    background: #333;
    content: "";
    display: inline-block;
    margin-right: 6px;
    margin-bottom: 3px;
}

.nav-home6 .menu-lv1 a:hover:before {
    background: #80b435;
}

.nav-home6 .menu-lv1 .menu-lv2 {
    padding: 0 10px;
    border-top: 1px dashed #e8e8e8;
}

.nav-home6 .fa {
    float: right;
    font-size: 13px;
    display: inline-block;
    padding: 15px;
    cursor: pointer;
    display: none;
}

.nav-home6 .fa.active:before,
.nav-home6 .ordering span.fa:hover:before,
.ordering .nav-home6 span.fa:hover:before {
    content: "";
}

.nav-home6 .menu-lv1,
.nav-home6 .menu-lv2 {
    display: none;
    float: left;
    width: 100%;
}

.nav-home6 li.lv1,
.nav-home6 li.lv2 {
    border-bottom: 1px solid #e8e8e8;
    float: left;
    width: 100%;
}

.nav-home6 li.lv1:last-child,
.nav-home6 li.lv2:last-child {
    border: none;
}

.nav-home6 li.lv2 {
    border-bottom: 1px dashed #cdcdcd;
}

.nav-home6 li.hassub .fa {
    display: inline-block;
    font-size: 0px;
    width: 50px;
    height: 50px;
}

.nav-home6 li.hassub .fa.active,
.nav-home6 li.hassub .ordering span.fa:hover,
.ordering .nav-home6 li.hassub span.fa:hover {
    background-position: center bottom 25px;
}

.nav-home6 li.hassub .lv2 .fa {
    display: none;
}

.nav-home6 li.hassub .lv2.hassub .fa {
    display: inline-block;
}

.nav-home6 li.hassub .lv2.hassub .lv3 .fa {
    display: none;
}

.furniture {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}

.furniture .product {
    text-align: left;
}

.rtl .furniture .product {
    text-align: right;
}

.furniture .product .product-title {
    padding: 0 20px;
}

.furniture .product .product-price-old {
    margin-left: 20px;
}

.client-say {
    margin-top: 10px;
}

@media (max-width: 460px) {
    .tp-banner-container .tp-rightarrow,
    .tp-banner-container .tp-leftarrow {
        width: 30px;
        background-size: 100% auto;
    }
}

.hoz-tab-container .tabs li {
    font-size: 20px;
}

.categories-home3 {
    position: relative;
}

.categories-home3 .icon-click {
    position: absolute;
    font-size: 25px;
    color: #80b435;
    right: 10px;
    top: 20px;
}

.categories-home3 .icon-click.active:before,
.categories-home3 .ordering span.icon-click:hover:before,
.ordering .categories-home3 span.icon-click:hover:before {
    content: "\f139";
}

@media (min-width: 768px) {
    .categories-home3 .icon-click {
        display: none;
    }
    .menu-vertical {
        display: inline-block;
    }
}

@media (max-width: 767px) {
    .menu-vertical {
        display: none;
    }
}

/** 6.7. Pages **/
/** 6.7.1. About Us **/
/** 6.7.2. Contact Us **/
/** 6.7.3. Shopping Cart**/
/** 6.7.4. Page 404 **/
.page-404 {
    position: relative;
}

.page-404 .text {
    width: 100%;
}

.page-404 .text h3 {
    font: 700 36px/40px "Gotham";
    color: #2b2b2b;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.page-404 .text p {
    font: 300 18px "Gotham";
    color: #2b2b2b;
    margin-bottom: 30px;
}

.page-404 .text p a {
    color: #80b435;
    margin-left: 15px;
}

.page-404 .text p a i {
    font-size: 14px;
}

.page-404 .text form {
    position: relative;
    width: 670px;
    margin: auto;
    margin-bottom: 100px;
}

.page-404 .text input {
    height: 50px;
    width: 670px;
    background: none;
    border: 1px solid #517664;
    font: 300 16px/48px "Gotham";
    color: #fff;
    padding-left: 20px;
}

.page-404 .text button {
    font: 700 16px/41px "Gotham";
    width: 130px;
    border: 1px solid #fff;
    color: #000;
    text-transform: uppercase;
    position: absolute;
    height: 43px;
    right: 2px;
    top: 3px;
    transition: all 0.3s ease 0s;
}

.page-404 .text button:hover {
    background: none;
    color: #fff;
}

.page-404 .icon-box {
    margin-bottom: 80px;
}

@media (max-width: 1200px) {
    .page-404 .icon-box {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .page-404 .icon-box img {
        width: 80px;
    }
    .page-404 .text h3 {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .page-404 .text p {
        font-size: 15px;
    }
    .page-404 .text input,
    .page-404 .text form {
        max-width: 100%;
        width: calc(100% - 30px);
    }
    .page-404 .text input {
        width: 100%;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .page-404 {
        height: 500px;
    }
    .page-404 .images img {
        width: 1000px;
        max-width: inherit;
    }
    .page-404 .text h3 {
        font-size: 16px;
        line-height: 20px;
    }
    .page-404 .text p {
        font-size: 12px;
    }
    .page-404 .text button {
        width: 80px;
    }
    .page-404 .text input {
        padding-right: 80px;
    }
}

@media (max-width: 479px) {
    .page-404 {
        height: 400px;
        overflow: hidden;
    }
    .page-404 .images img {
        width: 1000px;
        max-width: inherit;
    }
    .page-404 .icon-box img {
        width: 50px;
    }
    .page-404 .text h3 {
        font-size: 16px;
        line-height: 20px;
    }
    .page-404 .text p {
        font-size: 12px;
    }
    .page-404 .text button {
        width: 80px;
    }
    .page-404 .text input {
        padding-right: 80px;
    }
}

.page-404 h1 {
    font-size: 250px;
    font-weight: 700;
    color: #d6d6d6;
}

.page-404 h2 {
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 700;
    color: #333333;
}

.page-404 h3 {
    font-size: 28px;
    text-transform: uppercase;
    color: #555555;
}

.page-404 a.link-button {
    margin: 0px 10px;
}

#googleMap {
    height: 640px;
    display: inline-block;
    width: 100%;
}

.map-icon-label {
}
.page-faq .content-text {
    border-bottom: 1px solid #ddd;
    padding-bottom: 30px;
}

.page-faq .content-text:last-child {
    border: none;
}

.page-faq .content-text h2 {
    color: #80b435;
    font: 400 30px/30px "Gotham";
    margin-bottom: 50px;
}

.page-faq .content-text h3 {
    font: 400 20px/25px "Gotham";
    color: #333;
    margin-bottom: 0px;
}

.page-faq .content-text h3:after {
    width: 0px;
}

.page-faq .content-text p {
    font: 400 16px/25px "Gotham";
    color: #949494;
}

.page-contact .head {
    background: #fff;
    position: relative;
    padding: 40px;
    box-shadow: 0px 8px 33px 0px rgba(0, 0, 0, 0.13);
    display: inline-block;
    width: 100%;
    margin-top: -100px;
    margin-bottom: 35px;
}

.page-contact .head .item {
    width: 33.33%;
    float: left;
    display: table;
}

.page-contact .head .item:nth-child(2),
.page-contact .head .item:nth-child(3) {
    padding-left: 50px;
}

.page-contact .head .item .icon,
.page-contact .head .item .text {
    display: table-cell;
    vertical-align: middle;
}

.page-contact .head .item .text p {
    font: 400 18px/20px "Gotham";
    padding-left: 20px;
}

.page-contact .head .item .icon {
    width: 60px;
}

.page-contact .head .item .icon span {
    width: 40px;
    height: 40px;
    background: #80b435;
    line-height: 40px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    border-radius: 50%;
}

.page-contact .content-text {
    width: 100%;
    max-width: 660px;
    margin: auto;
}

.page-contact .content-text h3 {
    color: #80b435;
    font: 400 48px/50px "Gotham";
    margin-bottom: 0px;
}

.page-contact .content-text h3:after {
    height: 0px;
}

.page-contact .content-text p {
    font: 400 24px/30px "Gotham";
    color: #333;
    margin-bottom: 50px;
}

.page-contact .content-text .form-control {
    border: 1px solid #e6e6e6;
}

.page-contact .content-text textarea {
    min-height: 130px;
}

.shopping-cart .breadcrumb {
    margin-bottom: 50px;
}

.shopping-cart .shipping-total {
    margin-bottom: 80px;
    display: inline-block;
    width: 100%;
}

.wishlist .breadcrumb {
    margin-bottom: 50px;
}

.login-box-container {
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-bottom: 50px;
}

.login-box-container ul.tabs {
    margin-bottom: 30px;
}

.login-box-container ul.tabs li {
    display: inline-block;
    text-transform: uppercase;
    color: #c6c6c6;
    font-size: 14px;
    letter-spacing: 2px;
    position: relative;
    padding-right: 20px;
    margin-right: 10px;
    cursor: pointer;
    font-weight: 700;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.login-box-container ul.tabs li:last-child {
    padding-right: 0px;
    margin-right: 0px;
}

.login-box-container ul.tabs li:last-child:after {
    content: "";
}

.login-box-container ul.tabs li:last-child.active:before,
.login-box-container ul.tabs li:last-child:hover:before {
    right: 2px;
}

.login-box-container ul.tabs li:after {
    position: absolute;
    content: "/";
    right: 0px;
    top: 0px;
}

.login-box-container ul.tabs li:before {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 2px;
    background: #000;
}

.login-box-container ul.tabs li.active,
.login-box-container ul.tabs li:hover {
    color: #000000;
}

.login-box-container ul.tabs li.active:before,
.login-box-container ul.tabs li:hover:before {
    content: "";
    right: 21px;
}

.login-box-container .tab-container p {
    font-family: "Poppins";
    font-size: 13px;
    color: #858585;
    margin-bottom: 60px;
}

.login-box-container .form-control {
    border: none;
    border-bottom: 2px solid #252525;
    box-shadow: none;
    max-width: 360px;
    padding-left: 0px;
    font-family: "Poppins";
    font-size: 13px;
    color: #636363;
}

.login-box-container .contact-form .col-md-6 .form-control {
    float: left;
}

.login-box-container .contact-form .col-md-6:nth-child(2n + 1) .form-control {
    float: right;
}

#lightgallery .col-md-4 {
    padding: 0px;
}

#lightgallery .col-md-4 img {
    width: 100%;
}

#lightgallery .hover-images {
    position: relative;
}

#lightgallery .hover-images:after {
    position: absolute;
    height: 26px;
    width: 26px;
    top: 50%;
    left: 50%;
    margin-top: -13px;
    margin-left: -13px;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    content: "";
    opacity: 0;
}

#lightgallery .hover-images:hover:after {
    opacity: 1;
}

#lightgallery .effect-v6 img {
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.lookbook-v4 img,
.lookbook-v5 img {
    width: 100%;
}

.lookbook-v4 .col-md-12,
.lookbook-v4 .col-md-6,
.lookbook-v5 .col-md-12,
.lookbook-v5 .col-md-6 {
    padding: 0px;
    background: #141618;
}

.lookbook-v4 .bg-container,
.lookbook-v5 .bg-container {
    background: #141414;
    overflow: hidden;
}

.slider-loobook2 .items {
    position: relative;
    margin-bottom: 100px;
    padding: 50px 0px;
}

.slider-loobook2 .items .text {
    position: absolute;
    left: 100px;
    right: 100px;
    background: #fff;
    bottom: -65px;
    padding-top: 65px;
    text-align: center;
}

.slider-loobook2 .items .text h3 {
    font: 400 40px "Gotham";
    color: #000;
    line-height: 40px;
    margin-bottom: 15px;
}

.slider-loobook2 .items .text p {
    font-weight: 300;
    font-family: "Oswald";
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #9d9d9d;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 35px;
}

.slider-loobook2 .items .text p:after {
    position: absolute;
    content: "";
    left: 50%;
    width: 70px;
    height: 2px;
    background: #000;
    bottom: 0px;
    margin-left: -35px;
}

.slider-loobook2 .items .text a {
    display: inline-block;
    padding: 10px;
    background: #000;
    color: #fff;
    border: 2px solid #000;
}

.table.wishlist {
    margin-bottom: 100px;
}

@media (max-width: 767px) {
    .table.wishlist {
        margin-bottom: 50px;
    }
}

.table.wishlist img {
    width: 100px;
}

.table.wishlist tbody tr:first-child td {
    border-top: none;
}

.table.wishlist tbody tr {
    padding: 30px 0px;
}

.table.wishlist tbody td {
    vertical-align: middle;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media (min-width: 768px) {
    .table.wishlist tbody .product-photo {
        width: 120px;
    }
}

.table.wishlist tbody .produc-name {
    text-align: left;
    font: 400 14px/20px "Poppins";
}

@media (min-width: 768px) {
    .table.wishlist tbody .produc-name {
        width: 350px;
    }
}

.table.wishlist tbody .produc-name a {
    color: #333;
}

.table.wishlist tbody .produc-name a:hover {
    color: #80b435;
}

.table.wishlist tbody .produc-name p {
    margin: 0px;
    color: #979797;
}

.table.wishlist tbody .product-remove a {
    color: #333;
    font-family: "Poppins";
}

.table.wishlist tbody .product-price {
    font-family: "Poppins";
}

@media (min-width: 768px) {
    .table.wishlist tbody .product-price {
        width: 200px;
    }
}

@media (max-width: 480px) {
    .table.wishlist tbody .product-price {
        display: none;
    }
}

@media (max-width: 480px) {
    .table.wishlist tbody .product-instock {
        display: none;
    }
}

.table.wishlist tbody .product-instock a,
.table.wishlist tbody .product-instock span {
    font: 400 10px/20px "Oswald";
    letter-spacing: 2px;
    border: 2px solid #000;
    text-transform: uppercase;
    display: inline-block;
    width: 100px;
    text-align: center;
    color: #000;
}

.table.wishlist tbody .product-instock span {
    border-color: #e7e7e7;
    background: #e7e7e7;
    color: #c1c1c1;
}

.table.wishlist tbody .add-cart {
    text-align: right;
}

.table.wishlist tbody .add-cart a.btn {
    height: 44px;
    line-height: 40px;
    width: 130px;
    text-align: center;
    font-size: 10px;
    padding: 0px;
    background: #f56666;
    border-color: #f56666;
}

.accordion .panel-title a {
    color: #80b435;
    font: 700 18px/25px "Gotham";
}

#collapse-v2-Three {
    padding-top: 30px;
}

#collapse-v2-Three #customer h3 {
    margin-bottom: 20px;
}

.accordion .panel-default:last-child .panel-heading {
    border: none;
}

.accordion .panel-group {
    margin-bottom: 0px;
}

/** 7. Templates Layout **/
@media (min-width: 1500px) {
    .container {
        padding: 0px 30px;
        width: 100%;
    }
    .container .col-md-5 {
        max-width: 725px;
    }
    .container .col-md-5.padding-right-0 {
        padding-right: 0px;
    }
    .container .col-md-5.padding-left-0 {
        padding-left: 0px;
    }
    .container .col-md-5.pro {
        max-width: 733px;
    }
    .container .col-md-7 {
        width: 1115px;
    }
    .container .col-md-7.pro {
        width: 1125px;
    }
    .blog-slider .blog-post-item .blog-post-content {
        left: 60px;
        right: 60px;
    }
    .related-products .product-details {
        padding: 60px 80px;
    }
    .newsletter {
        margin-bottom: 75px;
    }
    .newsletter .col-md-5 .col-md-6:first-child .border-free-shipping {
        padding: 40px 40px 42px;
    }
    .breadcrumb {
        margin-bottom: 40px;
    }
    #secondary {
        width: 20%;
    }
    #primary {
        width: 80%;
    }
    .wrap-googlemap .col-md-6:nth-child(2n + 1) {
        padding-right: 15px;
    }
    .wrap-googlemap .col-md-6:nth-child(2n) {
        padding-left: 15px;
    }
}

@media (min-width: 1025px) and (max-width: 1499px) {
    .container .col-md-5 {
        width: 39.5%;
    }
    .container .col-md-7 {
        width: 60.5%;
    }
    .related-products .product-details {
        padding: 30px;
    }
    .related-products .product-details h4 {
        font-size: 12px;
        margin-bottom: 10px;
    }
    .related-products .product-details h3 {
        font-size: 35px;
    }
    .related-products .product-details .price {
        margin: 15px 0 10px;
        font-size: 35px;
    }
    .related-products .product-details .product-content {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 19px;
    }
    .related-products .product-details a {
        margin: 0px;
    }
    .product-img-box #image-view {
        max-width: 600px;
    }
    .banner-top-home3 .items {
        padding: 40px 20px 15px;
    }
    .banner-top-home3 .items .text {
        padding: 20px;
    }
    #header.header-v4 #topbar .topbar-left .topbar-title {
        width: 300px;
    }
    #header.header-v4 #topbar .topbar-left a {
        width: 300px;
    }
    .best-selling .col-md-4 .product-images {
        margin-left: 10px;
        margin-right: 10px;
    }
    .slider-loobook2 .slick-next {
        right: 195px;
    }
    .slider-loobook2 .slick-prev {
        left: 195px;
    }
    .slider-slick-home8 .slick-next {
        right: 320px;
    }
    .slider-slick-home8 .slick-prev {
        left: 320px;
    }
    .slider-loobook2 .slick-list.draggable {
        padding: 0 285px;
    }
    .tab-product-all-mason .col-md-7 .slider-one-item {
        width: 750px;
    }
    .slider-full-screen2 {
        position: relative;
    }
    .slider-full-screen2 .col-md-2 {
        width: 270px;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 9;
    }
    .slider-full-screen2 .col-md-10 {
        width: 100%;
        margin-bottom: 30px;
    }
    .slider-full-screen2 .col-md-10 .slider-one-item {
        height: 606px;
    }
    .slider-full-screen2 .col-md-10 .owl-carousel .owl-item {
        height: 606px;
    }
    .slider-full-screen2 .bx-viewport {
        height: 571px;
    }
    .slider-full-screen2 .slide:before {
        width: 20px;
    }
    .slider-full-screen2 .slide .text {
        padding-left: 30px;
    }
    .slider-full-screen2 .slide .text h3 {
        font: 300 10px/20px "Oswald";
    }
    .slider-full-screen2 .slider-one-item .product .text {
        top: 25%;
        left: 300px;
    }
    .slider-full-screen2 .slider-one-item .owl-dots {
        padding-left: 300px;
        bottom: 100px;
    }
    .slider-full-screen2 .products .product .product-images img {
        float: right;
        height: 606px;
        width: auto;
    }
    .wrap-googlemap .col-md-6:nth-child(2n + 1) {
        padding-right: 15px;
    }
    .wrap-googlemap .col-md-6:nth-child(2n) {
        padding-left: 15px;
    }
    .blog-masonry .inner-por-masonry .grid-item {
        height: 316px;
    }
    .blog-masonry .inner-por-masonry .grid-item.width-2x {
        height: 632px;
    }
    .popup-content-wrapper {
        margin-top: 100px;
    }
}

@media (max-width: 1024px) {
    #topbar .voucher {
        display: none;
    }
    .container {
        padding: 0px 15px;
    }
    .container .col-md-5,
    .container .col-md-7 {
        width: 100%;
        display: inline-block;
    }
    #primary.float-left {
        padding-right: 0px;
    }
    #secondary.col-md-3.float-right {
        border: none;
        padding-left: 0px;
    }
    #primary.col-md-9 {
        padding-left: 0px;
    }
    #secondary.col-md-3 {
        padding-right: 0px;
    }
    .banner-header.banner-lbook3 img {
        min-height: 150px;
    }
    #header .header-top {
        padding-top: 20px;
    }
    #header .header-top .menu-top {
        display: none;
    }
    #header .header-top .logo {
        display: none;
    }
    #header .header-top .logo-mobile {
        display: inline-block;
        float: left;
    }
    #header .header-top .search,
    #header .header-top .settings {
        margin-top: 10px;
    }
    #header .header-top .cart {
        margin-right: 0px;
        margin-top: 5px;
    }
    #header .header-top .cart .icons {
        color: #000;
    }
    #header .header-top .col-sm-3 {
        padding-right: 5px;
    }
    #header.absolute {
        padding-top: 5px;
    }
    #header.header-v3 .header-top .container:before {
        height: 0px;
    }
    #header.header-v3 #topbar .logo {
        display: none;
    }
    #header.header-v3.header-v6 #topbar .logo {
        display: inline-block;
    }
    #header.header-v2 .box-right {
        padding-right: 20px;
    }
    #header.header-v4 #topbar .topbar-left {
        width: 100%;
    }
    #header.header-v4 #topbar .topbar-left .topbar-title,
    #header.header-v4 #topbar .topbar-left a {
        width: 33%;
        text-align: center;
    }
    #header.header-v4 #topbar .topbar-left .topbar-title:nth-child(3),
    #header.header-v4 #topbar .topbar-left a:nth-child(3) {
        border: none;
    }
    #header.header-v4 #topbar .topbar-left .topbar-title span {
        display: none;
    }
    #header.header-v4 #topbar .topbar-right {
        width: 100%;
        text-align: center;
    }
    #header.header-v4 #topbar .topbar-right .social {
        margin-top: 10px;
    }
    #header.header-v4 .header-top .container:before {
        height: 0px;
    }
    .home1-banner1 img {
        margin: auto;
    }
    .box-home2 {
        display: inline-block;
        width: 100%;
    }
    .icon-menu-mobile {
        display: inline-block;
        width: 100%;
        padding: 5px 0px;
        cursor: pointer;
    }
    .icon-menu-mobile.active i,
    .ordering span.icon-menu-mobile:hover i {
        background-size: 18px auto;
        background: url(../images/icon-menu-mobile-active.svg) no-repeat scroll 0px 0px;
    }
    .icon-menu-mobile i {
        height: 23px;
        width: 32px;
        color: #000;
        background: url(../images/icon-menu-mobile.svg) no-repeat;
        background-size: 24px auto;
    }
    .icon-menu-mobile i::before {
        content: "";
    }
    .product-details-content img {
        max-width: 100%;
    }
    .product-details-content .action a.link-v1.add-cart {
        width: 150px;
    }
    .hoz-tab-container:after {
        width: 0px;
    }
    .hoz-tab-container .tabs,
    .hoz-tab-container .tab-container {
        width: 100%;
    }
    .hoz-tab-container .tabs {
        max-width: inherit;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .hoz-tab-container .tabs li {
        display: inline-block;
        width: auto;
        border: none;
        font-size: 20px;
        margin: 0 10px;
    }
    .hoz-tab-container .tab-container #description p {
        line-height: 24px;
        font-size: 15px;
    }
    .hoz-tab-container .tab-container .tab-content h3 {
        font-size: 18px;
        line-height: 20px;
    }
    .hoz-tab-container .tab-container .tab-content p {
        font-size: 15px;
        line-height: 24px;
    }
    .icon-menu-mobile {
        float: left;
        width: auto;
        margin-top: 8px !important;
        margin-right: 10px;
    }
    .fa-plus.active::before,
    .ordering span.fa-plus:hover::before {
        content: "\f068";
    }
    .newsletter-bottom {
        overflow: hidden;
    }
    .newsletter-bottom img {
        max-width: inherit;
        height: 100%;
    }
    .mega-menu {
        padding: 0 15px;
    }
    .mega-menu .navbar-header {
        display: inline-block;
        width: 100%;
        padding: 5px 0px;
    }
    .mega-menu ul.navbar-nav {
        display: none;
        margin-bottom: 20px;
        border: 1px solid #eeeeee;
    }
    .mega-menu ul.navbar-nav ul.menu-level-1 li.level2 {
        height: auto;
    }
    .mega-menu ul.navbar-nav ul.menu-level-1 li.level2:hover .menu-level-2 {
        left: 0px;
    }
    .mega-menu ul.navbar-nav ul.menu-level-1 li.level2 .menu-level-2 {
        display: none;
        opacity: 1;
        -webkit-transition: all 0s ease 0s;
        -o-transition: all 0s ease 0s;
        transition: all 0s ease 0s;
    }
    .mega-menu ul.navbar-nav .sub-menu {
        display: none;
        min-width: 100%;
        padding: 0px 20px;
    }
    .mega-menu ul.navbar-nav .sub-menu .top-sub-menu {
        display: none;
    }
    .mega-menu ul.navbar-nav .sub-menu .bottom-sub-menu {
        display: none;
    }
    .mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 {
        padding: 0 0 10px 20px;
    }
    .mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li {
        display: inline-block;
        width: 100%;
    }
    .mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level2 {
        height: auto;
        padding-top: 0px;
    }
    .mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level2 a {
        font: 400 14px/40px "Gotham";
        text-transform: capitalize;
        letter-spacing: 0px;
        padding-left: 15px;
    }
    .mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level2 a:after {
        width: 5px;
    }
    .mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level2 img {
        display: none;
    }
    .mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level2.images {
        border: none !important;
    }
    .mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li li.level3 a::after {
        top: 8px;
    }
    .mega-menu ul.navbar-nav li {
        display: inline-block !important;
        width: 100% !important;
        border-left: none;
        position: relative;
    }
    .mega-menu ul.navbar-nav li a {
        height: auto;
        line-height: 40px;
        background: none;
        text-align: left;
        padding-left: 20px;
        color: #888;
    }
    .mega-menu ul.navbar-nav li a:hover {
        background: none;
        color: #000;
    }
    .mega-menu ul.navbar-nav li.dropdown.active a {
        background: none;
        color: #000;
    }
    .mega-menu ul.navbar-nav li.level1 {
        border-bottom: 1px solid #eeeeee;
    }
    .mega-menu ul.navbar-nav li.level1:last-child {
        border-bottom: none;
        border-right: none;
    }
    .mega-menu ul.navbar-nav li.hassub i {
        display: block;
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 13px;
        font-size: 12px;
    }
    .mega-menu ul.navbar-nav li.level3 i {
        display: none !important;
    }
    .mega-menu {
        width: 100%;
        float: initial;
    }
    .mega-menu ul.navbar-nav ul.menu-level-1 {
        opacity: 1;
        position: static;
        transform: translate(0px, 0px);
        -webkit-transition: all 0s ease 0s;
        -o-transition: all 0s ease 0s;
        transition: all 0s ease 0s;
        display: none;
        background: none;
        padding: 0px 35px;
        margin: 0px;
    }
    .mega-menu ul.navbar-nav ul.menu-level-1 li a {
        color: #000 !important;
        border-bottom: none;
    }
    .mega-menu ul.navbar-nav ul.menu-level-1 li a:after {
        top: 6px;
    }
    .mega-menu ul.navbar-nav ul.menu-level-1 li.level2:hover {
        overflow: hidden;
    }
    .mega-menu ul.navbar-nav ul.menu-level-1 li.level2 i {
        display: none;
    }
    .mega-menu ul.navbar-nav ul.menu-level-1 li.level2.hassub i {
        display: block;
    }
    .mega-menu ul.navbar-nav ul.menu-level-1 li.level2 li:first-child {
        padding-top: 0px;
    }
    .mega-menu ul.navbar-nav ul.menu-level-1 .menu-level-2 {
        left: 0px;
        position: relative;
        top: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .mega-menu ul.navbar-nav li {
        text-align: left;
    }
    .mega-menu ul.navbar-nav li.level1 {
        padding: 0px;
    }
    .mega-menu ul.navbar-nav li.dropdown.images {
        position: relative;
    }
    .mega-menu ul.navbar-nav li.hassub i {
        color: #555555;
    }
    .mega-menu ul.navbar-nav li.level2 {
        padding: 0px;
        border-top: 1px dashed #eeeeee !important;
        height: 30px;
        line-height: 30px;
    }
    .mega-menu ul.navbar-nav li.level2 a {
        padding-left: 15px;
    }
    .mega-menu ul.navbar-nav .sub-menu {
        transform: translate(0px, 0px);
        background: none;
        -webkit-transition: all 0s ease 0s;
        -o-transition: all 0s ease 0s;
        transition: all 0s ease 0s;
    }
    .mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 {
        padding: 0;
        padding-left: 15px;
        margin: 0px;
    }
    .mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li .menu-level-2 {
        padding-left: 20px;
    }
    .mega-menu ul.navbar-nav .sub-menu ul.menu-level-1 li.level3:last-child {
        border-bottom: none;
    }
    .shipping-container .shipping-content {
        padding: 0px;
    }
    .shipping-container .shipping-content .col-md-4 {
        width: 100%;
        padding: 15px 0px;
        border-bottom: 1px solid #ececec;
        border-right: none;
    }
    .shipping-container .shipping-content .col-md-4:last-child {
        border: none;
    }
    .container .col-md-7:first-child,
    .container .col-md-5:first-child {
        margin-bottom: 30px;
    }
    .banner-home2 .col-md-5 a {
        width: 100%;
    }
    .contact-us .col-md-6:last-child {
        padding: 0px;
    }
    .contact-us .form-horizontal .col-md-6:nth-child(2n) {
        padding-left: 0px;
    }
    .contact-us .contact-form {
        padding: 50px 30px 30px;
    }
    .contact-us .contact-form p::after {
        left: -18px;
        right: -18px;
    }
    .blog-slider .blog-post-item .blog-post-content .blog-title {
        padding: 0px 20px;
    }
    .slide-show-home3 .col-md-9,
    .slide-show-home3 .col-md-3 {
        padding: 0px !important;
    }
    .slide-show-home3 .col-md-3 {
        margin-top: 30px;
    }
    .newsletter-v2 {
        text-align: center;
    }
    .newsletter-v2 .col-md-6:last-child {
        margin-top: 30px;
        text-align: center;
    }
    .cat-box-home3 .col-md-6 {
        padding: 0px !important;
    }
    .slider-one-item-home3 .product .text {
        width: 400px;
        height: 200px;
        margin-left: -200px;
        margin-top: -100px;
    }
    .slider-one-item-home3 .product .text h4 {
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    .slider-one-item-home3 .product .text h3 {
        font-size: 30px;
        margin-bottom: 15px;
    }
    .slider-one-item-home3 .product .text button {
        height: 30px;
        line-height: 26px;
        padding: 0 25px;
        font-size: 10px;
        margin-top: 10px;
    }
    .shipping-v2 i.icons {
        font-size: 25px;
    }
    .shipping-v2 h3 {
        font-size: 10px;
    }
    .shipping-v2 p {
        font-size: 10px;
        line-height: 15px;
    }
    .shipping-v2 .col-md-3:after {
        top: 15px;
        height: 60px;
    }
    .banner-home4-top .col-md-6 {
        width: 100%;
    }
    .tab-product-all {
        padding: 0px 15px;
    }
    .newsletter-home4 .col-md-4 {
        padding: 0px !important;
    }
    .banner-hom7-center .col-md-6 {
        display: inline;
    }
    .instafeed .items {
        width: 33.33%;
    }
    .instafeed .items:nth-child(4),
    .instafeed .items:nth-child(5) {
        width: 50%;
    }
    .banner-hom7-center .banner-private-v2 {
        display: inline-block;
    }
    .banner-hom7-center .banner-private-v2 .text {
        width: 400px;
        height: 200px;
        margin-left: -200px;
        margin-top: -100px;
    }
    .banner-hom7-center .banner-private-v2 .text h4 {
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    .banner-hom7-center .banner-private-v2 .text h3 {
        font-size: 30px;
        margin-bottom: 15px;
    }
    .banner-hom7-center .banner-private-v2 .text button {
        height: 30px;
        line-height: 26px;
        padding: 0 25px;
        font-size: 10px;
        margin-top: 10px;
    }
    #header .header-top .container::before {
        height: 0px;
    }
    .shipping-container.shipping-container-home8 {
        padding-top: 30px;
    }
    .shipping-container.shipping-container-home8 .shipping-content .col-md-4 {
        border-bottom: 1px solid #2d2d2d;
    }
    .slider-slick-home8 .slick-prev,
    .slider-slick-home8 .slick-next {
        width: 80px;
    }
    .slider-slick-home8 .slick-prev {
        /* left: 215px; */
    }
    .slider-slick-home8 .slick-next {
        /* right: 215px; */
    }
    .time .distance {
        width: 150px;
    }
    .banner-home8-top .col-md-12 {
        width: 100%;
    }
    .slider-slick-home8 .slick-slide h3 {
        font-size: 20px;
        line-height: 20px;
    }
    .product-img-box #image-view {
        float: initial;
    }
    .product-img-box .product-thumb {
        text-align: center;
        width: 100%;
    }
    .product-img-box .thumb-content {
        max-width: 100%;
        text-align: center;
        margin-top: 30px;
    }
    .product-img-box .thumb-content li {
        display: inline-block;
        max-width: 100px;
        float: initial;
    }
    .mockup .row:nth-child(2n) {
        background: none;
    }
    .mockup .row:nth-child(2n) .mockup-text {
        background: #262626;
    }
    .mockup .col-md-6 {
        width: 100%;
    }
    #customer .col-md-6 {
        padding: 0px;
    }
    .slider-loobook2 .items .text {
        right: 50px;
        left: 50px;
    }
    .container.container-ver2 {
        width: 100%;
    }
    .cart-box-container .col-md-8,
    .cart-box-container .col-md-4 {
        padding: 0px;
    }
    .title-page h3 {
        font-size: 30px;
        padding-bottom: 10px;
    }
    .boxed-content.images {
        width: 100%;
    }
    .boxed-content.boxed-content-v2 {
        margin-left: 30px;
    }
    .icon-box-v5 .feature-box.ver4 {
        background-size: 100% auto;
    }
    .tab-v1 .nav-tabs li {
        width: 100%;
        margin-bottom: 5px;
    }
    .tab-v1 .nav-tabs li.active a:after,
    .tab-v1 .nav-tabs li:hover a:after {
        position: static;
        border: none;
    }
    .tab-v1 .custom-content .col-md-6 {
        padding: 0px;
        margin-bottom: 30px;
    }
    .tab-v1 .custom-content .col-md-6 img {
        max-width: 100%;
    }
    .banner-home3-lookbook2 .text {
        height: 190px;
        margin-top: -95px;
        top: 50%;
    }
    .banner-home3-lookbook2 .text h3 {
        font: 300 20px/30px "Oswald";
        padding-bottom: 20px;
    }
    .banner-home3-lookbook2 .text .icons {
        font-size: 50px;
        padding-bottom: 30px;
    }
    .banner-home-collec1.related-products .product-details .text {
        left: 30px;
        top: 20%;
        max-width: 400px;
    }
    .banner-home-collec1.related-products .product-details h3 {
        font-size: 30px;
    }
    .banner-home-collec1.related-products .product-details h4 {
        font-size: 12px;
        margin-bottom: 15px;
    }
    .banner-home-collec1.related-products .product-details .product-content {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 20px;
    }
    .products.grid_full.grid_sidebar.list-item .item-inner {
        width: 100%;
    }
    .product-details-content.product-details-content-v2 .col-md-5 {
        position: relative;
        top: 0px;
        left: 0px;
    }
    .product-details-content.product-details-content-v2 .box-details-info {
        padding-left: 0px;
        padding-right: 0px;
    }
    #description .row {
        margin: 0px;
    }
    .mockup-v2 .col-md-6 .text {
        margin-top: -5px;
        padding-top: 30px;
        padding-left: 20px;
    }
    .banner-product-details3 h3 {
        font: 400 30px/30px "Oswald";
    }
    .container-ver2 .product-details-content .col-md-6:last-child {
        padding: 0px;
    }
    .container-ver2 .product-details-content .box-details-info {
        padding: 0px;
    }
    .container-ver2 .product-details-content .box-detalis-v2 .options {
        padding: 0px;
    }
    .blog-classic #secondary.col-md-3,
    .blog-classic #primary.col-md-9 {
        width: 100%;
        padding: 0px;
    }
    .blog-post-container .blog-post-item .blog-post-image iframe {
        max-width: 100%;
    }
    .form-horizontal .form-group.col-md-6 {
        padding: 0px !important;
    }
    #footer {
        text-align: center;
    }
    #footer .logo-footer,
    #footer .menu-footer,
    #footer .social {
        margin: auto;
        width: 100%;
        float: initial;
        margin-bottom: 20px;
        text-align: center;
    }
    #footer .menu-footer li a {
        margin: 0 10px;
    }
    #footer.footer-v3 {
        padding-left: 0px;
        padding-right: 0px;
        text-align: left;
        margin-bottom: 0px;
    }
    #footer.footer-v3 .footer-inner {
        padding-left: 15px;
        padding-right: 15px;
    }
    #footer.footer-v3 .footer-inner .col-md-3,
    #footer.footer-v3 .footer-inner .col-md-2 {
        margin-bottom: 30px;
    }
    #footer.footer-v3 .social {
        text-align: left;
    }
    .blog-masonry .inner-por-masonry .grid-item {
        height: auto;
        width: 50%;
    }
    .blog-masonry .inner-por-masonry .grid-item.width-2x {
        height: auto;
        width: 100%;
    }
    .blog-masonry .inner-por-masonry .grid-item h3,
    .blog-masonry .inner-por-masonry .grid-item h4,
    .blog-masonry .inner-por-masonry .grid-item p {
        position: absolute;
        bottom: 40px;
        left: 0px;
    }
    .blog-masonry .inner-por-masonry .grid-item h3 {
        bottom: 50px;
    }
    .blog-masonry .inner-por-masonry .grid-item p {
        bottom: 60px;
    }
    .blog-masonry .inner-por-masonry .grid-item:hover:after {
        opacity: 0;
    }
    .blog-masonry .inner-por-masonry .grid-item:hover h3,
    .blog-masonry .inner-por-masonry .grid-item:hover h4 {
        transform: translate(0, 0px);
    }
    .blog-masonry .inner-por-masonry .grid-item:hover p {
        transform: translate(0, 0px);
    }
    ul.bastian li {
        width: 100%;
    }
    ul.bastian li:first-child {
        border-right: 1px solid #000;
        border-bottom: none;
    }
    ul.bastian li:last-child {
        border-left: 1px solid #000;
        border-top: none;
    }
    .control-page a.title {
        font: 400 30px/30px "Gotham";
    }
    .popup-content-wrapper {
        margin-top: 50px;
    }
    .popup-content-wrapper .close-popup {
        top: 20px;
    }
    .popup-content-wrapper h3 {
        margin-top: 80px;
        margin-bottom: 15px;
    }
    .popup-content-wrapper h4 {
        font-size: 25px;
        line-height: 30px;
    }
    .popup-content-wrapper p {
        font-size: 12px;
        line-height: 15px;
    }
    .popup-content-wrapper button {
        margin-top: 15px;
    }
    #quickview-content .product-details-content .options .action {
        max-width: 330px;
    }
    #quickview-content .product-details-content .options .action form {
        margin-top: 10px;
        margin-right: 10px;
    }
    #quickview-content .product-details-content .options .action a.link-ver1 {
        margin-left: 0px;
        margin-right: 10px;
        margin-top: 10px;
    }
    .banner-top-home3 .items {
        width: 50%;
        float: left;
        max-width: 400px;
    }
    .banner-top-home3 .items:last-child {
        float: right;
    }
    .products .product .action a {
        transform: translate(0px, 0px);
        opacity: 1;
    }
    .special-content {
        padding-left: 20px;
        text-align: center;
    }
    .special-content h5:after {
        left: 0px;
        right: 0px;
        margin: auto;
    }
    .special-content .time {
        text-align: center;
    }
    .head-about .box {
        margin: 0px;
        text-align: center;
    }
    .head-about .box img {
        margin: auto;
    }
    .special {
        text-align: center;
    }
    .special .col-md-5,
    .special .col-md-7 {
        float: inherit;
        text-align: center;
    }
    .special .images-logo {
        margin: auto;
    }
    .special-content .time {
        margin: auto;
        padding-top: 10px;
    }
    .special-content .link-v1 {
        margin-left: 0px;
        float: inherit;
    }
    .choose-us .col-md-3 {
        text-align: center;
    }
    .choose-us .text {
        margin-bottom: 20px;
    }
    .page-contact .head {
        margin-top: 0px;
        padding: 15px;
    }
    .page-contact .head .item {
        padding: 0px;
        margin: 10px 0px;
    }
    .page-contact .head .item:nth-child(2),
    .page-contact .head .item:nth-child(3) {
        padding-left: 0px;
    }
    .page-contact .head .item .text p {
        font-size: 14px;
        padding-left: 8px;
    }
    .cart-box-container .cart-total .col-md-4 {
        padding-right: 0px;
    }
    .choose-us-home2 {
        text-align: center;
    }
    .special.special-v2 .special-content {
        margin: auto;
    }
    #header.header-v1.header-v4 #topbar .inner-topbar {
        padding: 10px 0px 0px;
    }
    .hidden-table {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .banner-home2-top {
        margin-top: 30px;
    }
    .banner-home2-top .col-md-3 {
        width: 50%;
    }
    .banner-home2-top .col-md-6 {
        float: left;
        width: 100%;
        padding: 0px;
        margin-top: 25px;
    }
    #primary {
        float: left;
        width: 100%;
        padding: 0px;
    }
    #secondary {
        padding: 0px;
        margin-top: 30px;
    }
    .banner-home3-bottom .col-md-8 {
        padding: 0px;
        margin-bottom: 30px;
    }
    .banner-home3-bottom .col-md-4 {
        padding: 0px;
    }
    .col-md-6.padding-left-0 {
        padding: 0px;
    }
    .col-md-6.padding-right-0 {
        padding: 0px;
    }
}

@media (max-width: 767px) {
    .tp-banner-container .tp-leftarrow {
        margin-top: -40px !important;
    }
    .tp-banner-container .tp-rightarrow {
        margin-top: -40px !important;
    }
    .tp-banner-container span {
        font-size: 12px;
        line-height: 20px;
    }
    .title-text h3 {
        font-size: 20px;
    }
    .title-text p {
        line-height: 20px;
    }
    .title-text::before {
        top: 17%;
    }
    .slidershow-container {
        margin-bottom: 20px;
    }
    .brand-container ul li {
        width: 33.33%;
        border-bottom: 1px solid #eeeeee;
    }
    .brand-container ul li:first-child,
    .brand-container ul li:nth-child(2) {
        width: 50%;
    }
    .brand-container ul li:nth-child(2) {
        border-right: 1px solid #eeeeee;
    }
    .blog-classic .blog-post-container.single-post .blog-post-item .head h3 {
        font-size: 20px;
        line-height: 30px;
    }
    .blog-classic .blog-post-container.single-post .blog-post-item .text {
        width: calc(100% - 10px);
        padding: 15px;
    }
    .blog-classic .blog-post-container.single-post .blog-post-item .text .content {
        padding: 15px;
    }
    .blog-post-container .comments {
        padding: 30px 15px;
    }
    .blog-classic .wrap-sidebar {
        padding: 30px 15px;
    }
    .mockup h2 {
        font-size: 30px;
    }
    .title-text.title-text-v2.title-text-v3 h3 {
        font-size: 24px;
    }
    #topbar .social {
        display: none;
    }
    #topbar .dropdown .btn {
        line-height: 40px;
    }
    #header .cart .icon-cart {
        height: auto;
    }
    #header .search i {
        height: auto;
    }
    #header .topbar-right {
        width: 100%;
        text-align: center;
    }
    #header.absolute {
        position: relative;
    }
    #header.absolute .header-top {
        padding: 0px;
        margin: 0px;
    }
    #header.absolute .float-left {
        padding-left: 0px;
    }
    #secondary {
        display: inline-block;
        width: 100%;
    }
    .mega-menu .navbar-header,
    .mega-menu ul.navbar-nav {
        margin: 0px;
    }
    .hoz-tab-container.ver2 .tabs li {
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    #topbar {
        height: 40px;
    }
    #topbar .dropdown .btn {
        height: 40px;
        padding: 0 10px;
    }
    #topbar .topbar-right .sign-in {
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
    }
    #header .header-top {
        padding: 10px 0px 0px;
        margin-top: -23px;
        margin-bottom: 20px;
    }
    #header .header-top .logo-mobile {
        margin-top: 13px;
    }
    #header .header-top .logo-mobile img {
        width: 130px;
    }
    #header .header-top .col-md-10,
    #header .header-top .col-md-2 {
        width: auto;
    }
    #header .header-top .col-md-2 {
        float: right;
        margin-top: 8px;
    }
    #header .header-top .icon-menu-mobile {
        float: left;
        width: auto;
        margin-top: 6px;
        margin-right: 10px;
    }
    .mega-menu ul.navbar-nav {
        margin-top: 20px;
        margin-bottom: 15px;
    }
    .bg-slider-one-item {
        background-size: auto 100%;
    }
    #footer .menu-footer li {
        line-height: 30px;
    }
    #footer .menu-footer li a {
        margin: 0px 10px;
        font-size: 10px;
    }
    #footer .social a {
        margin: 0 10px;
    }
    .cart-list ul.list {
        overflow: scroll;
    }
    .cart-list ul.list li {
        padding: 10px 0px;
    }
    .blog-slider .blog-post-item .blog-post-content .cat {
        margin: 10px 0px;
        margin-top: 20px;
    }
    .blog-slider .blog-post-item .blog-post-content .cat:after {
        bottom: -7px;
    }
    .blog-slider .blog-post-item .blog-post-content .blog-title {
        font-size: 20px;
    }
    .blog-slider .blog-post-item .blog-post-content .comment {
        font-size: 10px;
    }
    .products .product .action a {
        transform: translate(0px, 0px);
        opacity: 1;
    }
    .products.grid_full.list-item .item-inner .product .product-title {
        font-size: 25px;
        margin-bottom: 0px;
    }
    .products.grid_full.list-item .item-inner .product .product-price-old {
        margin-left: 20px;
        font-size: 20px;
    }
    .products.grid_full.list-item .item-inner .product .product-price {
        font-size: 22px;
    }
    .products.grid_full.list-item .item-inner .product .description {
        font-size: 14px;
        line-height: 20px;
        margin-top: 0px;
    }
    .products.grid_full.list-item .item-inner .product .action {
        padding-left: 0px;
        width: 100%;
    }
    .products.grid_full.list-item .item-inner .product .social {
        width: 100%;
        padding-left: 0px;
    }
    #header #topbar.topbar-v3 {
        padding: 0px;
    }
    #header #topbar.topbar-v3 .social {
        display: inline-block;
        margin-top: 0px;
    }
    #header #topbar.topbar-v3 .topbar-right {
        width: auto;
        text-align: right;
    }
    #header.header-v6 #topbar.topbar-v3 {
        margin: 15px 0px;
    }
    #header.header-v3 #topbar .float-left p {
        display: none;
    }
    .special.home3-special .special-content .time {
        margin-left: 0px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .shipping-v2 {
        padding: 25px 0px;
    }
    .shipping-v2 .col-md-3 {
        margin-bottom: 20px;
        padding: 0px 8px;
    }
    #header.header-v4 #topbar {
        padding: 3px;
    }
    .banner-home8-top .col-md-3 {
        width: 100%;
    }
    .time .distance {
        width: 100px;
    }
    .slider-loobook2 .slick-prev,
    .slider-loobook2 .slick-next {
        width: 100px;
    }
    .slider-loobook2 .items .text {
        left: 30px;
        right: 30px;
        padding-top: 30px;
        bottom: -35px;
    }
    .slider-loobook2 .items .text h3 {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .slider-loobook2 .items .text p {
        font-size: 12px;
        margin-bottom: 20px;
    }
    .banner-header.banner-lbook3 .text {
        height: 100px;
        margin-top: -70px;
    }
    .banner-header.banner-lbook3 .text h3 {
        font-size: 40px;
        line-height: 30px;
        margin-bottom: 5px;
    }
    .blog-post-container-v4 .blog-post-content,
    .blog-post-container-v4 .blog-post-image {
        transform: translate(0px, 0px);
        opacity: 1;
    }
    .girllook .col-md-6 {
        padding: 0px;
    }
    .slider-full-screen2 .slider-one-item .product .text {
        top: 60px;
    }
    .slider-full-screen2 .slider-one-item .product .text .product-content {
        margin: 0px;
    }
    .slider-full-screen2 .slider-one-item .product .text .price {
        margin: 0px;
    }
    .wrap-breadcrumb .breadcrumb {
        width: 100%;
        text-align: center;
    }
    .wrap-breadcrumb .ordering-action {
        margin: 10px auto;
        width: 100%;
    }
    .wrap-breadcrumb .ordering {
        float: initial;
        margin: 0 auto 20px;
    }
    #secondary.col-md-3 {
        padding-right: 0px;
    }
    .mockup-v2 .mockup-center {
        width: 100%;
        text-align: center;
    }
    .mockup-v2 .mockup-center .col-md-4 {
        margin-bottom: 30px;
    }
    .mockup-v2 .mockup-center .col-md-4:last-child() {
        margin-bottom: 0px;
    }
    .product-details-content .product-name h1 {
        margin-top: 30px;
        font-size: 30px;
        line-height: 35px;
    }
    .product-details-content p {
        font-size: 16px;
        line-height: 25px;
    }
    .product-details-content .col-md-6 {
        padding: 0px;
    }
    .product-details-content .wrap-price p {
        margin-bottom: 0px;
    }
    .product-details-content .social i {
        margin: 0px 8px;
    }
    .mockup .col-md-6 {
        height: auto;
        padding-bottom: 30px;
        overflow: hidden;
    }
    .mockup .col-md-6 .wrap-text .text {
        padding-top: 15px;
    }
    .mockup-v2 .col-md-6 {
        height: auto;
        overflow: hidden;
        padding-bottom: 30px;
    }
    .mockup-v2 .col-md-6 .text .box-icons p {
        font: 300 12px/15px "Oswald";
    }
    .mockup-v2 .col-md-6 .text .box-icons p .icons {
        height: 50px;
        width: 50px;
        line-height: 50px;
        font-size: 20px;
        margin-right: 10px;
    }
    .banner-product-details3 h3 {
        font: 400 14px/30px "Oswald";
    }
    .blog-masonry .grid-sizer,
    .blog-masonry .grid-item {
        width: 50%;
    }
    .blog-masonry {
        margin: 0px;
    }
    .banner-header.banner-lbook3.single-post .text h3 {
        font: 400 30px/30px "Gotham";
    }
    .wrap-googlemap .col-md-6 {
        padding: 0px;
    }
    #footer .newsletter-footer form {
        width: 100%;
    }
    #footer .footer-top .col-md-6 {
        padding-left: 5px;
        padding-right: 5px;
    }
    #footer.footer-v3 .bottom {
        text-align: center;
    }
    #footer.footer-v3 .bottom p {
        margin-bottom: 15px;
        width: 100%;
    }
    ul.tabs.tabs-title.tabs-title-por li {
        padding: 0 15px;
    }
    .control-page a.title {
        font: 400 20px/20px "Gotham";
    }
    .control-page .box-icon {
        height: 40px;
        width: 40px;
        margin-left: -20px;
    }
    .control-page .box-icon i {
        line-height: 40px;
        font-size: 15px;
    }
    .popup-content {
        overflow-y: scroll;
    }
    .popup-content .popup-container .images {
        width: 100%;
        text-align: center;
    }
    .popup-content .popup-container .images img {
        margin: 0 auto;
    }
    .popup-content-wrapper.popup-content-wrapper-v2 {
        max-width: 100%;
        border-radius: 0px;
    }
    .quickview-wrapper {
        overflow-y: scroll;
    }
    .quickview-wrapper .quick-modal {
        margin-top: 20px;
    }
    .quickview-wrapper #quickview-content .product .product-images,
    .quickview-wrapper #quickview-content .product .product-info {
        width: 100%;
        padding: 0px;
    }
    .head-about .text {
        padding: 15px;
    }
    .head-about .text h3 {
        font-size: 22px;
        line-height: 25px;
    }
    .about-choose h3 {
        font-size: 30px;
        line-height: 35px;
    }
    .about-choose .border p {
        padding: 0px;
        font-size: 14px;
        line-height: 20px;
    }
    .our-team .head h3 {
        font-size: 30px;
        line-height: 35px;
    }
    .page-contact .content-text h3 {
        font-size: 30px;
        line-height: 35px;
    }
    table.table.cart-table td.product-quantity input {
        width: 80px;
    }
    table.table.cart-table td.product-quantity .quantity .plus {
        left: 60px;
    }
    .mega-menu + div {
        margin-top: -20px !important;
    }
    #header.header-v3-new.header-v3 .header-top {
        padding: 15px 0px 0px;
        margin: 0px;
    }
    #header.header-v3-new.header-v3 .header-top .cart {
        margin-top: 0px;
    }
    #header.header-v4 .float-left {
        display: none;
    }
}

@media (max-width: 467px) {
    #topbar .topbar-right a {
        display: inline-block;
        border: none;
    }
    #topbar .topbar-right .sign-in {
        width: 100%;
        text-align: right;
        padding: 0px;
    }
    #topbar .topbar-right .sign-in a:nth-child(1) {
        float: left;
        padding: 0px;
    }
    #topbar .topbar-right .sign-in a:nth-child(1) span {
        display: inline-block;
    }
    #topbar .topbar-right span {
        display: none;
    }
    .modal-body {
        padding: 0 20px;
    }
    button.button_search {
        width: 80px;
    }
    #header .header-top {
        display: inline-block;
        width: 100%;
    }
    #header .header-top .cart {
        margin-left: 10px;
        padding-left: 10px;
    }
    #header .header-top .settings {
        margin-left: 10px;
    }
    #header .header-top .settings .submenu {
        right: -42px;
        width: 290px;
    }
    #header .header-top .settings .submenu ul.language li img {
        width: 55px;
    }
    #header .header-top .search {
        margin-left: 0px;
    }
    #header .search .search-form {
        right: -15px;
    }
    #header .cart .cart-list {
        width: 290px;
    }
    #header.header-v3 .box-right {
        width: auto;
    }
    #header.header-v3.header-v2 .box-right {
        width: auto;
        padding-right: 10px;
    }
    .related-products .product-details {
        padding: 15px;
    }
    .related-products .product-details:after {
        right: 0px;
    }
    .newsletter-bottom h3 {
        font-size: 20px;
    }
    .slider-product .tabs li {
        font-size: 12px;
    }
    .shipping-v2 {
        background-size: auto 100%;
    }
    .newsletter-home4 .wrap-center.float-left .items .text {
        top: 10px;
    }
    .newsletter-home4 .wrap-center.float-left .items .text i {
        margin-bottom: 10px;
    }
    .newsletter-home4 .wrap-center.float-left .items .text h3 {
        font-size: 18px;
    }
    .newsletter-home4 .wrap-center.float-left .items .text p {
        padding: 0 15px;
        line-height: 15px;
        font-size: 10px;
        margin-top: 5px;
    }
    .newsletter-home4 .wrap-center.float-left .items form {
        height: 45px;
    }
    .newsletter-home4 .wrap-center.float-left .items form button {
        padding: 0 20px;
    }
    .check-billing .table tr th:nth-child(2) {
        display: none;
    }
    .check-billing .table tr td:nth-child(2) {
        display: none;
    }
    .acc-login .form-group .btn:nth-child(2n) {
        width: 100%;
        margin-left: 0px;
        margin-top: 20px;
    }
    .acc-login .form-group .btn:nth-child(2n + 1) {
        width: 100%;
        margin: 0px;
    }
    .products.grid_full .item-inner,
    .products.grid_full.grid_sidebar .item-inner {
        padding: 0px !important;
    }
    ul.tabs.title span {
        display: none;
    }
    ul.tabs.title li {
        font-size: 18px;
    }
    .newsletter .col-md-7 .newsletter-content form {
        height: 40px;
    }
    .newsletter .col-md-7 .newsletter-content form input {
        height: 40px;
        padding: 0 100px 0 15px;
    }
    .newsletter .col-md-7 .newsletter-content form button {
        height: 40px;
        width: 100px;
    }
    .pushmenu {
        width: 320px;
    }
    .pushmenu-left {
        right: -320px;
    }
    .pushmenu-push-toright {
        right: 320px;
    }
    .cart-list ul.list li {
        padding: 10px 0px;
    }
    .shipping-container .col-md-4 i {
        font-size: 16px;
    }
    .shipping-container .col-md-4 h3 {
        letter-spacing: 2px;
        font-size: 11px;
    }
    .slider-product .tabs li {
        font-size: 11px;
        margin: 0 0 15px;
        padding: 0 10px;
    }
    .slider-product .tabs li:after {
        right: -4px;
    }
    .cart-list li a.delete-item {
        position: relative;
        display: inline-block;
        top: 12px;
    }
    .slider-one-item-home3 .product .text {
        width: 300px;
        height: 100px;
        margin-left: -150px;
        margin-top: -50px;
    }
    .slider-one-item-home3 .product .text h4 {
        margin-bottom: 5px;
        font-size: 10px;
        margin-top: 10px;
    }
    .slider-one-item-home3 .product .text h3 {
        font-size: 20px;
        margin-bottom: 10px;
        line-height: 20px;
        margin-bottom: 0px;
    }
    .slider-one-item-home3 .product .text p {
        display: none;
    }
    .slider-one-item-home3 .product .text button {
        font-size: 8px;
        padding: 0 10px;
        line-height: 21px;
        height: 25px;
    }
    .tab-product-all .products .item-inner {
        width: 100%;
    }
    .tab-product-all .products .item-inner:nth-child(3n),
    .tab-product-all .products .item-inner:nth-child(3n + 1),
    .tab-product-all .products .item-inner:nth-child(3n + 2) {
        padding: 0px !important;
    }
    .testimonial p {
        line-height: 25px;
        font-size: 14px;
    }
    .banner-private.center .text h2 {
        font-size: 30px;
        line-height: 30px;
    }
    .banner-private.center .text p {
        display: none;
    }
    .title-text-v2 h3 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .special.special-v2 .special-content h5 {
        font-size: 16px;
    }
    .special-content h3 {
        font-size: 30px;
        line-height: 30px;
    }
    .special-content h5 {
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .special-content .time .countdown-times .distance {
        width: 70px;
        height: 55px;
    }
    .special-content .time .countdown-times .distance.hours .text {
        margin-left: 0px;
    }
    .special-content .time .countdown-times .distance .number {
        font-size: 28px;
    }
    .special-content .time .countdown-times .distance .text {
        font-size: 14px;
        text-align: center;
    }
    .home3-special .col-md-6 {
        padding: 0px;
    }
    .home3-special.special .special-content .time {
        padding: 15px 0px;
    }
    .home3-special.special .special-content .time .distance {
        width: 70px;
    }
    .home3-newsletter-content {
        padding: 30px;
        margin-bottom: 30px;
    }
    .special-content {
        padding-left: 0px;
    }
    .newsletter-home4 .col-md-4:nth-child(3n + 2) .items .text h3,
    .newsletter-home4 .col-md-4:nth-child(3n + 2) .items .text p {
        display: none;
    }
    .newsletter-home4 .col-md-4:nth-child(3n + 2) .items form input {
        font-size: 10px;
        padding: 0 60px 0 10px;
    }
    .newsletter-home4 .col-md-4:nth-child(3n + 2) .items form button {
        padding: 0 20px;
    }
    #header.header-v4 #topbar .topbar-left .topbar-title,
    #header.header-v4 #topbar .topbar-left a {
        border: none;
        font-size: 9px;
        width: 32%;
    }
    #header.header-v4 #topbar .topbar-left .topbar-title i,
    #header.header-v4 #topbar .topbar-left a i {
        font-size: 12px;
    }
    .banner-home6 .col-md-6 .items .text {
        left: 20px;
    }
    .newsletter-home6 .newsletter-content form {
        max-width: 250px;
    }
    .banner-home7-top.banner-private .text {
        top: 15px;
    }
    .banner-home7-top.banner-private .text img {
        width: 30px;
        margin-bottom: 10px;
    }
    .banner-home7-top.banner-private .text h3 {
        font-size: 20px;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .banner-home7-top.banner-private .text p {
        display: none;
    }
    .instafeed .items {
        width: 100%;
    }
    .instafeed .items:nth-child(4),
    .instafeed .items:nth-child(5) {
        width: 100%;
    }
    .banner-hom7-center .col-md-6 {
        display: inline-block;
    }
    .banner-hom7-center .banner-private-v2 .text {
        width: 300px;
        height: 100px;
        margin-left: -150px;
        margin-top: -50px;
    }
    .banner-hom7-center .banner-private-v2 .text h4 {
        margin-bottom: 5px;
        font-size: 10px;
        margin-top: 10px;
    }
    .banner-hom7-center .banner-private-v2 .text h3 {
        font-size: 20px;
        margin-bottom: 10px;
        line-height: 20px;
        margin-bottom: 0px;
    }
    .banner-hom7-center .banner-private-v2 .text p {
        display: none;
    }
    .banner-hom7-center .banner-private-v2 .text button {
        font-size: 8px;
        padding: 0 10px;
        line-height: 21px;
        height: 25px;
    }
    #header.header-v7 #topbar .topbar-title span {
        display: none;
    }
    .banner-home8-top.banner-home8-center .text h3 {
        font-size: 30px;
        line-height: 30px;
    }
    .slider-slick-home8 .slick-next {
        right: 0px;
    }
    .slider-slick-home8 .slick-prev {
        /* left: 0px; */
    }
    .best-selling .col-md-4 .products {
        padding-left: 15px;
        padding-right: 15px;
    }
    .best-selling .col-md-4 .products .product-images {
        margin: 0px;
        max-width: 100%;
    }
    .product-img-box .thumb-content li {
        max-width: 80px;
    }
    .product-details-content .options .action a.link-ver1 {
        margin-left: 0px;
        margin-right: 10px;
    }
    .product-details-content .options .action form {
        margin-bottom: 20px;
    }
    .upsell-product.products {
        margin: 0px;
    }
    .mockup .col-md-6 .wrap-text .text h3 {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 10px;
    }
    .mockup .col-md-6 .wrap-text .text p.title {
        margin-bottom: 10px;
    }
    .mockup .col-md-6 .wrap-text .text p {
        font-size: 12px;
        margin-bottom: 20px;
    }
    .slider-loobook2 .items .text h3 {
        font-size: 20px;
        line-height: 20px;
    }
    .slider-loobook2 .items .text p {
        font-size: 10px;
    }
    .tile-lookbook-v2 p {
        font-size: 12px;
        line-height: 18px;
    }
    .banner-header .text {
        height: 80px;
        margin-top: -40px;
        padding: 10px 30px;
    }
    .banner-header .text h3 {
        font-size: 14px;
        line-height: 15px;
    }
    .banner-header .text p {
        font-size: 10px;
    }
    .banner-header .text p i {
        font-size: 12px;
        margin: 0 10px;
    }
    .banner-header.banner-lbook3 img {
        min-height: 100px;
    }
    .banner-header.banner-lbook3 .text {
        height: 60px;
        margin-top: -30px;
    }
    .banner-header.banner-lbook3 .text h3 {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 5px;
        padding-bottom: 0px;
    }
    .banner-header.banner-lbook3 .text p {
        font-size: 9px;
    }
    .banner-header.banner-lbook3 .text p i {
        font-size: 12px;
        margin: 0 10px;
    }
    .banner-header.banner-lbook3 .text img {
        width: 100px;
    }
    .coupon .form-horizontal .control-label {
        display: none;
    }
    .coupon .form-control {
        padding-left: 10px;
        padding-right: 100px;
    }
    .coupon button {
        padding: 0px;
        font-size: 10px;
        width: 100px;
    }
    .boxed-content.boxed-content-v2 {
        padding: 20px 20px 20px 45px;
    }
    .login-box-container .form-horizontal .col-xs-6 {
        width: 100%;
        padding: 0px;
    }
    .login-box-container .link-button-fb {
        margin-bottom: 30px;
    }
    .tab-v1 .products .item-inner {
        width: 100%;
    }
    .banner-home3-lookbook1 .text h3 {
        font-size: 25px;
        letter-spacing: 15px;
    }
    .banner-home3-lookbook1 .text p {
        font-size: 10px;
    }
    .title-text h3 {
        font-size: 15px;
    }
    .banner-video .text {
        top: 30%;
    }
    .banner-video .text .icons {
        height: 80px;
        width: 80px;
        background-size: 100% auto;
    }
    .banner-video .text h3 {
        font: 400 15px/20px "Oswald";
        letter-spacing: 10px;
    }
    .banner-home-collec1.related-products .product-details .text {
        left: 20px;
        top: 20px;
        max-width: 400px;
    }
    .banner-home-collec1.related-products .product-details h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .banner-home-collec1.related-products .product-details h4 {
        font-size: 10px;
        margin-bottom: 5px;
    }
    .banner-home-collec1.related-products .product-details h4:after {
        position: static;
        height: 0px;
    }
    .banner-home-collec1.related-products .product-details .product-content {
        display: none;
    }
    .slider-full-screen2 .bx-wrapper {
        width: 290px;
    }
    .slider-full-screen2 .slider-one-item .product .text {
        top: 30px;
        left: 30px;
    }
    .slider-full-screen2 .slider-one-item .product .text .product-content {
        display: none;
    }
    .slider-full-screen2 .slider-one-item .product .text h3 {
        font-size: 25px;
        margin-bottom: 10px;
    }
    .slider-full-screen2 .slider-one-item .product .text .price {
        font-size: 25px;
    }
    .wrap-breadcrumb .ordering {
        text-align: center;
    }
    .wrap-breadcrumb .ordering .float-left {
        margin-bottom: 10px;
    }
    .wrap-breadcrumb .ordering .float-right {
        width: 100%;
        text-align: left;
    }
    .wrap-breadcrumb .ordering p.result-count {
        margin: 0px;
    }
    .wrap-breadcrumb .ordering .order-by {
        margin: 0px;
    }
    .products.grid_full.list-item .item-inner .product-images,
    .products.grid_full.list-item .item-inner .product-title,
    .products.grid_full.list-item .item-inner .product-price,
    .products.grid_full.list-item .item-inner .description {
        width: 100%;
        padding: 0px;
    }
    .products.grid_full.list-item .item-inner .product-title {
        margin-top: 20px;
    }
    .products.grid_full.list-item .item-inner .product .product-price-old {
        margin-left: 0px;
    }
    .mockup-v2 .col-md-6 .text {
        padding-left: 15px;
        padding-right: 15px;
    }
    .mockup-v2 .col-md-6 .text h4 {
        font: 300 12px/15px "Oswald";
    }
    .mockup-v2 .col-md-6 .text h3 {
        font: 400 20px/20px "Gotham";
    }
    .mockup-v2 .col-md-6 .text .box-icons p {
        width: 100%;
    }
    .product-details-content.product-details-content-v2 .box-detalis-v2 .options {
        padding-bottom: 15px;
    }
    .product-details-content .box-detalis-v2 .options .infomation {
        padding-bottom: 50px;
    }
    .banner-product-details3 h3 {
        font: 400 12px/20px "Oswald";
    }
    .blog-post-container .blog-post-item .blog-post-image iframe {
        max-height: 200px;
    }
    .blog-masonry .grid-sizer,
    .blog-masonry .grid-item {
        width: 100%;
    }
    .blog-masonry .grid-item {
        padding: 0px;
    }
    .media-left,
    .media-right,
    .media-body {
        display: inline;
    }
    .tab-product-all.tab-product-all-v2.tab-product-all-v3.tab-por .products .item-inner {
        width: 100%;
        padding: 0px !important;
    }
    ul.tabs.tabs-title.tabs-title-por li {
        line-height: 40px;
    }
    .blog-masonry .inner-por-masonry .grid-item {
        height: auto;
        width: 100%;
    }
    .title-page.size-50 h3 {
        font-size: 30px;
        line-height: 30px;
    }
    .banner-top-home3 .items {
        width: 100%;
        float: left;
        max-width: 400px;
    }
    .banner-top-home3 .items:last-child {
        float: right;
    }
    .page-login h3 {
        font-size: 20px;
    }
    .page-login p {
        font-size: 14px;
    }
    .page-login .social a {
        width: 100%;
    }
    .page-login .social a.float-left {
        margin-bottom: 20px;
    }
    .page-login form label.label-check {
        font-size: 16px;
    }
    .page-login .remember .help {
        font-size: 15px;
    }
    .cart-total .item {
        padding: 15px;
    }
    .wrap-breadcrumb .ordering .float-left {
        width: 100%;
    }
    .wrap-breadcrumb .ordering .float-left .col,
    .wrap-breadcrumb .ordering .float-left .list {
        float: left;
    }
    .wrap-breadcrumb .ordering .float-left .result-count {
        float: right;
        margin-top: 12px;
    }
    table.table.cart-table thead th {
        text-align: center;
        font-size: 12px;
    }
}
